import { Fade, Grid } from "@mui/material"
import { Form, Formik, FormikValues } from "formik"
import { useState } from "react"
import { ExpiredDocumentError } from "../../errors/ExpiredDocumentError"
import { UncategorizedDocumentError } from "../../errors/UncategorizedDocumentError"
import { UndatedDocumentError } from "../../errors/UndatedDocumentError"
import useAccreditationDocumentManager from "../../hooks/useAccreditationDocumentManager"
import useAccreditationManager from "../../hooks/useAccreditationManager"
import useAccreditationRecordManager, { Accreditation } from "../../hooks/useAccreditationRecordManager"
import useParamId from "../../hooks/useParamId"
import useToast from "../../hooks/useToast"
import { Actions, Subjects } from "../../services/ability"
import { truncate } from "../../services/utils"
import { OperationResult } from "../../types/types"
import AccreditationDocuments from "../AccreditationDocuments/AccreditationDocuments"
import accreditationFormInit from "../AccreditationInfo/AccreditationFormInit"
import AccreditationInfo from "../AccreditationInfo/AccreditationInfo"
import AccreditationViewMoreActions from "../AccreditationViewMoreActions/AccreditationViewMoreActions"
import ActionsBar from "../ActionsBar/ActionsBar"
import BackButton from "../BackButton/BackButton"
import CustomModal from "../CustomModal/CustomModal"
import DocumentPreviewer from "../DocumentPreviewer/DocumentPreviewer"
import { SelectedDocument, SelectedDocumentRow } from "../OrganizationDocumentsActions/OrganizationDocumentsActions"
import Permission from "../Permission"
import SaveIconButton from "../SaveIconButton/SaveIconButton"
import styles from './AccreditationView.module.scss'
import inSubmittedDocumentState from "./Helpers/inSubmittedDocumentState"

export default function AccreditationView() {

    const id = useParamId()

    const { accreditation, accreditationLoading, accreditationRefetch } = useAccreditationRecordManager({ id })
    const { updateAccreditation } = useAccreditationManager()
    const { deleteAccreditationDocument, createAccreditationDocument, importAccreditationDocuments } = useAccreditationDocumentManager()
    const { successToast, errorToast, infoToast } = useToast()

    const [previewFile, setPreviewFile] = useState<any | null>(null)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const { initial, schema } = accreditationFormInit(accreditation)

    const currentState = accreditation?.concessionOrganizationAccreditationState?.id?.toString()
    const disableUpdateDocuments = accreditation?.concessionOrganizationAccreditationState?.code !== "PREPARATION"

    const handleDeleteDocument = async (id: string) => {
        const result = await deleteAccreditationDocument(id)
        if (result?.success) accreditationRefetch()
    }

    const beforeAttachDocuments = (selection: SelectedDocumentRow[]) => {
        const isCategorized = selection.every(row => row?.concessionOrganizationDocumentSubcategory)

        if (!isCategorized) {
            throw new UncategorizedDocumentError("Erro! Não é possível associar documentos não categorizados")
        }

        const isExpired = selection.every(row => row?.expiresAt && row?.expiresAt < new Date())

        if (isExpired) {
            throw new ExpiredDocumentError("Erro! Não é possível associar documentos expirados")
        }

        const isDated = selection.every(row => row?.expiresAt || !row?.concessionOrganizationDocumentSubcategory?.expirationRequired)

        if (!isDated) {
            throw new UndatedDocumentError("Erro! Não é possível associar documentos sem data de expiração obrigatória")
        }
    }

    const beforeExpiration = (selection: SelectedDocumentRow[]) => {
        if (!inSubmittedDocumentState(selection)) {
            throw new Error("Erro! Apenas é possível atualizar data de expiração de documentos no estado submetido")
        }
    }

    const beforeCategorization = (selection: SelectedDocumentRow[]) => {
        if (!inSubmittedDocumentState(selection)) {
            throw new Error("Erro! Apenas é possível atualizar subcategoria de documentos no estado submetido")
        }
    }

    const beforeDelete = (selection: SelectedDocumentRow[]) => {
        if (!inSubmittedDocumentState(selection)) {
            throw new Error("Erro! Apenas é possível remover documentos no estado submetido")
        }
    }


    const handleAttachDocuments = async (selectedDocuments: SelectedDocument[]) => {

        setIsOpen(false)

        if (id) {
            for await (const document of selectedDocuments) {
                const displayName = truncate(document?.name)
                const response = await createAccreditationDocument(
                    {
                        concessionOrganizationAccreditationId: id,
                        concessionOrganizationDocumentId: document.id
                    })
                if (response.success) {
                    successToast(`Documento ${displayName} associado com sucesso`)
                } else {
                    if (response?.errors?.includes("Chave duplicada")) {
                        errorToast(`Não é possível associar o documento ${displayName} múltiplas vezes`)
                    }
                }
            }
            await accreditationRefetch()
        }
    }

    const handleResponse = (response: OperationResult<Accreditation>) => {
        if (response.success) {
            successToast("Acreditação atualizada")
            accreditationRefetch()
        } else {
            response.errors?.map(error => errorToast(error))
        }
    }

    const handleChangeState = async (stateTo: string) => {
        if (id) {
            const response = await updateAccreditation({ id, state: stateTo })
            handleResponse(response)
        } else {
            console.error("Error! Accreditation id is undefined")
        }
    }

    const handleSubmit = async (values: FormikValues) => {
        const { id, observations, startDate, endDate } = values
        const response = await updateAccreditation({
            id, observations, startDate, endDate
        })
        handleResponse(response)
    }

    const handleImport = async (originAccreditationId: string) => {
        if (accreditation?.id) {
            const destinationAccreditationId = accreditation.id
            const response = await importAccreditationDocuments(originAccreditationId, destinationAccreditationId)
            if (response.success) {
                if (response?.data?.length) {
                    const documents = response?.data?.join(", \n")
                    const message = `Os documentos ${documents} não foram importados porque já fazem parte da atual documentação`
                    infoToast(message)
                } else {
                    successToast("Documentos importados")
                }

                accreditationRefetch()
            } else {
                if (response?.errors?.length) {
                    errorToast(response?.errors.join(", "))
                }
            }
        }
    }

    const hasDocuments = !!accreditation?.concessionOrganizationAccreditationDocuments?.length
    const isSubmittable = hasDocuments

    return (
        <Formik initialValues={initial} onSubmit={handleSubmit} validationSchema={schema} enableReinitialize>
            {() => {
                return (
                    <Form>
                        <Fade in={!accreditationLoading} timeout={500}>
                            <div className={styles.Wrapper}>
                                <ActionsBar variant="DETAIL">
                                    <Grid container justifyContent="space-between" style={{ height: "100%" }}>
                                        <Grid item xs={2} container justifyContent="flex-start" alignContent="center">
                                            <BackButton />
                                        </Grid>
                                        <Grid item xs={10} container justifyContent="flex-end" alignContent="center">
                                            <Permission action={Actions.UPDATE} subject={Subjects.ACCREDITATION}>
                                                <SaveIconButton />
                                            </Permission>
                                            <AccreditationViewMoreActions
                                                accreditationStateId={currentState}
                                                changeState={handleChangeState}
                                                isSubmittable={isSubmittable}
                                            />
                                        </Grid>
                                    </Grid>
                                </ActionsBar>

                                <Grid container direction="column" className={styles.Content}>
                                    <Grid container>
                                        <Grid item xs={5}>
                                            <AccreditationInfo accreditation={accreditation} />
                                        </Grid>
                                        <Grid item xs={7} container>
                                            <AccreditationDocuments
                                                documents={accreditation?.concessionOrganizationAccreditationDocuments}
                                                setPreviewFile={setPreviewFile}
                                                onDelete={handleDeleteDocument}
                                                onAttach={handleAttachDocuments}
                                                beforeAttach={beforeAttachDocuments}
                                                beforeExpiration={beforeExpiration}
                                                beforeCategorization={beforeCategorization}
                                                beforeDelete={beforeDelete}
                                                isOpen={isOpen}
                                                setIsOpen={setIsOpen}
                                                handleImport={handleImport}
                                                concessionOrganizationId={accreditation?.concessionOrganization?.id}
                                                concessionOrganizationAccreditationTypeId={accreditation?.concessionOrganizationAccreditationType?.id}
                                                disableUpdateDocuments={disableUpdateDocuments}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <CustomModal isActive={!!previewFile} handleClose={() => setPreviewFile(null)} fullScreen={true} backgroundColor="gray" textColor="white">
                                    <Grid container justifyContent="center">
                                        <DocumentPreviewer file={previewFile?.path} mimeType={previewFile?.fileType?.mimeType} />
                                    </Grid>
                                </CustomModal>
                            </div >
                        </Fade>
                    </Form>
                )
            }}
        </Formik >

    )
}
