import { menuItems } from "../components/Sidemenu/Sidemenu"
import { useAuth } from "../services/auth"

/**
 * Filters the menu items by the user permissions
 */
export default function useMenuItems() {
    const auth = useAuth()
    const ability = auth?.user?.ability

    if (ability) {
        return menuItems.filter(menuItem => ability.can(menuItem.action, menuItem.subject))
    }

    return []
}
