import { Block, Checklist, Done, Publish } from "@mui/icons-material"
import { Settings, useSettingManager } from "../../hooks/useSettingManager"
import { Actions, Subjects } from "../../services/ability"
import { StateOption, TransitionMap } from "../../types/types"
import { getStateOptions, getTransitions } from "../../utils/states"
import MoreMenu from "../MoreMenu/MoreMenu"
import { MoreMenuItemPermission } from "../MoreMenuItemPermission/MoreMenuItemPermission"

interface AccreditationViewMoreActionsProps {
    accreditationStateId?: string
    changeState: (stateTo: string) => void
    isSubmittable: boolean
}

export default function AccreditationViewMoreActions(props: AccreditationViewMoreActionsProps) {

    const {
        accreditationStateId,
        changeState,
        isSubmittable
    } = props

    const getSetting = useSettingManager()
    const preparationState = getSetting(Settings.ACCREDITATION_PREPARATION_STATE)
    const submittedState = getSetting(Settings.ACCREDITATION_SUBMITTED_STATE)
    const pendingState = getSetting(Settings.ACCREDITATION_PENDING_STATE)
    const approvedState = getSetting(Settings.ACCREDITATION_APPROVED_STATE)
    const rejectedState = getSetting(Settings.ACCREDITATION_REJECTED_STATE)

    const stateOptions: StateOption[] = [
        {
            stateTo: submittedState,
            icon: <Publish fontSize="small" />,
            label: "Submeter Processo",
            action: Actions.SUBMIT,
            subject: Subjects.ACCREDITATION,
            disabled: !isSubmittable
        },
        {
            stateTo: pendingState,
            icon: <Checklist fontSize="small" />,
            label: "Solicitar Acreditação",
            action: Actions.REQUEST,
            subject: Subjects.ACCREDITATION
        },
        {
            stateTo: approvedState,
            icon: <Done fontSize="small" />,
            label: "Aprovar Acreditação",
            action: Actions.APROVE,
            subject: Subjects.ACCREDITATION
        },
        {
            stateTo: rejectedState,
            icon: <Block fontSize="small" />,
            label: "Rejeitar Acreditação",
            action: Actions.REJECT,
            subject: Subjects.ACCREDITATION
        }
    ]

    const accreditationTransitionMap: TransitionMap = {
        [preparationState]: [submittedState],
        [submittedState]: [pendingState],
        [pendingState]: [approvedState, rejectedState],
        [approvedState]: [rejectedState],
    }

    const transitions = getTransitions(accreditationTransitionMap, accreditationStateId)
    const options = getStateOptions(stateOptions, transitions)

    return (
        <div>
            <MoreMenu>
                {options.map((option: StateOption) => {
                    const { action, subject, stateTo, icon, label, disabled } = option
                    return (
                        <MoreMenuItemPermission
                            key={stateTo}
                            icon={icon}
                            label={label}
                            onClick={() => changeState(stateTo)}
                            action={action}
                            subject={subject}
                            disabled={disabled}
                        />
                    )
                })}
            </MoreMenu>
        </div>
    )
}
