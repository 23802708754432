import { Tooltip } from "@mui/material"
import { FC } from "react"
import { Maybe } from "../../generated/graphql"
import styles from './Truncate.module.scss'

interface TruncateProps {
    text?: Maybe<string>
}
const Truncate: FC<TruncateProps> = props => {
    const { text } = props
    if (!text) return null
    return (
        <Tooltip title={text}>
            <div className={styles.Truncate}>
                {text}
            </div>
        </Tooltip>
    )
}


export default Truncate
