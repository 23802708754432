import { Delete } from '@mui/icons-material';
import { Card, CardContent, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { clsx } from 'clsx';
import useAnchor from '../../hooks/useAnchor';
import { Actions, Subjects } from '../../services/ability';
import { downloadRequestStatic } from '../../services/utils';
import AccreditationDocumentsImportPopover from '../AccreditationDocumentsImportPopover/AccreditationDocumentsImportPopover';
import AddIconButton from '../AddIconButton/AddIconButton';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import CustomModal from '../CustomModal/CustomModal';
import { DocumentStateDisplay } from '../DocumentStateDisplay/DocumentStateDisplay';
import DownloadIconButton from '../DownloadIconButton/DownloadIconButton';
import Empty from "../Empty/Empty";
import { FormatDate } from '../FormatDate/FormatDate';
import ImportIconButton from '../ImportIconButton/ImportIconButton';
import { SelectedDocument, SelectedDocumentRow } from '../OrganizationDocumentsActions/OrganizationDocumentsActions';
import Permission from '../Permission';
import PreviewIconButton from '../PreviewIconButton/PreviewIconButton';
import Show from "../Show/Show";
import SubcontractOrganizationDocumentsView from '../SubcontractOrganizationDocumentsView/SubcontractOrganizationDocumentsView';
import Truncate from "../Truncate/Truncate";
import styles from './AccreditationDocuments.module.scss';

interface DocumentRowProps {
    document: any
    setPreviewFile: any
    onDelete: (id: string) => void
    disableDelete?: boolean
}


function DocumentRow(props: DocumentRowProps) {
    const {
        onDelete,
        setPreviewFile,
        disableDelete = false,
        document
    } = props

    const {
        id,
        name,
        path,
        expiresAt,
        fileType,
        concessionOrganizationDocumentCategory,
        concessionOrganizationDocumentSubcategory,
        documentState,
    } = document

    const handlePreview = () => {
        setPreviewFile({ name, path, fileType })
    }

    const handleDownload = (event: any) => {
        event.stopPropagation();
        downloadRequestStatic(path, name)
    }

    const handleDelete = () => {
        onDelete(id)
    }

    const isExpired = expiresAt < new Date()
    const classes = clsx({ [styles.Expired]: isExpired })

    return (
        <TableRow key={0}>
            <TableCell>
                {name}
            </TableCell>
            <TableCell>
                {concessionOrganizationDocumentCategory?.name}
            </TableCell>
            <TableCell>
                {concessionOrganizationDocumentSubcategory?.name}
            </TableCell>
            <TableCell>
                <DocumentStateDisplay
                    id={documentState?.id}
                    name={documentState?.name}
                />
            </TableCell>
            <TableCell>
                <span className={classes}>
                    <FormatDate date={expiresAt} />
                </span>
            </TableCell>
            <TableCell>
                {fileType?.name}
            </TableCell>
            <TableCell style={{ minWidth: 120 }}>
                <PreviewIconButton onClick={handlePreview} disabled={false} />
                <DownloadIconButton onClick={handleDownload} disabled={false} />
                <Permission action={Actions.DELETE} subject={Subjects.ACCREDITATIONDOCUMENT}>
                    <ConfirmButton onClick={handleDelete} disabled={disableDelete}>
                        <Delete />
                    </ConfirmButton>
                </Permission>
            </TableCell>
        </TableRow >
    )
}


interface AccreditationDocumentsProps {
    documents?: any
    setPreviewFile: any
    onDelete: (id: string) => void
    onAttach: (selection: SelectedDocument[]) => void
    name?: string
    isOpen: boolean
    setIsOpen: any
    handleImport: (originAccreditationId: string) => void
    concessionOrganizationId?: string
    concessionOrganizationAccreditationTypeId?: string
    disableUpdateDocuments?: boolean
    beforeAttach?: (selection: SelectedDocumentRow[]) => void
    beforeExpiration?: (selection: SelectedDocumentRow[]) => void
    beforeCategorization?: (selection: SelectedDocumentRow[]) => void
    beforeDelete?: (selection: SelectedDocumentRow[]) => void
}

export default function AccreditationDocuments(props: AccreditationDocumentsProps) {

    const {
        documents,
        setPreviewFile,
        onDelete,
        onAttach,
        name = "Documentos Acreditação",
        isOpen,
        setIsOpen,
        handleImport,
        concessionOrganizationId,
        concessionOrganizationAccreditationTypeId,
        disableUpdateDocuments,
        beforeAttach,
        beforeExpiration,
        beforeCategorization,
        beforeDelete
    } = props

    const [anchor, setAnchor] = useAnchor()

    const handleClick = (event: any) => {
        setAnchor(event.currentTarget)
    }

    return (
        <Grid container className={styles.SurveyRecordForm}>
            <Grid item xs={12} className={styles.GeneralSection}>
                <Card style={{ padding: 20 }}>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={9}>
                                <Typography variant="h4" color="primary">
                                    <Truncate text={name} />
                                </Typography>
                            </Grid>
                            <Grid container item xs={3} justifyContent="flex-end">
                                <Permission action={Actions.CREATE} subject={Subjects.ACCREDITATIONDOCUMENT} >
                                    <AddIconButton onClick={() => setIsOpen(true)} title="associar documentos" disabled={disableUpdateDocuments} />
                                </Permission>
                                <Permission action={Actions.IMPORT} subject={Subjects.ACCREDITATIONDOCUMENT} >
                                    <ImportIconButton onClick={handleClick} title="importar documentos" disabled={disableUpdateDocuments} />
                                    <AccreditationDocumentsImportPopover
                                        anchor={anchor}
                                        setAnchor={setAnchor}
                                        concessionOrganizationId={concessionOrganizationId}
                                        concessionOrganizationAccreditationTypeId={concessionOrganizationAccreditationTypeId}
                                        handleImport={handleImport}
                                    />
                                </Permission>
                            </Grid>
                        </Grid>

                        <Grid container alignItems="center">

                            <Show on={!documents?.length}>
                                <Empty message="<p>Ainda não existem documentos associados a esta acreditação.</p>
						<p><b>Processos sem documentação não podem ser submetidos.</b></p>" />
                            </Show>

                            <Show on={documents?.length}>
                                <TableContainer>
                                    <Table className={styles.ContractTable} aria-label="subcontract details">
                                        <TableBody>
                                            <TableRow key={0}>
                                                <TableCell>
                                                    <b>Nome</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>Categoria</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>Subcategoria</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>Estado</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>Expira</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>Tipo</b>
                                                </TableCell>
                                                <TableCell>
                                                </TableCell>
                                            </TableRow>

                                            {documents?.map(
                                                (document: any) =>
                                                    <DocumentRow
                                                        key={document?.id}
                                                        document={document}
                                                        setPreviewFile={setPreviewFile}
                                                        onDelete={onDelete}
                                                        disableDelete={disableUpdateDocuments}
                                                    />
                                            )}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Show>

                        </Grid>
                    </CardContent>
                </Card>

                <CustomModal isActive={isOpen} handleClose={() => setIsOpen(false)} fullScreen>
                    <SubcontractOrganizationDocumentsView
                        onAttach={onAttach}
                        beforeAttach={beforeAttach}
                        beforeExpiration={beforeExpiration}
                        beforeCategorization={beforeCategorization}
                        beforeDelete={beforeDelete}
                        organizationDocumentsActionsProps={
                            { showBackButton: false }
                        }
                    />
                </CustomModal>
            </Grid>
        </Grid>

    )
}
