import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ConnectionCursor: any;
  DateTime: any;
  Upload: any;
};

export type AddBadgesToWorkPermitInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddChecklistItemsToChecklistInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddChildrenToConcessionOrganizationDocumentCategoryInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddChildrenToContractDocumentCategoryInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddConcessionOrganizationAccreditationDocumentsToConcessionOrganizationAccreditationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddConcessionOrganizationDocumentsToConcessionOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddConcessionOrganizationUsersToUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddConcessionOrganizationsToOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddConcessionUsersToConcessionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddConcessionUsersToUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddConcessionsToConcessionOrganizationAccreditationTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddContractConcessionOrganizationsToContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddContractDocumentsToContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddContractRoadsToContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddContractRoadsToRoadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddContractTransitionsToContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddContractUsersToContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddContractUsersToUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddContractsToConcessionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddContractsToContractStateInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddContractsToContractTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddContractsToInterventionElementInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddContractsToInterventionLocationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddContractsToInterventionTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddContractsToServiceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddControlCentersToConcessionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddEndJuntionSurveyRecordsToJunctionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddEndJuntionTasksToJunctionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddInterventionsToWorkPermitInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddJunctionsToRoadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddOrganizationTypesToConcessionOrganizationAccreditationTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddPermissionsToRoleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddRoadsToConcessionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddStartJuntionSurveyRecordsToJunctionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddStartJuntionTasksToJunctionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddSurveyRecordPhotosToSurveyRecordInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddSurveyRecordVerificationsToSurveyRecordInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddSurveyRecordVerificationsToVerificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddSurveyRecordsToContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddSurveyRecordsToRoadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddSurveyRecordsToUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddSurveyRecordsToWeatherConditionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddTasksToInterventionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddTasksToRestrictionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddTasksToRoadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddVerificationsToVerificationScopeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddWorkPermitsToContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddWorkspaceViewColumnsToWorkspaceViewInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddWorkspaceViewsToWorkspaceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type Badge = {
  __typename?: 'Badge';
  company: Scalars['String'];
  createdAt: Scalars['DateTime'];
  driver: Scalars['String'];
  id: Scalars['ID'];
  licensePlate: Scalars['String'];
  name: Scalars['String'];
  state: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workPermit: WorkPermit;
};

export type BadgeAggregateGroupBy = {
  __typename?: 'BadgeAggregateGroupBy';
  company?: Maybe<Scalars['String']>;
  driver?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  licensePlate?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type BadgeAvgAggregate = {
  __typename?: 'BadgeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type BadgeConnection = {
  __typename?: 'BadgeConnection';
  /** Array of nodes. */
  nodes: Array<Badge>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type BadgeCountAggregate = {
  __typename?: 'BadgeCountAggregate';
  company?: Maybe<Scalars['Int']>;
  driver?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  licensePlate?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type BadgeDeleteFilter = {
  and?: InputMaybe<Array<BadgeDeleteFilter>>;
  company?: InputMaybe<StringFieldComparison>;
  driver?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  licensePlate?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<BadgeDeleteFilter>>;
};

export type BadgeDeleteResponse = {
  __typename?: 'BadgeDeleteResponse';
  company?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  driver?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  licensePlate?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BadgeFilter = {
  and?: InputMaybe<Array<BadgeFilter>>;
  company?: InputMaybe<StringFieldComparison>;
  driver?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  licensePlate?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<BadgeFilter>>;
  workPermit?: InputMaybe<BadgeFilterWorkPermitFilter>;
};

export type BadgeFilterWorkPermitFilter = {
  and?: InputMaybe<Array<BadgeFilterWorkPermitFilter>>;
  endAt?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isPublished?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<BadgeFilterWorkPermitFilter>>;
  publishedAt?: InputMaybe<StringFieldComparison>;
  startAt?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
};

export type BadgeMaxAggregate = {
  __typename?: 'BadgeMaxAggregate';
  company?: Maybe<Scalars['String']>;
  driver?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  licensePlate?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type BadgeMinAggregate = {
  __typename?: 'BadgeMinAggregate';
  company?: Maybe<Scalars['String']>;
  driver?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  licensePlate?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type BadgeSort = {
  direction: SortDirection;
  field: BadgeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum BadgeSortFields {
  Company = 'company',
  Driver = 'driver',
  Id = 'id',
  LicensePlate = 'licensePlate',
  Name = 'name'
}

export enum BadgeStates {
  Approved = 'APPROVED',
  Preparation = 'PREPARATION',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED'
}

export type BadgeSumAggregate = {
  __typename?: 'BadgeSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type BadgeUpdateFilter = {
  and?: InputMaybe<Array<BadgeUpdateFilter>>;
  company?: InputMaybe<StringFieldComparison>;
  driver?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  licensePlate?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<BadgeUpdateFilter>>;
};

export type BooleanFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
};

export type Checklist = {
  __typename?: 'Checklist';
  checklistItems?: Maybe<Array<ChecklistItem>>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isCompleted: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  version: Scalars['Float'];
};


export type ChecklistChecklistItemsArgs = {
  filter?: InputMaybe<ChecklistItemFilter>;
  sorting?: InputMaybe<Array<ChecklistItemSort>>;
};

export type ChecklistAggregateGroupBy = {
  __typename?: 'ChecklistAggregateGroupBy';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Float']>;
};

export type ChecklistAvgAggregate = {
  __typename?: 'ChecklistAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

export type ChecklistCountAggregate = {
  __typename?: 'ChecklistCountAggregate';
  code?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Int']>;
  isCompleted?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

export type ChecklistEdge = {
  __typename?: 'ChecklistEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Checklist */
  node: Checklist;
};

export type ChecklistItem = {
  __typename?: 'ChecklistItem';
  checklist: Checklist;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isCompleted: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  name: Scalars['String'];
  order: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type ChecklistItemAggregateGroupBy = {
  __typename?: 'ChecklistItemAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  isOptional?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
};

export type ChecklistItemAvgAggregate = {
  __typename?: 'ChecklistItemAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

export type ChecklistItemCountAggregate = {
  __typename?: 'ChecklistItemCountAggregate';
  id?: Maybe<Scalars['Int']>;
  isCompleted?: Maybe<Scalars['Int']>;
  isOptional?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

export type ChecklistItemEdge = {
  __typename?: 'ChecklistItemEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the ChecklistItem */
  node: ChecklistItem;
};

export type ChecklistItemFilter = {
  and?: InputMaybe<Array<ChecklistItemFilter>>;
  checklist?: InputMaybe<ChecklistItemFilterChecklistFilter>;
  id?: InputMaybe<IdFilterComparison>;
  isCompleted?: InputMaybe<BooleanFieldComparison>;
  isOptional?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ChecklistItemFilter>>;
  order?: InputMaybe<NumberFieldComparison>;
};

export type ChecklistItemFilterChecklistFilter = {
  and?: InputMaybe<Array<ChecklistItemFilterChecklistFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isActive?: InputMaybe<BooleanFieldComparison>;
  isCompleted?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ChecklistItemFilterChecklistFilter>>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type ChecklistItemMaxAggregate = {
  __typename?: 'ChecklistItemMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
};

export type ChecklistItemMinAggregate = {
  __typename?: 'ChecklistItemMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
};

export type ChecklistItemSort = {
  direction: SortDirection;
  field: ChecklistItemSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ChecklistItemSortFields {
  Id = 'id',
  IsCompleted = 'isCompleted',
  IsOptional = 'isOptional',
  Name = 'name',
  Order = 'order'
}

export type ChecklistItemSumAggregate = {
  __typename?: 'ChecklistItemSumAggregate';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

export type ChecklistMaxAggregate = {
  __typename?: 'ChecklistMaxAggregate';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Float']>;
};

export type ChecklistMinAggregate = {
  __typename?: 'ChecklistMinAggregate';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Float']>;
};

export type ChecklistSumAggregate = {
  __typename?: 'ChecklistSumAggregate';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

export type Concession = {
  __typename?: 'Concession';
  concessionUsers: Array<ConcessionUser>;
  contracts: Array<Contract>;
  controlCenters: Array<ControlCenter>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  roads: Array<Road>;
  updatedAt: Scalars['DateTime'];
};


export type ConcessionConcessionUsersArgs = {
  filter?: InputMaybe<ConcessionUserFilter>;
  sorting?: InputMaybe<Array<ConcessionUserSort>>;
};


export type ConcessionContractsArgs = {
  filter?: InputMaybe<ContractFilter>;
  sorting?: InputMaybe<Array<ContractSort>>;
};


export type ConcessionControlCentersArgs = {
  filter?: InputMaybe<ControlCenterFilter>;
  sorting?: InputMaybe<Array<ControlCenterSort>>;
};


export type ConcessionRoadsArgs = {
  filter?: InputMaybe<RoadFilter>;
  sorting?: InputMaybe<Array<RoadSort>>;
};

export type ConcessionAggregateGroupBy = {
  __typename?: 'ConcessionAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ConcessionAvgAggregate = {
  __typename?: 'ConcessionAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ConcessionConnection = {
  __typename?: 'ConcessionConnection';
  /** Array of nodes. */
  nodes: Array<Concession>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ConcessionCountAggregate = {
  __typename?: 'ConcessionCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type ConcessionDeleteFilter = {
  and?: InputMaybe<Array<ConcessionDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionDeleteFilter>>;
};

export type ConcessionDeleteResponse = {
  __typename?: 'ConcessionDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConcessionFilter = {
  and?: InputMaybe<Array<ConcessionFilter>>;
  concessionUser?: InputMaybe<ConcessionFilterConcessionUserFilter>;
  contract?: InputMaybe<ConcessionFilterContractFilter>;
  controlCenters?: InputMaybe<ConcessionFilterControlCenterFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionFilter>>;
  road?: InputMaybe<ConcessionFilterRoadFilter>;
};

export type ConcessionFilterConcessionUserFilter = {
  and?: InputMaybe<Array<ConcessionFilterConcessionUserFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ConcessionFilterConcessionUserFilter>>;
  role?: InputMaybe<ConcessionUserRolesFilterComparison>;
};

export type ConcessionFilterContractFilter = {
  and?: InputMaybe<Array<ConcessionFilterContractFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionFilterContractFilter>>;
  realEndDate?: InputMaybe<StringFieldComparison>;
  realStartDate?: InputMaybe<StringFieldComparison>;
  road?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type ConcessionFilterControlCenterFilter = {
  and?: InputMaybe<Array<ConcessionFilterControlCenterFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionFilterControlCenterFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
};

export type ConcessionFilterRoadFilter = {
  and?: InputMaybe<Array<ConcessionFilterRoadFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionFilterRoadFilter>>;
};

export type ConcessionMaxAggregate = {
  __typename?: 'ConcessionMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ConcessionMinAggregate = {
  __typename?: 'ConcessionMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ConcessionOrganization = {
  __typename?: 'ConcessionOrganization';
  concession: Concession;
  concessionOrganizationDocuments: Array<ConcessionOrganizationDocument>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  organization: Organization;
  organizationType: OrganizationType;
  updatedAt: Scalars['DateTime'];
};


export type ConcessionOrganizationConcessionOrganizationDocumentsArgs = {
  filter?: InputMaybe<ConcessionOrganizationDocumentFilter>;
  sorting?: InputMaybe<Array<ConcessionOrganizationDocumentSort>>;
};

export type ConcessionOrganizationAccreditation = {
  __typename?: 'ConcessionOrganizationAccreditation';
  concessionOrganization: ConcessionOrganization;
  concessionOrganizationAccreditationDocuments?: Maybe<Array<ConcessionOrganizationAccreditationDocument>>;
  concessionOrganizationAccreditationState: ConcessionOrganizationAccreditationState;
  concessionOrganizationAccreditationType: ConcessionOrganizationAccreditationType;
  createdAt: Scalars['DateTime'];
  endDate: Scalars['String'];
  id: Scalars['ID'];
  mediatingOrganization?: Maybe<ConcessionOrganization>;
  name: Scalars['String'];
  observations?: Maybe<Scalars['String']>;
  sequence: Scalars['Float'];
  startDate: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type ConcessionOrganizationAccreditationConcessionOrganizationAccreditationDocumentsArgs = {
  filter?: InputMaybe<ConcessionOrganizationAccreditationDocumentFilter>;
  sorting?: InputMaybe<Array<ConcessionOrganizationAccreditationDocumentSort>>;
};

export type ConcessionOrganizationAccreditationAggregateGroupBy = {
  __typename?: 'ConcessionOrganizationAccreditationAggregateGroupBy';
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
};

export type ConcessionOrganizationAccreditationAvgAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

export type ConcessionOrganizationAccreditationConnection = {
  __typename?: 'ConcessionOrganizationAccreditationConnection';
  /** Array of nodes. */
  nodes: Array<ConcessionOrganizationAccreditation>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ConcessionOrganizationAccreditationCountAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationCountAggregate';
  endDate?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  observations?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Int']>;
};

export type ConcessionOrganizationAccreditationDeleteFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationDeleteFilter>>;
  endDate?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationDeleteFilter>>;
  sequence?: InputMaybe<NumberFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type ConcessionOrganizationAccreditationDeleteResponse = {
  __typename?: 'ConcessionOrganizationAccreditationDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConcessionOrganizationAccreditationDocument = {
  __typename?: 'ConcessionOrganizationAccreditationDocument';
  concessionOrganizationAccreditation: ConcessionOrganizationAccreditation;
  concessionOrganizationDocumentCategory?: Maybe<ConcessionOrganizationDocumentCategory>;
  concessionOrganizationDocumentSubcategory?: Maybe<ConcessionOrganizationDocumentCategory>;
  createdAt: Scalars['DateTime'];
  documentState?: Maybe<DocumentState>;
  expiresAt?: Maybe<Scalars['String']>;
  fileType?: Maybe<FileType>;
  id: Scalars['ID'];
  name: Scalars['String'];
  path: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ConcessionOrganizationAccreditationDocumentAggregateGroupBy = {
  __typename?: 'ConcessionOrganizationAccreditationDocumentAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ConcessionOrganizationAccreditationDocumentAvgAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationDocumentAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ConcessionOrganizationAccreditationDocumentConnection = {
  __typename?: 'ConcessionOrganizationAccreditationDocumentConnection';
  /** Array of nodes. */
  nodes: Array<ConcessionOrganizationAccreditationDocument>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ConcessionOrganizationAccreditationDocumentCountAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationDocumentCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type ConcessionOrganizationAccreditationDocumentDeleteFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationDocumentDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationDocumentDeleteFilter>>;
};

export type ConcessionOrganizationAccreditationDocumentDeleteResponse = {
  __typename?: 'ConcessionOrganizationAccreditationDocumentDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConcessionOrganizationAccreditationDocumentFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationDocumentFilter>>;
  concessionOrganizationAccreditation?: InputMaybe<ConcessionOrganizationAccreditationDocumentFilterConcessionOrganizationAccreditationFilter>;
  concessionOrganizationDocumentCategory?: InputMaybe<ConcessionOrganizationAccreditationDocumentFilterConcessionOrganizationDocumentCategoryFilter>;
  concessionOrganizationDocumentSubcategory?: InputMaybe<ConcessionOrganizationAccreditationDocumentFilterConcessionOrganizationDocumentCategoryFilter>;
  documentState?: InputMaybe<ConcessionOrganizationAccreditationDocumentFilterDocumentStateFilter>;
  fileType?: InputMaybe<ConcessionOrganizationAccreditationDocumentFilterFileTypeFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationDocumentFilter>>;
};

export type ConcessionOrganizationAccreditationDocumentFilterConcessionOrganizationAccreditationFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationDocumentFilterConcessionOrganizationAccreditationFilter>>;
  endDate?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationDocumentFilterConcessionOrganizationAccreditationFilter>>;
  sequence?: InputMaybe<NumberFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type ConcessionOrganizationAccreditationDocumentFilterConcessionOrganizationDocumentCategoryFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationDocumentFilterConcessionOrganizationDocumentCategoryFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationDocumentFilterConcessionOrganizationDocumentCategoryFilter>>;
  policy?: InputMaybe<StringFieldComparison>;
};

export type ConcessionOrganizationAccreditationDocumentFilterDocumentStateFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationDocumentFilterDocumentStateFilter>>;
  backgroundColor?: InputMaybe<StringFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  color?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationDocumentFilterDocumentStateFilter>>;
  stateType?: InputMaybe<StringFieldComparison>;
};

export type ConcessionOrganizationAccreditationDocumentFilterFileTypeFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationDocumentFilterFileTypeFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isPreviewable?: InputMaybe<BooleanFieldComparison>;
  mimeType?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationDocumentFilterFileTypeFilter>>;
};

export type ConcessionOrganizationAccreditationDocumentMaxAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationDocumentMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ConcessionOrganizationAccreditationDocumentMinAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationDocumentMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ConcessionOrganizationAccreditationDocumentSort = {
  direction: SortDirection;
  field: ConcessionOrganizationAccreditationDocumentSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ConcessionOrganizationAccreditationDocumentSortFields {
  Id = 'id',
  Name = 'name'
}

export type ConcessionOrganizationAccreditationDocumentSumAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationDocumentSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ConcessionOrganizationAccreditationFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationFilter>>;
  concessionOrganization?: InputMaybe<ConcessionOrganizationAccreditationFilterConcessionOrganizationFilter>;
  concessionOrganizationAccreditationDocuments?: InputMaybe<ConcessionOrganizationAccreditationFilterConcessionOrganizationAccreditationDocumentFilter>;
  concessionOrganizationAccreditationState?: InputMaybe<ConcessionOrganizationAccreditationFilterConcessionOrganizationAccreditationStateFilter>;
  concessionOrganizationAccreditationType?: InputMaybe<ConcessionOrganizationAccreditationFilterConcessionOrganizationAccreditationTypeFilter>;
  endDate?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  mediatingOrganization?: InputMaybe<ConcessionOrganizationAccreditationFilterConcessionOrganizationFilter>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationFilter>>;
  sequence?: InputMaybe<NumberFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type ConcessionOrganizationAccreditationFilterConcessionOrganizationAccreditationDocumentFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationFilterConcessionOrganizationAccreditationDocumentFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationFilterConcessionOrganizationAccreditationDocumentFilter>>;
};

export type ConcessionOrganizationAccreditationFilterConcessionOrganizationAccreditationStateFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationFilterConcessionOrganizationAccreditationStateFilter>>;
  backgroundColor?: InputMaybe<StringFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  color?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationFilterConcessionOrganizationAccreditationStateFilter>>;
};

export type ConcessionOrganizationAccreditationFilterConcessionOrganizationAccreditationTypeFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationFilterConcessionOrganizationAccreditationTypeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationFilterConcessionOrganizationAccreditationTypeFilter>>;
};

export type ConcessionOrganizationAccreditationFilterConcessionOrganizationFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationFilterConcessionOrganizationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationFilterConcessionOrganizationFilter>>;
};

export type ConcessionOrganizationAccreditationMaxAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationMaxAggregate';
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
};

export type ConcessionOrganizationAccreditationMinAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationMinAggregate';
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
};

export type ConcessionOrganizationAccreditationSort = {
  direction: SortDirection;
  field: ConcessionOrganizationAccreditationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ConcessionOrganizationAccreditationSortFields {
  EndDate = 'endDate',
  Id = 'id',
  Name = 'name',
  Observations = 'observations',
  Sequence = 'sequence',
  StartDate = 'startDate'
}

export type ConcessionOrganizationAccreditationState = {
  __typename?: 'ConcessionOrganizationAccreditationState';
  backgroundColor: Scalars['String'];
  code: Scalars['String'];
  color: Scalars['String'];
  concession: Concession;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ConcessionOrganizationAccreditationStateAggregateGroupBy = {
  __typename?: 'ConcessionOrganizationAccreditationStateAggregateGroupBy';
  backgroundColor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ConcessionOrganizationAccreditationStateAvgAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationStateAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ConcessionOrganizationAccreditationStateConnection = {
  __typename?: 'ConcessionOrganizationAccreditationStateConnection';
  /** Array of nodes. */
  nodes: Array<ConcessionOrganizationAccreditationState>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ConcessionOrganizationAccreditationStateCountAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationStateCountAggregate';
  backgroundColor?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type ConcessionOrganizationAccreditationStateDeleteFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationStateDeleteFilter>>;
  backgroundColor?: InputMaybe<StringFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  color?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationStateDeleteFilter>>;
};

export type ConcessionOrganizationAccreditationStateDeleteResponse = {
  __typename?: 'ConcessionOrganizationAccreditationStateDeleteResponse';
  backgroundColor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConcessionOrganizationAccreditationStateFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationStateFilter>>;
  backgroundColor?: InputMaybe<StringFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  color?: InputMaybe<StringFieldComparison>;
  concession?: InputMaybe<ConcessionOrganizationAccreditationStateFilterConcessionFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationStateFilter>>;
};

export type ConcessionOrganizationAccreditationStateFilterConcessionFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationStateFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationStateFilterConcessionFilter>>;
};

export type ConcessionOrganizationAccreditationStateMaxAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationStateMaxAggregate';
  backgroundColor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ConcessionOrganizationAccreditationStateMinAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationStateMinAggregate';
  backgroundColor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ConcessionOrganizationAccreditationStateSort = {
  direction: SortDirection;
  field: ConcessionOrganizationAccreditationStateSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ConcessionOrganizationAccreditationStateSortFields {
  BackgroundColor = 'backgroundColor',
  Code = 'code',
  Color = 'color',
  Id = 'id',
  Name = 'name'
}

export type ConcessionOrganizationAccreditationStateSumAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationStateSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ConcessionOrganizationAccreditationStateUpdateFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationStateUpdateFilter>>;
  backgroundColor?: InputMaybe<StringFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  color?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationStateUpdateFilter>>;
};

export type ConcessionOrganizationAccreditationSumAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationSumAggregate';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

export type ConcessionOrganizationAccreditationType = {
  __typename?: 'ConcessionOrganizationAccreditationType';
  code: Scalars['String'];
  concessions: Array<Concession>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationTypes: Array<OrganizationType>;
  updatedAt: Scalars['DateTime'];
};


export type ConcessionOrganizationAccreditationTypeConcessionsArgs = {
  filter?: InputMaybe<ConcessionFilter>;
  sorting?: InputMaybe<Array<ConcessionSort>>;
};


export type ConcessionOrganizationAccreditationTypeOrganizationTypesArgs = {
  filter?: InputMaybe<OrganizationTypeFilter>;
  sorting?: InputMaybe<Array<OrganizationTypeSort>>;
};

export type ConcessionOrganizationAccreditationTypeAggregateGroupBy = {
  __typename?: 'ConcessionOrganizationAccreditationTypeAggregateGroupBy';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ConcessionOrganizationAccreditationTypeAvgAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationTypeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ConcessionOrganizationAccreditationTypeConnection = {
  __typename?: 'ConcessionOrganizationAccreditationTypeConnection';
  /** Array of nodes. */
  nodes: Array<ConcessionOrganizationAccreditationType>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ConcessionOrganizationAccreditationTypeCountAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationTypeCountAggregate';
  code?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type ConcessionOrganizationAccreditationTypeDeleteFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationTypeDeleteFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationTypeDeleteFilter>>;
};

export type ConcessionOrganizationAccreditationTypeDeleteResponse = {
  __typename?: 'ConcessionOrganizationAccreditationTypeDeleteResponse';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConcessionOrganizationAccreditationTypeFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationTypeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  concession?: InputMaybe<ConcessionOrganizationAccreditationTypeFilterConcessionFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationTypeFilter>>;
  organizationType?: InputMaybe<ConcessionOrganizationAccreditationTypeFilterOrganizationTypeFilter>;
};

export type ConcessionOrganizationAccreditationTypeFilterConcessionFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationTypeFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationTypeFilterConcessionFilter>>;
};

export type ConcessionOrganizationAccreditationTypeFilterOrganizationTypeFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationTypeFilterOrganizationTypeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationTypeFilterOrganizationTypeFilter>>;
};

export type ConcessionOrganizationAccreditationTypeMaxAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationTypeMaxAggregate';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ConcessionOrganizationAccreditationTypeMinAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationTypeMinAggregate';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ConcessionOrganizationAccreditationTypeSort = {
  direction: SortDirection;
  field: ConcessionOrganizationAccreditationTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ConcessionOrganizationAccreditationTypeSortFields {
  Code = 'code',
  Id = 'id',
  Name = 'name'
}

export type ConcessionOrganizationAccreditationTypeSumAggregate = {
  __typename?: 'ConcessionOrganizationAccreditationTypeSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ConcessionOrganizationAccreditationTypeUpdateFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationTypeUpdateFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationTypeUpdateFilter>>;
};

export type ConcessionOrganizationAccreditationUpdateFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationAccreditationUpdateFilter>>;
  endDate?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationAccreditationUpdateFilter>>;
  sequence?: InputMaybe<NumberFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type ConcessionOrganizationAggregateGroupBy = {
  __typename?: 'ConcessionOrganizationAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type ConcessionOrganizationAvgAggregate = {
  __typename?: 'ConcessionOrganizationAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ConcessionOrganizationConnection = {
  __typename?: 'ConcessionOrganizationConnection';
  /** Array of nodes. */
  nodes: Array<ConcessionOrganization>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ConcessionOrganizationCountAggregate = {
  __typename?: 'ConcessionOrganizationCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type ConcessionOrganizationDeleteFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationDeleteFilter>>;
};

export type ConcessionOrganizationDeleteResponse = {
  __typename?: 'ConcessionOrganizationDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConcessionOrganizationDocument = {
  __typename?: 'ConcessionOrganizationDocument';
  concessionOrganization: ConcessionOrganization;
  concessionOrganizationDocumentCategory?: Maybe<ConcessionOrganizationDocumentCategory>;
  concessionOrganizationDocumentSubcategory?: Maybe<ConcessionOrganizationDocumentCategory>;
  createdAt: Scalars['DateTime'];
  documentState?: Maybe<DocumentState>;
  expiresAt?: Maybe<Scalars['String']>;
  fileType?: Maybe<FileType>;
  id: Scalars['ID'];
  name: Scalars['String'];
  owner?: Maybe<User>;
  path: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ConcessionOrganizationDocumentAggregateGroupBy = {
  __typename?: 'ConcessionOrganizationDocumentAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ConcessionOrganizationDocumentAvgAggregate = {
  __typename?: 'ConcessionOrganizationDocumentAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ConcessionOrganizationDocumentCategory = {
  __typename?: 'ConcessionOrganizationDocumentCategory';
  children?: Maybe<Array<ContractDocumentCategory>>;
  concession: Concession;
  createdAt: Scalars['DateTime'];
  expirationRequired: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  observations?: Maybe<Scalars['String']>;
  parent?: Maybe<ConcessionOrganizationDocumentCategory>;
  policy: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type ConcessionOrganizationDocumentCategoryChildrenArgs = {
  filter?: InputMaybe<ContractDocumentCategoryFilter>;
  sorting?: InputMaybe<Array<ContractDocumentCategorySort>>;
};

export type ConcessionOrganizationDocumentCategoryAggregateGroupBy = {
  __typename?: 'ConcessionOrganizationDocumentCategoryAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  policy?: Maybe<Scalars['String']>;
};

export type ConcessionOrganizationDocumentCategoryAvgAggregate = {
  __typename?: 'ConcessionOrganizationDocumentCategoryAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ConcessionOrganizationDocumentCategoryConnection = {
  __typename?: 'ConcessionOrganizationDocumentCategoryConnection';
  /** Array of nodes. */
  nodes: Array<ConcessionOrganizationDocumentCategory>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ConcessionOrganizationDocumentCategoryCountAggregate = {
  __typename?: 'ConcessionOrganizationDocumentCategoryCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  policy?: Maybe<Scalars['Int']>;
};

export type ConcessionOrganizationDocumentCategoryDeleteFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationDocumentCategoryDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationDocumentCategoryDeleteFilter>>;
  policy?: InputMaybe<StringFieldComparison>;
};

export type ConcessionOrganizationDocumentCategoryDeleteResponse = {
  __typename?: 'ConcessionOrganizationDocumentCategoryDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationRequired?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  policy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConcessionOrganizationDocumentCategoryFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationDocumentCategoryFilter>>;
  concession?: InputMaybe<ConcessionOrganizationDocumentCategoryFilterConcessionFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationDocumentCategoryFilter>>;
  parent?: InputMaybe<ConcessionOrganizationDocumentCategoryFilterConcessionOrganizationDocumentCategoryFilter>;
  policy?: InputMaybe<StringFieldComparison>;
};

export type ConcessionOrganizationDocumentCategoryFilterConcessionFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationDocumentCategoryFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationDocumentCategoryFilterConcessionFilter>>;
};

export type ConcessionOrganizationDocumentCategoryFilterConcessionOrganizationDocumentCategoryFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationDocumentCategoryFilterConcessionOrganizationDocumentCategoryFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationDocumentCategoryFilterConcessionOrganizationDocumentCategoryFilter>>;
  policy?: InputMaybe<StringFieldComparison>;
};

export type ConcessionOrganizationDocumentCategoryMaxAggregate = {
  __typename?: 'ConcessionOrganizationDocumentCategoryMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  policy?: Maybe<Scalars['String']>;
};

export type ConcessionOrganizationDocumentCategoryMinAggregate = {
  __typename?: 'ConcessionOrganizationDocumentCategoryMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  policy?: Maybe<Scalars['String']>;
};

export type ConcessionOrganizationDocumentCategorySort = {
  direction: SortDirection;
  field: ConcessionOrganizationDocumentCategorySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ConcessionOrganizationDocumentCategorySortFields {
  Id = 'id',
  Name = 'name',
  Policy = 'policy'
}

export type ConcessionOrganizationDocumentCategorySumAggregate = {
  __typename?: 'ConcessionOrganizationDocumentCategorySumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ConcessionOrganizationDocumentCategoryUpdateFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationDocumentCategoryUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationDocumentCategoryUpdateFilter>>;
  policy?: InputMaybe<StringFieldComparison>;
};

export type ConcessionOrganizationDocumentCountAggregate = {
  __typename?: 'ConcessionOrganizationDocumentCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type ConcessionOrganizationDocumentDeleteFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationDocumentDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationDocumentDeleteFilter>>;
};

export type ConcessionOrganizationDocumentDeleteResponse = {
  __typename?: 'ConcessionOrganizationDocumentDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConcessionOrganizationDocumentFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationDocumentFilter>>;
  concessionOrganization?: InputMaybe<ConcessionOrganizationDocumentFilterConcessionOrganizationFilter>;
  concessionOrganizationDocumentCategory?: InputMaybe<ConcessionOrganizationDocumentFilterConcessionOrganizationDocumentCategoryFilter>;
  concessionOrganizationDocumentSubcategory?: InputMaybe<ConcessionOrganizationDocumentFilterConcessionOrganizationDocumentCategoryFilter>;
  documentState?: InputMaybe<ConcessionOrganizationDocumentFilterDocumentStateFilter>;
  fileType?: InputMaybe<ConcessionOrganizationDocumentFilterFileTypeFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationDocumentFilter>>;
  owner?: InputMaybe<ConcessionOrganizationDocumentFilterUserFilter>;
};

export type ConcessionOrganizationDocumentFilterConcessionOrganizationDocumentCategoryFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationDocumentFilterConcessionOrganizationDocumentCategoryFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationDocumentFilterConcessionOrganizationDocumentCategoryFilter>>;
  policy?: InputMaybe<StringFieldComparison>;
};

export type ConcessionOrganizationDocumentFilterConcessionOrganizationFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationDocumentFilterConcessionOrganizationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationDocumentFilterConcessionOrganizationFilter>>;
};

export type ConcessionOrganizationDocumentFilterDocumentStateFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationDocumentFilterDocumentStateFilter>>;
  backgroundColor?: InputMaybe<StringFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  color?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationDocumentFilterDocumentStateFilter>>;
  stateType?: InputMaybe<StringFieldComparison>;
};

export type ConcessionOrganizationDocumentFilterFileTypeFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationDocumentFilterFileTypeFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isPreviewable?: InputMaybe<BooleanFieldComparison>;
  mimeType?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationDocumentFilterFileTypeFilter>>;
};

export type ConcessionOrganizationDocumentFilterUserFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationDocumentFilterUserFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationDocumentFilterUserFilter>>;
};

export type ConcessionOrganizationDocumentMaxAggregate = {
  __typename?: 'ConcessionOrganizationDocumentMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ConcessionOrganizationDocumentMinAggregate = {
  __typename?: 'ConcessionOrganizationDocumentMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ConcessionOrganizationDocumentOffsetConnection = {
  __typename?: 'ConcessionOrganizationDocumentOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ConcessionOrganizationDocument>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ConcessionOrganizationDocumentSort = {
  direction: SortDirection;
  field: ConcessionOrganizationDocumentSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ConcessionOrganizationDocumentSortFields {
  Id = 'id',
  Name = 'name'
}

export type ConcessionOrganizationDocumentSumAggregate = {
  __typename?: 'ConcessionOrganizationDocumentSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ConcessionOrganizationDocumentUpdateFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationDocumentUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationDocumentUpdateFilter>>;
};

export type ConcessionOrganizationFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationFilter>>;
  concession?: InputMaybe<ConcessionOrganizationFilterConcessionFilter>;
  concessionOrganizationDocuments?: InputMaybe<ConcessionOrganizationFilterConcessionOrganizationDocumentFilter>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationFilter>>;
  organization?: InputMaybe<ConcessionOrganizationFilterOrganizationFilter>;
  organizationType?: InputMaybe<ConcessionOrganizationFilterOrganizationTypeFilter>;
};

export type ConcessionOrganizationFilterConcessionFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationFilterConcessionFilter>>;
};

export type ConcessionOrganizationFilterConcessionOrganizationDocumentFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationFilterConcessionOrganizationDocumentFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationFilterConcessionOrganizationDocumentFilter>>;
};

export type ConcessionOrganizationFilterOrganizationFilter = {
  address?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ConcessionOrganizationFilterOrganizationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationFilterOrganizationFilter>>;
  tin?: InputMaybe<StringFieldComparison>;
  zipCode?: InputMaybe<StringFieldComparison>;
};

export type ConcessionOrganizationFilterOrganizationTypeFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationFilterOrganizationTypeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationFilterOrganizationTypeFilter>>;
};

export type ConcessionOrganizationMaxAggregate = {
  __typename?: 'ConcessionOrganizationMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type ConcessionOrganizationMinAggregate = {
  __typename?: 'ConcessionOrganizationMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type ConcessionOrganizationSort = {
  direction: SortDirection;
  field: ConcessionOrganizationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ConcessionOrganizationSortFields {
  Id = 'id'
}

export type ConcessionOrganizationSumAggregate = {
  __typename?: 'ConcessionOrganizationSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ConcessionOrganizationUpdateFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationUpdateFilter>>;
};

export type ConcessionOrganizationUser = {
  __typename?: 'ConcessionOrganizationUser';
  concessionOrganization: ConcessionOrganization;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  role: ConcessionOrganizationUserRoles;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type ConcessionOrganizationUserAggregateGroupBy = {
  __typename?: 'ConcessionOrganizationUserAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  role?: Maybe<ConcessionOrganizationUserRoles>;
};

export type ConcessionOrganizationUserAvgAggregate = {
  __typename?: 'ConcessionOrganizationUserAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ConcessionOrganizationUserConnection = {
  __typename?: 'ConcessionOrganizationUserConnection';
  /** Array of nodes. */
  nodes: Array<ConcessionOrganizationUser>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ConcessionOrganizationUserCountAggregate = {
  __typename?: 'ConcessionOrganizationUserCountAggregate';
  id?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['Int']>;
};

export type ConcessionOrganizationUserDeleteFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationUserDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationUserDeleteFilter>>;
  role?: InputMaybe<ConcessionOrganizationUserRolesFilterComparison>;
};

export type ConcessionOrganizationUserDeleteResponse = {
  __typename?: 'ConcessionOrganizationUserDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  role?: Maybe<ConcessionOrganizationUserRoles>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConcessionOrganizationUserFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationUserFilter>>;
  concessionOrganization?: InputMaybe<ConcessionOrganizationUserFilterConcessionOrganizationFilter>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationUserFilter>>;
  role?: InputMaybe<ConcessionOrganizationUserRolesFilterComparison>;
  user?: InputMaybe<ConcessionOrganizationUserFilterUserFilter>;
};

export type ConcessionOrganizationUserFilterConcessionOrganizationFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationUserFilterConcessionOrganizationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationUserFilterConcessionOrganizationFilter>>;
};

export type ConcessionOrganizationUserFilterUserFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationUserFilterUserFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationUserFilterUserFilter>>;
};

export type ConcessionOrganizationUserMaxAggregate = {
  __typename?: 'ConcessionOrganizationUserMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  role?: Maybe<ConcessionOrganizationUserRoles>;
};

export type ConcessionOrganizationUserMinAggregate = {
  __typename?: 'ConcessionOrganizationUserMinAggregate';
  id?: Maybe<Scalars['ID']>;
  role?: Maybe<ConcessionOrganizationUserRoles>;
};

export enum ConcessionOrganizationUserRoles {
  Externaladministrative = 'EXTERNALADMINISTRATIVE',
  Externalauthority = 'EXTERNALAUTHORITY',
  Externalmanager = 'EXTERNALMANAGER',
  Externaltechnician = 'EXTERNALTECHNICIAN',
  Subcontractauthority = 'SUBCONTRACTAUTHORITY'
}

export type ConcessionOrganizationUserRolesFilterComparison = {
  eq?: InputMaybe<ConcessionOrganizationUserRoles>;
  gt?: InputMaybe<ConcessionOrganizationUserRoles>;
  gte?: InputMaybe<ConcessionOrganizationUserRoles>;
  iLike?: InputMaybe<ConcessionOrganizationUserRoles>;
  in?: InputMaybe<Array<ConcessionOrganizationUserRoles>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<ConcessionOrganizationUserRoles>;
  lt?: InputMaybe<ConcessionOrganizationUserRoles>;
  lte?: InputMaybe<ConcessionOrganizationUserRoles>;
  neq?: InputMaybe<ConcessionOrganizationUserRoles>;
  notILike?: InputMaybe<ConcessionOrganizationUserRoles>;
  notIn?: InputMaybe<Array<ConcessionOrganizationUserRoles>>;
  notLike?: InputMaybe<ConcessionOrganizationUserRoles>;
};

export type ConcessionOrganizationUserSort = {
  direction: SortDirection;
  field: ConcessionOrganizationUserSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ConcessionOrganizationUserSortFields {
  Id = 'id',
  Role = 'role'
}

export type ConcessionOrganizationUserSumAggregate = {
  __typename?: 'ConcessionOrganizationUserSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ConcessionOrganizationUserUpdateFilter = {
  and?: InputMaybe<Array<ConcessionOrganizationUserUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ConcessionOrganizationUserUpdateFilter>>;
  role?: InputMaybe<ConcessionOrganizationUserRolesFilterComparison>;
};

export type ConcessionSort = {
  direction: SortDirection;
  field: ConcessionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ConcessionSortFields {
  Id = 'id',
  Name = 'name'
}

export type ConcessionSumAggregate = {
  __typename?: 'ConcessionSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ConcessionUpdateFilter = {
  and?: InputMaybe<Array<ConcessionUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionUpdateFilter>>;
};

export type ConcessionUser = {
  __typename?: 'ConcessionUser';
  concession: Concession;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  role: ConcessionUserRoles;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type ConcessionUserAggregateGroupBy = {
  __typename?: 'ConcessionUserAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  role?: Maybe<ConcessionUserRoles>;
};

export type ConcessionUserAvgAggregate = {
  __typename?: 'ConcessionUserAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ConcessionUserConnection = {
  __typename?: 'ConcessionUserConnection';
  /** Array of nodes. */
  nodes: Array<ConcessionUser>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ConcessionUserCountAggregate = {
  __typename?: 'ConcessionUserCountAggregate';
  id?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['Int']>;
};

export type ConcessionUserDeleteFilter = {
  and?: InputMaybe<Array<ConcessionUserDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ConcessionUserDeleteFilter>>;
  role?: InputMaybe<ConcessionUserRolesFilterComparison>;
};

export type ConcessionUserDeleteResponse = {
  __typename?: 'ConcessionUserDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  role?: Maybe<ConcessionUserRoles>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConcessionUserFilter = {
  and?: InputMaybe<Array<ConcessionUserFilter>>;
  concession?: InputMaybe<ConcessionUserFilterConcessionFilter>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ConcessionUserFilter>>;
  role?: InputMaybe<ConcessionUserRolesFilterComparison>;
  user?: InputMaybe<ConcessionUserFilterUserFilter>;
};

export type ConcessionUserFilterConcessionFilter = {
  and?: InputMaybe<Array<ConcessionUserFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionUserFilterConcessionFilter>>;
};

export type ConcessionUserFilterUserFilter = {
  and?: InputMaybe<Array<ConcessionUserFilterUserFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConcessionUserFilterUserFilter>>;
};

export type ConcessionUserMaxAggregate = {
  __typename?: 'ConcessionUserMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  role?: Maybe<ConcessionUserRoles>;
};

export type ConcessionUserMinAggregate = {
  __typename?: 'ConcessionUserMinAggregate';
  id?: Maybe<Scalars['ID']>;
  role?: Maybe<ConcessionUserRoles>;
};

export enum ConcessionUserRoles {
  Contractmanager = 'CONTRACTMANAGER',
  Operator = 'OPERATOR',
  Platformmanager = 'PLATFORMMANAGER',
  Safetytechnician = 'SAFETYTECHNICIAN',
  Servicemanager = 'SERVICEMANAGER',
  Supervisor = 'SUPERVISOR'
}

export type ConcessionUserRolesFilterComparison = {
  eq?: InputMaybe<ConcessionUserRoles>;
  gt?: InputMaybe<ConcessionUserRoles>;
  gte?: InputMaybe<ConcessionUserRoles>;
  iLike?: InputMaybe<ConcessionUserRoles>;
  in?: InputMaybe<Array<ConcessionUserRoles>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<ConcessionUserRoles>;
  lt?: InputMaybe<ConcessionUserRoles>;
  lte?: InputMaybe<ConcessionUserRoles>;
  neq?: InputMaybe<ConcessionUserRoles>;
  notILike?: InputMaybe<ConcessionUserRoles>;
  notIn?: InputMaybe<Array<ConcessionUserRoles>>;
  notLike?: InputMaybe<ConcessionUserRoles>;
};

export type ConcessionUserSort = {
  direction: SortDirection;
  field: ConcessionUserSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ConcessionUserSortFields {
  Id = 'id',
  Role = 'role'
}

export type ConcessionUserSumAggregate = {
  __typename?: 'ConcessionUserSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ConcessionUserUpdateFilter = {
  and?: InputMaybe<Array<ConcessionUserUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ConcessionUserUpdateFilter>>;
  role?: InputMaybe<ConcessionUserRolesFilterComparison>;
};

export type Contract = {
  __typename?: 'Contract';
  amendedValue?: Maybe<Scalars['Float']>;
  billedValue?: Maybe<Scalars['Float']>;
  concession: Concession;
  contractClosedNotificationSent: Scalars['Boolean'];
  contractConcessionOrganizations?: Maybe<Array<ContractConcessionOrganization>>;
  contractDocuments?: Maybe<Array<ContractDocument>>;
  contractOpenedNotificationSent: Scalars['Boolean'];
  contractRoads?: Maybe<Array<ContractRoad>>;
  contractRoadsObservations?: Maybe<Scalars['String']>;
  contractState: ContractState;
  contractTransitions?: Maybe<Array<ContractTransition>>;
  contractType: ContractType;
  contractUsers?: Maybe<Array<ContractUser>>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  endDate: Scalars['String'];
  executingOrganization?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  interventionElement: InterventionElement;
  interventionLocation: InterventionLocation;
  interventionType: InterventionType;
  name: Scalars['String'];
  originalValue?: Maybe<Scalars['Float']>;
  realEndDate: Scalars['String'];
  realStartDate: Scalars['String'];
  road?: Maybe<Scalars['String']>;
  service: Service;
  startDate: Scalars['String'];
  surveyRecords?: Maybe<Array<SurveyRecord>>;
  updatedAt: Scalars['DateTime'];
  workPermits: Array<WorkPermit>;
};


export type ContractContractConcessionOrganizationsArgs = {
  filter?: InputMaybe<ContractConcessionOrganizationFilter>;
  sorting?: InputMaybe<Array<ContractConcessionOrganizationSort>>;
};


export type ContractContractDocumentsArgs = {
  filter?: InputMaybe<ContractDocumentFilter>;
  sorting?: InputMaybe<Array<ContractDocumentSort>>;
};


export type ContractContractRoadsArgs = {
  filter?: InputMaybe<ContractRoadFilter>;
  sorting?: InputMaybe<Array<ContractRoadSort>>;
};


export type ContractContractTransitionsArgs = {
  filter?: InputMaybe<ContractTransitionFilter>;
  sorting?: InputMaybe<Array<ContractTransitionSort>>;
};


export type ContractContractUsersArgs = {
  filter?: InputMaybe<ContractUserFilter>;
  sorting?: InputMaybe<Array<ContractUserSort>>;
};


export type ContractSurveyRecordsArgs = {
  filter?: InputMaybe<SurveyRecordFilter>;
  sorting?: InputMaybe<Array<SurveyRecordSort>>;
};


export type ContractWorkPermitsArgs = {
  filter?: InputMaybe<WorkPermitFilter>;
  sorting?: InputMaybe<Array<WorkPermitSort>>;
};

export type ContractAggregateGroupBy = {
  __typename?: 'ContractAggregateGroupBy';
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  executingOrganization?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  realEndDate?: Maybe<Scalars['String']>;
  realStartDate?: Maybe<Scalars['String']>;
  road?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

export type ContractAvgAggregate = {
  __typename?: 'ContractAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractConcessionOrganization = {
  __typename?: 'ContractConcessionOrganization';
  concessionOrganization: ConcessionOrganization;
  concessionOrganizationAccreditation?: Maybe<ConcessionOrganizationAccreditation>;
  contract: Contract;
  contractConcessionOrganizationState?: Maybe<ContractConcessionOrganizationState>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  startDate?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ContractConcessionOrganizationAggregateGroupBy = {
  __typename?: 'ContractConcessionOrganizationAggregateGroupBy';
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['String']>;
};

export type ContractConcessionOrganizationAvgAggregate = {
  __typename?: 'ContractConcessionOrganizationAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractConcessionOrganizationConnection = {
  __typename?: 'ContractConcessionOrganizationConnection';
  /** Array of nodes. */
  nodes: Array<ContractConcessionOrganization>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ContractConcessionOrganizationCountAggregate = {
  __typename?: 'ContractConcessionOrganizationCountAggregate';
  description?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Int']>;
};

export type ContractConcessionOrganizationDeleteFilter = {
  and?: InputMaybe<Array<ContractConcessionOrganizationDeleteFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ContractConcessionOrganizationDeleteFilter>>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type ContractConcessionOrganizationDeleteResponse = {
  __typename?: 'ContractConcessionOrganizationDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContractConcessionOrganizationFilter = {
  and?: InputMaybe<Array<ContractConcessionOrganizationFilter>>;
  concessionOrganization?: InputMaybe<ContractConcessionOrganizationFilterConcessionOrganizationFilter>;
  concessionOrganizationAccreditation?: InputMaybe<ContractConcessionOrganizationFilterConcessionOrganizationAccreditationFilter>;
  contract?: InputMaybe<ContractConcessionOrganizationFilterContractFilter>;
  contractConcessionOrganizationState?: InputMaybe<ContractConcessionOrganizationFilterContractConcessionOrganizationStateFilter>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ContractConcessionOrganizationFilter>>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type ContractConcessionOrganizationFilterConcessionOrganizationAccreditationFilter = {
  and?: InputMaybe<Array<ContractConcessionOrganizationFilterConcessionOrganizationAccreditationFilter>>;
  endDate?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractConcessionOrganizationFilterConcessionOrganizationAccreditationFilter>>;
  sequence?: InputMaybe<NumberFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type ContractConcessionOrganizationFilterConcessionOrganizationFilter = {
  and?: InputMaybe<Array<ContractConcessionOrganizationFilterConcessionOrganizationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ContractConcessionOrganizationFilterConcessionOrganizationFilter>>;
};

export type ContractConcessionOrganizationFilterContractConcessionOrganizationStateFilter = {
  and?: InputMaybe<Array<ContractConcessionOrganizationFilterContractConcessionOrganizationStateFilter>>;
  backgroundColor?: InputMaybe<StringFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  color?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractConcessionOrganizationFilterContractConcessionOrganizationStateFilter>>;
};

export type ContractConcessionOrganizationFilterContractFilter = {
  and?: InputMaybe<Array<ContractConcessionOrganizationFilterContractFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractConcessionOrganizationFilterContractFilter>>;
  realEndDate?: InputMaybe<StringFieldComparison>;
  realStartDate?: InputMaybe<StringFieldComparison>;
  road?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type ContractConcessionOrganizationMaxAggregate = {
  __typename?: 'ContractConcessionOrganizationMaxAggregate';
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['String']>;
};

export type ContractConcessionOrganizationMinAggregate = {
  __typename?: 'ContractConcessionOrganizationMinAggregate';
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['String']>;
};

export type ContractConcessionOrganizationOffsetConnection = {
  __typename?: 'ContractConcessionOrganizationOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ContractConcessionOrganization>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ContractConcessionOrganizationSort = {
  direction: SortDirection;
  field: ContractConcessionOrganizationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ContractConcessionOrganizationSortFields {
  Description = 'description',
  EndDate = 'endDate',
  Id = 'id',
  StartDate = 'startDate'
}

export type ContractConcessionOrganizationState = {
  __typename?: 'ContractConcessionOrganizationState';
  backgroundColor: Scalars['String'];
  code: Scalars['String'];
  color: Scalars['String'];
  concession: Concession;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ContractConcessionOrganizationStateAggregateGroupBy = {
  __typename?: 'ContractConcessionOrganizationStateAggregateGroupBy';
  backgroundColor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ContractConcessionOrganizationStateAvgAggregate = {
  __typename?: 'ContractConcessionOrganizationStateAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractConcessionOrganizationStateConnection = {
  __typename?: 'ContractConcessionOrganizationStateConnection';
  /** Array of nodes. */
  nodes: Array<ContractConcessionOrganizationState>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ContractConcessionOrganizationStateCountAggregate = {
  __typename?: 'ContractConcessionOrganizationStateCountAggregate';
  backgroundColor?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type ContractConcessionOrganizationStateDeleteFilter = {
  and?: InputMaybe<Array<ContractConcessionOrganizationStateDeleteFilter>>;
  backgroundColor?: InputMaybe<StringFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  color?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractConcessionOrganizationStateDeleteFilter>>;
};

export type ContractConcessionOrganizationStateDeleteResponse = {
  __typename?: 'ContractConcessionOrganizationStateDeleteResponse';
  backgroundColor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContractConcessionOrganizationStateFilter = {
  and?: InputMaybe<Array<ContractConcessionOrganizationStateFilter>>;
  backgroundColor?: InputMaybe<StringFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  color?: InputMaybe<StringFieldComparison>;
  concession?: InputMaybe<ContractConcessionOrganizationStateFilterConcessionFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractConcessionOrganizationStateFilter>>;
};

export type ContractConcessionOrganizationStateFilterConcessionFilter = {
  and?: InputMaybe<Array<ContractConcessionOrganizationStateFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractConcessionOrganizationStateFilterConcessionFilter>>;
};

export type ContractConcessionOrganizationStateMaxAggregate = {
  __typename?: 'ContractConcessionOrganizationStateMaxAggregate';
  backgroundColor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ContractConcessionOrganizationStateMinAggregate = {
  __typename?: 'ContractConcessionOrganizationStateMinAggregate';
  backgroundColor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ContractConcessionOrganizationStateSort = {
  direction: SortDirection;
  field: ContractConcessionOrganizationStateSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ContractConcessionOrganizationStateSortFields {
  BackgroundColor = 'backgroundColor',
  Code = 'code',
  Color = 'color',
  Id = 'id',
  Name = 'name'
}

export type ContractConcessionOrganizationStateSumAggregate = {
  __typename?: 'ContractConcessionOrganizationStateSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractConcessionOrganizationStateUpdateFilter = {
  and?: InputMaybe<Array<ContractConcessionOrganizationStateUpdateFilter>>;
  backgroundColor?: InputMaybe<StringFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  color?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractConcessionOrganizationStateUpdateFilter>>;
};

export type ContractConcessionOrganizationSumAggregate = {
  __typename?: 'ContractConcessionOrganizationSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractConcessionOrganizationUpdateFilter = {
  and?: InputMaybe<Array<ContractConcessionOrganizationUpdateFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ContractConcessionOrganizationUpdateFilter>>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type ContractCountAggregate = {
  __typename?: 'ContractCountAggregate';
  description?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Int']>;
  executingOrganization?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  realEndDate?: Maybe<Scalars['Int']>;
  realStartDate?: Maybe<Scalars['Int']>;
  road?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Int']>;
};

export type ContractDeleteFilter = {
  and?: InputMaybe<Array<ContractDeleteFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractDeleteFilter>>;
  realEndDate?: InputMaybe<StringFieldComparison>;
  realStartDate?: InputMaybe<StringFieldComparison>;
  road?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type ContractDeleteResponse = {
  __typename?: 'ContractDeleteResponse';
  amendedValue?: Maybe<Scalars['Float']>;
  billedValue?: Maybe<Scalars['Float']>;
  contractClosedNotificationSent?: Maybe<Scalars['Boolean']>;
  contractOpenedNotificationSent?: Maybe<Scalars['Boolean']>;
  contractRoadsObservations?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  executingOrganization?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  originalValue?: Maybe<Scalars['Float']>;
  realEndDate?: Maybe<Scalars['String']>;
  realStartDate?: Maybe<Scalars['String']>;
  road?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContractDocument = {
  __typename?: 'ContractDocument';
  contract: Contract;
  contractDocumentCategory?: Maybe<ContractDocumentCategory>;
  contractDocumentSubcategory?: Maybe<ContractDocumentCategory>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  documentState?: Maybe<DocumentState>;
  expiresAt?: Maybe<Scalars['String']>;
  fileType?: Maybe<FileType>;
  id: Scalars['ID'];
  name: Scalars['String'];
  owner?: Maybe<User>;
  path: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ContractDocumentAggregateGroupBy = {
  __typename?: 'ContractDocumentAggregateGroupBy';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type ContractDocumentAvgAggregate = {
  __typename?: 'ContractDocumentAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractDocumentCategory = {
  __typename?: 'ContractDocumentCategory';
  children?: Maybe<Array<ContractDocumentCategory>>;
  concession: Concession;
  createdAt: Scalars['DateTime'];
  expirationRequired: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  observations?: Maybe<Scalars['String']>;
  parent?: Maybe<ContractDocumentCategory>;
  policy: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type ContractDocumentCategoryChildrenArgs = {
  filter?: InputMaybe<ContractDocumentCategoryFilter>;
  sorting?: InputMaybe<Array<ContractDocumentCategorySort>>;
};

export type ContractDocumentCategoryAggregateGroupBy = {
  __typename?: 'ContractDocumentCategoryAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  policy?: Maybe<Scalars['String']>;
};

export type ContractDocumentCategoryAvgAggregate = {
  __typename?: 'ContractDocumentCategoryAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractDocumentCategoryConnection = {
  __typename?: 'ContractDocumentCategoryConnection';
  /** Array of nodes. */
  nodes: Array<ContractDocumentCategory>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ContractDocumentCategoryCountAggregate = {
  __typename?: 'ContractDocumentCategoryCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  policy?: Maybe<Scalars['Int']>;
};

export type ContractDocumentCategoryDeleteFilter = {
  and?: InputMaybe<Array<ContractDocumentCategoryDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractDocumentCategoryDeleteFilter>>;
  policy?: InputMaybe<StringFieldComparison>;
};

export type ContractDocumentCategoryDeleteResponse = {
  __typename?: 'ContractDocumentCategoryDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationRequired?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  policy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContractDocumentCategoryFilter = {
  and?: InputMaybe<Array<ContractDocumentCategoryFilter>>;
  concession?: InputMaybe<ContractDocumentCategoryFilterConcessionFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractDocumentCategoryFilter>>;
  parent?: InputMaybe<ContractDocumentCategoryFilterContractDocumentCategoryFilter>;
  policy?: InputMaybe<StringFieldComparison>;
};

export type ContractDocumentCategoryFilterConcessionFilter = {
  and?: InputMaybe<Array<ContractDocumentCategoryFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractDocumentCategoryFilterConcessionFilter>>;
};

export type ContractDocumentCategoryFilterContractDocumentCategoryFilter = {
  and?: InputMaybe<Array<ContractDocumentCategoryFilterContractDocumentCategoryFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractDocumentCategoryFilterContractDocumentCategoryFilter>>;
  policy?: InputMaybe<StringFieldComparison>;
};

export type ContractDocumentCategoryMaxAggregate = {
  __typename?: 'ContractDocumentCategoryMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  policy?: Maybe<Scalars['String']>;
};

export type ContractDocumentCategoryMinAggregate = {
  __typename?: 'ContractDocumentCategoryMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  policy?: Maybe<Scalars['String']>;
};

export type ContractDocumentCategorySort = {
  direction: SortDirection;
  field: ContractDocumentCategorySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ContractDocumentCategorySortFields {
  Id = 'id',
  Name = 'name',
  Policy = 'policy'
}

export type ContractDocumentCategorySumAggregate = {
  __typename?: 'ContractDocumentCategorySumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractDocumentCategoryUpdateFilter = {
  and?: InputMaybe<Array<ContractDocumentCategoryUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractDocumentCategoryUpdateFilter>>;
  policy?: InputMaybe<StringFieldComparison>;
};

export type ContractDocumentCountAggregate = {
  __typename?: 'ContractDocumentCountAggregate';
  description?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['Int']>;
};

export type ContractDocumentDeleteFilter = {
  and?: InputMaybe<Array<ContractDocumentDeleteFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractDocumentDeleteFilter>>;
  path?: InputMaybe<StringFieldComparison>;
};

export type ContractDocumentDeleteResponse = {
  __typename?: 'ContractDocumentDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContractDocumentFilter = {
  and?: InputMaybe<Array<ContractDocumentFilter>>;
  contract?: InputMaybe<ContractDocumentFilterContractFilter>;
  contractDocumentCategory?: InputMaybe<ContractDocumentFilterContractDocumentCategoryFilter>;
  contractDocumentSubcategory?: InputMaybe<ContractDocumentFilterContractDocumentCategoryFilter>;
  description?: InputMaybe<StringFieldComparison>;
  documentState?: InputMaybe<ContractDocumentFilterDocumentStateFilter>;
  fileType?: InputMaybe<ContractDocumentFilterFileTypeFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractDocumentFilter>>;
  owner?: InputMaybe<ContractDocumentFilterUserFilter>;
  path?: InputMaybe<StringFieldComparison>;
};

export type ContractDocumentFilterContractDocumentCategoryFilter = {
  and?: InputMaybe<Array<ContractDocumentFilterContractDocumentCategoryFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractDocumentFilterContractDocumentCategoryFilter>>;
  policy?: InputMaybe<StringFieldComparison>;
};

export type ContractDocumentFilterContractFilter = {
  and?: InputMaybe<Array<ContractDocumentFilterContractFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractDocumentFilterContractFilter>>;
  realEndDate?: InputMaybe<StringFieldComparison>;
  realStartDate?: InputMaybe<StringFieldComparison>;
  road?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type ContractDocumentFilterDocumentStateFilter = {
  and?: InputMaybe<Array<ContractDocumentFilterDocumentStateFilter>>;
  backgroundColor?: InputMaybe<StringFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  color?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractDocumentFilterDocumentStateFilter>>;
  stateType?: InputMaybe<StringFieldComparison>;
};

export type ContractDocumentFilterFileTypeFilter = {
  and?: InputMaybe<Array<ContractDocumentFilterFileTypeFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isPreviewable?: InputMaybe<BooleanFieldComparison>;
  mimeType?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractDocumentFilterFileTypeFilter>>;
};

export type ContractDocumentFilterUserFilter = {
  and?: InputMaybe<Array<ContractDocumentFilterUserFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractDocumentFilterUserFilter>>;
};

export type ContractDocumentMaxAggregate = {
  __typename?: 'ContractDocumentMaxAggregate';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type ContractDocumentMinAggregate = {
  __typename?: 'ContractDocumentMinAggregate';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type ContractDocumentOffsetConnection = {
  __typename?: 'ContractDocumentOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ContractDocument>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ContractDocumentSort = {
  direction: SortDirection;
  field: ContractDocumentSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ContractDocumentSortFields {
  Description = 'description',
  Id = 'id',
  Name = 'name',
  Path = 'path'
}

export type ContractDocumentSumAggregate = {
  __typename?: 'ContractDocumentSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractDocumentUpdateFilter = {
  and?: InputMaybe<Array<ContractDocumentUpdateFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractDocumentUpdateFilter>>;
  path?: InputMaybe<StringFieldComparison>;
};

export type ContractFilter = {
  and?: InputMaybe<Array<ContractFilter>>;
  concession?: InputMaybe<ContractFilterConcessionFilter>;
  contractConcessionOrganizations?: InputMaybe<ContractFilterContractConcessionOrganizationFilter>;
  contractDocuments?: InputMaybe<ContractFilterContractDocumentFilter>;
  contractRoads?: InputMaybe<ContractFilterContractRoadFilter>;
  contractState?: InputMaybe<ContractFilterContractStateFilter>;
  contractTransitions?: InputMaybe<ContractFilterContractTransitionFilter>;
  contractType?: InputMaybe<ContractFilterContractTypeFilter>;
  contractUsers?: InputMaybe<ContractFilterContractUserFilter>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  interventionElement?: InputMaybe<ContractFilterInterventionElementFilter>;
  interventionLocation?: InputMaybe<ContractFilterInterventionLocationFilter>;
  interventionType?: InputMaybe<ContractFilterInterventionTypeFilter>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractFilter>>;
  realEndDate?: InputMaybe<StringFieldComparison>;
  realStartDate?: InputMaybe<StringFieldComparison>;
  road?: InputMaybe<StringFieldComparison>;
  service?: InputMaybe<ContractFilterServiceFilter>;
  startDate?: InputMaybe<StringFieldComparison>;
  surveyRecords?: InputMaybe<ContractFilterSurveyRecordFilter>;
  workPermits?: InputMaybe<ContractFilterWorkPermitFilter>;
};

export type ContractFilterConcessionFilter = {
  and?: InputMaybe<Array<ContractFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractFilterConcessionFilter>>;
};

export type ContractFilterContractConcessionOrganizationFilter = {
  and?: InputMaybe<Array<ContractFilterContractConcessionOrganizationFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ContractFilterContractConcessionOrganizationFilter>>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type ContractFilterContractDocumentFilter = {
  and?: InputMaybe<Array<ContractFilterContractDocumentFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractFilterContractDocumentFilter>>;
  path?: InputMaybe<StringFieldComparison>;
};

export type ContractFilterContractRoadFilter = {
  and?: InputMaybe<Array<ContractFilterContractRoadFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ContractFilterContractRoadFilter>>;
};

export type ContractFilterContractStateFilter = {
  and?: InputMaybe<Array<ContractFilterContractStateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractFilterContractStateFilter>>;
  stateType?: InputMaybe<StringFieldComparison>;
};

export type ContractFilterContractTransitionFilter = {
  and?: InputMaybe<Array<ContractFilterContractTransitionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ContractFilterContractTransitionFilter>>;
};

export type ContractFilterContractTypeFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ContractFilterContractTypeFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractFilterContractTypeFilter>>;
};

export type ContractFilterContractUserFilter = {
  and?: InputMaybe<Array<ContractFilterContractUserFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ContractFilterContractUserFilter>>;
};

export type ContractFilterInterventionElementFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ContractFilterInterventionElementFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractFilterInterventionElementFilter>>;
};

export type ContractFilterInterventionLocationFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ContractFilterInterventionLocationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  locationType?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractFilterInterventionLocationFilter>>;
};

export type ContractFilterInterventionTypeFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ContractFilterInterventionTypeFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractFilterInterventionTypeFilter>>;
};

export type ContractFilterServiceFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ContractFilterServiceFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractFilterServiceFilter>>;
};

export type ContractFilterSurveyRecordFilter = {
  and?: InputMaybe<Array<ContractFilterSurveyRecordFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endPk?: InputMaybe<StringFieldComparison>;
  hasCorrectiveRequests?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isPublished?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractFilterSurveyRecordFilter>>;
  participants?: InputMaybe<StringFieldComparison>;
  sequence?: InputMaybe<NumberFieldComparison>;
  startPk?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  surveyedAt?: InputMaybe<DateFieldComparison>;
};

export type ContractFilterWorkPermitFilter = {
  and?: InputMaybe<Array<ContractFilterWorkPermitFilter>>;
  endAt?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isPublished?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractFilterWorkPermitFilter>>;
  publishedAt?: InputMaybe<StringFieldComparison>;
  startAt?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
};

export type ContractMaxAggregate = {
  __typename?: 'ContractMaxAggregate';
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  executingOrganization?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  realEndDate?: Maybe<Scalars['String']>;
  realStartDate?: Maybe<Scalars['String']>;
  road?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

export type ContractMinAggregate = {
  __typename?: 'ContractMinAggregate';
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  executingOrganization?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  realEndDate?: Maybe<Scalars['String']>;
  realStartDate?: Maybe<Scalars['String']>;
  road?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

export type ContractOffsetConnection = {
  __typename?: 'ContractOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Contract>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ContractRoad = {
  __typename?: 'ContractRoad';
  contract: Contract;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  road: Road;
  updatedAt: Scalars['DateTime'];
};

export type ContractRoadAggregateGroupBy = {
  __typename?: 'ContractRoadAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type ContractRoadAvgAggregate = {
  __typename?: 'ContractRoadAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractRoadConnection = {
  __typename?: 'ContractRoadConnection';
  /** Array of nodes. */
  nodes: Array<ContractRoad>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ContractRoadCountAggregate = {
  __typename?: 'ContractRoadCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type ContractRoadDeleteFilter = {
  and?: InputMaybe<Array<ContractRoadDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ContractRoadDeleteFilter>>;
};

export type ContractRoadDeleteResponse = {
  __typename?: 'ContractRoadDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContractRoadFilter = {
  and?: InputMaybe<Array<ContractRoadFilter>>;
  contract?: InputMaybe<ContractRoadFilterContractFilter>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ContractRoadFilter>>;
  road?: InputMaybe<ContractRoadFilterRoadFilter>;
};

export type ContractRoadFilterContractFilter = {
  and?: InputMaybe<Array<ContractRoadFilterContractFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractRoadFilterContractFilter>>;
  realEndDate?: InputMaybe<StringFieldComparison>;
  realStartDate?: InputMaybe<StringFieldComparison>;
  road?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type ContractRoadFilterRoadFilter = {
  and?: InputMaybe<Array<ContractRoadFilterRoadFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractRoadFilterRoadFilter>>;
};

export type ContractRoadMaxAggregate = {
  __typename?: 'ContractRoadMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type ContractRoadMinAggregate = {
  __typename?: 'ContractRoadMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type ContractRoadSort = {
  direction: SortDirection;
  field: ContractRoadSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ContractRoadSortFields {
  Id = 'id'
}

export type ContractRoadSumAggregate = {
  __typename?: 'ContractRoadSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractRoadUpdateFilter = {
  and?: InputMaybe<Array<ContractRoadUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ContractRoadUpdateFilter>>;
};

export type ContractSort = {
  direction: SortDirection;
  field: ContractSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ContractSortFields {
  Description = 'description',
  EndDate = 'endDate',
  ExecutingOrganization = 'executingOrganization',
  Id = 'id',
  Name = 'name',
  RealEndDate = 'realEndDate',
  RealStartDate = 'realStartDate',
  Road = 'road',
  StartDate = 'startDate'
}

export type ContractState = {
  __typename?: 'ContractState';
  concession: Concession;
  contracts: Array<Contract>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  stateType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type ContractStateContractsArgs = {
  filter?: InputMaybe<ContractFilter>;
  sorting?: InputMaybe<Array<ContractSort>>;
};

export type ContractStateAggregateGroupBy = {
  __typename?: 'ContractStateAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  stateType?: Maybe<Scalars['String']>;
};

export type ContractStateAvgAggregate = {
  __typename?: 'ContractStateAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractStateConnection = {
  __typename?: 'ContractStateConnection';
  /** Array of nodes. */
  nodes: Array<ContractState>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ContractStateCountAggregate = {
  __typename?: 'ContractStateCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  stateType?: Maybe<Scalars['Int']>;
};

export type ContractStateDeleteFilter = {
  and?: InputMaybe<Array<ContractStateDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractStateDeleteFilter>>;
  stateType?: InputMaybe<StringFieldComparison>;
};

export type ContractStateDeleteResponse = {
  __typename?: 'ContractStateDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  stateType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContractStateFilter = {
  and?: InputMaybe<Array<ContractStateFilter>>;
  concession?: InputMaybe<ContractStateFilterConcessionFilter>;
  contract?: InputMaybe<ContractStateFilterContractFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractStateFilter>>;
  stateType?: InputMaybe<StringFieldComparison>;
};

export type ContractStateFilterConcessionFilter = {
  and?: InputMaybe<Array<ContractStateFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractStateFilterConcessionFilter>>;
};

export type ContractStateFilterContractFilter = {
  and?: InputMaybe<Array<ContractStateFilterContractFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractStateFilterContractFilter>>;
  realEndDate?: InputMaybe<StringFieldComparison>;
  realStartDate?: InputMaybe<StringFieldComparison>;
  road?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type ContractStateMaxAggregate = {
  __typename?: 'ContractStateMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  stateType?: Maybe<Scalars['String']>;
};

export type ContractStateMinAggregate = {
  __typename?: 'ContractStateMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  stateType?: Maybe<Scalars['String']>;
};

export type ContractStateSort = {
  direction: SortDirection;
  field: ContractStateSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ContractStateSortFields {
  Id = 'id',
  Name = 'name',
  StateType = 'stateType'
}

export type ContractStateSumAggregate = {
  __typename?: 'ContractStateSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractStateUpdateFilter = {
  and?: InputMaybe<Array<ContractStateUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractStateUpdateFilter>>;
  stateType?: InputMaybe<StringFieldComparison>;
};

export type ContractSumAggregate = {
  __typename?: 'ContractSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractTransition = {
  __typename?: 'ContractTransition';
  createdAt: Scalars['DateTime'];
  fromState: ContractState;
  id: Scalars['ID'];
  toState: ContractState;
  updatedAt: Scalars['DateTime'];
};

export type ContractTransitionAggregateGroupBy = {
  __typename?: 'ContractTransitionAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type ContractTransitionAvgAggregate = {
  __typename?: 'ContractTransitionAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractTransitionConnection = {
  __typename?: 'ContractTransitionConnection';
  /** Array of nodes. */
  nodes: Array<ContractTransition>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ContractTransitionCountAggregate = {
  __typename?: 'ContractTransitionCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type ContractTransitionDeleteFilter = {
  and?: InputMaybe<Array<ContractTransitionDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ContractTransitionDeleteFilter>>;
};

export type ContractTransitionDeleteResponse = {
  __typename?: 'ContractTransitionDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContractTransitionFilter = {
  and?: InputMaybe<Array<ContractTransitionFilter>>;
  fromState?: InputMaybe<ContractTransitionFilterContractStateFilter>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ContractTransitionFilter>>;
  toState?: InputMaybe<ContractTransitionFilterContractStateFilter>;
};

export type ContractTransitionFilterContractStateFilter = {
  and?: InputMaybe<Array<ContractTransitionFilterContractStateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractTransitionFilterContractStateFilter>>;
  stateType?: InputMaybe<StringFieldComparison>;
};

export type ContractTransitionMaxAggregate = {
  __typename?: 'ContractTransitionMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type ContractTransitionMinAggregate = {
  __typename?: 'ContractTransitionMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type ContractTransitionSort = {
  direction: SortDirection;
  field: ContractTransitionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ContractTransitionSortFields {
  Id = 'id'
}

export type ContractTransitionSumAggregate = {
  __typename?: 'ContractTransitionSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractTransitionUpdateFilter = {
  and?: InputMaybe<Array<ContractTransitionUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ContractTransitionUpdateFilter>>;
};

export type ContractType = {
  __typename?: 'ContractType';
  abbreviation: Scalars['String'];
  concession: Concession;
  contracts: Array<Contract>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type ContractTypeContractsArgs = {
  filter?: InputMaybe<ContractFilter>;
  sorting?: InputMaybe<Array<ContractSort>>;
};

export type ContractTypeAggregateGroupBy = {
  __typename?: 'ContractTypeAggregateGroupBy';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ContractTypeAvgAggregate = {
  __typename?: 'ContractTypeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractTypeConnection = {
  __typename?: 'ContractTypeConnection';
  /** Array of nodes. */
  nodes: Array<ContractType>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ContractTypeCountAggregate = {
  __typename?: 'ContractTypeCountAggregate';
  abbreviation?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type ContractTypeDeleteFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ContractTypeDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractTypeDeleteFilter>>;
};

export type ContractTypeDeleteResponse = {
  __typename?: 'ContractTypeDeleteResponse';
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContractTypeFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ContractTypeFilter>>;
  concession?: InputMaybe<ContractTypeFilterConcessionFilter>;
  contract?: InputMaybe<ContractTypeFilterContractFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractTypeFilter>>;
};

export type ContractTypeFilterConcessionFilter = {
  and?: InputMaybe<Array<ContractTypeFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractTypeFilterConcessionFilter>>;
};

export type ContractTypeFilterContractFilter = {
  and?: InputMaybe<Array<ContractTypeFilterContractFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractTypeFilterContractFilter>>;
  realEndDate?: InputMaybe<StringFieldComparison>;
  realStartDate?: InputMaybe<StringFieldComparison>;
  road?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type ContractTypeMaxAggregate = {
  __typename?: 'ContractTypeMaxAggregate';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ContractTypeMinAggregate = {
  __typename?: 'ContractTypeMinAggregate';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ContractTypeSort = {
  direction: SortDirection;
  field: ContractTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ContractTypeSortFields {
  Abbreviation = 'abbreviation',
  Id = 'id',
  Name = 'name'
}

export type ContractTypeSumAggregate = {
  __typename?: 'ContractTypeSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractTypeUpdateFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ContractTypeUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractTypeUpdateFilter>>;
};

export type ContractUpdateFilter = {
  and?: InputMaybe<Array<ContractUpdateFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractUpdateFilter>>;
  realEndDate?: InputMaybe<StringFieldComparison>;
  realStartDate?: InputMaybe<StringFieldComparison>;
  road?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type ContractUser = {
  __typename?: 'ContractUser';
  contract: Contract;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  role: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type ContractUserAggregateGroupBy = {
  __typename?: 'ContractUserAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type ContractUserAvgAggregate = {
  __typename?: 'ContractUserAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractUserConnection = {
  __typename?: 'ContractUserConnection';
  /** Array of nodes. */
  nodes: Array<ContractUser>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ContractUserCountAggregate = {
  __typename?: 'ContractUserCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type ContractUserDeleteFilter = {
  and?: InputMaybe<Array<ContractUserDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ContractUserDeleteFilter>>;
};

export type ContractUserDeleteResponse = {
  __typename?: 'ContractUserDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContractUserFilter = {
  and?: InputMaybe<Array<ContractUserFilter>>;
  contract?: InputMaybe<ContractUserFilterContractFilter>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ContractUserFilter>>;
  user?: InputMaybe<ContractUserFilterUserFilter>;
};

export type ContractUserFilterContractFilter = {
  and?: InputMaybe<Array<ContractUserFilterContractFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractUserFilterContractFilter>>;
  realEndDate?: InputMaybe<StringFieldComparison>;
  realStartDate?: InputMaybe<StringFieldComparison>;
  road?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type ContractUserFilterUserFilter = {
  and?: InputMaybe<Array<ContractUserFilterUserFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContractUserFilterUserFilter>>;
};

export type ContractUserMaxAggregate = {
  __typename?: 'ContractUserMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type ContractUserMinAggregate = {
  __typename?: 'ContractUserMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type ContractUserRole = {
  role: ContractUserRoles;
  user: Scalars['ID'];
};

export enum ContractUserRoles {
  Contractmanager = 'CONTRACTMANAGER',
  Externaladministrative = 'EXTERNALADMINISTRATIVE',
  Externalauthority = 'EXTERNALAUTHORITY',
  Externalmanager = 'EXTERNALMANAGER',
  Externaltechnician = 'EXTERNALTECHNICIAN',
  Safetytechnician = 'SAFETYTECHNICIAN',
  Servicemanager = 'SERVICEMANAGER',
  Subcontractauthority = 'SUBCONTRACTAUTHORITY'
}

export type ContractUserSort = {
  direction: SortDirection;
  field: ContractUserSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ContractUserSortFields {
  Id = 'id'
}

export type ContractUserSumAggregate = {
  __typename?: 'ContractUserSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ContractUserUpdateFilter = {
  and?: InputMaybe<Array<ContractUserUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ContractUserUpdateFilter>>;
};

export type ControlCenter = {
  __typename?: 'ControlCenter';
  concession: Concession;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ControlCenterAggregateGroupBy = {
  __typename?: 'ControlCenterAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ControlCenterAvgAggregate = {
  __typename?: 'ControlCenterAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ControlCenterConnection = {
  __typename?: 'ControlCenterConnection';
  /** Array of nodes. */
  nodes: Array<ControlCenter>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ControlCenterCountAggregate = {
  __typename?: 'ControlCenterCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['Int']>;
};

export type ControlCenterDeleteFilter = {
  and?: InputMaybe<Array<ControlCenterDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ControlCenterDeleteFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
};

export type ControlCenterDeleteResponse = {
  __typename?: 'ControlCenterDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ControlCenterFilter = {
  and?: InputMaybe<Array<ControlCenterFilter>>;
  concession?: InputMaybe<ControlCenterFilterConcessionFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ControlCenterFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
};

export type ControlCenterFilterConcessionFilter = {
  and?: InputMaybe<Array<ControlCenterFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ControlCenterFilterConcessionFilter>>;
};

export type ControlCenterMaxAggregate = {
  __typename?: 'ControlCenterMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ControlCenterMinAggregate = {
  __typename?: 'ControlCenterMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ControlCenterSort = {
  direction: SortDirection;
  field: ControlCenterSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ControlCenterSortFields {
  Id = 'id',
  Name = 'name',
  Phone = 'phone'
}

export type ControlCenterSumAggregate = {
  __typename?: 'ControlCenterSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ControlCenterUpdateFilter = {
  and?: InputMaybe<Array<ControlCenterUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ControlCenterUpdateFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
};

export type CreateBadge = {
  company: Scalars['String'];
  driver: Scalars['String'];
  licensePlate: Scalars['String'];
  state?: InputMaybe<BadgeStates>;
  workPermit: Scalars['ID'];
};

export type CreateConcession = {
  name: Scalars['String'];
};

export type CreateConcessionOrganization = {
  concession: Scalars['ID'];
  organization: Scalars['ID'];
  organizationType: Scalars['ID'];
};

export type CreateConcessionOrganizationAccreditation = {
  concessionOrganization: Scalars['ID'];
  concessionOrganizationAccreditationType: Scalars['ID'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type CreateConcessionOrganizationAccreditationDocument = {
  concessionOrganizationAccreditation: Scalars['ID'];
  concessionOrganizationDocument: Scalars['ID'];
};

export type CreateConcessionOrganizationAccreditationState = {
  backgroundColor?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateConcessionOrganizationAccreditationType = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateConcessionOrganizationDocument = {
  concessionOrganization: Scalars['ID'];
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  path: Scalars['Upload'];
};

export type CreateConcessionOrganizationDocumentCategory = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expirationRequired?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  observations?: InputMaybe<Scalars['String']>;
  policy?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateConcessionOrganizationUser = {
  concessionOrganization: Scalars['ID'];
  role: ConcessionOrganizationUserRoles;
  user: Scalars['ID'];
};

export type CreateConcessionUser = {
  concession: Scalars['ID'];
  role: ConcessionUserRoles;
  user: Scalars['ID'];
};

export type CreateContract = {
  amendedValue?: InputMaybe<Scalars['Float']>;
  billedValue?: InputMaybe<Scalars['Float']>;
  contractRoadsObservations?: InputMaybe<Scalars['String']>;
  contractType: Scalars['ID'];
  description: Scalars['String'];
  endDate: Scalars['DateTime'];
  interventionElement: Scalars['ID'];
  interventionLocation: Scalars['ID'];
  interventionType: Scalars['ID'];
  originalValue?: InputMaybe<Scalars['Float']>;
  realEndDate: Scalars['DateTime'];
  realStartDate: Scalars['DateTime'];
  service: Scalars['ID'];
  startDate: Scalars['DateTime'];
};

export type CreateContractConcessionOrganization = {
  concessionOrganization: Scalars['ID'];
  contract: Scalars['ID'];
};

export type CreateContractConcessionOrganizationState = {
  backgroundColor?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateContractDocument = {
  contract: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  path: Scalars['Upload'];
};

export type CreateContractDocumentCategory = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expirationRequired?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  observations?: InputMaybe<Scalars['String']>;
  policy?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateContractRoad = {
  contract: Scalars['ID'];
  road: Scalars['ID'];
};

export type CreateContractState = {
  name: Scalars['String'];
};

export type CreateContractTransition = {
  fromState: Scalars['ID'];
  toState: Scalars['ID'];
};

export type CreateContractType = {
  abbreviation: Scalars['String'];
  concession: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateContractUser = {
  contract: Scalars['ID'];
  role: ContractUserRoles;
  user: Scalars['ID'];
};

export type CreateControlCenter = {
  concession: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type CreateDirection = {
  concession: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateIntervention = {
  week: Scalars['Float'];
  workPermit: Scalars['ID'];
  year: Scalars['Float'];
};

export type CreateInterventionElement = {
  abbreviation: Scalars['String'];
  concession: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateInterventionLocation = {
  abbreviation: Scalars['String'];
  concession: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateInterventionType = {
  abbreviation: Scalars['String'];
  concession: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateJuntion = {
  name: Scalars['String'];
  pk: Scalars['String'];
  road: Scalars['ID'];
};

export type CreateLayout = {
  concession: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateManyBadgesInput = {
  /** Array of records to create */
  badges: Array<CreateBadge>;
};

export type CreateManyConcessionOrganizationAccreditationStatesInput = {
  /** Array of records to create */
  concessionOrganizationAccreditationStates: Array<CreateConcessionOrganizationAccreditationState>;
};

export type CreateManyConcessionOrganizationAccreditationTypesInput = {
  /** Array of records to create */
  concessionOrganizationAccreditationTypes: Array<CreateConcessionOrganizationAccreditationType>;
};

export type CreateManyConcessionOrganizationAccreditationsInput = {
  /** Array of records to create */
  concessionOrganizationAccreditations: Array<CreateConcessionOrganizationAccreditation>;
};

export type CreateManyConcessionOrganizationDocumentCategoriesInput = {
  /** Array of records to create */
  concessionOrganizationDocumentCategories: Array<CreateConcessionOrganizationDocumentCategory>;
};

export type CreateManyConcessionOrganizationUsersInput = {
  /** Array of records to create */
  concessionOrganizationUsers: Array<CreateConcessionOrganizationUser>;
};

export type CreateManyConcessionOrganizationsInput = {
  /** Array of records to create */
  concessionOrganizations: Array<CreateConcessionOrganization>;
};

export type CreateManyConcessionUsersInput = {
  /** Array of records to create */
  concessionUsers: Array<CreateConcessionUser>;
};

export type CreateManyConcessionsInput = {
  /** Array of records to create */
  concessions: Array<CreateConcession>;
};

export type CreateManyContractConcessionOrganizationStatesInput = {
  /** Array of records to create */
  contractConcessionOrganizationStates: Array<CreateContractConcessionOrganizationState>;
};

export type CreateManyContractConcessionOrganizationsDedupedInput = {
  concessionOrganizations: Array<Scalars['ID']>;
  contract: Scalars['ID'];
};

export type CreateManyContractConcessionOrganizationsInput = {
  /** Array of records to create */
  contractConcessionOrganizations: Array<CreateContractConcessionOrganization>;
};

export type CreateManyContractDocumentCategoriesInput = {
  /** Array of records to create */
  contractDocumentCategories: Array<CreateContractDocumentCategory>;
};

export type CreateManyContractRoadsInput = {
  /** Array of records to create */
  contractRoads: Array<CreateContractRoad>;
};

export type CreateManyContractStatesInput = {
  /** Array of records to create */
  contractStates: Array<CreateContractState>;
};

export type CreateManyContractTransitionsInput = {
  /** Array of records to create */
  contractTransitions: Array<CreateContractTransition>;
};

export type CreateManyContractTypesInput = {
  /** Array of records to create */
  contractTypes: Array<CreateContractType>;
};

export type CreateManyContractUsersInput = {
  /** Array of records to create */
  contractUsers: Array<CreateContractUser>;
};

export type CreateManyControlCentersInput = {
  /** Array of records to create */
  controlCenters: Array<CreateControlCenter>;
};

export type CreateManyDirectionsInput = {
  /** Array of records to create */
  directions: Array<CreateDirection>;
};

export type CreateManyInterventionElementsInput = {
  /** Array of records to create */
  interventionElements: Array<CreateInterventionElement>;
};

export type CreateManyInterventionLocationsInput = {
  /** Array of records to create */
  interventionLocations: Array<CreateInterventionLocation>;
};

export type CreateManyInterventionTypesInput = {
  /** Array of records to create */
  interventionTypes: Array<CreateInterventionType>;
};

export type CreateManyInterventionsInput = {
  /** Array of records to create */
  interventions: Array<CreateIntervention>;
};

export type CreateManyJunctionsInput = {
  /** Array of records to create */
  junctions: Array<CreateJuntion>;
};

export type CreateManyLayoutsInput = {
  /** Array of records to create */
  layouts: Array<CreateLayout>;
};

export type CreateManyOrganizationTypesInput = {
  /** Array of records to create */
  organizationTypes: Array<CreateOrganizationType>;
};

export type CreateManyOrganizationsInput = {
  /** Array of records to create */
  organizations: Array<CreateOrganization>;
};

export type CreateManyRestrictionsInput = {
  /** Array of records to create */
  restrictions: Array<CreateRestriction>;
};

export type CreateManyRoadsInput = {
  /** Array of records to create */
  roads: Array<CreateRoad>;
};

export type CreateManyServicesInput = {
  /** Array of records to create */
  services: Array<CreateService>;
};

export type CreateManySurveyRecordPhotosInput = {
  /** Array of records to create */
  surveyRecordPhotos: Array<CreateSurveyRecordPhoto>;
};

export type CreateManySurveyRecordVerificationClassificationsInput = {
  /** Array of records to create */
  surveyRecordVerificationClassifications: Array<CreateSurveyRecordVerificationClassification>;
};

export type CreateManySurveyRecordVerificationStatesInput = {
  /** Array of records to create */
  surveyRecordVerificationStates: Array<CreateSurveyRecordVerificationState>;
};

export type CreateManySurveyRecordVerificationsInput = {
  /** Array of records to create */
  surveyRecordVerifications: Array<CreateSurveyRecordVerification>;
};

export type CreateManySurveyRecordsInput = {
  surveyRecords: Array<CreateSurveyRecord>;
};

export type CreateManyTasksInput = {
  /** Array of records to create */
  tasks: Array<CreateTask>;
};

export type CreateManyUsersInput = {
  /** Array of records to create */
  users: Array<CreateUser>;
};

export type CreateManyVerificationScopesInput = {
  /** Array of records to create */
  verificationScopes: Array<CreateVerificationScope>;
};

export type CreateManyVerificationsInput = {
  /** Array of records to create */
  verifications: Array<CreateVerification>;
};

export type CreateManyWeatherConditionsInput = {
  /** Array of records to create */
  weatherConditions: Array<CreateWeatherCondition>;
};

export type CreateManyWorkPermitsInput = {
  /** Array of records to create */
  workPermits: Array<CreateWorkPermit>;
};

export type CreateOneBadgeInput = {
  /** The record to create */
  badge: CreateBadge;
};

export type CreateOneConcessionInput = {
  /** The record to create */
  concession: CreateConcession;
};

export type CreateOneConcessionOrganizationAccreditationDocumentInput = {
  concessionOrganizationAccreditationDocument: CreateConcessionOrganizationAccreditationDocument;
};

export type CreateOneConcessionOrganizationAccreditationInput = {
  /** The record to create */
  concessionOrganizationAccreditation: CreateConcessionOrganizationAccreditation;
};

export type CreateOneConcessionOrganizationAccreditationStateInput = {
  /** The record to create */
  concessionOrganizationAccreditationState: CreateConcessionOrganizationAccreditationState;
};

export type CreateOneConcessionOrganizationAccreditationTypeInput = {
  /** The record to create */
  concessionOrganizationAccreditationType: CreateConcessionOrganizationAccreditationType;
};

export type CreateOneConcessionOrganizationDocumentCategoryInput = {
  /** The record to create */
  concessionOrganizationDocumentCategory: CreateConcessionOrganizationDocumentCategory;
};

export type CreateOneConcessionOrganizationDocumentInput = {
  concessionOrganizationDocument: CreateConcessionOrganizationDocument;
};

export type CreateOneConcessionOrganizationInput = {
  /** The record to create */
  concessionOrganization: CreateConcessionOrganization;
};

export type CreateOneConcessionOrganizationUserInput = {
  concessionOrganizationUser: CreateConcessionOrganizationUser;
};

export type CreateOneConcessionUserInput = {
  concessionUser: CreateConcessionUser;
};

export type CreateOneContractConcessionOrganizationInput = {
  /** The record to create */
  contractConcessionOrganization: CreateContractConcessionOrganization;
};

export type CreateOneContractConcessionOrganizationStateInput = {
  /** The record to create */
  contractConcessionOrganizationState: CreateContractConcessionOrganizationState;
};

export type CreateOneContractDocumentCategoryInput = {
  /** The record to create */
  contractDocumentCategory: CreateContractDocumentCategory;
};

export type CreateOneContractDocumentInput = {
  contractDocument: CreateContractDocument;
};

export type CreateOneContractInput = {
  contract: CreateContract;
};

export type CreateOneContractRoadInput = {
  /** The record to create */
  contractRoad: CreateContractRoad;
};

export type CreateOneContractStateInput = {
  /** The record to create */
  contractState: CreateContractState;
};

export type CreateOneContractTransitionInput = {
  /** The record to create */
  contractTransition: CreateContractTransition;
};

export type CreateOneContractTypeInput = {
  /** The record to create */
  contractType: CreateContractType;
};

export type CreateOneContractUserInput = {
  /** The record to create */
  contractUser: CreateContractUser;
};

export type CreateOneControlCenterInput = {
  /** The record to create */
  controlCenter: CreateControlCenter;
};

export type CreateOneDirectionInput = {
  /** The record to create */
  direction: CreateDirection;
};

export type CreateOneInterventionElementInput = {
  /** The record to create */
  interventionElement: CreateInterventionElement;
};

export type CreateOneInterventionInput = {
  /** The record to create */
  intervention: CreateIntervention;
};

export type CreateOneInterventionLocationInput = {
  /** The record to create */
  interventionLocation: CreateInterventionLocation;
};

export type CreateOneInterventionTypeInput = {
  /** The record to create */
  interventionType: CreateInterventionType;
};

export type CreateOneJunctionInput = {
  /** The record to create */
  junction: CreateJuntion;
};

export type CreateOneLayoutInput = {
  /** The record to create */
  layout: CreateLayout;
};

export type CreateOneOrganizationInput = {
  /** The record to create */
  organization: CreateOrganization;
};

export type CreateOneOrganizationTypeInput = {
  /** The record to create */
  organizationType: CreateOrganizationType;
};

export type CreateOneRestrictionInput = {
  /** The record to create */
  restriction: CreateRestriction;
};

export type CreateOneRoadInput = {
  /** The record to create */
  road: CreateRoad;
};

export type CreateOneServiceInput = {
  /** The record to create */
  service: CreateService;
};

export type CreateOneSubcontractInput = {
  concessionOrganization: Scalars['ID'];
  concessionOrganizationAccreditationType: Scalars['ID'];
  contract: Scalars['ID'];
  description: Scalars['String'];
  endDate: Scalars['DateTime'];
  mediated: Scalars['Boolean'];
  startDate: Scalars['DateTime'];
};

export type CreateOneSurveyRecordInput = {
  surveyRecord: CreateSurveyRecord;
};

export type CreateOneSurveyRecordPhotoInput = {
  /** The record to create */
  surveyRecordPhoto: CreateSurveyRecordPhoto;
};

export type CreateOneSurveyRecordVerificationClassificationInput = {
  /** The record to create */
  surveyRecordVerificationClassification: CreateSurveyRecordVerificationClassification;
};

export type CreateOneSurveyRecordVerificationInput = {
  /** The record to create */
  surveyRecordVerification: CreateSurveyRecordVerification;
};

export type CreateOneSurveyRecordVerificationStateInput = {
  /** The record to create */
  surveyRecordVerificationState: CreateSurveyRecordVerificationState;
};

export type CreateOneTaskInput = {
  /** The record to create */
  task: CreateTask;
};

export type CreateOneUserInput = {
  user: CreateUser;
};

export type CreateOneVerificationInput = {
  /** The record to create */
  verification: CreateVerification;
};

export type CreateOneVerificationScopeInput = {
  /** The record to create */
  verificationScope: CreateVerificationScope;
};

export type CreateOneWeatherConditionInput = {
  /** The record to create */
  weatherCondition: CreateWeatherCondition;
};

export type CreateOneWorkPermitInput = {
  /** The record to create */
  workPermit: CreateWorkPermit;
};

export type CreateOrganization = {
  address: Scalars['String'];
  name: Scalars['String'];
  tin: Scalars['String'];
  zipCode: Scalars['String'];
};

export type CreateOrganizationType = {
  name: Scalars['String'];
};

export type CreateOwnConcesssionOrganizationDocumentCreate = {
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  path: Scalars['Upload'];
};

export type CreateOwnConcesssionOrganizationDocumentInput = {
  concessionOrganizationDocument: CreateOwnConcesssionOrganizationDocumentCreate;
};

export type CreateRestriction = {
  concession: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateRoad = {
  concession: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateService = {
  abbreviation: Scalars['String'];
  concession: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateSurveyRecord = {
  contract: Scalars['ID'];
  description: Scalars['String'];
  endJunction: Scalars['ID'];
  endPk: Scalars['String'];
  observations?: InputMaybe<Scalars['String']>;
  participants?: InputMaybe<Scalars['String']>;
  road: Scalars['ID'];
  startJunction: Scalars['ID'];
  startPk: Scalars['String'];
  surveyedAt: Scalars['DateTime'];
  weatherCondition: Scalars['ID'];
};

export type CreateSurveyRecordPhoto = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  path: Scalars['Upload'];
  surveyRecord: Scalars['ID'];
};

export type CreateSurveyRecordVerification = {
  description?: InputMaybe<Scalars['String']>;
  surveyRecord: Scalars['ID'];
  surveyRecordVerificationClassification?: InputMaybe<Scalars['ID']>;
  surveyRecordVerificationState?: InputMaybe<Scalars['ID']>;
  verification: Scalars['ID'];
};

export type CreateSurveyRecordVerificationClassification = {
  abbreviation: Scalars['String'];
  concession: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateSurveyRecordVerificationState = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  stateType?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateTask = {
  direction: Scalars['ID'];
  endAt: Scalars['DateTime'];
  endJunction: Scalars['ID'];
  endPk: Scalars['String'];
  executingAuthority: Scalars['String'];
  executingAuthorityContact: Scalars['String'];
  intervention: Scalars['ID'];
  interventionLocation: Scalars['ID'];
  layout: Scalars['ID'];
  observations?: InputMaybe<Scalars['String']>;
  reason: Scalars['String'];
  restriction: Scalars['ID'];
  road: Scalars['ID'];
  signalingAuthority: Scalars['String'];
  signalingAuthorityContact: Scalars['String'];
  startAt: Scalars['DateTime'];
  startJunction: Scalars['ID'];
  startPk: Scalars['String'];
};

export type CreateUser = {
  defaultConcession: Scalars['ID'];
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type CreateVerification = {
  concession: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateVerificationScope = {
  abbreviation: Scalars['String'];
  concession: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateWeatherCondition = {
  concession: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateWorkPermit = {
  contract: Scalars['ID'];
  endAt: Scalars['DateTime'];
  observations?: InputMaybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  state?: InputMaybe<WorkPermitStates>;
};

export type DateFieldComparison = {
  between?: InputMaybe<DateFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  notBetween?: InputMaybe<DateFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime'];
  upper: Scalars['DateTime'];
};

export type DeleteManyBadgesInput = {
  /** Filter to find records to delete */
  filter: BadgeDeleteFilter;
};

export type DeleteManyConcessionOrganizationAccreditationDocumentsInput = {
  /** Filter to find records to delete */
  filter: ConcessionOrganizationAccreditationDocumentDeleteFilter;
};

export type DeleteManyConcessionOrganizationAccreditationStatesInput = {
  /** Filter to find records to delete */
  filter: ConcessionOrganizationAccreditationStateDeleteFilter;
};

export type DeleteManyConcessionOrganizationAccreditationTypesInput = {
  /** Filter to find records to delete */
  filter: ConcessionOrganizationAccreditationTypeDeleteFilter;
};

export type DeleteManyConcessionOrganizationAccreditationsInput = {
  /** Filter to find records to delete */
  filter: ConcessionOrganizationAccreditationDeleteFilter;
};

export type DeleteManyConcessionOrganizationDocumentCategoriesInput = {
  /** Filter to find records to delete */
  filter: ConcessionOrganizationDocumentCategoryDeleteFilter;
};

export type DeleteManyConcessionOrganizationDocumentsInput = {
  /** Filter to find records to delete */
  filter: ConcessionOrganizationDocumentDeleteFilter;
};

export type DeleteManyConcessionOrganizationUsersInput = {
  /** Filter to find records to delete */
  filter: ConcessionOrganizationUserDeleteFilter;
};

export type DeleteManyConcessionOrganizationsInput = {
  /** Filter to find records to delete */
  filter: ConcessionOrganizationDeleteFilter;
};

export type DeleteManyConcessionUsersInput = {
  /** Filter to find records to delete */
  filter: ConcessionUserDeleteFilter;
};

export type DeleteManyConcessionsInput = {
  /** Filter to find records to delete */
  filter: ConcessionDeleteFilter;
};

export type DeleteManyContractConcessionOrganizationStatesInput = {
  /** Filter to find records to delete */
  filter: ContractConcessionOrganizationStateDeleteFilter;
};

export type DeleteManyContractConcessionOrganizationsInput = {
  /** Filter to find records to delete */
  filter: ContractConcessionOrganizationDeleteFilter;
};

export type DeleteManyContractDocumentCategoriesInput = {
  /** Filter to find records to delete */
  filter: ContractDocumentCategoryDeleteFilter;
};

export type DeleteManyContractDocumentsInput = {
  /** Filter to find records to delete */
  filter: ContractDocumentDeleteFilter;
};

export type DeleteManyContractRoadsInput = {
  /** Filter to find records to delete */
  filter: ContractRoadDeleteFilter;
};

export type DeleteManyContractStatesInput = {
  /** Filter to find records to delete */
  filter: ContractStateDeleteFilter;
};

export type DeleteManyContractTransitionsInput = {
  /** Filter to find records to delete */
  filter: ContractTransitionDeleteFilter;
};

export type DeleteManyContractTypesInput = {
  /** Filter to find records to delete */
  filter: ContractTypeDeleteFilter;
};

export type DeleteManyContractUsersInput = {
  /** Filter to find records to delete */
  filter: ContractUserDeleteFilter;
};

export type DeleteManyContractsInput = {
  /** Filter to find records to delete */
  filter: ContractDeleteFilter;
};

export type DeleteManyControlCentersInput = {
  /** Filter to find records to delete */
  filter: ControlCenterDeleteFilter;
};

export type DeleteManyDirectionsInput = {
  /** Filter to find records to delete */
  filter: DirectionDeleteFilter;
};

export type DeleteManyInterventionElementsInput = {
  /** Filter to find records to delete */
  filter: InterventionElementDeleteFilter;
};

export type DeleteManyInterventionLocationsInput = {
  /** Filter to find records to delete */
  filter: InterventionLocationDeleteFilter;
};

export type DeleteManyInterventionTypesInput = {
  /** Filter to find records to delete */
  filter: InterventionTypeDeleteFilter;
};

export type DeleteManyInterventionsInput = {
  /** Filter to find records to delete */
  filter: InterventionDeleteFilter;
};

export type DeleteManyJunctionsInput = {
  /** Filter to find records to delete */
  filter: JunctionDeleteFilter;
};

export type DeleteManyLayoutsInput = {
  /** Filter to find records to delete */
  filter: LayoutDeleteFilter;
};

export type DeleteManyOrganizationTypesInput = {
  /** Filter to find records to delete */
  filter: OrganizationTypeDeleteFilter;
};

export type DeleteManyOrganizationsInput = {
  /** Filter to find records to delete */
  filter: OrganizationDeleteFilter;
};

export type DeleteManyResponse = {
  __typename?: 'DeleteManyResponse';
  /** The number of records deleted. */
  deletedCount: Scalars['Int'];
};

export type DeleteManyRestrictionsInput = {
  /** Filter to find records to delete */
  filter: RestrictionDeleteFilter;
};

export type DeleteManyRoadsInput = {
  /** Filter to find records to delete */
  filter: RoadDeleteFilter;
};

export type DeleteManyServicesInput = {
  /** Filter to find records to delete */
  filter: ServiceDeleteFilter;
};

export type DeleteManySurveyRecordPhotosInput = {
  /** Filter to find records to delete */
  filter: SurveyRecordPhotoDeleteFilter;
};

export type DeleteManySurveyRecordVerificationClassificationsInput = {
  /** Filter to find records to delete */
  filter: SurveyRecordVerificationClassificationDeleteFilter;
};

export type DeleteManySurveyRecordVerificationStatesInput = {
  /** Filter to find records to delete */
  filter: SurveyRecordVerificationStateDeleteFilter;
};

export type DeleteManySurveyRecordVerificationsInput = {
  /** Filter to find records to delete */
  filter: SurveyRecordVerificationDeleteFilter;
};

export type DeleteManySurveyRecordsInput = {
  /** Filter to find records to delete */
  filter: SurveyRecordDeleteFilter;
};

export type DeleteManyTasksInput = {
  /** Filter to find records to delete */
  filter: TaskDeleteFilter;
};

export type DeleteManyUsersInput = {
  /** Filter to find records to delete */
  filter: UserDeleteFilter;
};

export type DeleteManyVerificationScopesInput = {
  /** Filter to find records to delete */
  filter: VerificationScopeDeleteFilter;
};

export type DeleteManyVerificationsInput = {
  /** Filter to find records to delete */
  filter: VerificationDeleteFilter;
};

export type DeleteManyWeatherConditionsInput = {
  /** Filter to find records to delete */
  filter: WeatherConditionDeleteFilter;
};

export type DeleteManyWorkPermitsInput = {
  /** Filter to find records to delete */
  filter: WorkPermitDeleteFilter;
};

export type DeleteOneBadgeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneConcessionInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneConcessionOrganizationAccreditationDocumentInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneConcessionOrganizationAccreditationInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneConcessionOrganizationAccreditationStateInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneConcessionOrganizationAccreditationTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneConcessionOrganizationDocumentCategoryInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneConcessionOrganizationDocumentInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneConcessionOrganizationInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneConcessionOrganizationUserInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneConcessionUserInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneContractConcessionOrganizationInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneContractConcessionOrganizationStateInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneContractDocumentCategoryInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneContractDocumentInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneContractInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneContractRoadInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneContractStateInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneContractTransitionInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneContractTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneContractUserInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneControlCenterInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneDirectionInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneInterventionElementInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneInterventionInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneInterventionLocationInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneInterventionTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneJunctionInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneLayoutInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneOrganizationInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneOrganizationTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneRestrictionInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneRoadInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneServiceInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneSubcontractInput = {
  id: Scalars['ID'];
};

export type DeleteOneSurveyRecordInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneSurveyRecordPhotoInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneSurveyRecordVerificationClassificationInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneSurveyRecordVerificationInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneSurveyRecordVerificationStateInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneTaskInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneUserInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneVerificationInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneVerificationScopeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneWeatherConditionInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneWorkPermitInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOwnConcesssionOrganizationDocument = {
  id: Scalars['ID'];
};

export type Direction = {
  __typename?: 'Direction';
  concession: Concession;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DirectionAggregateGroupBy = {
  __typename?: 'DirectionAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type DirectionAvgAggregate = {
  __typename?: 'DirectionAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type DirectionConnection = {
  __typename?: 'DirectionConnection';
  /** Array of nodes. */
  nodes: Array<Direction>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type DirectionCountAggregate = {
  __typename?: 'DirectionCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type DirectionDeleteFilter = {
  and?: InputMaybe<Array<DirectionDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DirectionDeleteFilter>>;
};

export type DirectionDeleteResponse = {
  __typename?: 'DirectionDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectionFilter = {
  and?: InputMaybe<Array<DirectionFilter>>;
  concession?: InputMaybe<DirectionFilterConcessionFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DirectionFilter>>;
};

export type DirectionFilterConcessionFilter = {
  and?: InputMaybe<Array<DirectionFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DirectionFilterConcessionFilter>>;
};

export type DirectionMaxAggregate = {
  __typename?: 'DirectionMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type DirectionMinAggregate = {
  __typename?: 'DirectionMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type DirectionSort = {
  direction: SortDirection;
  field: DirectionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DirectionSortFields {
  Id = 'id',
  Name = 'name'
}

export type DirectionSumAggregate = {
  __typename?: 'DirectionSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type DirectionUpdateFilter = {
  and?: InputMaybe<Array<DirectionUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DirectionUpdateFilter>>;
};

export type DocumentNotification = {
  __typename?: 'DocumentNotification';
  concessionOrganization: ConcessionOrganization;
  concessionOrganizationDocument: ConcessionOrganizationDocument;
  contract: Contract;
  contractDocument: ContractDocument;
  createdAt: Scalars['DateTime'];
  documentState: DocumentState;
  id: Scalars['ID'];
  state: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DocumentNotificationAggregateGroupBy = {
  __typename?: 'DocumentNotificationAggregateGroupBy';
  concessionOrganization?: Maybe<Scalars['ID']>;
  concessionOrganizationDocument?: Maybe<Scalars['ID']>;
  contract?: Maybe<Scalars['ID']>;
  contractDocument?: Maybe<Scalars['ID']>;
  documentState?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<Scalars['String']>;
};

export type DocumentNotificationAvgAggregate = {
  __typename?: 'DocumentNotificationAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type DocumentNotificationConnection = {
  __typename?: 'DocumentNotificationConnection';
  /** Array of nodes. */
  nodes: Array<DocumentNotification>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type DocumentNotificationCountAggregate = {
  __typename?: 'DocumentNotificationCountAggregate';
  concessionOrganization?: Maybe<Scalars['Int']>;
  concessionOrganizationDocument?: Maybe<Scalars['Int']>;
  contract?: Maybe<Scalars['Int']>;
  contractDocument?: Maybe<Scalars['Int']>;
  documentState?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
};

export type DocumentNotificationFilter = {
  and?: InputMaybe<Array<DocumentNotificationFilter>>;
  concessionOrganization?: InputMaybe<IdFilterComparison>;
  concessionOrganizationDocument?: InputMaybe<IdFilterComparison>;
  contract?: InputMaybe<IdFilterComparison>;
  contractDocument?: InputMaybe<IdFilterComparison>;
  documentState?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<DocumentNotificationFilter>>;
  state?: InputMaybe<StringFieldComparison>;
};

export type DocumentNotificationMaxAggregate = {
  __typename?: 'DocumentNotificationMaxAggregate';
  concessionOrganization?: Maybe<Scalars['ID']>;
  concessionOrganizationDocument?: Maybe<Scalars['ID']>;
  contract?: Maybe<Scalars['ID']>;
  contractDocument?: Maybe<Scalars['ID']>;
  documentState?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<Scalars['String']>;
};

export type DocumentNotificationMinAggregate = {
  __typename?: 'DocumentNotificationMinAggregate';
  concessionOrganization?: Maybe<Scalars['ID']>;
  concessionOrganizationDocument?: Maybe<Scalars['ID']>;
  contract?: Maybe<Scalars['ID']>;
  contractDocument?: Maybe<Scalars['ID']>;
  documentState?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<Scalars['String']>;
};

export type DocumentNotificationSort = {
  direction: SortDirection;
  field: DocumentNotificationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DocumentNotificationSortFields {
  ConcessionOrganization = 'concessionOrganization',
  ConcessionOrganizationDocument = 'concessionOrganizationDocument',
  Contract = 'contract',
  ContractDocument = 'contractDocument',
  DocumentState = 'documentState',
  Id = 'id',
  State = 'state'
}

export type DocumentNotificationSumAggregate = {
  __typename?: 'DocumentNotificationSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type DocumentState = {
  __typename?: 'DocumentState';
  backgroundColor: Scalars['String'];
  code: Scalars['String'];
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  stateType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DocumentStateAggregateGroupBy = {
  __typename?: 'DocumentStateAggregateGroupBy';
  backgroundColor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  stateType?: Maybe<Scalars['String']>;
};

export type DocumentStateAvgAggregate = {
  __typename?: 'DocumentStateAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type DocumentStateConnection = {
  __typename?: 'DocumentStateConnection';
  /** Array of nodes. */
  nodes: Array<DocumentState>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type DocumentStateCountAggregate = {
  __typename?: 'DocumentStateCountAggregate';
  backgroundColor?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  stateType?: Maybe<Scalars['Int']>;
};

export type DocumentStateFilter = {
  and?: InputMaybe<Array<DocumentStateFilter>>;
  backgroundColor?: InputMaybe<StringFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  color?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DocumentStateFilter>>;
  stateType?: InputMaybe<StringFieldComparison>;
};

export type DocumentStateMaxAggregate = {
  __typename?: 'DocumentStateMaxAggregate';
  backgroundColor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  stateType?: Maybe<Scalars['String']>;
};

export type DocumentStateMinAggregate = {
  __typename?: 'DocumentStateMinAggregate';
  backgroundColor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  stateType?: Maybe<Scalars['String']>;
};

export type DocumentStateSort = {
  direction: SortDirection;
  field: DocumentStateSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DocumentStateSortFields {
  BackgroundColor = 'backgroundColor',
  Code = 'code',
  Color = 'color',
  Id = 'id',
  Name = 'name',
  StateType = 'stateType'
}

export type DocumentStateSumAggregate = {
  __typename?: 'DocumentStateSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type DocumentTemplate = {
  __typename?: 'DocumentTemplate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  path: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DocumentTemplateFilter = {
  and?: InputMaybe<Array<DocumentTemplateFilter>>;
  concession?: InputMaybe<DocumentTemplateFilterConcessionFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DocumentTemplateFilter>>;
  path?: InputMaybe<StringFieldComparison>;
};

export type DocumentTemplateFilterConcessionFilter = {
  and?: InputMaybe<Array<DocumentTemplateFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DocumentTemplateFilterConcessionFilter>>;
};

export type DocumentTemplateOffsetConnection = {
  __typename?: 'DocumentTemplateOffsetConnection';
  /** Array of nodes. */
  nodes: Array<DocumentTemplate>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type DocumentTemplateSort = {
  direction: SortDirection;
  field: DocumentTemplateSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DocumentTemplateSortFields {
  Id = 'id',
  Name = 'name',
  Path = 'path'
}

export type ExportSurveyRecordInput = {
  surveyRecord: Scalars['ID'];
};

export type FileType = {
  __typename?: 'FileType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isPreviewable: Scalars['Boolean'];
  mimeType: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FileTypeAggregateGroupBy = {
  __typename?: 'FileTypeAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  isPreviewable?: Maybe<Scalars['Boolean']>;
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type FileTypeAvgAggregate = {
  __typename?: 'FileTypeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type FileTypeConnection = {
  __typename?: 'FileTypeConnection';
  /** Array of nodes. */
  nodes: Array<FileType>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type FileTypeCountAggregate = {
  __typename?: 'FileTypeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  isPreviewable?: Maybe<Scalars['Int']>;
  mimeType?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type FileTypeFilter = {
  and?: InputMaybe<Array<FileTypeFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isPreviewable?: InputMaybe<BooleanFieldComparison>;
  mimeType?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<FileTypeFilter>>;
};

export type FileTypeMaxAggregate = {
  __typename?: 'FileTypeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type FileTypeMinAggregate = {
  __typename?: 'FileTypeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type FileTypeSort = {
  direction: SortDirection;
  field: FileTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FileTypeSortFields {
  Id = 'id',
  IsPreviewable = 'isPreviewable',
  MimeType = 'mimeType',
  Name = 'name'
}

export type FileTypeSumAggregate = {
  __typename?: 'FileTypeSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type FindOverlapsInput = {
  concession: Scalars['ID'];
  week: Scalars['Float'];
  year: Scalars['Float'];
};

export type GenerateTaskExcelTemplate = {
  intervention: Scalars['ID'];
};

export type GenerateWeeklyTasksZip = {
  week: Scalars['Float'];
  year: Scalars['Float'];
};

export type IdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  iLike?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  neq?: InputMaybe<Scalars['ID']>;
  notILike?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<Scalars['ID']>>;
  notLike?: InputMaybe<Scalars['ID']>;
};

export type ImportConcessionOrganizationAccreditationDocumentsInput = {
  accreditationDestinationId: Scalars['ID'];
  accreditationOriginId: Scalars['ID'];
};

export type ImportConcessionOrganizationAccreditationDocumentsOutput = {
  __typename?: 'ImportConcessionOrganizationAccreditationDocumentsOutput';
  duplicates: Array<Scalars['String']>;
};

export type ImportManyTasksInput = {
  intervention: Scalars['ID'];
  path: Scalars['Upload'];
};

export type ImportValidationErrorOutput = {
  __typename?: 'ImportValidationErrorOutput';
  address: Scalars['String'];
  code: Scalars['String'];
  value: Scalars['String'];
};

export type Intervention = {
  __typename?: 'Intervention';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  state: Scalars['String'];
  tasks?: Maybe<Array<Task>>;
  updatedAt: Scalars['DateTime'];
  week: Scalars['Float'];
  workPermit: WorkPermit;
  year: Scalars['Float'];
};


export type InterventionTasksArgs = {
  filter?: InputMaybe<TaskFilter>;
  sorting?: InputMaybe<Array<TaskSort>>;
};

export type InterventionAggregateGroupBy = {
  __typename?: 'InterventionAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  week?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type InterventionAvgAggregate = {
  __typename?: 'InterventionAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  week?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type InterventionCountAggregate = {
  __typename?: 'InterventionCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
  week?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type InterventionDeleteFilter = {
  and?: InputMaybe<Array<InterventionDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionDeleteFilter>>;
  state?: InputMaybe<StringFieldComparison>;
  week?: InputMaybe<NumberFieldComparison>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type InterventionDeleteResponse = {
  __typename?: 'InterventionDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  week?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type InterventionElement = {
  __typename?: 'InterventionElement';
  abbreviation: Scalars['String'];
  concession: Concession;
  contracts: Array<Contract>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type InterventionElementContractsArgs = {
  filter?: InputMaybe<ContractFilter>;
  sorting?: InputMaybe<Array<ContractSort>>;
};

export type InterventionElementAggregateGroupBy = {
  __typename?: 'InterventionElementAggregateGroupBy';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type InterventionElementAvgAggregate = {
  __typename?: 'InterventionElementAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type InterventionElementConnection = {
  __typename?: 'InterventionElementConnection';
  /** Array of nodes. */
  nodes: Array<InterventionElement>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type InterventionElementCountAggregate = {
  __typename?: 'InterventionElementCountAggregate';
  abbreviation?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type InterventionElementDeleteFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<InterventionElementDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionElementDeleteFilter>>;
};

export type InterventionElementDeleteResponse = {
  __typename?: 'InterventionElementDeleteResponse';
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterventionElementFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<InterventionElementFilter>>;
  concession?: InputMaybe<InterventionElementFilterConcessionFilter>;
  contract?: InputMaybe<InterventionElementFilterContractFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionElementFilter>>;
};

export type InterventionElementFilterConcessionFilter = {
  and?: InputMaybe<Array<InterventionElementFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionElementFilterConcessionFilter>>;
};

export type InterventionElementFilterContractFilter = {
  and?: InputMaybe<Array<InterventionElementFilterContractFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionElementFilterContractFilter>>;
  realEndDate?: InputMaybe<StringFieldComparison>;
  realStartDate?: InputMaybe<StringFieldComparison>;
  road?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type InterventionElementMaxAggregate = {
  __typename?: 'InterventionElementMaxAggregate';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type InterventionElementMinAggregate = {
  __typename?: 'InterventionElementMinAggregate';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type InterventionElementSort = {
  direction: SortDirection;
  field: InterventionElementSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum InterventionElementSortFields {
  Abbreviation = 'abbreviation',
  Id = 'id',
  Name = 'name'
}

export type InterventionElementSumAggregate = {
  __typename?: 'InterventionElementSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type InterventionElementUpdateFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<InterventionElementUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionElementUpdateFilter>>;
};

export type InterventionFilter = {
  and?: InputMaybe<Array<InterventionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionFilter>>;
  state?: InputMaybe<StringFieldComparison>;
  tasks?: InputMaybe<InterventionFilterTaskFilter>;
  week?: InputMaybe<NumberFieldComparison>;
  workPermit?: InputMaybe<InterventionFilterWorkPermitFilter>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type InterventionFilterTaskFilter = {
  and?: InputMaybe<Array<InterventionFilterTaskFilter>>;
  endAt?: InputMaybe<DateFieldComparison>;
  endPk?: InputMaybe<StringFieldComparison>;
  executingAuthority?: InputMaybe<StringFieldComparison>;
  executingAuthorityContact?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  extension?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isoWeek?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionFilterTaskFilter>>;
  overlaps?: InputMaybe<StringFieldComparison>;
  reason?: InputMaybe<StringFieldComparison>;
  sequence?: InputMaybe<NumberFieldComparison>;
  signalingAuthority?: InputMaybe<StringFieldComparison>;
  signalingAuthorityContact?: InputMaybe<StringFieldComparison>;
  startAt?: InputMaybe<DateFieldComparison>;
  startPk?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
};

export type InterventionFilterWorkPermitFilter = {
  and?: InputMaybe<Array<InterventionFilterWorkPermitFilter>>;
  endAt?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isPublished?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionFilterWorkPermitFilter>>;
  publishedAt?: InputMaybe<StringFieldComparison>;
  startAt?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
};

export type InterventionLocation = {
  __typename?: 'InterventionLocation';
  abbreviation: Scalars['String'];
  concession: Concession;
  contracts: Array<Contract>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  locationType: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type InterventionLocationContractsArgs = {
  filter?: InputMaybe<ContractFilter>;
  sorting?: InputMaybe<Array<ContractSort>>;
};

export type InterventionLocationAggregateGroupBy = {
  __typename?: 'InterventionLocationAggregateGroupBy';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  locationType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type InterventionLocationAvgAggregate = {
  __typename?: 'InterventionLocationAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type InterventionLocationConnection = {
  __typename?: 'InterventionLocationConnection';
  /** Array of nodes. */
  nodes: Array<InterventionLocation>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type InterventionLocationCountAggregate = {
  __typename?: 'InterventionLocationCountAggregate';
  abbreviation?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  locationType?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type InterventionLocationDeleteFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<InterventionLocationDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  locationType?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionLocationDeleteFilter>>;
};

export type InterventionLocationDeleteResponse = {
  __typename?: 'InterventionLocationDeleteResponse';
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  locationType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterventionLocationFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<InterventionLocationFilter>>;
  concession?: InputMaybe<InterventionLocationFilterConcessionFilter>;
  contract?: InputMaybe<InterventionLocationFilterContractFilter>;
  id?: InputMaybe<IdFilterComparison>;
  locationType?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionLocationFilter>>;
};

export type InterventionLocationFilterConcessionFilter = {
  and?: InputMaybe<Array<InterventionLocationFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionLocationFilterConcessionFilter>>;
};

export type InterventionLocationFilterContractFilter = {
  and?: InputMaybe<Array<InterventionLocationFilterContractFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionLocationFilterContractFilter>>;
  realEndDate?: InputMaybe<StringFieldComparison>;
  realStartDate?: InputMaybe<StringFieldComparison>;
  road?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type InterventionLocationMaxAggregate = {
  __typename?: 'InterventionLocationMaxAggregate';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  locationType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type InterventionLocationMinAggregate = {
  __typename?: 'InterventionLocationMinAggregate';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  locationType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type InterventionLocationSort = {
  direction: SortDirection;
  field: InterventionLocationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum InterventionLocationSortFields {
  Abbreviation = 'abbreviation',
  Id = 'id',
  LocationType = 'locationType',
  Name = 'name'
}

export type InterventionLocationSumAggregate = {
  __typename?: 'InterventionLocationSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type InterventionLocationUpdateFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<InterventionLocationUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  locationType?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionLocationUpdateFilter>>;
};

export type InterventionMaxAggregate = {
  __typename?: 'InterventionMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  week?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type InterventionMinAggregate = {
  __typename?: 'InterventionMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  week?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type InterventionOffsetConnection = {
  __typename?: 'InterventionOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Intervention>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type InterventionSort = {
  direction: SortDirection;
  field: InterventionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum InterventionSortFields {
  Id = 'id',
  Name = 'name',
  State = 'state',
  Week = 'week',
  Year = 'year'
}

export enum InterventionStates {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Preparation = 'PREPARATION',
  Review = 'REVIEW',
  Submitted = 'SUBMITTED'
}

export type InterventionSumAggregate = {
  __typename?: 'InterventionSumAggregate';
  id?: Maybe<Scalars['Float']>;
  week?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type InterventionType = {
  __typename?: 'InterventionType';
  abbreviation: Scalars['String'];
  concession: Concession;
  contracts: Array<Contract>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type InterventionTypeContractsArgs = {
  filter?: InputMaybe<ContractFilter>;
  sorting?: InputMaybe<Array<ContractSort>>;
};

export type InterventionTypeAggregateGroupBy = {
  __typename?: 'InterventionTypeAggregateGroupBy';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type InterventionTypeAvgAggregate = {
  __typename?: 'InterventionTypeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type InterventionTypeConnection = {
  __typename?: 'InterventionTypeConnection';
  /** Array of nodes. */
  nodes: Array<InterventionType>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type InterventionTypeCountAggregate = {
  __typename?: 'InterventionTypeCountAggregate';
  abbreviation?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type InterventionTypeDeleteFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<InterventionTypeDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionTypeDeleteFilter>>;
};

export type InterventionTypeDeleteResponse = {
  __typename?: 'InterventionTypeDeleteResponse';
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterventionTypeFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<InterventionTypeFilter>>;
  concession?: InputMaybe<InterventionTypeFilterConcessionFilter>;
  contract?: InputMaybe<InterventionTypeFilterContractFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionTypeFilter>>;
};

export type InterventionTypeFilterConcessionFilter = {
  and?: InputMaybe<Array<InterventionTypeFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionTypeFilterConcessionFilter>>;
};

export type InterventionTypeFilterContractFilter = {
  and?: InputMaybe<Array<InterventionTypeFilterContractFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionTypeFilterContractFilter>>;
  realEndDate?: InputMaybe<StringFieldComparison>;
  realStartDate?: InputMaybe<StringFieldComparison>;
  road?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type InterventionTypeMaxAggregate = {
  __typename?: 'InterventionTypeMaxAggregate';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type InterventionTypeMinAggregate = {
  __typename?: 'InterventionTypeMinAggregate';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type InterventionTypeSort = {
  direction: SortDirection;
  field: InterventionTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum InterventionTypeSortFields {
  Abbreviation = 'abbreviation',
  Id = 'id',
  Name = 'name'
}

export type InterventionTypeSumAggregate = {
  __typename?: 'InterventionTypeSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type InterventionTypeUpdateFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<InterventionTypeUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionTypeUpdateFilter>>;
};

export type InterventionUpdateFilter = {
  and?: InputMaybe<Array<InterventionUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InterventionUpdateFilter>>;
  state?: InputMaybe<StringFieldComparison>;
  week?: InputMaybe<NumberFieldComparison>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type Junction = {
  __typename?: 'Junction';
  concession: Concession;
  createdAt: Scalars['DateTime'];
  endJuntionSurveyRecords?: Maybe<Array<SurveyRecord>>;
  endJuntionTasks?: Maybe<Array<Task>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Float'];
  pk: Scalars['String'];
  road: Road;
  startJuntionSurveyRecords?: Maybe<Array<SurveyRecord>>;
  startJuntionTasks?: Maybe<Array<Task>>;
  updatedAt: Scalars['DateTime'];
};


export type JunctionEndJuntionSurveyRecordsArgs = {
  filter?: InputMaybe<SurveyRecordFilter>;
  sorting?: InputMaybe<Array<SurveyRecordSort>>;
};


export type JunctionEndJuntionTasksArgs = {
  filter?: InputMaybe<TaskFilter>;
  sorting?: InputMaybe<Array<TaskSort>>;
};


export type JunctionStartJuntionSurveyRecordsArgs = {
  filter?: InputMaybe<SurveyRecordFilter>;
  sorting?: InputMaybe<Array<SurveyRecordSort>>;
};


export type JunctionStartJuntionTasksArgs = {
  filter?: InputMaybe<TaskFilter>;
  sorting?: InputMaybe<Array<TaskSort>>;
};

export type JunctionAggregateGroupBy = {
  __typename?: 'JunctionAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['String']>;
};

export type JunctionAvgAggregate = {
  __typename?: 'JunctionAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

export type JunctionConnection = {
  __typename?: 'JunctionConnection';
  /** Array of nodes. */
  nodes: Array<Junction>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type JunctionCountAggregate = {
  __typename?: 'JunctionCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  pk?: Maybe<Scalars['Int']>;
};

export type JunctionDeleteFilter = {
  and?: InputMaybe<Array<JunctionDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<JunctionDeleteFilter>>;
  order?: InputMaybe<NumberFieldComparison>;
  pk?: InputMaybe<StringFieldComparison>;
};

export type JunctionDeleteResponse = {
  __typename?: 'JunctionDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type JunctionFilter = {
  and?: InputMaybe<Array<JunctionFilter>>;
  concession?: InputMaybe<JunctionFilterConcessionFilter>;
  endJuntionSurveyRecords?: InputMaybe<JunctionFilterSurveyRecordFilter>;
  endJuntionTasks?: InputMaybe<JunctionFilterTaskFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<JunctionFilter>>;
  order?: InputMaybe<NumberFieldComparison>;
  pk?: InputMaybe<StringFieldComparison>;
  road?: InputMaybe<JunctionFilterRoadFilter>;
  startJuntionSurveyRecords?: InputMaybe<JunctionFilterSurveyRecordFilter>;
  startJuntionTasks?: InputMaybe<JunctionFilterTaskFilter>;
};

export type JunctionFilterConcessionFilter = {
  and?: InputMaybe<Array<JunctionFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<JunctionFilterConcessionFilter>>;
};

export type JunctionFilterRoadFilter = {
  and?: InputMaybe<Array<JunctionFilterRoadFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<JunctionFilterRoadFilter>>;
};

export type JunctionFilterSurveyRecordFilter = {
  and?: InputMaybe<Array<JunctionFilterSurveyRecordFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endPk?: InputMaybe<StringFieldComparison>;
  hasCorrectiveRequests?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isPublished?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<JunctionFilterSurveyRecordFilter>>;
  participants?: InputMaybe<StringFieldComparison>;
  sequence?: InputMaybe<NumberFieldComparison>;
  startPk?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  surveyedAt?: InputMaybe<DateFieldComparison>;
};

export type JunctionFilterTaskFilter = {
  and?: InputMaybe<Array<JunctionFilterTaskFilter>>;
  endAt?: InputMaybe<DateFieldComparison>;
  endPk?: InputMaybe<StringFieldComparison>;
  executingAuthority?: InputMaybe<StringFieldComparison>;
  executingAuthorityContact?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  extension?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isoWeek?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<JunctionFilterTaskFilter>>;
  overlaps?: InputMaybe<StringFieldComparison>;
  reason?: InputMaybe<StringFieldComparison>;
  sequence?: InputMaybe<NumberFieldComparison>;
  signalingAuthority?: InputMaybe<StringFieldComparison>;
  signalingAuthorityContact?: InputMaybe<StringFieldComparison>;
  startAt?: InputMaybe<DateFieldComparison>;
  startPk?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
};

export type JunctionMaxAggregate = {
  __typename?: 'JunctionMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['String']>;
};

export type JunctionMinAggregate = {
  __typename?: 'JunctionMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['String']>;
};

export type JunctionSort = {
  direction: SortDirection;
  field: JunctionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum JunctionSortFields {
  Id = 'id',
  Name = 'name',
  Order = 'order',
  Pk = 'pk'
}

export type JunctionSumAggregate = {
  __typename?: 'JunctionSumAggregate';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

export type JunctionUpdateFilter = {
  and?: InputMaybe<Array<JunctionUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<JunctionUpdateFilter>>;
  order?: InputMaybe<NumberFieldComparison>;
  pk?: InputMaybe<StringFieldComparison>;
};

export type Layout = {
  __typename?: 'Layout';
  concession: Concession;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type LayoutAggregateGroupBy = {
  __typename?: 'LayoutAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type LayoutAvgAggregate = {
  __typename?: 'LayoutAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type LayoutConnection = {
  __typename?: 'LayoutConnection';
  /** Array of nodes. */
  nodes: Array<Layout>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type LayoutCountAggregate = {
  __typename?: 'LayoutCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type LayoutDeleteFilter = {
  and?: InputMaybe<Array<LayoutDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LayoutDeleteFilter>>;
};

export type LayoutDeleteResponse = {
  __typename?: 'LayoutDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LayoutFilter = {
  and?: InputMaybe<Array<LayoutFilter>>;
  concession?: InputMaybe<LayoutFilterConcessionFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LayoutFilter>>;
};

export type LayoutFilterConcessionFilter = {
  and?: InputMaybe<Array<LayoutFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LayoutFilterConcessionFilter>>;
};

export type LayoutMaxAggregate = {
  __typename?: 'LayoutMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type LayoutMinAggregate = {
  __typename?: 'LayoutMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type LayoutSort = {
  direction: SortDirection;
  field: LayoutSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LayoutSortFields {
  Id = 'id',
  Name = 'name'
}

export type LayoutSumAggregate = {
  __typename?: 'LayoutSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type LayoutUpdateFilter = {
  and?: InputMaybe<Array<LayoutUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LayoutUpdateFilter>>;
};

export type ManageWorkspaceViewColumn = {
  column?: InputMaybe<Scalars['String']>;
  oldIndex?: InputMaybe<Scalars['Float']>;
  size?: InputMaybe<Scalars['Float']>;
  targetIndex?: InputMaybe<Scalars['Float']>;
  view: Scalars['String'];
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addBadgesToWorkPermit: WorkPermit;
  addChecklistItemsToChecklist: Checklist;
  addChildrenToConcessionOrganizationDocumentCategory: ConcessionOrganizationDocumentCategory;
  addChildrenToContractDocumentCategory: ContractDocumentCategory;
  addConcessionOrganizationAccreditationDocumentsToConcessionOrganizationAccreditation: ConcessionOrganizationAccreditation;
  addConcessionOrganizationDocumentsToConcessionOrganization: ConcessionOrganization;
  addConcessionOrganizationUsersToUser: User;
  addConcessionOrganizationsToOrganization: Organization;
  addConcessionUsersToConcession: Concession;
  addConcessionUsersToUser: User;
  addConcessionsToConcessionOrganizationAccreditationType: ConcessionOrganizationAccreditationType;
  addContractConcessionOrganizationsToContract: Contract;
  addContractDocumentsToContract: Contract;
  addContractRoadsToContract: Contract;
  addContractRoadsToRoad: Road;
  addContractTransitionsToContract: Contract;
  addContractUsersToContract: Contract;
  addContractUsersToUser: User;
  addContractsToConcession: Concession;
  addContractsToContractState: ContractState;
  addContractsToContractType: ContractType;
  addContractsToInterventionElement: InterventionElement;
  addContractsToInterventionLocation: InterventionLocation;
  addContractsToInterventionType: InterventionType;
  addContractsToService: Service;
  addControlCentersToConcession: Concession;
  addEndJuntionSurveyRecordsToJunction: Junction;
  addEndJuntionTasksToJunction: Junction;
  addInterventionsToWorkPermit: WorkPermit;
  addJunctionsToRoad: Road;
  addOrganizationTypesToConcessionOrganizationAccreditationType: ConcessionOrganizationAccreditationType;
  addPermissionsToRole: Role;
  addRoadsToConcession: Concession;
  addStartJuntionSurveyRecordsToJunction: Junction;
  addStartJuntionTasksToJunction: Junction;
  addSurveyRecordPhotosToSurveyRecord: SurveyRecord;
  addSurveyRecordVerificationsToSurveyRecord: SurveyRecord;
  addSurveyRecordVerificationsToVerification: Verification;
  addSurveyRecordsToContract: Contract;
  addSurveyRecordsToRoad: Road;
  addSurveyRecordsToUser: User;
  addSurveyRecordsToWeatherCondition: WeatherCondition;
  addTasksToIntervention: Intervention;
  addTasksToRestriction: Restriction;
  addTasksToRoad: Road;
  addVerificationsToVerificationScope: VerificationScope;
  addWorkPermitsToContract: Contract;
  addWorkspaceViewColumnsToWorkspaceView: WorkspaceView;
  addWorkspaceViewsToWorkspace: Workspace;
  clearOverlaps: Scalars['String'];
  createManyBadges: Array<Badge>;
  createManyConcessionOrganizationAccreditationStates: Array<ConcessionOrganizationAccreditationState>;
  createManyConcessionOrganizationAccreditationTypes: Array<ConcessionOrganizationAccreditationType>;
  createManyConcessionOrganizationAccreditations: Array<ConcessionOrganizationAccreditation>;
  createManyConcessionOrganizationDocumentCategories: Array<ConcessionOrganizationDocumentCategory>;
  createManyConcessionOrganizationUsers: Array<ConcessionOrganizationUser>;
  createManyConcessionOrganizations: Array<ConcessionOrganization>;
  createManyConcessionUsers: Array<ConcessionUser>;
  createManyConcessions: Array<Concession>;
  createManyContractConcessionOrganizationStates: Array<ContractConcessionOrganizationState>;
  createManyContractConcessionOrganizations: Array<ContractConcessionOrganization>;
  createManyContractConcessionOrganizationsDeduped: Contract;
  createManyContractDocumentCategories: Array<ContractDocumentCategory>;
  createManyContractRoads: Array<ContractRoad>;
  createManyContractStates: Array<ContractState>;
  createManyContractTransitions: Array<ContractTransition>;
  createManyContractTypes: Array<ContractType>;
  createManyContractUsers: Array<ContractUser>;
  createManyControlCenters: Array<ControlCenter>;
  createManyDirections: Array<Direction>;
  createManyInterventionElements: Array<InterventionElement>;
  createManyInterventionLocations: Array<InterventionLocation>;
  createManyInterventionTypes: Array<InterventionType>;
  createManyInterventions: Array<Intervention>;
  createManyJunctions: Array<Junction>;
  createManyLayouts: Array<Layout>;
  createManyOrganizationTypes: Array<OrganizationType>;
  createManyOrganizations: Array<Organization>;
  createManyRestrictions: Array<Restriction>;
  createManyRoads: Array<Road>;
  createManyServices: Array<Service>;
  createManySurveyRecordPhotos: Array<SurveyRecordPhoto>;
  createManySurveyRecordVerificationClassifications: Array<SurveyRecordVerificationClassification>;
  createManySurveyRecordVerificationStates: Array<SurveyRecordVerificationState>;
  createManySurveyRecordVerifications: Array<SurveyRecordVerification>;
  createManySurveyRecords: Array<SurveyRecord>;
  createManyTasks: Array<Task>;
  createManyUsers: Array<User>;
  createManyVerificationScopes: Array<VerificationScope>;
  createManyVerifications: Array<Verification>;
  createManyWeatherConditions: Array<WeatherCondition>;
  createManyWorkPermits: Array<WorkPermit>;
  createOneBadge: Badge;
  createOneConcession: Concession;
  createOneConcessionOrganization: ConcessionOrganization;
  createOneConcessionOrganizationAccreditation: ConcessionOrganizationAccreditation;
  createOneConcessionOrganizationAccreditationDocument: ConcessionOrganizationAccreditationDocument;
  createOneConcessionOrganizationAccreditationState: ConcessionOrganizationAccreditationState;
  createOneConcessionOrganizationAccreditationType: ConcessionOrganizationAccreditationType;
  createOneConcessionOrganizationDocument: ConcessionOrganizationDocument;
  createOneConcessionOrganizationDocumentCategory: ConcessionOrganizationDocumentCategory;
  createOneConcessionOrganizationUser: ConcessionOrganizationUser;
  createOneConcessionUser: ConcessionUser;
  createOneContract: Contract;
  createOneContractConcessionOrganization: ContractConcessionOrganization;
  createOneContractConcessionOrganizationState: ContractConcessionOrganizationState;
  createOneContractDocument: ContractDocument;
  createOneContractDocumentCategory: ContractDocumentCategory;
  createOneContractRoad: ContractRoad;
  createOneContractState: ContractState;
  createOneContractTransition: ContractTransition;
  createOneContractType: ContractType;
  createOneContractUser: ContractUser;
  createOneControlCenter: ControlCenter;
  createOneDirection: Direction;
  createOneIntervention: Intervention;
  createOneInterventionElement: InterventionElement;
  createOneInterventionLocation: InterventionLocation;
  createOneInterventionType: InterventionType;
  createOneJunction: Junction;
  createOneLayout: Layout;
  createOneOrganization: Organization;
  createOneOrganizationType: OrganizationType;
  createOneRestriction: Restriction;
  createOneRoad: Road;
  createOneService: Service;
  createOneSubcontract: ContractConcessionOrganization;
  createOneSurveyRecord: SurveyRecord;
  createOneSurveyRecordPhoto: SurveyRecordPhoto;
  createOneSurveyRecordVerification: SurveyRecordVerification;
  createOneSurveyRecordVerificationClassification: SurveyRecordVerificationClassification;
  createOneSurveyRecordVerificationState: SurveyRecordVerificationState;
  createOneTask: Task;
  createOneUser: User;
  createOneVerification: Verification;
  createOneVerificationScope: VerificationScope;
  createOneWeatherCondition: WeatherCondition;
  createOneWorkPermit: WorkPermit;
  createOwnConcessionOrganizationDocument: ConcessionOrganizationDocument;
  createOwnSubcontractOrganizationDocument: ConcessionOrganizationDocument;
  deleteManyBadges: DeleteManyResponse;
  deleteManyConcessionOrganizationAccreditationDocuments: DeleteManyResponse;
  deleteManyConcessionOrganizationAccreditationStates: DeleteManyResponse;
  deleteManyConcessionOrganizationAccreditationTypes: DeleteManyResponse;
  deleteManyConcessionOrganizationAccreditations: DeleteManyResponse;
  deleteManyConcessionOrganizationDocumentCategories: DeleteManyResponse;
  deleteManyConcessionOrganizationDocuments: DeleteManyResponse;
  deleteManyConcessionOrganizationUsers: DeleteManyResponse;
  deleteManyConcessionOrganizations: DeleteManyResponse;
  deleteManyConcessionUsers: DeleteManyResponse;
  deleteManyConcessions: DeleteManyResponse;
  deleteManyContractConcessionOrganizationStates: DeleteManyResponse;
  deleteManyContractConcessionOrganizations: DeleteManyResponse;
  deleteManyContractDocumentCategories: DeleteManyResponse;
  deleteManyContractDocuments: DeleteManyResponse;
  deleteManyContractRoads: DeleteManyResponse;
  deleteManyContractStates: DeleteManyResponse;
  deleteManyContractTransitions: DeleteManyResponse;
  deleteManyContractTypes: DeleteManyResponse;
  deleteManyContractUsers: DeleteManyResponse;
  deleteManyContracts: DeleteManyResponse;
  deleteManyControlCenters: DeleteManyResponse;
  deleteManyDirections: DeleteManyResponse;
  deleteManyInterventionElements: DeleteManyResponse;
  deleteManyInterventionLocations: DeleteManyResponse;
  deleteManyInterventionTypes: DeleteManyResponse;
  deleteManyInterventions: DeleteManyResponse;
  deleteManyJunctions: DeleteManyResponse;
  deleteManyLayouts: DeleteManyResponse;
  deleteManyOrganizationTypes: DeleteManyResponse;
  deleteManyOrganizations: DeleteManyResponse;
  deleteManyRestrictions: DeleteManyResponse;
  deleteManyRoads: DeleteManyResponse;
  deleteManyServices: DeleteManyResponse;
  deleteManySurveyRecordPhotos: DeleteManyResponse;
  deleteManySurveyRecordVerificationClassifications: DeleteManyResponse;
  deleteManySurveyRecordVerificationStates: DeleteManyResponse;
  deleteManySurveyRecordVerifications: DeleteManyResponse;
  deleteManySurveyRecords: DeleteManyResponse;
  deleteManyTasks: DeleteManyResponse;
  deleteManyUsers: DeleteManyResponse;
  deleteManyVerificationScopes: DeleteManyResponse;
  deleteManyVerifications: DeleteManyResponse;
  deleteManyWeatherConditions: DeleteManyResponse;
  deleteManyWorkPermits: DeleteManyResponse;
  deleteOneBadge: BadgeDeleteResponse;
  deleteOneConcession: ConcessionDeleteResponse;
  deleteOneConcessionOrganization: ConcessionOrganizationDeleteResponse;
  deleteOneConcessionOrganizationAccreditation: ConcessionOrganizationAccreditationDeleteResponse;
  deleteOneConcessionOrganizationAccreditationDocument: ConcessionOrganizationAccreditationDocumentDeleteResponse;
  deleteOneConcessionOrganizationAccreditationState: ConcessionOrganizationAccreditationStateDeleteResponse;
  deleteOneConcessionOrganizationAccreditationType: ConcessionOrganizationAccreditationTypeDeleteResponse;
  deleteOneConcessionOrganizationDocument: ConcessionOrganizationDocumentDeleteResponse;
  deleteOneConcessionOrganizationDocumentCategory: ConcessionOrganizationDocumentCategoryDeleteResponse;
  deleteOneConcessionOrganizationUser: ConcessionOrganizationUserDeleteResponse;
  deleteOneConcessionUser: ConcessionUserDeleteResponse;
  deleteOneContract: ContractDeleteResponse;
  deleteOneContractConcessionOrganization: ContractConcessionOrganizationDeleteResponse;
  deleteOneContractConcessionOrganizationState: ContractConcessionOrganizationStateDeleteResponse;
  deleteOneContractDocument: ContractDocumentDeleteResponse;
  deleteOneContractDocumentCategory: ContractDocumentCategoryDeleteResponse;
  deleteOneContractRoad: ContractRoadDeleteResponse;
  deleteOneContractState: ContractStateDeleteResponse;
  deleteOneContractTransition: ContractTransitionDeleteResponse;
  deleteOneContractType: ContractTypeDeleteResponse;
  deleteOneContractUser: ContractUserDeleteResponse;
  deleteOneControlCenter: ControlCenterDeleteResponse;
  deleteOneDirection: DirectionDeleteResponse;
  deleteOneIntervention: InterventionDeleteResponse;
  deleteOneInterventionElement: InterventionElementDeleteResponse;
  deleteOneInterventionLocation: InterventionLocationDeleteResponse;
  deleteOneInterventionType: InterventionTypeDeleteResponse;
  deleteOneJunction: JunctionDeleteResponse;
  deleteOneLayout: LayoutDeleteResponse;
  deleteOneOrganization: OrganizationDeleteResponse;
  deleteOneOrganizationType: OrganizationTypeDeleteResponse;
  deleteOneRestriction: RestrictionDeleteResponse;
  deleteOneRoad: RoadDeleteResponse;
  deleteOneService: ServiceDeleteResponse;
  deleteOneSubcontract: SubcontractDeleteResponse;
  deleteOneSurveyRecord: SurveyRecordDeleteResponse;
  deleteOneSurveyRecordPhoto: SurveyRecordPhotoDeleteResponse;
  deleteOneSurveyRecordVerification: SurveyRecordVerificationDeleteResponse;
  deleteOneSurveyRecordVerificationClassification: SurveyRecordVerificationClassificationDeleteResponse;
  deleteOneSurveyRecordVerificationState: SurveyRecordVerificationStateDeleteResponse;
  deleteOneTask: TaskDeleteResponse;
  deleteOneUser: UserDeleteResponse;
  deleteOneVerification: VerificationDeleteResponse;
  deleteOneVerificationScope: VerificationScopeDeleteResponse;
  deleteOneWeatherCondition: WeatherConditionDeleteResponse;
  deleteOneWorkPermit: WorkPermitDeleteResponse;
  deleteOwnConcessionOrganizationDocument: ConcessionOrganizationDocument;
  deleteOwnSubcontractOrganizationDocument: ConcessionOrganizationDocument;
  exportSurveyRecordPDF: Scalars['String'];
  findOverlaps: Scalars['String'];
  generateTaskExcelTemplate: Scalars['String'];
  generateWeeklyTasksSheet: Scalars['String'];
  generateWeeklyTasksZip: Scalars['String'];
  importConcessionOrganizationAccreditationDocuments: ImportConcessionOrganizationAccreditationDocumentsOutput;
  importManyTasks: Scalars['String'];
  manageWorkspaceViewColumn: Scalars['Boolean'];
  removeBadgesFromWorkPermit: WorkPermit;
  removeChecklistFromChecklistItem: ChecklistItem;
  removeChecklistFromWorkPermit: WorkPermit;
  removeChecklistItemsFromChecklist: Checklist;
  removeChildrenFromConcessionOrganizationDocumentCategory: ConcessionOrganizationDocumentCategory;
  removeChildrenFromContractDocumentCategory: ContractDocumentCategory;
  removeConcessionFromConcessionOrganization: ConcessionOrganization;
  removeConcessionFromConcessionOrganizationAccreditationState: ConcessionOrganizationAccreditationState;
  removeConcessionFromConcessionOrganizationDocumentCategory: ConcessionOrganizationDocumentCategory;
  removeConcessionFromConcessionUser: ConcessionUser;
  removeConcessionFromContract: Contract;
  removeConcessionFromContractConcessionOrganizationState: ContractConcessionOrganizationState;
  removeConcessionFromContractDocumentCategory: ContractDocumentCategory;
  removeConcessionFromContractState: ContractState;
  removeConcessionFromContractType: ContractType;
  removeConcessionFromControlCenter: ControlCenter;
  removeConcessionFromDirection: Direction;
  removeConcessionFromInterventionElement: InterventionElement;
  removeConcessionFromInterventionLocation: InterventionLocation;
  removeConcessionFromInterventionType: InterventionType;
  removeConcessionFromJunction: Junction;
  removeConcessionFromLayout: Layout;
  removeConcessionFromRestriction: Restriction;
  removeConcessionFromRoad: Road;
  removeConcessionFromService: Service;
  removeConcessionFromSurveyRecordVerificationClassification: SurveyRecordVerificationClassification;
  removeConcessionFromSurveyRecordVerificationState: SurveyRecordVerificationState;
  removeConcessionFromVerification: Verification;
  removeConcessionFromVerificationScope: VerificationScope;
  removeConcessionFromWeatherCondition: WeatherCondition;
  removeConcessionOrganizationAccreditationDocumentsFromConcessionOrganizationAccreditation: ConcessionOrganizationAccreditation;
  removeConcessionOrganizationAccreditationFromConcessionOrganizationAccreditationDocument: ConcessionOrganizationAccreditationDocument;
  removeConcessionOrganizationAccreditationFromContractConcessionOrganization: ContractConcessionOrganization;
  removeConcessionOrganizationAccreditationStateFromConcessionOrganizationAccreditation: ConcessionOrganizationAccreditation;
  removeConcessionOrganizationAccreditationTypeFromConcessionOrganizationAccreditation: ConcessionOrganizationAccreditation;
  removeConcessionOrganizationDocumentCategoryFromConcessionOrganizationAccreditationDocument: ConcessionOrganizationAccreditationDocument;
  removeConcessionOrganizationDocumentCategoryFromConcessionOrganizationDocument: ConcessionOrganizationDocument;
  removeConcessionOrganizationDocumentFromDocumentNotification: DocumentNotification;
  removeConcessionOrganizationDocumentSubcategoryFromConcessionOrganizationAccreditationDocument: ConcessionOrganizationAccreditationDocument;
  removeConcessionOrganizationDocumentSubcategoryFromConcessionOrganizationDocument: ConcessionOrganizationDocument;
  removeConcessionOrganizationDocumentsFromConcessionOrganization: ConcessionOrganization;
  removeConcessionOrganizationFromConcessionOrganizationAccreditation: ConcessionOrganizationAccreditation;
  removeConcessionOrganizationFromConcessionOrganizationDocument: ConcessionOrganizationDocument;
  removeConcessionOrganizationFromConcessionOrganizationUser: ConcessionOrganizationUser;
  removeConcessionOrganizationFromContractConcessionOrganization: ContractConcessionOrganization;
  removeConcessionOrganizationFromDocumentNotification: DocumentNotification;
  removeConcessionOrganizationUsersFromUser: User;
  removeConcessionOrganizationsFromOrganization: Organization;
  removeConcessionUsersFromConcession: Concession;
  removeConcessionUsersFromUser: User;
  removeConcessionsFromConcessionOrganizationAccreditationType: ConcessionOrganizationAccreditationType;
  removeContractConcessionOrganizationStateFromContractConcessionOrganization: ContractConcessionOrganization;
  removeContractConcessionOrganizationsFromContract: Contract;
  removeContractDocumentCategoryFromContractDocument: ContractDocument;
  removeContractDocumentFromDocumentNotification: DocumentNotification;
  removeContractDocumentSubcategoryFromContractDocument: ContractDocument;
  removeContractDocumentsFromContract: Contract;
  removeContractFromContractConcessionOrganization: ContractConcessionOrganization;
  removeContractFromContractDocument: ContractDocument;
  removeContractFromContractRoad: ContractRoad;
  removeContractFromContractUser: ContractUser;
  removeContractFromDocumentNotification: DocumentNotification;
  removeContractFromSurveyRecord: SurveyRecord;
  removeContractFromWorkPermit: WorkPermit;
  removeContractRoadsFromContract: Contract;
  removeContractRoadsFromRoad: Road;
  removeContractStateFromContract: Contract;
  removeContractTransitionsFromContract: Contract;
  removeContractTypeFromContract: Contract;
  removeContractUsersFromContract: Contract;
  removeContractUsersFromUser: User;
  removeContractsFromConcession: Concession;
  removeContractsFromContractState: ContractState;
  removeContractsFromContractType: ContractType;
  removeContractsFromInterventionElement: InterventionElement;
  removeContractsFromInterventionLocation: InterventionLocation;
  removeContractsFromInterventionType: InterventionType;
  removeContractsFromService: Service;
  removeControlCentersFromConcession: Concession;
  removeDefaultConcessionFromUser: User;
  removeDirectionFromTask: Task;
  removeDocumentStateFromConcessionOrganizationAccreditationDocument: ConcessionOrganizationAccreditationDocument;
  removeDocumentStateFromConcessionOrganizationDocument: ConcessionOrganizationDocument;
  removeDocumentStateFromContractDocument: ContractDocument;
  removeDocumentStateFromDocumentNotification: DocumentNotification;
  removeEndJunctionFromSurveyRecord: SurveyRecord;
  removeEndJunctionFromTask: Task;
  removeEndJuntionSurveyRecordsFromJunction: Junction;
  removeEndJuntionTasksFromJunction: Junction;
  removeFileTypeFromConcessionOrganizationAccreditationDocument: ConcessionOrganizationAccreditationDocument;
  removeFileTypeFromConcessionOrganizationDocument: ConcessionOrganizationDocument;
  removeFileTypeFromContractDocument: ContractDocument;
  removeFromStateFromContractTransition: ContractTransition;
  removeInterventionElementFromContract: Contract;
  removeInterventionFromTask: Task;
  removeInterventionLocationFromContract: Contract;
  removeInterventionLocationFromTask: Task;
  removeInterventionTypeFromContract: Contract;
  removeInterventionsFromWorkPermit: WorkPermit;
  removeJunctionsFromRoad: Road;
  removeLayoutFromTask: Task;
  removeMediatingOrganizationFromConcessionOrganizationAccreditation: ConcessionOrganizationAccreditation;
  removeOrganizationFromConcessionOrganization: ConcessionOrganization;
  removeOrganizationTypeFromConcessionOrganization: ConcessionOrganization;
  removeOrganizationTypesFromConcessionOrganizationAccreditationType: ConcessionOrganizationAccreditationType;
  removeOwnerFromConcessionOrganizationDocument: ConcessionOrganizationDocument;
  removeOwnerFromContractDocument: ContractDocument;
  removeParentFromConcessionOrganizationDocumentCategory: ConcessionOrganizationDocumentCategory;
  removeParentFromContractDocumentCategory: ContractDocumentCategory;
  removePermissionsFromRole: Role;
  removeRestrictionFromTask: Task;
  removeRoadFromContractRoad: ContractRoad;
  removeRoadFromJunction: Junction;
  removeRoadFromSurveyRecord: SurveyRecord;
  removeRoadFromTask: Task;
  removeRoadsFromConcession: Concession;
  removeRoleFromPermission: Permission;
  removeServiceFromContract: Contract;
  removeStartJunctionFromSurveyRecord: SurveyRecord;
  removeStartJunctionFromTask: Task;
  removeStartJuntionSurveyRecordsFromJunction: Junction;
  removeStartJuntionTasksFromJunction: Junction;
  removeSurveyRecordFromSurveyRecordPhoto: SurveyRecordPhoto;
  removeSurveyRecordFromSurveyRecordVerification: SurveyRecordVerification;
  removeSurveyRecordPhotosFromSurveyRecord: SurveyRecord;
  removeSurveyRecordVerificationClassificationFromSurveyRecordVerification: SurveyRecordVerification;
  removeSurveyRecordVerificationStateFromSurveyRecordVerification: SurveyRecordVerification;
  removeSurveyRecordVerificationsFromSurveyRecord: SurveyRecord;
  removeSurveyRecordVerificationsFromVerification: Verification;
  removeSurveyRecordsFromContract: Contract;
  removeSurveyRecordsFromRoad: Road;
  removeSurveyRecordsFromUser: User;
  removeSurveyRecordsFromWeatherCondition: WeatherCondition;
  removeTasksFromIntervention: Intervention;
  removeTasksFromRestriction: Restriction;
  removeTasksFromRoad: Road;
  removeToStateFromContractTransition: ContractTransition;
  removeUserFromConcessionOrganizationUser: ConcessionOrganizationUser;
  removeUserFromConcessionUser: ConcessionUser;
  removeUserFromContractUser: ContractUser;
  removeUserFromSurveyRecord: SurveyRecord;
  removeUserFromWorkspace: Workspace;
  removeVerificationFromSurveyRecordVerification: SurveyRecordVerification;
  removeVerificationScopeFromVerification: Verification;
  removeVerificationsFromVerificationScope: VerificationScope;
  removeWeatherConditionFromSurveyRecord: SurveyRecord;
  removeWorkPermitFromBadge: Badge;
  removeWorkPermitFromIntervention: Intervention;
  removeWorkPermitsFromContract: Contract;
  removeWorkspaceFromWorkspaceView: WorkspaceView;
  removeWorkspaceViewColumnsFromWorkspaceView: WorkspaceView;
  removeWorkspaceViewsFromWorkspace: Workspace;
  sendContractClosedNotification: Scalars['Boolean'];
  sendContractOpenedNotification: Scalars['Boolean'];
  sendPublishedSurveyRecordEmail: Scalars['Boolean'];
  sendPublishedWorkPermitEmail: Scalars['Boolean'];
  sendResetPasswordEmail: Scalars['Boolean'];
  setBadgesOnWorkPermit: WorkPermit;
  setChecklistItemsOnChecklist: Checklist;
  setChecklistOnChecklistItem: ChecklistItem;
  setChecklistOnWorkPermit: WorkPermit;
  setChildrenOnConcessionOrganizationDocumentCategory: ConcessionOrganizationDocumentCategory;
  setChildrenOnContractDocumentCategory: ContractDocumentCategory;
  setConcessionOnConcessionOrganization: ConcessionOrganization;
  setConcessionOnConcessionOrganizationAccreditationState: ConcessionOrganizationAccreditationState;
  setConcessionOnConcessionOrganizationDocumentCategory: ConcessionOrganizationDocumentCategory;
  setConcessionOnConcessionUser: ConcessionUser;
  setConcessionOnContract: Contract;
  setConcessionOnContractConcessionOrganizationState: ContractConcessionOrganizationState;
  setConcessionOnContractDocumentCategory: ContractDocumentCategory;
  setConcessionOnContractState: ContractState;
  setConcessionOnContractType: ContractType;
  setConcessionOnControlCenter: ControlCenter;
  setConcessionOnDirection: Direction;
  setConcessionOnInterventionElement: InterventionElement;
  setConcessionOnInterventionLocation: InterventionLocation;
  setConcessionOnInterventionType: InterventionType;
  setConcessionOnJunction: Junction;
  setConcessionOnLayout: Layout;
  setConcessionOnRestriction: Restriction;
  setConcessionOnRoad: Road;
  setConcessionOnService: Service;
  setConcessionOnSurveyRecordVerificationClassification: SurveyRecordVerificationClassification;
  setConcessionOnSurveyRecordVerificationState: SurveyRecordVerificationState;
  setConcessionOnVerification: Verification;
  setConcessionOnVerificationScope: VerificationScope;
  setConcessionOnWeatherCondition: WeatherCondition;
  setConcessionOrganizationAccreditationDocumentsOnConcessionOrganizationAccreditation: ConcessionOrganizationAccreditation;
  setConcessionOrganizationAccreditationOnConcessionOrganizationAccreditationDocument: ConcessionOrganizationAccreditationDocument;
  setConcessionOrganizationAccreditationOnContractConcessionOrganization: ContractConcessionOrganization;
  setConcessionOrganizationAccreditationStateOnConcessionOrganizationAccreditation: ConcessionOrganizationAccreditation;
  setConcessionOrganizationAccreditationTypeOnConcessionOrganizationAccreditation: ConcessionOrganizationAccreditation;
  setConcessionOrganizationDocumentCategoryOnConcessionOrganizationAccreditationDocument: ConcessionOrganizationAccreditationDocument;
  setConcessionOrganizationDocumentCategoryOnConcessionOrganizationDocument: ConcessionOrganizationDocument;
  setConcessionOrganizationDocumentOnDocumentNotification: DocumentNotification;
  setConcessionOrganizationDocumentSubcategoryOnConcessionOrganizationAccreditationDocument: ConcessionOrganizationAccreditationDocument;
  setConcessionOrganizationDocumentSubcategoryOnConcessionOrganizationDocument: ConcessionOrganizationDocument;
  setConcessionOrganizationDocumentsOnConcessionOrganization: ConcessionOrganization;
  setConcessionOrganizationOnConcessionOrganizationAccreditation: ConcessionOrganizationAccreditation;
  setConcessionOrganizationOnConcessionOrganizationDocument: ConcessionOrganizationDocument;
  setConcessionOrganizationOnConcessionOrganizationUser: ConcessionOrganizationUser;
  setConcessionOrganizationOnContractConcessionOrganization: ContractConcessionOrganization;
  setConcessionOrganizationOnDocumentNotification: DocumentNotification;
  setConcessionOrganizationUsersOnUser: User;
  setConcessionOrganizationsOnOrganization: Organization;
  setConcessionUsersOnConcession: Concession;
  setConcessionUsersOnUser: User;
  setConcessionsOnConcessionOrganizationAccreditationType: ConcessionOrganizationAccreditationType;
  setContractConcessionOrganizationStateOnContractConcessionOrganization: ContractConcessionOrganization;
  setContractConcessionOrganizationsOnContract: Contract;
  setContractDocumentCategoryOnContractDocument: ContractDocument;
  setContractDocumentOnDocumentNotification: DocumentNotification;
  setContractDocumentSubcategoryOnContractDocument: ContractDocument;
  setContractDocumentsOnContract: Contract;
  setContractOnContractConcessionOrganization: ContractConcessionOrganization;
  setContractOnContractDocument: ContractDocument;
  setContractOnContractRoad: ContractRoad;
  setContractOnContractUser: ContractUser;
  setContractOnDocumentNotification: DocumentNotification;
  setContractOnSurveyRecord: SurveyRecord;
  setContractOnWorkPermit: WorkPermit;
  setContractRoadsForContract: Contract;
  setContractRoadsOnContract: Contract;
  setContractRoadsOnRoad: Road;
  setContractStateOnContract: Contract;
  setContractTransitionsOnContract: Contract;
  setContractTypeOnContract: Contract;
  setContractUsersForContract: Contract;
  setContractUsersOnContract: Contract;
  setContractUsersOnUser: User;
  setContractsOnConcession: Concession;
  setContractsOnContractState: ContractState;
  setContractsOnContractType: ContractType;
  setContractsOnInterventionElement: InterventionElement;
  setContractsOnInterventionLocation: InterventionLocation;
  setContractsOnInterventionType: InterventionType;
  setContractsOnService: Service;
  setControlCentersOnConcession: Concession;
  setDefaultConcessionOnUser: User;
  setDirectionOnTask: Task;
  setDocumentStateOnConcessionOrganizationAccreditationDocument: ConcessionOrganizationAccreditationDocument;
  setDocumentStateOnConcessionOrganizationDocument: ConcessionOrganizationDocument;
  setDocumentStateOnContractDocument: ContractDocument;
  setDocumentStateOnDocumentNotification: DocumentNotification;
  setEndJunctionOnSurveyRecord: SurveyRecord;
  setEndJunctionOnTask: Task;
  setEndJuntionSurveyRecordsOnJunction: Junction;
  setEndJuntionTasksOnJunction: Junction;
  setFileTypeOnConcessionOrganizationAccreditationDocument: ConcessionOrganizationAccreditationDocument;
  setFileTypeOnConcessionOrganizationDocument: ConcessionOrganizationDocument;
  setFileTypeOnContractDocument: ContractDocument;
  setFromStateOnContractTransition: ContractTransition;
  setInterventionElementOnContract: Contract;
  setInterventionLocationOnContract: Contract;
  setInterventionLocationOnTask: Task;
  setInterventionOnTask: Task;
  setInterventionTypeOnContract: Contract;
  setInterventionsOnWorkPermit: WorkPermit;
  setJunctionsOnRoad: Road;
  setLayoutOnTask: Task;
  setMediatingOrganizationOnConcessionOrganizationAccreditation: ConcessionOrganizationAccreditation;
  setOrganizationOnConcessionOrganization: ConcessionOrganization;
  setOrganizationTypeOnConcessionOrganization: ConcessionOrganization;
  setOrganizationTypesOnConcessionOrganizationAccreditationType: ConcessionOrganizationAccreditationType;
  setOwnerOnConcessionOrganizationDocument: ConcessionOrganizationDocument;
  setOwnerOnContractDocument: ContractDocument;
  setParentOnConcessionOrganizationDocumentCategory: ConcessionOrganizationDocumentCategory;
  setParentOnContractDocumentCategory: ContractDocumentCategory;
  setPassword: Scalars['String'];
  setPermissionsOnRole: Role;
  setRestrictionOnTask: Task;
  setRoadOnContractRoad: ContractRoad;
  setRoadOnJunction: Junction;
  setRoadOnSurveyRecord: SurveyRecord;
  setRoadOnTask: Task;
  setRoadsOnConcession: Concession;
  setRoleOnPermission: Permission;
  setServiceOnContract: Contract;
  setStartJunctionOnSurveyRecord: SurveyRecord;
  setStartJunctionOnTask: Task;
  setStartJuntionSurveyRecordsOnJunction: Junction;
  setStartJuntionTasksOnJunction: Junction;
  setSurveyRecordOnSurveyRecordPhoto: SurveyRecordPhoto;
  setSurveyRecordOnSurveyRecordVerification: SurveyRecordVerification;
  setSurveyRecordPhotosOnSurveyRecord: SurveyRecord;
  setSurveyRecordVerificationClassificationOnSurveyRecordVerification: SurveyRecordVerification;
  setSurveyRecordVerificationStateOnSurveyRecordVerification: SurveyRecordVerification;
  setSurveyRecordVerificationsOnSurveyRecord: SurveyRecord;
  setSurveyRecordVerificationsOnVerification: Verification;
  setSurveyRecordsOnContract: Contract;
  setSurveyRecordsOnRoad: Road;
  setSurveyRecordsOnUser: User;
  setSurveyRecordsOnWeatherCondition: WeatherCondition;
  setTasksOnIntervention: Intervention;
  setTasksOnRestriction: Restriction;
  setTasksOnRoad: Road;
  setToStateOnContractTransition: ContractTransition;
  setUserOnConcessionOrganizationUser: ConcessionOrganizationUser;
  setUserOnConcessionUser: ConcessionUser;
  setUserOnContractUser: ContractUser;
  setUserOnSurveyRecord: SurveyRecord;
  setUserOnWorkspace: Workspace;
  setVerificationOnSurveyRecordVerification: SurveyRecordVerification;
  setVerificationScopeOnVerification: Verification;
  setVerificationsOnVerificationScope: VerificationScope;
  setWeatherConditionOnSurveyRecord: SurveyRecord;
  setWorkPermitOnBadge: Badge;
  setWorkPermitOnIntervention: Intervention;
  setWorkPermitsOnContract: Contract;
  setWorkspaceOnWorkspaceView: WorkspaceView;
  setWorkspaceView: Scalars['Boolean'];
  setWorkspaceViewColumnsOnWorkspaceView: WorkspaceView;
  setWorkspaceViewsOnWorkspace: Workspace;
  updateManyBadges: UpdateManyResponse;
  updateManyConcessionOrganizationAccreditationStates: UpdateManyResponse;
  updateManyConcessionOrganizationAccreditationTypes: UpdateManyResponse;
  updateManyConcessionOrganizationAccreditations: UpdateManyResponse;
  updateManyConcessionOrganizationDocumentCategories: UpdateManyResponse;
  updateManyConcessionOrganizationDocuments: UpdateManyResponse;
  updateManyConcessionOrganizationUsers: UpdateManyResponse;
  updateManyConcessionOrganizations: UpdateManyResponse;
  updateManyConcessionUsers: UpdateManyResponse;
  updateManyConcessions: UpdateManyResponse;
  updateManyContractConcessionOrganizationStates: UpdateManyResponse;
  updateManyContractConcessionOrganizations: UpdateManyResponse;
  updateManyContractDocumentCategories: UpdateManyResponse;
  updateManyContractDocuments: UpdateManyResponse;
  updateManyContractRoads: UpdateManyResponse;
  updateManyContractStates: UpdateManyResponse;
  updateManyContractTransitions: UpdateManyResponse;
  updateManyContractTypes: UpdateManyResponse;
  updateManyContractUsers: UpdateManyResponse;
  updateManyContracts: UpdateManyResponse;
  updateManyControlCenters: UpdateManyResponse;
  updateManyDirections: UpdateManyResponse;
  updateManyInterventionElements: UpdateManyResponse;
  updateManyInterventionLocations: UpdateManyResponse;
  updateManyInterventionTypes: UpdateManyResponse;
  updateManyInterventions: UpdateManyResponse;
  updateManyJunctions: UpdateManyResponse;
  updateManyLayouts: UpdateManyResponse;
  updateManyOrganizationTypes: UpdateManyResponse;
  updateManyOrganizations: UpdateManyResponse;
  updateManyRestrictions: UpdateManyResponse;
  updateManyRoads: UpdateManyResponse;
  updateManyServices: UpdateManyResponse;
  updateManySurveyRecordPhotos: UpdateManyResponse;
  updateManySurveyRecordVerificationClassifications: UpdateManyResponse;
  updateManySurveyRecordVerificationStates: UpdateManyResponse;
  updateManySurveyRecordVerifications: UpdateManyResponse;
  updateManySurveyRecords: UpdateManyResponse;
  updateManyTasks: UpdateManyResponse;
  updateManyUsers: UpdateManyResponse;
  updateManyVerificationScopes: UpdateManyResponse;
  updateManyVerifications: UpdateManyResponse;
  updateManyWeatherConditions: UpdateManyResponse;
  updateManyWorkPermits: UpdateManyResponse;
  updateMe: User;
  updateOneBadge: Badge;
  updateOneChecklistItem: ChecklistItem;
  updateOneConcession: Concession;
  updateOneConcessionOrganization: ConcessionOrganization;
  updateOneConcessionOrganizationAccreditation: ConcessionOrganizationAccreditation;
  updateOneConcessionOrganizationAccreditationState: ConcessionOrganizationAccreditationState;
  updateOneConcessionOrganizationAccreditationType: ConcessionOrganizationAccreditationType;
  updateOneConcessionOrganizationDocument: ConcessionOrganizationDocument;
  updateOneConcessionOrganizationDocumentCategory: ConcessionOrganizationDocumentCategory;
  updateOneConcessionOrganizationUser: ConcessionOrganizationUser;
  updateOneConcessionUser: ConcessionUser;
  updateOneContract: Contract;
  updateOneContractConcessionOrganization: ContractConcessionOrganization;
  updateOneContractConcessionOrganizationState: ContractConcessionOrganizationState;
  updateOneContractDocument: ContractDocument;
  updateOneContractDocumentCategory: ContractDocumentCategory;
  updateOneContractRoad: ContractRoad;
  updateOneContractState: ContractState;
  updateOneContractTransition: ContractTransition;
  updateOneContractType: ContractType;
  updateOneContractUser: ContractUser;
  updateOneControlCenter: ControlCenter;
  updateOneDirection: Direction;
  updateOneIntervention: Intervention;
  updateOneInterventionElement: InterventionElement;
  updateOneInterventionLocation: InterventionLocation;
  updateOneInterventionType: InterventionType;
  updateOneJunction: Junction;
  updateOneLayout: Layout;
  updateOneOrganization: Organization;
  updateOneOrganizationType: OrganizationType;
  updateOneRestriction: Restriction;
  updateOneRoad: Road;
  updateOneService: Service;
  updateOneSubcontract: ContractConcessionOrganization;
  updateOneSurveyRecord: SurveyRecord;
  updateOneSurveyRecordPhoto: SurveyRecordPhoto;
  updateOneSurveyRecordVerification: SurveyRecordVerification;
  updateOneSurveyRecordVerificationClassification: SurveyRecordVerificationClassification;
  updateOneSurveyRecordVerificationState: SurveyRecordVerificationState;
  updateOneTask: Task;
  updateOneUser: User;
  updateOneVerification: Verification;
  updateOneVerificationScope: VerificationScope;
  updateOneWeatherCondition: WeatherCondition;
  updateOneWorkPermit: WorkPermit;
  updateOwnConcessionOrganizationDocument: ConcessionOrganizationDocument;
  updateOwnSubcontractOrganizationDocument: ConcessionOrganizationDocument;
  validateTaskImport: Array<ImportValidationErrorOutput>;
};


export type MutationAddBadgesToWorkPermitArgs = {
  input: AddBadgesToWorkPermitInput;
};


export type MutationAddChecklistItemsToChecklistArgs = {
  input: AddChecklistItemsToChecklistInput;
};


export type MutationAddChildrenToConcessionOrganizationDocumentCategoryArgs = {
  input: AddChildrenToConcessionOrganizationDocumentCategoryInput;
};


export type MutationAddChildrenToContractDocumentCategoryArgs = {
  input: AddChildrenToContractDocumentCategoryInput;
};


export type MutationAddConcessionOrganizationAccreditationDocumentsToConcessionOrganizationAccreditationArgs = {
  input: AddConcessionOrganizationAccreditationDocumentsToConcessionOrganizationAccreditationInput;
};


export type MutationAddConcessionOrganizationDocumentsToConcessionOrganizationArgs = {
  input: AddConcessionOrganizationDocumentsToConcessionOrganizationInput;
};


export type MutationAddConcessionOrganizationUsersToUserArgs = {
  input: AddConcessionOrganizationUsersToUserInput;
};


export type MutationAddConcessionOrganizationsToOrganizationArgs = {
  input: AddConcessionOrganizationsToOrganizationInput;
};


export type MutationAddConcessionUsersToConcessionArgs = {
  input: AddConcessionUsersToConcessionInput;
};


export type MutationAddConcessionUsersToUserArgs = {
  input: AddConcessionUsersToUserInput;
};


export type MutationAddConcessionsToConcessionOrganizationAccreditationTypeArgs = {
  input: AddConcessionsToConcessionOrganizationAccreditationTypeInput;
};


export type MutationAddContractConcessionOrganizationsToContractArgs = {
  input: AddContractConcessionOrganizationsToContractInput;
};


export type MutationAddContractDocumentsToContractArgs = {
  input: AddContractDocumentsToContractInput;
};


export type MutationAddContractRoadsToContractArgs = {
  input: AddContractRoadsToContractInput;
};


export type MutationAddContractRoadsToRoadArgs = {
  input: AddContractRoadsToRoadInput;
};


export type MutationAddContractTransitionsToContractArgs = {
  input: AddContractTransitionsToContractInput;
};


export type MutationAddContractUsersToContractArgs = {
  input: AddContractUsersToContractInput;
};


export type MutationAddContractUsersToUserArgs = {
  input: AddContractUsersToUserInput;
};


export type MutationAddContractsToConcessionArgs = {
  input: AddContractsToConcessionInput;
};


export type MutationAddContractsToContractStateArgs = {
  input: AddContractsToContractStateInput;
};


export type MutationAddContractsToContractTypeArgs = {
  input: AddContractsToContractTypeInput;
};


export type MutationAddContractsToInterventionElementArgs = {
  input: AddContractsToInterventionElementInput;
};


export type MutationAddContractsToInterventionLocationArgs = {
  input: AddContractsToInterventionLocationInput;
};


export type MutationAddContractsToInterventionTypeArgs = {
  input: AddContractsToInterventionTypeInput;
};


export type MutationAddContractsToServiceArgs = {
  input: AddContractsToServiceInput;
};


export type MutationAddControlCentersToConcessionArgs = {
  input: AddControlCentersToConcessionInput;
};


export type MutationAddEndJuntionSurveyRecordsToJunctionArgs = {
  input: AddEndJuntionSurveyRecordsToJunctionInput;
};


export type MutationAddEndJuntionTasksToJunctionArgs = {
  input: AddEndJuntionTasksToJunctionInput;
};


export type MutationAddInterventionsToWorkPermitArgs = {
  input: AddInterventionsToWorkPermitInput;
};


export type MutationAddJunctionsToRoadArgs = {
  input: AddJunctionsToRoadInput;
};


export type MutationAddOrganizationTypesToConcessionOrganizationAccreditationTypeArgs = {
  input: AddOrganizationTypesToConcessionOrganizationAccreditationTypeInput;
};


export type MutationAddPermissionsToRoleArgs = {
  input: AddPermissionsToRoleInput;
};


export type MutationAddRoadsToConcessionArgs = {
  input: AddRoadsToConcessionInput;
};


export type MutationAddStartJuntionSurveyRecordsToJunctionArgs = {
  input: AddStartJuntionSurveyRecordsToJunctionInput;
};


export type MutationAddStartJuntionTasksToJunctionArgs = {
  input: AddStartJuntionTasksToJunctionInput;
};


export type MutationAddSurveyRecordPhotosToSurveyRecordArgs = {
  input: AddSurveyRecordPhotosToSurveyRecordInput;
};


export type MutationAddSurveyRecordVerificationsToSurveyRecordArgs = {
  input: AddSurveyRecordVerificationsToSurveyRecordInput;
};


export type MutationAddSurveyRecordVerificationsToVerificationArgs = {
  input: AddSurveyRecordVerificationsToVerificationInput;
};


export type MutationAddSurveyRecordsToContractArgs = {
  input: AddSurveyRecordsToContractInput;
};


export type MutationAddSurveyRecordsToRoadArgs = {
  input: AddSurveyRecordsToRoadInput;
};


export type MutationAddSurveyRecordsToUserArgs = {
  input: AddSurveyRecordsToUserInput;
};


export type MutationAddSurveyRecordsToWeatherConditionArgs = {
  input: AddSurveyRecordsToWeatherConditionInput;
};


export type MutationAddTasksToInterventionArgs = {
  input: AddTasksToInterventionInput;
};


export type MutationAddTasksToRestrictionArgs = {
  input: AddTasksToRestrictionInput;
};


export type MutationAddTasksToRoadArgs = {
  input: AddTasksToRoadInput;
};


export type MutationAddVerificationsToVerificationScopeArgs = {
  input: AddVerificationsToVerificationScopeInput;
};


export type MutationAddWorkPermitsToContractArgs = {
  input: AddWorkPermitsToContractInput;
};


export type MutationAddWorkspaceViewColumnsToWorkspaceViewArgs = {
  input: AddWorkspaceViewColumnsToWorkspaceViewInput;
};


export type MutationAddWorkspaceViewsToWorkspaceArgs = {
  input: AddWorkspaceViewsToWorkspaceInput;
};


export type MutationClearOverlapsArgs = {
  input: FindOverlapsInput;
};


export type MutationCreateManyBadgesArgs = {
  input: CreateManyBadgesInput;
};


export type MutationCreateManyConcessionOrganizationAccreditationStatesArgs = {
  input: CreateManyConcessionOrganizationAccreditationStatesInput;
};


export type MutationCreateManyConcessionOrganizationAccreditationTypesArgs = {
  input: CreateManyConcessionOrganizationAccreditationTypesInput;
};


export type MutationCreateManyConcessionOrganizationAccreditationsArgs = {
  input: CreateManyConcessionOrganizationAccreditationsInput;
};


export type MutationCreateManyConcessionOrganizationDocumentCategoriesArgs = {
  input: CreateManyConcessionOrganizationDocumentCategoriesInput;
};


export type MutationCreateManyConcessionOrganizationUsersArgs = {
  input: CreateManyConcessionOrganizationUsersInput;
};


export type MutationCreateManyConcessionOrganizationsArgs = {
  input: CreateManyConcessionOrganizationsInput;
};


export type MutationCreateManyConcessionUsersArgs = {
  input: CreateManyConcessionUsersInput;
};


export type MutationCreateManyConcessionsArgs = {
  input: CreateManyConcessionsInput;
};


export type MutationCreateManyContractConcessionOrganizationStatesArgs = {
  input: CreateManyContractConcessionOrganizationStatesInput;
};


export type MutationCreateManyContractConcessionOrganizationsArgs = {
  input: CreateManyContractConcessionOrganizationsInput;
};


export type MutationCreateManyContractConcessionOrganizationsDedupedArgs = {
  input: CreateManyContractConcessionOrganizationsDedupedInput;
};


export type MutationCreateManyContractDocumentCategoriesArgs = {
  input: CreateManyContractDocumentCategoriesInput;
};


export type MutationCreateManyContractRoadsArgs = {
  input: CreateManyContractRoadsInput;
};


export type MutationCreateManyContractStatesArgs = {
  input: CreateManyContractStatesInput;
};


export type MutationCreateManyContractTransitionsArgs = {
  input: CreateManyContractTransitionsInput;
};


export type MutationCreateManyContractTypesArgs = {
  input: CreateManyContractTypesInput;
};


export type MutationCreateManyContractUsersArgs = {
  input: CreateManyContractUsersInput;
};


export type MutationCreateManyControlCentersArgs = {
  input: CreateManyControlCentersInput;
};


export type MutationCreateManyDirectionsArgs = {
  input: CreateManyDirectionsInput;
};


export type MutationCreateManyInterventionElementsArgs = {
  input: CreateManyInterventionElementsInput;
};


export type MutationCreateManyInterventionLocationsArgs = {
  input: CreateManyInterventionLocationsInput;
};


export type MutationCreateManyInterventionTypesArgs = {
  input: CreateManyInterventionTypesInput;
};


export type MutationCreateManyInterventionsArgs = {
  input: CreateManyInterventionsInput;
};


export type MutationCreateManyJunctionsArgs = {
  input: CreateManyJunctionsInput;
};


export type MutationCreateManyLayoutsArgs = {
  input: CreateManyLayoutsInput;
};


export type MutationCreateManyOrganizationTypesArgs = {
  input: CreateManyOrganizationTypesInput;
};


export type MutationCreateManyOrganizationsArgs = {
  input: CreateManyOrganizationsInput;
};


export type MutationCreateManyRestrictionsArgs = {
  input: CreateManyRestrictionsInput;
};


export type MutationCreateManyRoadsArgs = {
  input: CreateManyRoadsInput;
};


export type MutationCreateManyServicesArgs = {
  input: CreateManyServicesInput;
};


export type MutationCreateManySurveyRecordPhotosArgs = {
  input: CreateManySurveyRecordPhotosInput;
};


export type MutationCreateManySurveyRecordVerificationClassificationsArgs = {
  input: CreateManySurveyRecordVerificationClassificationsInput;
};


export type MutationCreateManySurveyRecordVerificationStatesArgs = {
  input: CreateManySurveyRecordVerificationStatesInput;
};


export type MutationCreateManySurveyRecordVerificationsArgs = {
  input: CreateManySurveyRecordVerificationsInput;
};


export type MutationCreateManySurveyRecordsArgs = {
  input: CreateManySurveyRecordsInput;
};


export type MutationCreateManyTasksArgs = {
  input: CreateManyTasksInput;
};


export type MutationCreateManyUsersArgs = {
  input: CreateManyUsersInput;
};


export type MutationCreateManyVerificationScopesArgs = {
  input: CreateManyVerificationScopesInput;
};


export type MutationCreateManyVerificationsArgs = {
  input: CreateManyVerificationsInput;
};


export type MutationCreateManyWeatherConditionsArgs = {
  input: CreateManyWeatherConditionsInput;
};


export type MutationCreateManyWorkPermitsArgs = {
  input: CreateManyWorkPermitsInput;
};


export type MutationCreateOneBadgeArgs = {
  input: CreateOneBadgeInput;
};


export type MutationCreateOneConcessionArgs = {
  input: CreateOneConcessionInput;
};


export type MutationCreateOneConcessionOrganizationArgs = {
  input: CreateOneConcessionOrganizationInput;
};


export type MutationCreateOneConcessionOrganizationAccreditationArgs = {
  input: CreateOneConcessionOrganizationAccreditationInput;
};


export type MutationCreateOneConcessionOrganizationAccreditationDocumentArgs = {
  input: CreateOneConcessionOrganizationAccreditationDocumentInput;
};


export type MutationCreateOneConcessionOrganizationAccreditationStateArgs = {
  input: CreateOneConcessionOrganizationAccreditationStateInput;
};


export type MutationCreateOneConcessionOrganizationAccreditationTypeArgs = {
  input: CreateOneConcessionOrganizationAccreditationTypeInput;
};


export type MutationCreateOneConcessionOrganizationDocumentArgs = {
  input: CreateOneConcessionOrganizationDocumentInput;
};


export type MutationCreateOneConcessionOrganizationDocumentCategoryArgs = {
  input: CreateOneConcessionOrganizationDocumentCategoryInput;
};


export type MutationCreateOneConcessionOrganizationUserArgs = {
  input: CreateOneConcessionOrganizationUserInput;
};


export type MutationCreateOneConcessionUserArgs = {
  input: CreateOneConcessionUserInput;
};


export type MutationCreateOneContractArgs = {
  input: CreateOneContractInput;
};


export type MutationCreateOneContractConcessionOrganizationArgs = {
  input: CreateOneContractConcessionOrganizationInput;
};


export type MutationCreateOneContractConcessionOrganizationStateArgs = {
  input: CreateOneContractConcessionOrganizationStateInput;
};


export type MutationCreateOneContractDocumentArgs = {
  input: CreateOneContractDocumentInput;
};


export type MutationCreateOneContractDocumentCategoryArgs = {
  input: CreateOneContractDocumentCategoryInput;
};


export type MutationCreateOneContractRoadArgs = {
  input: CreateOneContractRoadInput;
};


export type MutationCreateOneContractStateArgs = {
  input: CreateOneContractStateInput;
};


export type MutationCreateOneContractTransitionArgs = {
  input: CreateOneContractTransitionInput;
};


export type MutationCreateOneContractTypeArgs = {
  input: CreateOneContractTypeInput;
};


export type MutationCreateOneContractUserArgs = {
  input: CreateOneContractUserInput;
};


export type MutationCreateOneControlCenterArgs = {
  input: CreateOneControlCenterInput;
};


export type MutationCreateOneDirectionArgs = {
  input: CreateOneDirectionInput;
};


export type MutationCreateOneInterventionArgs = {
  input: CreateOneInterventionInput;
};


export type MutationCreateOneInterventionElementArgs = {
  input: CreateOneInterventionElementInput;
};


export type MutationCreateOneInterventionLocationArgs = {
  input: CreateOneInterventionLocationInput;
};


export type MutationCreateOneInterventionTypeArgs = {
  input: CreateOneInterventionTypeInput;
};


export type MutationCreateOneJunctionArgs = {
  input: CreateOneJunctionInput;
};


export type MutationCreateOneLayoutArgs = {
  input: CreateOneLayoutInput;
};


export type MutationCreateOneOrganizationArgs = {
  input: CreateOneOrganizationInput;
};


export type MutationCreateOneOrganizationTypeArgs = {
  input: CreateOneOrganizationTypeInput;
};


export type MutationCreateOneRestrictionArgs = {
  input: CreateOneRestrictionInput;
};


export type MutationCreateOneRoadArgs = {
  input: CreateOneRoadInput;
};


export type MutationCreateOneServiceArgs = {
  input: CreateOneServiceInput;
};


export type MutationCreateOneSubcontractArgs = {
  input: CreateOneSubcontractInput;
};


export type MutationCreateOneSurveyRecordArgs = {
  input: CreateOneSurveyRecordInput;
};


export type MutationCreateOneSurveyRecordPhotoArgs = {
  input: CreateOneSurveyRecordPhotoInput;
};


export type MutationCreateOneSurveyRecordVerificationArgs = {
  input: CreateOneSurveyRecordVerificationInput;
};


export type MutationCreateOneSurveyRecordVerificationClassificationArgs = {
  input: CreateOneSurveyRecordVerificationClassificationInput;
};


export type MutationCreateOneSurveyRecordVerificationStateArgs = {
  input: CreateOneSurveyRecordVerificationStateInput;
};


export type MutationCreateOneTaskArgs = {
  input: CreateOneTaskInput;
};


export type MutationCreateOneUserArgs = {
  input: CreateOneUserInput;
};


export type MutationCreateOneVerificationArgs = {
  input: CreateOneVerificationInput;
};


export type MutationCreateOneVerificationScopeArgs = {
  input: CreateOneVerificationScopeInput;
};


export type MutationCreateOneWeatherConditionArgs = {
  input: CreateOneWeatherConditionInput;
};


export type MutationCreateOneWorkPermitArgs = {
  input: CreateOneWorkPermitInput;
};


export type MutationCreateOwnConcessionOrganizationDocumentArgs = {
  input: CreateOwnConcesssionOrganizationDocumentInput;
};


export type MutationCreateOwnSubcontractOrganizationDocumentArgs = {
  input: CreateOwnConcesssionOrganizationDocumentInput;
};


export type MutationDeleteManyBadgesArgs = {
  input: DeleteManyBadgesInput;
};


export type MutationDeleteManyConcessionOrganizationAccreditationDocumentsArgs = {
  input: DeleteManyConcessionOrganizationAccreditationDocumentsInput;
};


export type MutationDeleteManyConcessionOrganizationAccreditationStatesArgs = {
  input: DeleteManyConcessionOrganizationAccreditationStatesInput;
};


export type MutationDeleteManyConcessionOrganizationAccreditationTypesArgs = {
  input: DeleteManyConcessionOrganizationAccreditationTypesInput;
};


export type MutationDeleteManyConcessionOrganizationAccreditationsArgs = {
  input: DeleteManyConcessionOrganizationAccreditationsInput;
};


export type MutationDeleteManyConcessionOrganizationDocumentCategoriesArgs = {
  input: DeleteManyConcessionOrganizationDocumentCategoriesInput;
};


export type MutationDeleteManyConcessionOrganizationDocumentsArgs = {
  input: DeleteManyConcessionOrganizationDocumentsInput;
};


export type MutationDeleteManyConcessionOrganizationUsersArgs = {
  input: DeleteManyConcessionOrganizationUsersInput;
};


export type MutationDeleteManyConcessionOrganizationsArgs = {
  input: DeleteManyConcessionOrganizationsInput;
};


export type MutationDeleteManyConcessionUsersArgs = {
  input: DeleteManyConcessionUsersInput;
};


export type MutationDeleteManyConcessionsArgs = {
  input: DeleteManyConcessionsInput;
};


export type MutationDeleteManyContractConcessionOrganizationStatesArgs = {
  input: DeleteManyContractConcessionOrganizationStatesInput;
};


export type MutationDeleteManyContractConcessionOrganizationsArgs = {
  input: DeleteManyContractConcessionOrganizationsInput;
};


export type MutationDeleteManyContractDocumentCategoriesArgs = {
  input: DeleteManyContractDocumentCategoriesInput;
};


export type MutationDeleteManyContractDocumentsArgs = {
  input: DeleteManyContractDocumentsInput;
};


export type MutationDeleteManyContractRoadsArgs = {
  input: DeleteManyContractRoadsInput;
};


export type MutationDeleteManyContractStatesArgs = {
  input: DeleteManyContractStatesInput;
};


export type MutationDeleteManyContractTransitionsArgs = {
  input: DeleteManyContractTransitionsInput;
};


export type MutationDeleteManyContractTypesArgs = {
  input: DeleteManyContractTypesInput;
};


export type MutationDeleteManyContractUsersArgs = {
  input: DeleteManyContractUsersInput;
};


export type MutationDeleteManyContractsArgs = {
  input: DeleteManyContractsInput;
};


export type MutationDeleteManyControlCentersArgs = {
  input: DeleteManyControlCentersInput;
};


export type MutationDeleteManyDirectionsArgs = {
  input: DeleteManyDirectionsInput;
};


export type MutationDeleteManyInterventionElementsArgs = {
  input: DeleteManyInterventionElementsInput;
};


export type MutationDeleteManyInterventionLocationsArgs = {
  input: DeleteManyInterventionLocationsInput;
};


export type MutationDeleteManyInterventionTypesArgs = {
  input: DeleteManyInterventionTypesInput;
};


export type MutationDeleteManyInterventionsArgs = {
  input: DeleteManyInterventionsInput;
};


export type MutationDeleteManyJunctionsArgs = {
  input: DeleteManyJunctionsInput;
};


export type MutationDeleteManyLayoutsArgs = {
  input: DeleteManyLayoutsInput;
};


export type MutationDeleteManyOrganizationTypesArgs = {
  input: DeleteManyOrganizationTypesInput;
};


export type MutationDeleteManyOrganizationsArgs = {
  input: DeleteManyOrganizationsInput;
};


export type MutationDeleteManyRestrictionsArgs = {
  input: DeleteManyRestrictionsInput;
};


export type MutationDeleteManyRoadsArgs = {
  input: DeleteManyRoadsInput;
};


export type MutationDeleteManyServicesArgs = {
  input: DeleteManyServicesInput;
};


export type MutationDeleteManySurveyRecordPhotosArgs = {
  input: DeleteManySurveyRecordPhotosInput;
};


export type MutationDeleteManySurveyRecordVerificationClassificationsArgs = {
  input: DeleteManySurveyRecordVerificationClassificationsInput;
};


export type MutationDeleteManySurveyRecordVerificationStatesArgs = {
  input: DeleteManySurveyRecordVerificationStatesInput;
};


export type MutationDeleteManySurveyRecordVerificationsArgs = {
  input: DeleteManySurveyRecordVerificationsInput;
};


export type MutationDeleteManySurveyRecordsArgs = {
  input: DeleteManySurveyRecordsInput;
};


export type MutationDeleteManyTasksArgs = {
  input: DeleteManyTasksInput;
};


export type MutationDeleteManyUsersArgs = {
  input: DeleteManyUsersInput;
};


export type MutationDeleteManyVerificationScopesArgs = {
  input: DeleteManyVerificationScopesInput;
};


export type MutationDeleteManyVerificationsArgs = {
  input: DeleteManyVerificationsInput;
};


export type MutationDeleteManyWeatherConditionsArgs = {
  input: DeleteManyWeatherConditionsInput;
};


export type MutationDeleteManyWorkPermitsArgs = {
  input: DeleteManyWorkPermitsInput;
};


export type MutationDeleteOneBadgeArgs = {
  input: DeleteOneBadgeInput;
};


export type MutationDeleteOneConcessionArgs = {
  input: DeleteOneConcessionInput;
};


export type MutationDeleteOneConcessionOrganizationArgs = {
  input: DeleteOneConcessionOrganizationInput;
};


export type MutationDeleteOneConcessionOrganizationAccreditationArgs = {
  input: DeleteOneConcessionOrganizationAccreditationInput;
};


export type MutationDeleteOneConcessionOrganizationAccreditationDocumentArgs = {
  input: DeleteOneConcessionOrganizationAccreditationDocumentInput;
};


export type MutationDeleteOneConcessionOrganizationAccreditationStateArgs = {
  input: DeleteOneConcessionOrganizationAccreditationStateInput;
};


export type MutationDeleteOneConcessionOrganizationAccreditationTypeArgs = {
  input: DeleteOneConcessionOrganizationAccreditationTypeInput;
};


export type MutationDeleteOneConcessionOrganizationDocumentArgs = {
  input: DeleteOneConcessionOrganizationDocumentInput;
};


export type MutationDeleteOneConcessionOrganizationDocumentCategoryArgs = {
  input: DeleteOneConcessionOrganizationDocumentCategoryInput;
};


export type MutationDeleteOneConcessionOrganizationUserArgs = {
  input: DeleteOneConcessionOrganizationUserInput;
};


export type MutationDeleteOneConcessionUserArgs = {
  input: DeleteOneConcessionUserInput;
};


export type MutationDeleteOneContractArgs = {
  input: DeleteOneContractInput;
};


export type MutationDeleteOneContractConcessionOrganizationArgs = {
  input: DeleteOneContractConcessionOrganizationInput;
};


export type MutationDeleteOneContractConcessionOrganizationStateArgs = {
  input: DeleteOneContractConcessionOrganizationStateInput;
};


export type MutationDeleteOneContractDocumentArgs = {
  input: DeleteOneContractDocumentInput;
};


export type MutationDeleteOneContractDocumentCategoryArgs = {
  input: DeleteOneContractDocumentCategoryInput;
};


export type MutationDeleteOneContractRoadArgs = {
  input: DeleteOneContractRoadInput;
};


export type MutationDeleteOneContractStateArgs = {
  input: DeleteOneContractStateInput;
};


export type MutationDeleteOneContractTransitionArgs = {
  input: DeleteOneContractTransitionInput;
};


export type MutationDeleteOneContractTypeArgs = {
  input: DeleteOneContractTypeInput;
};


export type MutationDeleteOneContractUserArgs = {
  input: DeleteOneContractUserInput;
};


export type MutationDeleteOneControlCenterArgs = {
  input: DeleteOneControlCenterInput;
};


export type MutationDeleteOneDirectionArgs = {
  input: DeleteOneDirectionInput;
};


export type MutationDeleteOneInterventionArgs = {
  input: DeleteOneInterventionInput;
};


export type MutationDeleteOneInterventionElementArgs = {
  input: DeleteOneInterventionElementInput;
};


export type MutationDeleteOneInterventionLocationArgs = {
  input: DeleteOneInterventionLocationInput;
};


export type MutationDeleteOneInterventionTypeArgs = {
  input: DeleteOneInterventionTypeInput;
};


export type MutationDeleteOneJunctionArgs = {
  input: DeleteOneJunctionInput;
};


export type MutationDeleteOneLayoutArgs = {
  input: DeleteOneLayoutInput;
};


export type MutationDeleteOneOrganizationArgs = {
  input: DeleteOneOrganizationInput;
};


export type MutationDeleteOneOrganizationTypeArgs = {
  input: DeleteOneOrganizationTypeInput;
};


export type MutationDeleteOneRestrictionArgs = {
  input: DeleteOneRestrictionInput;
};


export type MutationDeleteOneRoadArgs = {
  input: DeleteOneRoadInput;
};


export type MutationDeleteOneServiceArgs = {
  input: DeleteOneServiceInput;
};


export type MutationDeleteOneSubcontractArgs = {
  input: DeleteOneSubcontractInput;
};


export type MutationDeleteOneSurveyRecordArgs = {
  input: DeleteOneSurveyRecordInput;
};


export type MutationDeleteOneSurveyRecordPhotoArgs = {
  input: DeleteOneSurveyRecordPhotoInput;
};


export type MutationDeleteOneSurveyRecordVerificationArgs = {
  input: DeleteOneSurveyRecordVerificationInput;
};


export type MutationDeleteOneSurveyRecordVerificationClassificationArgs = {
  input: DeleteOneSurveyRecordVerificationClassificationInput;
};


export type MutationDeleteOneSurveyRecordVerificationStateArgs = {
  input: DeleteOneSurveyRecordVerificationStateInput;
};


export type MutationDeleteOneTaskArgs = {
  input: DeleteOneTaskInput;
};


export type MutationDeleteOneUserArgs = {
  input: DeleteOneUserInput;
};


export type MutationDeleteOneVerificationArgs = {
  input: DeleteOneVerificationInput;
};


export type MutationDeleteOneVerificationScopeArgs = {
  input: DeleteOneVerificationScopeInput;
};


export type MutationDeleteOneWeatherConditionArgs = {
  input: DeleteOneWeatherConditionInput;
};


export type MutationDeleteOneWorkPermitArgs = {
  input: DeleteOneWorkPermitInput;
};


export type MutationDeleteOwnConcessionOrganizationDocumentArgs = {
  input: DeleteOwnConcesssionOrganizationDocument;
};


export type MutationDeleteOwnSubcontractOrganizationDocumentArgs = {
  input: DeleteOwnConcesssionOrganizationDocument;
};


export type MutationExportSurveyRecordPdfArgs = {
  input: ExportSurveyRecordInput;
};


export type MutationFindOverlapsArgs = {
  input: FindOverlapsInput;
};


export type MutationGenerateTaskExcelTemplateArgs = {
  input: GenerateTaskExcelTemplate;
};


export type MutationGenerateWeeklyTasksSheetArgs = {
  input: GenerateWeeklyTasksZip;
};


export type MutationGenerateWeeklyTasksZipArgs = {
  input: GenerateWeeklyTasksZip;
};


export type MutationImportConcessionOrganizationAccreditationDocumentsArgs = {
  input: ImportConcessionOrganizationAccreditationDocumentsInput;
};


export type MutationImportManyTasksArgs = {
  input: ImportManyTasksInput;
};


export type MutationManageWorkspaceViewColumnArgs = {
  input: ManageWorkspaceViewColumn;
};


export type MutationRemoveBadgesFromWorkPermitArgs = {
  input: RemoveBadgesFromWorkPermitInput;
};


export type MutationRemoveChecklistFromChecklistItemArgs = {
  input: RemoveChecklistFromChecklistItemInput;
};


export type MutationRemoveChecklistFromWorkPermitArgs = {
  input: RemoveChecklistFromWorkPermitInput;
};


export type MutationRemoveChecklistItemsFromChecklistArgs = {
  input: RemoveChecklistItemsFromChecklistInput;
};


export type MutationRemoveChildrenFromConcessionOrganizationDocumentCategoryArgs = {
  input: RemoveChildrenFromConcessionOrganizationDocumentCategoryInput;
};


export type MutationRemoveChildrenFromContractDocumentCategoryArgs = {
  input: RemoveChildrenFromContractDocumentCategoryInput;
};


export type MutationRemoveConcessionFromConcessionOrganizationArgs = {
  input: RemoveConcessionFromConcessionOrganizationInput;
};


export type MutationRemoveConcessionFromConcessionOrganizationAccreditationStateArgs = {
  input: RemoveConcessionFromConcessionOrganizationAccreditationStateInput;
};


export type MutationRemoveConcessionFromConcessionOrganizationDocumentCategoryArgs = {
  input: RemoveConcessionFromConcessionOrganizationDocumentCategoryInput;
};


export type MutationRemoveConcessionFromConcessionUserArgs = {
  input: RemoveConcessionFromConcessionUserInput;
};


export type MutationRemoveConcessionFromContractArgs = {
  input: RemoveConcessionFromContractInput;
};


export type MutationRemoveConcessionFromContractConcessionOrganizationStateArgs = {
  input: RemoveConcessionFromContractConcessionOrganizationStateInput;
};


export type MutationRemoveConcessionFromContractDocumentCategoryArgs = {
  input: RemoveConcessionFromContractDocumentCategoryInput;
};


export type MutationRemoveConcessionFromContractStateArgs = {
  input: RemoveConcessionFromContractStateInput;
};


export type MutationRemoveConcessionFromContractTypeArgs = {
  input: RemoveConcessionFromContractTypeInput;
};


export type MutationRemoveConcessionFromControlCenterArgs = {
  input: RemoveConcessionFromControlCenterInput;
};


export type MutationRemoveConcessionFromDirectionArgs = {
  input: RemoveConcessionFromDirectionInput;
};


export type MutationRemoveConcessionFromInterventionElementArgs = {
  input: RemoveConcessionFromInterventionElementInput;
};


export type MutationRemoveConcessionFromInterventionLocationArgs = {
  input: RemoveConcessionFromInterventionLocationInput;
};


export type MutationRemoveConcessionFromInterventionTypeArgs = {
  input: RemoveConcessionFromInterventionTypeInput;
};


export type MutationRemoveConcessionFromJunctionArgs = {
  input: RemoveConcessionFromJunctionInput;
};


export type MutationRemoveConcessionFromLayoutArgs = {
  input: RemoveConcessionFromLayoutInput;
};


export type MutationRemoveConcessionFromRestrictionArgs = {
  input: RemoveConcessionFromRestrictionInput;
};


export type MutationRemoveConcessionFromRoadArgs = {
  input: RemoveConcessionFromRoadInput;
};


export type MutationRemoveConcessionFromServiceArgs = {
  input: RemoveConcessionFromServiceInput;
};


export type MutationRemoveConcessionFromSurveyRecordVerificationClassificationArgs = {
  input: RemoveConcessionFromSurveyRecordVerificationClassificationInput;
};


export type MutationRemoveConcessionFromSurveyRecordVerificationStateArgs = {
  input: RemoveConcessionFromSurveyRecordVerificationStateInput;
};


export type MutationRemoveConcessionFromVerificationArgs = {
  input: RemoveConcessionFromVerificationInput;
};


export type MutationRemoveConcessionFromVerificationScopeArgs = {
  input: RemoveConcessionFromVerificationScopeInput;
};


export type MutationRemoveConcessionFromWeatherConditionArgs = {
  input: RemoveConcessionFromWeatherConditionInput;
};


export type MutationRemoveConcessionOrganizationAccreditationDocumentsFromConcessionOrganizationAccreditationArgs = {
  input: RemoveConcessionOrganizationAccreditationDocumentsFromConcessionOrganizationAccreditationInput;
};


export type MutationRemoveConcessionOrganizationAccreditationFromConcessionOrganizationAccreditationDocumentArgs = {
  input: RemoveConcessionOrganizationAccreditationFromConcessionOrganizationAccreditationDocumentInput;
};


export type MutationRemoveConcessionOrganizationAccreditationFromContractConcessionOrganizationArgs = {
  input: RemoveConcessionOrganizationAccreditationFromContractConcessionOrganizationInput;
};


export type MutationRemoveConcessionOrganizationAccreditationStateFromConcessionOrganizationAccreditationArgs = {
  input: RemoveConcessionOrganizationAccreditationStateFromConcessionOrganizationAccreditationInput;
};


export type MutationRemoveConcessionOrganizationAccreditationTypeFromConcessionOrganizationAccreditationArgs = {
  input: RemoveConcessionOrganizationAccreditationTypeFromConcessionOrganizationAccreditationInput;
};


export type MutationRemoveConcessionOrganizationDocumentCategoryFromConcessionOrganizationAccreditationDocumentArgs = {
  input: RemoveConcessionOrganizationDocumentCategoryFromConcessionOrganizationAccreditationDocumentInput;
};


export type MutationRemoveConcessionOrganizationDocumentCategoryFromConcessionOrganizationDocumentArgs = {
  input: RemoveConcessionOrganizationDocumentCategoryFromConcessionOrganizationDocumentInput;
};


export type MutationRemoveConcessionOrganizationDocumentFromDocumentNotificationArgs = {
  input: RemoveConcessionOrganizationDocumentFromDocumentNotificationInput;
};


export type MutationRemoveConcessionOrganizationDocumentSubcategoryFromConcessionOrganizationAccreditationDocumentArgs = {
  input: RemoveConcessionOrganizationDocumentSubcategoryFromConcessionOrganizationAccreditationDocumentInput;
};


export type MutationRemoveConcessionOrganizationDocumentSubcategoryFromConcessionOrganizationDocumentArgs = {
  input: RemoveConcessionOrganizationDocumentSubcategoryFromConcessionOrganizationDocumentInput;
};


export type MutationRemoveConcessionOrganizationDocumentsFromConcessionOrganizationArgs = {
  input: RemoveConcessionOrganizationDocumentsFromConcessionOrganizationInput;
};


export type MutationRemoveConcessionOrganizationFromConcessionOrganizationAccreditationArgs = {
  input: RemoveConcessionOrganizationFromConcessionOrganizationAccreditationInput;
};


export type MutationRemoveConcessionOrganizationFromConcessionOrganizationDocumentArgs = {
  input: RemoveConcessionOrganizationFromConcessionOrganizationDocumentInput;
};


export type MutationRemoveConcessionOrganizationFromConcessionOrganizationUserArgs = {
  input: RemoveConcessionOrganizationFromConcessionOrganizationUserInput;
};


export type MutationRemoveConcessionOrganizationFromContractConcessionOrganizationArgs = {
  input: RemoveConcessionOrganizationFromContractConcessionOrganizationInput;
};


export type MutationRemoveConcessionOrganizationFromDocumentNotificationArgs = {
  input: RemoveConcessionOrganizationFromDocumentNotificationInput;
};


export type MutationRemoveConcessionOrganizationUsersFromUserArgs = {
  input: RemoveConcessionOrganizationUsersFromUserInput;
};


export type MutationRemoveConcessionOrganizationsFromOrganizationArgs = {
  input: RemoveConcessionOrganizationsFromOrganizationInput;
};


export type MutationRemoveConcessionUsersFromConcessionArgs = {
  input: RemoveConcessionUsersFromConcessionInput;
};


export type MutationRemoveConcessionUsersFromUserArgs = {
  input: RemoveConcessionUsersFromUserInput;
};


export type MutationRemoveConcessionsFromConcessionOrganizationAccreditationTypeArgs = {
  input: RemoveConcessionsFromConcessionOrganizationAccreditationTypeInput;
};


export type MutationRemoveContractConcessionOrganizationStateFromContractConcessionOrganizationArgs = {
  input: RemoveContractConcessionOrganizationStateFromContractConcessionOrganizationInput;
};


export type MutationRemoveContractConcessionOrganizationsFromContractArgs = {
  input: RemoveContractConcessionOrganizationsFromContractInput;
};


export type MutationRemoveContractDocumentCategoryFromContractDocumentArgs = {
  input: RemoveContractDocumentCategoryFromContractDocumentInput;
};


export type MutationRemoveContractDocumentFromDocumentNotificationArgs = {
  input: RemoveContractDocumentFromDocumentNotificationInput;
};


export type MutationRemoveContractDocumentSubcategoryFromContractDocumentArgs = {
  input: RemoveContractDocumentSubcategoryFromContractDocumentInput;
};


export type MutationRemoveContractDocumentsFromContractArgs = {
  input: RemoveContractDocumentsFromContractInput;
};


export type MutationRemoveContractFromContractConcessionOrganizationArgs = {
  input: RemoveContractFromContractConcessionOrganizationInput;
};


export type MutationRemoveContractFromContractDocumentArgs = {
  input: RemoveContractFromContractDocumentInput;
};


export type MutationRemoveContractFromContractRoadArgs = {
  input: RemoveContractFromContractRoadInput;
};


export type MutationRemoveContractFromContractUserArgs = {
  input: RemoveContractFromContractUserInput;
};


export type MutationRemoveContractFromDocumentNotificationArgs = {
  input: RemoveContractFromDocumentNotificationInput;
};


export type MutationRemoveContractFromSurveyRecordArgs = {
  input: RemoveContractFromSurveyRecordInput;
};


export type MutationRemoveContractFromWorkPermitArgs = {
  input: RemoveContractFromWorkPermitInput;
};


export type MutationRemoveContractRoadsFromContractArgs = {
  input: RemoveContractRoadsFromContractInput;
};


export type MutationRemoveContractRoadsFromRoadArgs = {
  input: RemoveContractRoadsFromRoadInput;
};


export type MutationRemoveContractStateFromContractArgs = {
  input: RemoveContractStateFromContractInput;
};


export type MutationRemoveContractTransitionsFromContractArgs = {
  input: RemoveContractTransitionsFromContractInput;
};


export type MutationRemoveContractTypeFromContractArgs = {
  input: RemoveContractTypeFromContractInput;
};


export type MutationRemoveContractUsersFromContractArgs = {
  input: RemoveContractUsersFromContractInput;
};


export type MutationRemoveContractUsersFromUserArgs = {
  input: RemoveContractUsersFromUserInput;
};


export type MutationRemoveContractsFromConcessionArgs = {
  input: RemoveContractsFromConcessionInput;
};


export type MutationRemoveContractsFromContractStateArgs = {
  input: RemoveContractsFromContractStateInput;
};


export type MutationRemoveContractsFromContractTypeArgs = {
  input: RemoveContractsFromContractTypeInput;
};


export type MutationRemoveContractsFromInterventionElementArgs = {
  input: RemoveContractsFromInterventionElementInput;
};


export type MutationRemoveContractsFromInterventionLocationArgs = {
  input: RemoveContractsFromInterventionLocationInput;
};


export type MutationRemoveContractsFromInterventionTypeArgs = {
  input: RemoveContractsFromInterventionTypeInput;
};


export type MutationRemoveContractsFromServiceArgs = {
  input: RemoveContractsFromServiceInput;
};


export type MutationRemoveControlCentersFromConcessionArgs = {
  input: RemoveControlCentersFromConcessionInput;
};


export type MutationRemoveDefaultConcessionFromUserArgs = {
  input: RemoveDefaultConcessionFromUserInput;
};


export type MutationRemoveDirectionFromTaskArgs = {
  input: RemoveDirectionFromTaskInput;
};


export type MutationRemoveDocumentStateFromConcessionOrganizationAccreditationDocumentArgs = {
  input: RemoveDocumentStateFromConcessionOrganizationAccreditationDocumentInput;
};


export type MutationRemoveDocumentStateFromConcessionOrganizationDocumentArgs = {
  input: RemoveDocumentStateFromConcessionOrganizationDocumentInput;
};


export type MutationRemoveDocumentStateFromContractDocumentArgs = {
  input: RemoveDocumentStateFromContractDocumentInput;
};


export type MutationRemoveDocumentStateFromDocumentNotificationArgs = {
  input: RemoveDocumentStateFromDocumentNotificationInput;
};


export type MutationRemoveEndJunctionFromSurveyRecordArgs = {
  input: RemoveEndJunctionFromSurveyRecordInput;
};


export type MutationRemoveEndJunctionFromTaskArgs = {
  input: RemoveEndJunctionFromTaskInput;
};


export type MutationRemoveEndJuntionSurveyRecordsFromJunctionArgs = {
  input: RemoveEndJuntionSurveyRecordsFromJunctionInput;
};


export type MutationRemoveEndJuntionTasksFromJunctionArgs = {
  input: RemoveEndJuntionTasksFromJunctionInput;
};


export type MutationRemoveFileTypeFromConcessionOrganizationAccreditationDocumentArgs = {
  input: RemoveFileTypeFromConcessionOrganizationAccreditationDocumentInput;
};


export type MutationRemoveFileTypeFromConcessionOrganizationDocumentArgs = {
  input: RemoveFileTypeFromConcessionOrganizationDocumentInput;
};


export type MutationRemoveFileTypeFromContractDocumentArgs = {
  input: RemoveFileTypeFromContractDocumentInput;
};


export type MutationRemoveFromStateFromContractTransitionArgs = {
  input: RemoveFromStateFromContractTransitionInput;
};


export type MutationRemoveInterventionElementFromContractArgs = {
  input: RemoveInterventionElementFromContractInput;
};


export type MutationRemoveInterventionFromTaskArgs = {
  input: RemoveInterventionFromTaskInput;
};


export type MutationRemoveInterventionLocationFromContractArgs = {
  input: RemoveInterventionLocationFromContractInput;
};


export type MutationRemoveInterventionLocationFromTaskArgs = {
  input: RemoveInterventionLocationFromTaskInput;
};


export type MutationRemoveInterventionTypeFromContractArgs = {
  input: RemoveInterventionTypeFromContractInput;
};


export type MutationRemoveInterventionsFromWorkPermitArgs = {
  input: RemoveInterventionsFromWorkPermitInput;
};


export type MutationRemoveJunctionsFromRoadArgs = {
  input: RemoveJunctionsFromRoadInput;
};


export type MutationRemoveLayoutFromTaskArgs = {
  input: RemoveLayoutFromTaskInput;
};


export type MutationRemoveMediatingOrganizationFromConcessionOrganizationAccreditationArgs = {
  input: RemoveMediatingOrganizationFromConcessionOrganizationAccreditationInput;
};


export type MutationRemoveOrganizationFromConcessionOrganizationArgs = {
  input: RemoveOrganizationFromConcessionOrganizationInput;
};


export type MutationRemoveOrganizationTypeFromConcessionOrganizationArgs = {
  input: RemoveOrganizationTypeFromConcessionOrganizationInput;
};


export type MutationRemoveOrganizationTypesFromConcessionOrganizationAccreditationTypeArgs = {
  input: RemoveOrganizationTypesFromConcessionOrganizationAccreditationTypeInput;
};


export type MutationRemoveOwnerFromConcessionOrganizationDocumentArgs = {
  input: RemoveOwnerFromConcessionOrganizationDocumentInput;
};


export type MutationRemoveOwnerFromContractDocumentArgs = {
  input: RemoveOwnerFromContractDocumentInput;
};


export type MutationRemoveParentFromConcessionOrganizationDocumentCategoryArgs = {
  input: RemoveParentFromConcessionOrganizationDocumentCategoryInput;
};


export type MutationRemoveParentFromContractDocumentCategoryArgs = {
  input: RemoveParentFromContractDocumentCategoryInput;
};


export type MutationRemovePermissionsFromRoleArgs = {
  input: RemovePermissionsFromRoleInput;
};


export type MutationRemoveRestrictionFromTaskArgs = {
  input: RemoveRestrictionFromTaskInput;
};


export type MutationRemoveRoadFromContractRoadArgs = {
  input: RemoveRoadFromContractRoadInput;
};


export type MutationRemoveRoadFromJunctionArgs = {
  input: RemoveRoadFromJunctionInput;
};


export type MutationRemoveRoadFromSurveyRecordArgs = {
  input: RemoveRoadFromSurveyRecordInput;
};


export type MutationRemoveRoadFromTaskArgs = {
  input: RemoveRoadFromTaskInput;
};


export type MutationRemoveRoadsFromConcessionArgs = {
  input: RemoveRoadsFromConcessionInput;
};


export type MutationRemoveRoleFromPermissionArgs = {
  input: RemoveRoleFromPermissionInput;
};


export type MutationRemoveServiceFromContractArgs = {
  input: RemoveServiceFromContractInput;
};


export type MutationRemoveStartJunctionFromSurveyRecordArgs = {
  input: RemoveStartJunctionFromSurveyRecordInput;
};


export type MutationRemoveStartJunctionFromTaskArgs = {
  input: RemoveStartJunctionFromTaskInput;
};


export type MutationRemoveStartJuntionSurveyRecordsFromJunctionArgs = {
  input: RemoveStartJuntionSurveyRecordsFromJunctionInput;
};


export type MutationRemoveStartJuntionTasksFromJunctionArgs = {
  input: RemoveStartJuntionTasksFromJunctionInput;
};


export type MutationRemoveSurveyRecordFromSurveyRecordPhotoArgs = {
  input: RemoveSurveyRecordFromSurveyRecordPhotoInput;
};


export type MutationRemoveSurveyRecordFromSurveyRecordVerificationArgs = {
  input: RemoveSurveyRecordFromSurveyRecordVerificationInput;
};


export type MutationRemoveSurveyRecordPhotosFromSurveyRecordArgs = {
  input: RemoveSurveyRecordPhotosFromSurveyRecordInput;
};


export type MutationRemoveSurveyRecordVerificationClassificationFromSurveyRecordVerificationArgs = {
  input: RemoveSurveyRecordVerificationClassificationFromSurveyRecordVerificationInput;
};


export type MutationRemoveSurveyRecordVerificationStateFromSurveyRecordVerificationArgs = {
  input: RemoveSurveyRecordVerificationStateFromSurveyRecordVerificationInput;
};


export type MutationRemoveSurveyRecordVerificationsFromSurveyRecordArgs = {
  input: RemoveSurveyRecordVerificationsFromSurveyRecordInput;
};


export type MutationRemoveSurveyRecordVerificationsFromVerificationArgs = {
  input: RemoveSurveyRecordVerificationsFromVerificationInput;
};


export type MutationRemoveSurveyRecordsFromContractArgs = {
  input: RemoveSurveyRecordsFromContractInput;
};


export type MutationRemoveSurveyRecordsFromRoadArgs = {
  input: RemoveSurveyRecordsFromRoadInput;
};


export type MutationRemoveSurveyRecordsFromUserArgs = {
  input: RemoveSurveyRecordsFromUserInput;
};


export type MutationRemoveSurveyRecordsFromWeatherConditionArgs = {
  input: RemoveSurveyRecordsFromWeatherConditionInput;
};


export type MutationRemoveTasksFromInterventionArgs = {
  input: RemoveTasksFromInterventionInput;
};


export type MutationRemoveTasksFromRestrictionArgs = {
  input: RemoveTasksFromRestrictionInput;
};


export type MutationRemoveTasksFromRoadArgs = {
  input: RemoveTasksFromRoadInput;
};


export type MutationRemoveToStateFromContractTransitionArgs = {
  input: RemoveToStateFromContractTransitionInput;
};


export type MutationRemoveUserFromConcessionOrganizationUserArgs = {
  input: RemoveUserFromConcessionOrganizationUserInput;
};


export type MutationRemoveUserFromConcessionUserArgs = {
  input: RemoveUserFromConcessionUserInput;
};


export type MutationRemoveUserFromContractUserArgs = {
  input: RemoveUserFromContractUserInput;
};


export type MutationRemoveUserFromSurveyRecordArgs = {
  input: RemoveUserFromSurveyRecordInput;
};


export type MutationRemoveUserFromWorkspaceArgs = {
  input: RemoveUserFromWorkspaceInput;
};


export type MutationRemoveVerificationFromSurveyRecordVerificationArgs = {
  input: RemoveVerificationFromSurveyRecordVerificationInput;
};


export type MutationRemoveVerificationScopeFromVerificationArgs = {
  input: RemoveVerificationScopeFromVerificationInput;
};


export type MutationRemoveVerificationsFromVerificationScopeArgs = {
  input: RemoveVerificationsFromVerificationScopeInput;
};


export type MutationRemoveWeatherConditionFromSurveyRecordArgs = {
  input: RemoveWeatherConditionFromSurveyRecordInput;
};


export type MutationRemoveWorkPermitFromBadgeArgs = {
  input: RemoveWorkPermitFromBadgeInput;
};


export type MutationRemoveWorkPermitFromInterventionArgs = {
  input: RemoveWorkPermitFromInterventionInput;
};


export type MutationRemoveWorkPermitsFromContractArgs = {
  input: RemoveWorkPermitsFromContractInput;
};


export type MutationRemoveWorkspaceFromWorkspaceViewArgs = {
  input: RemoveWorkspaceFromWorkspaceViewInput;
};


export type MutationRemoveWorkspaceViewColumnsFromWorkspaceViewArgs = {
  input: RemoveWorkspaceViewColumnsFromWorkspaceViewInput;
};


export type MutationRemoveWorkspaceViewsFromWorkspaceArgs = {
  input: RemoveWorkspaceViewsFromWorkspaceInput;
};


export type MutationSendContractClosedNotificationArgs = {
  id: Scalars['String'];
};


export type MutationSendContractOpenedNotificationArgs = {
  id: Scalars['String'];
};


export type MutationSendPublishedSurveyRecordEmailArgs = {
  id: Scalars['String'];
};


export type MutationSendPublishedWorkPermitEmailArgs = {
  id: Scalars['String'];
};


export type MutationSendResetPasswordEmailArgs = {
  email: Scalars['String'];
};


export type MutationSetBadgesOnWorkPermitArgs = {
  input: SetBadgesOnWorkPermitInput;
};


export type MutationSetChecklistItemsOnChecklistArgs = {
  input: SetChecklistItemsOnChecklistInput;
};


export type MutationSetChecklistOnChecklistItemArgs = {
  input: SetChecklistOnChecklistItemInput;
};


export type MutationSetChecklistOnWorkPermitArgs = {
  input: SetChecklistOnWorkPermitInput;
};


export type MutationSetChildrenOnConcessionOrganizationDocumentCategoryArgs = {
  input: SetChildrenOnConcessionOrganizationDocumentCategoryInput;
};


export type MutationSetChildrenOnContractDocumentCategoryArgs = {
  input: SetChildrenOnContractDocumentCategoryInput;
};


export type MutationSetConcessionOnConcessionOrganizationArgs = {
  input: SetConcessionOnConcessionOrganizationInput;
};


export type MutationSetConcessionOnConcessionOrganizationAccreditationStateArgs = {
  input: SetConcessionOnConcessionOrganizationAccreditationStateInput;
};


export type MutationSetConcessionOnConcessionOrganizationDocumentCategoryArgs = {
  input: SetConcessionOnConcessionOrganizationDocumentCategoryInput;
};


export type MutationSetConcessionOnConcessionUserArgs = {
  input: SetConcessionOnConcessionUserInput;
};


export type MutationSetConcessionOnContractArgs = {
  input: SetConcessionOnContractInput;
};


export type MutationSetConcessionOnContractConcessionOrganizationStateArgs = {
  input: SetConcessionOnContractConcessionOrganizationStateInput;
};


export type MutationSetConcessionOnContractDocumentCategoryArgs = {
  input: SetConcessionOnContractDocumentCategoryInput;
};


export type MutationSetConcessionOnContractStateArgs = {
  input: SetConcessionOnContractStateInput;
};


export type MutationSetConcessionOnContractTypeArgs = {
  input: SetConcessionOnContractTypeInput;
};


export type MutationSetConcessionOnControlCenterArgs = {
  input: SetConcessionOnControlCenterInput;
};


export type MutationSetConcessionOnDirectionArgs = {
  input: SetConcessionOnDirectionInput;
};


export type MutationSetConcessionOnInterventionElementArgs = {
  input: SetConcessionOnInterventionElementInput;
};


export type MutationSetConcessionOnInterventionLocationArgs = {
  input: SetConcessionOnInterventionLocationInput;
};


export type MutationSetConcessionOnInterventionTypeArgs = {
  input: SetConcessionOnInterventionTypeInput;
};


export type MutationSetConcessionOnJunctionArgs = {
  input: SetConcessionOnJunctionInput;
};


export type MutationSetConcessionOnLayoutArgs = {
  input: SetConcessionOnLayoutInput;
};


export type MutationSetConcessionOnRestrictionArgs = {
  input: SetConcessionOnRestrictionInput;
};


export type MutationSetConcessionOnRoadArgs = {
  input: SetConcessionOnRoadInput;
};


export type MutationSetConcessionOnServiceArgs = {
  input: SetConcessionOnServiceInput;
};


export type MutationSetConcessionOnSurveyRecordVerificationClassificationArgs = {
  input: SetConcessionOnSurveyRecordVerificationClassificationInput;
};


export type MutationSetConcessionOnSurveyRecordVerificationStateArgs = {
  input: SetConcessionOnSurveyRecordVerificationStateInput;
};


export type MutationSetConcessionOnVerificationArgs = {
  input: SetConcessionOnVerificationInput;
};


export type MutationSetConcessionOnVerificationScopeArgs = {
  input: SetConcessionOnVerificationScopeInput;
};


export type MutationSetConcessionOnWeatherConditionArgs = {
  input: SetConcessionOnWeatherConditionInput;
};


export type MutationSetConcessionOrganizationAccreditationDocumentsOnConcessionOrganizationAccreditationArgs = {
  input: SetConcessionOrganizationAccreditationDocumentsOnConcessionOrganizationAccreditationInput;
};


export type MutationSetConcessionOrganizationAccreditationOnConcessionOrganizationAccreditationDocumentArgs = {
  input: SetConcessionOrganizationAccreditationOnConcessionOrganizationAccreditationDocumentInput;
};


export type MutationSetConcessionOrganizationAccreditationOnContractConcessionOrganizationArgs = {
  input: SetConcessionOrganizationAccreditationOnContractConcessionOrganizationInput;
};


export type MutationSetConcessionOrganizationAccreditationStateOnConcessionOrganizationAccreditationArgs = {
  input: SetConcessionOrganizationAccreditationStateOnConcessionOrganizationAccreditationInput;
};


export type MutationSetConcessionOrganizationAccreditationTypeOnConcessionOrganizationAccreditationArgs = {
  input: SetConcessionOrganizationAccreditationTypeOnConcessionOrganizationAccreditationInput;
};


export type MutationSetConcessionOrganizationDocumentCategoryOnConcessionOrganizationAccreditationDocumentArgs = {
  input: SetConcessionOrganizationDocumentCategoryOnConcessionOrganizationAccreditationDocumentInput;
};


export type MutationSetConcessionOrganizationDocumentCategoryOnConcessionOrganizationDocumentArgs = {
  input: SetConcessionOrganizationDocumentCategoryOnConcessionOrganizationDocumentInput;
};


export type MutationSetConcessionOrganizationDocumentOnDocumentNotificationArgs = {
  input: SetConcessionOrganizationDocumentOnDocumentNotificationInput;
};


export type MutationSetConcessionOrganizationDocumentSubcategoryOnConcessionOrganizationAccreditationDocumentArgs = {
  input: SetConcessionOrganizationDocumentSubcategoryOnConcessionOrganizationAccreditationDocumentInput;
};


export type MutationSetConcessionOrganizationDocumentSubcategoryOnConcessionOrganizationDocumentArgs = {
  input: SetConcessionOrganizationDocumentSubcategoryOnConcessionOrganizationDocumentInput;
};


export type MutationSetConcessionOrganizationDocumentsOnConcessionOrganizationArgs = {
  input: SetConcessionOrganizationDocumentsOnConcessionOrganizationInput;
};


export type MutationSetConcessionOrganizationOnConcessionOrganizationAccreditationArgs = {
  input: SetConcessionOrganizationOnConcessionOrganizationAccreditationInput;
};


export type MutationSetConcessionOrganizationOnConcessionOrganizationDocumentArgs = {
  input: SetConcessionOrganizationOnConcessionOrganizationDocumentInput;
};


export type MutationSetConcessionOrganizationOnConcessionOrganizationUserArgs = {
  input: SetConcessionOrganizationOnConcessionOrganizationUserInput;
};


export type MutationSetConcessionOrganizationOnContractConcessionOrganizationArgs = {
  input: SetConcessionOrganizationOnContractConcessionOrganizationInput;
};


export type MutationSetConcessionOrganizationOnDocumentNotificationArgs = {
  input: SetConcessionOrganizationOnDocumentNotificationInput;
};


export type MutationSetConcessionOrganizationUsersOnUserArgs = {
  input: SetConcessionOrganizationUsersOnUserInput;
};


export type MutationSetConcessionOrganizationsOnOrganizationArgs = {
  input: SetConcessionOrganizationsOnOrganizationInput;
};


export type MutationSetConcessionUsersOnConcessionArgs = {
  input: SetConcessionUsersOnConcessionInput;
};


export type MutationSetConcessionUsersOnUserArgs = {
  input: SetConcessionUsersOnUserInput;
};


export type MutationSetConcessionsOnConcessionOrganizationAccreditationTypeArgs = {
  input: SetConcessionsOnConcessionOrganizationAccreditationTypeInput;
};


export type MutationSetContractConcessionOrganizationStateOnContractConcessionOrganizationArgs = {
  input: SetContractConcessionOrganizationStateOnContractConcessionOrganizationInput;
};


export type MutationSetContractConcessionOrganizationsOnContractArgs = {
  input: SetContractConcessionOrganizationsOnContractInput;
};


export type MutationSetContractDocumentCategoryOnContractDocumentArgs = {
  input: SetContractDocumentCategoryOnContractDocumentInput;
};


export type MutationSetContractDocumentOnDocumentNotificationArgs = {
  input: SetContractDocumentOnDocumentNotificationInput;
};


export type MutationSetContractDocumentSubcategoryOnContractDocumentArgs = {
  input: SetContractDocumentSubcategoryOnContractDocumentInput;
};


export type MutationSetContractDocumentsOnContractArgs = {
  input: SetContractDocumentsOnContractInput;
};


export type MutationSetContractOnContractConcessionOrganizationArgs = {
  input: SetContractOnContractConcessionOrganizationInput;
};


export type MutationSetContractOnContractDocumentArgs = {
  input: SetContractOnContractDocumentInput;
};


export type MutationSetContractOnContractRoadArgs = {
  input: SetContractOnContractRoadInput;
};


export type MutationSetContractOnContractUserArgs = {
  input: SetContractOnContractUserInput;
};


export type MutationSetContractOnDocumentNotificationArgs = {
  input: SetContractOnDocumentNotificationInput;
};


export type MutationSetContractOnSurveyRecordArgs = {
  input: SetContractOnSurveyRecordInput;
};


export type MutationSetContractOnWorkPermitArgs = {
  input: SetContractOnWorkPermitInput;
};


export type MutationSetContractRoadsForContractArgs = {
  input: SetContractRoadsForContractInput;
};


export type MutationSetContractRoadsOnContractArgs = {
  input: SetContractRoadsOnContractInput;
};


export type MutationSetContractRoadsOnRoadArgs = {
  input: SetContractRoadsOnRoadInput;
};


export type MutationSetContractStateOnContractArgs = {
  input: SetContractStateOnContractInput;
};


export type MutationSetContractTransitionsOnContractArgs = {
  input: SetContractTransitionsOnContractInput;
};


export type MutationSetContractTypeOnContractArgs = {
  input: SetContractTypeOnContractInput;
};


export type MutationSetContractUsersForContractArgs = {
  input: SetContractUsersForContractInput;
};


export type MutationSetContractUsersOnContractArgs = {
  input: SetContractUsersOnContractInput;
};


export type MutationSetContractUsersOnUserArgs = {
  input: SetContractUsersOnUserInput;
};


export type MutationSetContractsOnConcessionArgs = {
  input: SetContractsOnConcessionInput;
};


export type MutationSetContractsOnContractStateArgs = {
  input: SetContractsOnContractStateInput;
};


export type MutationSetContractsOnContractTypeArgs = {
  input: SetContractsOnContractTypeInput;
};


export type MutationSetContractsOnInterventionElementArgs = {
  input: SetContractsOnInterventionElementInput;
};


export type MutationSetContractsOnInterventionLocationArgs = {
  input: SetContractsOnInterventionLocationInput;
};


export type MutationSetContractsOnInterventionTypeArgs = {
  input: SetContractsOnInterventionTypeInput;
};


export type MutationSetContractsOnServiceArgs = {
  input: SetContractsOnServiceInput;
};


export type MutationSetControlCentersOnConcessionArgs = {
  input: SetControlCentersOnConcessionInput;
};


export type MutationSetDefaultConcessionOnUserArgs = {
  input: SetDefaultConcessionOnUserInput;
};


export type MutationSetDirectionOnTaskArgs = {
  input: SetDirectionOnTaskInput;
};


export type MutationSetDocumentStateOnConcessionOrganizationAccreditationDocumentArgs = {
  input: SetDocumentStateOnConcessionOrganizationAccreditationDocumentInput;
};


export type MutationSetDocumentStateOnConcessionOrganizationDocumentArgs = {
  input: SetDocumentStateOnConcessionOrganizationDocumentInput;
};


export type MutationSetDocumentStateOnContractDocumentArgs = {
  input: SetDocumentStateOnContractDocumentInput;
};


export type MutationSetDocumentStateOnDocumentNotificationArgs = {
  input: SetDocumentStateOnDocumentNotificationInput;
};


export type MutationSetEndJunctionOnSurveyRecordArgs = {
  input: SetEndJunctionOnSurveyRecordInput;
};


export type MutationSetEndJunctionOnTaskArgs = {
  input: SetEndJunctionOnTaskInput;
};


export type MutationSetEndJuntionSurveyRecordsOnJunctionArgs = {
  input: SetEndJuntionSurveyRecordsOnJunctionInput;
};


export type MutationSetEndJuntionTasksOnJunctionArgs = {
  input: SetEndJuntionTasksOnJunctionInput;
};


export type MutationSetFileTypeOnConcessionOrganizationAccreditationDocumentArgs = {
  input: SetFileTypeOnConcessionOrganizationAccreditationDocumentInput;
};


export type MutationSetFileTypeOnConcessionOrganizationDocumentArgs = {
  input: SetFileTypeOnConcessionOrganizationDocumentInput;
};


export type MutationSetFileTypeOnContractDocumentArgs = {
  input: SetFileTypeOnContractDocumentInput;
};


export type MutationSetFromStateOnContractTransitionArgs = {
  input: SetFromStateOnContractTransitionInput;
};


export type MutationSetInterventionElementOnContractArgs = {
  input: SetInterventionElementOnContractInput;
};


export type MutationSetInterventionLocationOnContractArgs = {
  input: SetInterventionLocationOnContractInput;
};


export type MutationSetInterventionLocationOnTaskArgs = {
  input: SetInterventionLocationOnTaskInput;
};


export type MutationSetInterventionOnTaskArgs = {
  input: SetInterventionOnTaskInput;
};


export type MutationSetInterventionTypeOnContractArgs = {
  input: SetInterventionTypeOnContractInput;
};


export type MutationSetInterventionsOnWorkPermitArgs = {
  input: SetInterventionsOnWorkPermitInput;
};


export type MutationSetJunctionsOnRoadArgs = {
  input: SetJunctionsOnRoadInput;
};


export type MutationSetLayoutOnTaskArgs = {
  input: SetLayoutOnTaskInput;
};


export type MutationSetMediatingOrganizationOnConcessionOrganizationAccreditationArgs = {
  input: SetMediatingOrganizationOnConcessionOrganizationAccreditationInput;
};


export type MutationSetOrganizationOnConcessionOrganizationArgs = {
  input: SetOrganizationOnConcessionOrganizationInput;
};


export type MutationSetOrganizationTypeOnConcessionOrganizationArgs = {
  input: SetOrganizationTypeOnConcessionOrganizationInput;
};


export type MutationSetOrganizationTypesOnConcessionOrganizationAccreditationTypeArgs = {
  input: SetOrganizationTypesOnConcessionOrganizationAccreditationTypeInput;
};


export type MutationSetOwnerOnConcessionOrganizationDocumentArgs = {
  input: SetOwnerOnConcessionOrganizationDocumentInput;
};


export type MutationSetOwnerOnContractDocumentArgs = {
  input: SetOwnerOnContractDocumentInput;
};


export type MutationSetParentOnConcessionOrganizationDocumentCategoryArgs = {
  input: SetParentOnConcessionOrganizationDocumentCategoryInput;
};


export type MutationSetParentOnContractDocumentCategoryArgs = {
  input: SetParentOnContractDocumentCategoryInput;
};


export type MutationSetPasswordArgs = {
  input: SetPasswordInput;
};


export type MutationSetPermissionsOnRoleArgs = {
  input: SetPermissionsOnRoleInput;
};


export type MutationSetRestrictionOnTaskArgs = {
  input: SetRestrictionOnTaskInput;
};


export type MutationSetRoadOnContractRoadArgs = {
  input: SetRoadOnContractRoadInput;
};


export type MutationSetRoadOnJunctionArgs = {
  input: SetRoadOnJunctionInput;
};


export type MutationSetRoadOnSurveyRecordArgs = {
  input: SetRoadOnSurveyRecordInput;
};


export type MutationSetRoadOnTaskArgs = {
  input: SetRoadOnTaskInput;
};


export type MutationSetRoadsOnConcessionArgs = {
  input: SetRoadsOnConcessionInput;
};


export type MutationSetRoleOnPermissionArgs = {
  input: SetRoleOnPermissionInput;
};


export type MutationSetServiceOnContractArgs = {
  input: SetServiceOnContractInput;
};


export type MutationSetStartJunctionOnSurveyRecordArgs = {
  input: SetStartJunctionOnSurveyRecordInput;
};


export type MutationSetStartJunctionOnTaskArgs = {
  input: SetStartJunctionOnTaskInput;
};


export type MutationSetStartJuntionSurveyRecordsOnJunctionArgs = {
  input: SetStartJuntionSurveyRecordsOnJunctionInput;
};


export type MutationSetStartJuntionTasksOnJunctionArgs = {
  input: SetStartJuntionTasksOnJunctionInput;
};


export type MutationSetSurveyRecordOnSurveyRecordPhotoArgs = {
  input: SetSurveyRecordOnSurveyRecordPhotoInput;
};


export type MutationSetSurveyRecordOnSurveyRecordVerificationArgs = {
  input: SetSurveyRecordOnSurveyRecordVerificationInput;
};


export type MutationSetSurveyRecordPhotosOnSurveyRecordArgs = {
  input: SetSurveyRecordPhotosOnSurveyRecordInput;
};


export type MutationSetSurveyRecordVerificationClassificationOnSurveyRecordVerificationArgs = {
  input: SetSurveyRecordVerificationClassificationOnSurveyRecordVerificationInput;
};


export type MutationSetSurveyRecordVerificationStateOnSurveyRecordVerificationArgs = {
  input: SetSurveyRecordVerificationStateOnSurveyRecordVerificationInput;
};


export type MutationSetSurveyRecordVerificationsOnSurveyRecordArgs = {
  input: SetSurveyRecordVerificationsOnSurveyRecordInput;
};


export type MutationSetSurveyRecordVerificationsOnVerificationArgs = {
  input: SetSurveyRecordVerificationsOnVerificationInput;
};


export type MutationSetSurveyRecordsOnContractArgs = {
  input: SetSurveyRecordsOnContractInput;
};


export type MutationSetSurveyRecordsOnRoadArgs = {
  input: SetSurveyRecordsOnRoadInput;
};


export type MutationSetSurveyRecordsOnUserArgs = {
  input: SetSurveyRecordsOnUserInput;
};


export type MutationSetSurveyRecordsOnWeatherConditionArgs = {
  input: SetSurveyRecordsOnWeatherConditionInput;
};


export type MutationSetTasksOnInterventionArgs = {
  input: SetTasksOnInterventionInput;
};


export type MutationSetTasksOnRestrictionArgs = {
  input: SetTasksOnRestrictionInput;
};


export type MutationSetTasksOnRoadArgs = {
  input: SetTasksOnRoadInput;
};


export type MutationSetToStateOnContractTransitionArgs = {
  input: SetToStateOnContractTransitionInput;
};


export type MutationSetUserOnConcessionOrganizationUserArgs = {
  input: SetUserOnConcessionOrganizationUserInput;
};


export type MutationSetUserOnConcessionUserArgs = {
  input: SetUserOnConcessionUserInput;
};


export type MutationSetUserOnContractUserArgs = {
  input: SetUserOnContractUserInput;
};


export type MutationSetUserOnSurveyRecordArgs = {
  input: SetUserOnSurveyRecordInput;
};


export type MutationSetUserOnWorkspaceArgs = {
  input: SetUserOnWorkspaceInput;
};


export type MutationSetVerificationOnSurveyRecordVerificationArgs = {
  input: SetVerificationOnSurveyRecordVerificationInput;
};


export type MutationSetVerificationScopeOnVerificationArgs = {
  input: SetVerificationScopeOnVerificationInput;
};


export type MutationSetVerificationsOnVerificationScopeArgs = {
  input: SetVerificationsOnVerificationScopeInput;
};


export type MutationSetWeatherConditionOnSurveyRecordArgs = {
  input: SetWeatherConditionOnSurveyRecordInput;
};


export type MutationSetWorkPermitOnBadgeArgs = {
  input: SetWorkPermitOnBadgeInput;
};


export type MutationSetWorkPermitOnInterventionArgs = {
  input: SetWorkPermitOnInterventionInput;
};


export type MutationSetWorkPermitsOnContractArgs = {
  input: SetWorkPermitsOnContractInput;
};


export type MutationSetWorkspaceOnWorkspaceViewArgs = {
  input: SetWorkspaceOnWorkspaceViewInput;
};


export type MutationSetWorkspaceViewArgs = {
  input: SetWorkspaceView;
};


export type MutationSetWorkspaceViewColumnsOnWorkspaceViewArgs = {
  input: SetWorkspaceViewColumnsOnWorkspaceViewInput;
};


export type MutationSetWorkspaceViewsOnWorkspaceArgs = {
  input: SetWorkspaceViewsOnWorkspaceInput;
};


export type MutationUpdateManyBadgesArgs = {
  input: UpdateManyBadgesInput;
};


export type MutationUpdateManyConcessionOrganizationAccreditationStatesArgs = {
  input: UpdateManyConcessionOrganizationAccreditationStatesInput;
};


export type MutationUpdateManyConcessionOrganizationAccreditationTypesArgs = {
  input: UpdateManyConcessionOrganizationAccreditationTypesInput;
};


export type MutationUpdateManyConcessionOrganizationAccreditationsArgs = {
  input: UpdateManyConcessionOrganizationAccreditationsInput;
};


export type MutationUpdateManyConcessionOrganizationDocumentCategoriesArgs = {
  input: UpdateManyConcessionOrganizationDocumentCategoriesInput;
};


export type MutationUpdateManyConcessionOrganizationDocumentsArgs = {
  input: UpdateManyConcessionOrganizationDocumentsInput;
};


export type MutationUpdateManyConcessionOrganizationUsersArgs = {
  input: UpdateManyConcessionOrganizationUsersInput;
};


export type MutationUpdateManyConcessionOrganizationsArgs = {
  input: UpdateManyConcessionOrganizationsInput;
};


export type MutationUpdateManyConcessionUsersArgs = {
  input: UpdateManyConcessionUsersInput;
};


export type MutationUpdateManyConcessionsArgs = {
  input: UpdateManyConcessionsInput;
};


export type MutationUpdateManyContractConcessionOrganizationStatesArgs = {
  input: UpdateManyContractConcessionOrganizationStatesInput;
};


export type MutationUpdateManyContractConcessionOrganizationsArgs = {
  input: UpdateManyContractConcessionOrganizationsInput;
};


export type MutationUpdateManyContractDocumentCategoriesArgs = {
  input: UpdateManyContractDocumentCategoriesInput;
};


export type MutationUpdateManyContractDocumentsArgs = {
  input: UpdateManyContractDocumentsInput;
};


export type MutationUpdateManyContractRoadsArgs = {
  input: UpdateManyContractRoadsInput;
};


export type MutationUpdateManyContractStatesArgs = {
  input: UpdateManyContractStatesInput;
};


export type MutationUpdateManyContractTransitionsArgs = {
  input: UpdateManyContractTransitionsInput;
};


export type MutationUpdateManyContractTypesArgs = {
  input: UpdateManyContractTypesInput;
};


export type MutationUpdateManyContractUsersArgs = {
  input: UpdateManyContractUsersInput;
};


export type MutationUpdateManyContractsArgs = {
  input: UpdateManyContractsInput;
};


export type MutationUpdateManyControlCentersArgs = {
  input: UpdateManyControlCentersInput;
};


export type MutationUpdateManyDirectionsArgs = {
  input: UpdateManyDirectionsInput;
};


export type MutationUpdateManyInterventionElementsArgs = {
  input: UpdateManyInterventionElementsInput;
};


export type MutationUpdateManyInterventionLocationsArgs = {
  input: UpdateManyInterventionLocationsInput;
};


export type MutationUpdateManyInterventionTypesArgs = {
  input: UpdateManyInterventionTypesInput;
};


export type MutationUpdateManyInterventionsArgs = {
  input: UpdateManyInterventionsInput;
};


export type MutationUpdateManyJunctionsArgs = {
  input: UpdateManyJunctionsInput;
};


export type MutationUpdateManyLayoutsArgs = {
  input: UpdateManyLayoutsInput;
};


export type MutationUpdateManyOrganizationTypesArgs = {
  input: UpdateManyOrganizationTypesInput;
};


export type MutationUpdateManyOrganizationsArgs = {
  input: UpdateManyOrganizationsInput;
};


export type MutationUpdateManyRestrictionsArgs = {
  input: UpdateManyRestrictionsInput;
};


export type MutationUpdateManyRoadsArgs = {
  input: UpdateManyRoadsInput;
};


export type MutationUpdateManyServicesArgs = {
  input: UpdateManyServicesInput;
};


export type MutationUpdateManySurveyRecordPhotosArgs = {
  input: UpdateManySurveyRecordPhotosInput;
};


export type MutationUpdateManySurveyRecordVerificationClassificationsArgs = {
  input: UpdateManySurveyRecordVerificationClassificationsInput;
};


export type MutationUpdateManySurveyRecordVerificationStatesArgs = {
  input: UpdateManySurveyRecordVerificationStatesInput;
};


export type MutationUpdateManySurveyRecordVerificationsArgs = {
  input: UpdateManySurveyRecordVerificationsInput;
};


export type MutationUpdateManySurveyRecordsArgs = {
  input: UpdateManySurveyRecordsInput;
};


export type MutationUpdateManyTasksArgs = {
  input: UpdateManyTasksInput;
};


export type MutationUpdateManyUsersArgs = {
  input: UpdateManyUsersInput;
};


export type MutationUpdateManyVerificationScopesArgs = {
  input: UpdateManyVerificationScopesInput;
};


export type MutationUpdateManyVerificationsArgs = {
  input: UpdateManyVerificationsInput;
};


export type MutationUpdateManyWeatherConditionsArgs = {
  input: UpdateManyWeatherConditionsInput;
};


export type MutationUpdateManyWorkPermitsArgs = {
  input: UpdateManyWorkPermitsInput;
};


export type MutationUpdateMeArgs = {
  input: UpdateMe;
};


export type MutationUpdateOneBadgeArgs = {
  input: UpdateOneBadgeInput;
};


export type MutationUpdateOneChecklistItemArgs = {
  input: UpdateOneChecklistItemInput;
};


export type MutationUpdateOneConcessionArgs = {
  input: UpdateOneConcessionInput;
};


export type MutationUpdateOneConcessionOrganizationArgs = {
  input: UpdateOneConcessionOrganizationInput;
};


export type MutationUpdateOneConcessionOrganizationAccreditationArgs = {
  input: UpdateOneConcessionOrganizationAccreditationInput;
};


export type MutationUpdateOneConcessionOrganizationAccreditationStateArgs = {
  input: UpdateOneConcessionOrganizationAccreditationStateInput;
};


export type MutationUpdateOneConcessionOrganizationAccreditationTypeArgs = {
  input: UpdateOneConcessionOrganizationAccreditationTypeInput;
};


export type MutationUpdateOneConcessionOrganizationDocumentArgs = {
  input: UpdateOneConcessionOrganizationDocumentInput;
};


export type MutationUpdateOneConcessionOrganizationDocumentCategoryArgs = {
  input: UpdateOneConcessionOrganizationDocumentCategoryInput;
};


export type MutationUpdateOneConcessionOrganizationUserArgs = {
  input: UpdateOneConcessionOrganizationUserInput;
};


export type MutationUpdateOneConcessionUserArgs = {
  input: UpdateOneConcessionUserInput;
};


export type MutationUpdateOneContractArgs = {
  input: UpdateOneContractInput;
};


export type MutationUpdateOneContractConcessionOrganizationArgs = {
  input: UpdateOneContractConcessionOrganizationInput;
};


export type MutationUpdateOneContractConcessionOrganizationStateArgs = {
  input: UpdateOneContractConcessionOrganizationStateInput;
};


export type MutationUpdateOneContractDocumentArgs = {
  input: UpdateOneContractDocumentInput;
};


export type MutationUpdateOneContractDocumentCategoryArgs = {
  input: UpdateOneContractDocumentCategoryInput;
};


export type MutationUpdateOneContractRoadArgs = {
  input: UpdateOneContractRoadInput;
};


export type MutationUpdateOneContractStateArgs = {
  input: UpdateOneContractStateInput;
};


export type MutationUpdateOneContractTransitionArgs = {
  input: UpdateOneContractTransitionInput;
};


export type MutationUpdateOneContractTypeArgs = {
  input: UpdateOneContractTypeInput;
};


export type MutationUpdateOneContractUserArgs = {
  input: UpdateOneContractUserInput;
};


export type MutationUpdateOneControlCenterArgs = {
  input: UpdateOneControlCenterInput;
};


export type MutationUpdateOneDirectionArgs = {
  input: UpdateOneDirectionInput;
};


export type MutationUpdateOneInterventionArgs = {
  input: UpdateOneInterventionInput;
};


export type MutationUpdateOneInterventionElementArgs = {
  input: UpdateOneInterventionElementInput;
};


export type MutationUpdateOneInterventionLocationArgs = {
  input: UpdateOneInterventionLocationInput;
};


export type MutationUpdateOneInterventionTypeArgs = {
  input: UpdateOneInterventionTypeInput;
};


export type MutationUpdateOneJunctionArgs = {
  input: UpdateOneJunctionInput;
};


export type MutationUpdateOneLayoutArgs = {
  input: UpdateOneLayoutInput;
};


export type MutationUpdateOneOrganizationArgs = {
  input: UpdateOneOrganizationInput;
};


export type MutationUpdateOneOrganizationTypeArgs = {
  input: UpdateOneOrganizationTypeInput;
};


export type MutationUpdateOneRestrictionArgs = {
  input: UpdateOneRestrictionInput;
};


export type MutationUpdateOneRoadArgs = {
  input: UpdateOneRoadInput;
};


export type MutationUpdateOneServiceArgs = {
  input: UpdateOneServiceInput;
};


export type MutationUpdateOneSubcontractArgs = {
  input: UpdateOneSubcontractInput;
};


export type MutationUpdateOneSurveyRecordArgs = {
  input: UpdateOneSurveyRecordInput;
};


export type MutationUpdateOneSurveyRecordPhotoArgs = {
  input: UpdateOneSurveyRecordPhotoInput;
};


export type MutationUpdateOneSurveyRecordVerificationArgs = {
  input: UpdateOneSurveyRecordVerificationInput;
};


export type MutationUpdateOneSurveyRecordVerificationClassificationArgs = {
  input: UpdateOneSurveyRecordVerificationClassificationInput;
};


export type MutationUpdateOneSurveyRecordVerificationStateArgs = {
  input: UpdateOneSurveyRecordVerificationStateInput;
};


export type MutationUpdateOneTaskArgs = {
  input: UpdateOneTaskInput;
};


export type MutationUpdateOneUserArgs = {
  input: UpdateOneUserInput;
};


export type MutationUpdateOneVerificationArgs = {
  input: UpdateOneVerificationInput;
};


export type MutationUpdateOneVerificationScopeArgs = {
  input: UpdateOneVerificationScopeInput;
};


export type MutationUpdateOneWeatherConditionArgs = {
  input: UpdateOneWeatherConditionInput;
};


export type MutationUpdateOneWorkPermitArgs = {
  input: UpdateOneWorkPermitInput;
};


export type MutationUpdateOwnConcessionOrganizationDocumentArgs = {
  input: UpdateOwnConcesssionOrganizationDocument;
};


export type MutationUpdateOwnSubcontractOrganizationDocumentArgs = {
  input: UpdateOwnConcesssionOrganizationDocument;
};


export type MutationValidateTaskImportArgs = {
  input: ValidateTaskImportInput;
};

export type NumberFieldComparison = {
  between?: InputMaybe<NumberFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  notBetween?: InputMaybe<NumberFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float'];
  upper: Scalars['Float'];
};

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
};

export type OffsetPaging = {
  /** Limit the number of records returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset to start returning records from */
  offset?: InputMaybe<Scalars['Int']>;
};

export type Organization = {
  __typename?: 'Organization';
  address: Scalars['String'];
  concessionOrganizations?: Maybe<Array<ConcessionOrganization>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  tin: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  zipCode: Scalars['String'];
};


export type OrganizationConcessionOrganizationsArgs = {
  filter?: InputMaybe<ConcessionOrganizationFilter>;
  sorting?: InputMaybe<Array<ConcessionOrganizationSort>>;
};

export type OrganizationAggregateGroupBy = {
  __typename?: 'OrganizationAggregateGroupBy';
  address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type OrganizationAvgAggregate = {
  __typename?: 'OrganizationAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  /** Array of nodes. */
  nodes: Array<Organization>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type OrganizationCountAggregate = {
  __typename?: 'OrganizationCountAggregate';
  address?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  tin?: Maybe<Scalars['Int']>;
  zipCode?: Maybe<Scalars['Int']>;
};

export type OrganizationDeleteFilter = {
  address?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<OrganizationDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrganizationDeleteFilter>>;
  tin?: InputMaybe<StringFieldComparison>;
  zipCode?: InputMaybe<StringFieldComparison>;
};

export type OrganizationDeleteResponse = {
  __typename?: 'OrganizationDeleteResponse';
  address?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type OrganizationFilter = {
  address?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<OrganizationFilter>>;
  concessionOrganizations?: InputMaybe<OrganizationFilterConcessionOrganizationFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrganizationFilter>>;
  tin?: InputMaybe<StringFieldComparison>;
  zipCode?: InputMaybe<StringFieldComparison>;
};

export type OrganizationFilterConcessionOrganizationFilter = {
  and?: InputMaybe<Array<OrganizationFilterConcessionOrganizationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<OrganizationFilterConcessionOrganizationFilter>>;
};

export type OrganizationMaxAggregate = {
  __typename?: 'OrganizationMaxAggregate';
  address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type OrganizationMinAggregate = {
  __typename?: 'OrganizationMinAggregate';
  address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type OrganizationSort = {
  direction: SortDirection;
  field: OrganizationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum OrganizationSortFields {
  Address = 'address',
  Id = 'id',
  Name = 'name',
  Tin = 'tin',
  ZipCode = 'zipCode'
}

export type OrganizationSumAggregate = {
  __typename?: 'OrganizationSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type OrganizationType = {
  __typename?: 'OrganizationType';
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OrganizationTypeAggregateGroupBy = {
  __typename?: 'OrganizationTypeAggregateGroupBy';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type OrganizationTypeAvgAggregate = {
  __typename?: 'OrganizationTypeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type OrganizationTypeConnection = {
  __typename?: 'OrganizationTypeConnection';
  /** Array of nodes. */
  nodes: Array<OrganizationType>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type OrganizationTypeCountAggregate = {
  __typename?: 'OrganizationTypeCountAggregate';
  code?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type OrganizationTypeDeleteFilter = {
  and?: InputMaybe<Array<OrganizationTypeDeleteFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrganizationTypeDeleteFilter>>;
};

export type OrganizationTypeDeleteResponse = {
  __typename?: 'OrganizationTypeDeleteResponse';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationTypeFilter = {
  and?: InputMaybe<Array<OrganizationTypeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrganizationTypeFilter>>;
};

export type OrganizationTypeMaxAggregate = {
  __typename?: 'OrganizationTypeMaxAggregate';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type OrganizationTypeMinAggregate = {
  __typename?: 'OrganizationTypeMinAggregate';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type OrganizationTypeSort = {
  direction: SortDirection;
  field: OrganizationTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum OrganizationTypeSortFields {
  Code = 'code',
  Id = 'id',
  Name = 'name'
}

export type OrganizationTypeSumAggregate = {
  __typename?: 'OrganizationTypeSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type OrganizationTypeUpdateFilter = {
  and?: InputMaybe<Array<OrganizationTypeUpdateFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrganizationTypeUpdateFilter>>;
};

export type OrganizationUpdateFilter = {
  address?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<OrganizationUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrganizationUpdateFilter>>;
  tin?: InputMaybe<StringFieldComparison>;
  zipCode?: InputMaybe<StringFieldComparison>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']>;
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']>;
};

export type Permission = {
  __typename?: 'Permission';
  action: Scalars['String'];
  createdAt: Scalars['DateTime'];
  fields?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  role: Role;
  subject: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PermissionAggregateGroupBy = {
  __typename?: 'PermissionAggregateGroupBy';
  action?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  subject?: Maybe<Scalars['String']>;
};

export type PermissionAvgAggregate = {
  __typename?: 'PermissionAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PermissionConnection = {
  __typename?: 'PermissionConnection';
  /** Array of nodes. */
  nodes: Array<Permission>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type PermissionCountAggregate = {
  __typename?: 'PermissionCountAggregate';
  action?: Maybe<Scalars['Int']>;
  fields?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['Int']>;
};

export type PermissionFilter = {
  action?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<PermissionFilter>>;
  fields?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<PermissionFilter>>;
  role?: InputMaybe<PermissionFilterRoleFilter>;
  subject?: InputMaybe<StringFieldComparison>;
};

export type PermissionFilterRoleFilter = {
  and?: InputMaybe<Array<PermissionFilterRoleFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PermissionFilterRoleFilter>>;
};

export type PermissionMaxAggregate = {
  __typename?: 'PermissionMaxAggregate';
  action?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  subject?: Maybe<Scalars['String']>;
};

export type PermissionMinAggregate = {
  __typename?: 'PermissionMinAggregate';
  action?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  subject?: Maybe<Scalars['String']>;
};

export type PermissionSort = {
  direction: SortDirection;
  field: PermissionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PermissionSortFields {
  Action = 'action',
  Fields = 'fields',
  Id = 'id',
  Subject = 'subject'
}

export type PermissionSumAggregate = {
  __typename?: 'PermissionSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  badge?: Maybe<Badge>;
  badges: BadgeConnection;
  concession?: Maybe<Concession>;
  concessionOrganization?: Maybe<ConcessionOrganization>;
  concessionOrganizationAccreditation?: Maybe<ConcessionOrganizationAccreditation>;
  concessionOrganizationAccreditationDocument?: Maybe<ConcessionOrganizationAccreditationDocument>;
  concessionOrganizationAccreditationDocuments: ConcessionOrganizationAccreditationDocumentConnection;
  concessionOrganizationAccreditationState?: Maybe<ConcessionOrganizationAccreditationState>;
  concessionOrganizationAccreditationStates: ConcessionOrganizationAccreditationStateConnection;
  concessionOrganizationAccreditationType?: Maybe<ConcessionOrganizationAccreditationType>;
  concessionOrganizationAccreditationTypes: ConcessionOrganizationAccreditationTypeConnection;
  concessionOrganizationAccreditations: ConcessionOrganizationAccreditationConnection;
  concessionOrganizationDocumentCategories: ConcessionOrganizationDocumentCategoryConnection;
  concessionOrganizationDocumentCategory?: Maybe<ConcessionOrganizationDocumentCategory>;
  concessionOrganizationDocuments: ConcessionOrganizationDocumentOffsetConnection;
  concessionOrganizationUser?: Maybe<ConcessionOrganizationUser>;
  concessionOrganizationUsers: ConcessionOrganizationUserConnection;
  concessionOrganizations: ConcessionOrganizationConnection;
  concessionUser?: Maybe<ConcessionUser>;
  concessionUsers: ConcessionUserConnection;
  concessions: ConcessionConnection;
  contract?: Maybe<Contract>;
  contractConcessionOrganization?: Maybe<ContractConcessionOrganization>;
  contractConcessionOrganizationState?: Maybe<ContractConcessionOrganizationState>;
  contractConcessionOrganizationStates: ContractConcessionOrganizationStateConnection;
  contractConcessionOrganizations: ContractConcessionOrganizationConnection;
  contractDocumentCategories: ContractDocumentCategoryConnection;
  contractDocumentCategory?: Maybe<ContractDocumentCategory>;
  contractDocuments: ContractDocumentOffsetConnection;
  contractRoad?: Maybe<ContractRoad>;
  contractRoads: ContractRoadConnection;
  contractState?: Maybe<ContractState>;
  contractStates: ContractStateConnection;
  contractTransition?: Maybe<ContractTransition>;
  contractTransitions: ContractTransitionConnection;
  contractType?: Maybe<ContractType>;
  contractTypes: ContractTypeConnection;
  contractUser?: Maybe<ContractUser>;
  contractUsers: ContractUserConnection;
  contracts: ContractOffsetConnection;
  controlCenter?: Maybe<ControlCenter>;
  controlCenters: ControlCenterConnection;
  direction?: Maybe<Direction>;
  directions: DirectionConnection;
  documentNotification?: Maybe<DocumentNotification>;
  documentNotifications: DocumentNotificationConnection;
  documentState?: Maybe<DocumentState>;
  documentStates: DocumentStateConnection;
  documentTemplates: DocumentTemplateOffsetConnection;
  fileType?: Maybe<FileType>;
  fileTypes: FileTypeConnection;
  intervention?: Maybe<Intervention>;
  interventionElement?: Maybe<InterventionElement>;
  interventionElements: InterventionElementConnection;
  interventionLocation?: Maybe<InterventionLocation>;
  interventionLocations: InterventionLocationConnection;
  interventionType?: Maybe<InterventionType>;
  interventionTypes: InterventionTypeConnection;
  interventions: InterventionOffsetConnection;
  junction?: Maybe<Junction>;
  junctions: JunctionConnection;
  layout?: Maybe<Layout>;
  layouts: LayoutConnection;
  me: User;
  organization?: Maybe<Organization>;
  organizationType?: Maybe<OrganizationType>;
  organizationTypes: OrganizationTypeConnection;
  organizations: OrganizationConnection;
  ownConcessionOrganizationDocuments: ConcessionOrganizationDocumentOffsetConnection;
  ownSubcontractOrganizationDocuments: ConcessionOrganizationDocumentOffsetConnection;
  permission?: Maybe<Permission>;
  permissions: PermissionConnection;
  restriction?: Maybe<Restriction>;
  restrictions: RestrictionConnection;
  road?: Maybe<Road>;
  roads: RoadConnection;
  role?: Maybe<Role>;
  roles: RoleConnection;
  selectedAccreditation?: Maybe<ConcessionOrganizationAccreditation>;
  service?: Maybe<Service>;
  services: ServiceConnection;
  status: Scalars['String'];
  subcontracts: ContractConcessionOrganizationOffsetConnection;
  surveyRecord?: Maybe<SurveyRecord>;
  surveyRecordAuthors: UserOffsetConnection;
  surveyRecordPhoto?: Maybe<SurveyRecordPhoto>;
  surveyRecordPhotos: SurveyRecordPhotoConnection;
  surveyRecordVerification?: Maybe<SurveyRecordVerification>;
  surveyRecordVerificationClassification?: Maybe<SurveyRecordVerificationClassification>;
  surveyRecordVerificationClassifications: SurveyRecordVerificationClassificationConnection;
  surveyRecordVerificationState?: Maybe<SurveyRecordVerificationState>;
  surveyRecordVerificationStates: SurveyRecordVerificationStateConnection;
  surveyRecordVerifications: SurveyRecordVerificationConnection;
  surveyRecords: SurveyRecordOffsetConnection;
  task?: Maybe<Task>;
  tasks: TaskOffsetConnection;
  user?: Maybe<User>;
  users: UserConnection;
  verification?: Maybe<Verification>;
  verificationScope?: Maybe<VerificationScope>;
  verificationScopes: VerificationScopeConnection;
  verifications: VerificationConnection;
  weatherCondition?: Maybe<WeatherCondition>;
  weatherConditions: WeatherConditionConnection;
  workPermit?: Maybe<WorkPermit>;
  workPermits: WorkPermitOffsetConnection;
  workspace: Workspace;
};


export type QueryBadgeArgs = {
  id: Scalars['ID'];
};


export type QueryBadgesArgs = {
  filter?: InputMaybe<BadgeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<BadgeSort>>;
};


export type QueryConcessionArgs = {
  id: Scalars['ID'];
};


export type QueryConcessionOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryConcessionOrganizationAccreditationArgs = {
  id: Scalars['ID'];
};


export type QueryConcessionOrganizationAccreditationDocumentArgs = {
  id: Scalars['ID'];
};


export type QueryConcessionOrganizationAccreditationDocumentsArgs = {
  filter?: InputMaybe<ConcessionOrganizationAccreditationDocumentFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionOrganizationAccreditationDocumentSort>>;
};


export type QueryConcessionOrganizationAccreditationStateArgs = {
  id: Scalars['ID'];
};


export type QueryConcessionOrganizationAccreditationStatesArgs = {
  filter?: InputMaybe<ConcessionOrganizationAccreditationStateFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionOrganizationAccreditationStateSort>>;
};


export type QueryConcessionOrganizationAccreditationTypeArgs = {
  id: Scalars['ID'];
};


export type QueryConcessionOrganizationAccreditationTypesArgs = {
  filter?: InputMaybe<ConcessionOrganizationAccreditationTypeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionOrganizationAccreditationTypeSort>>;
};


export type QueryConcessionOrganizationAccreditationsArgs = {
  filter?: InputMaybe<ConcessionOrganizationAccreditationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionOrganizationAccreditationSort>>;
};


export type QueryConcessionOrganizationDocumentCategoriesArgs = {
  filter?: InputMaybe<ConcessionOrganizationDocumentCategoryFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionOrganizationDocumentCategorySort>>;
};


export type QueryConcessionOrganizationDocumentCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryConcessionOrganizationDocumentsArgs = {
  filter?: InputMaybe<ConcessionOrganizationDocumentFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionOrganizationDocumentSort>>;
};


export type QueryConcessionOrganizationUserArgs = {
  id: Scalars['ID'];
};


export type QueryConcessionOrganizationUsersArgs = {
  filter?: InputMaybe<ConcessionOrganizationUserFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionOrganizationUserSort>>;
};


export type QueryConcessionOrganizationsArgs = {
  filter?: InputMaybe<ConcessionOrganizationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionOrganizationSort>>;
};


export type QueryConcessionUserArgs = {
  id: Scalars['ID'];
};


export type QueryConcessionUsersArgs = {
  filter?: InputMaybe<ConcessionUserFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionUserSort>>;
};


export type QueryConcessionsArgs = {
  filter?: InputMaybe<ConcessionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionSort>>;
};


export type QueryContractArgs = {
  id: Scalars['ID'];
};


export type QueryContractConcessionOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryContractConcessionOrganizationStateArgs = {
  id: Scalars['ID'];
};


export type QueryContractConcessionOrganizationStatesArgs = {
  filter?: InputMaybe<ContractConcessionOrganizationStateFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ContractConcessionOrganizationStateSort>>;
};


export type QueryContractConcessionOrganizationsArgs = {
  filter?: InputMaybe<ContractConcessionOrganizationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ContractConcessionOrganizationSort>>;
};


export type QueryContractDocumentCategoriesArgs = {
  filter?: InputMaybe<ContractDocumentCategoryFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ContractDocumentCategorySort>>;
};


export type QueryContractDocumentCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryContractDocumentsArgs = {
  filter?: InputMaybe<ContractDocumentFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ContractDocumentSort>>;
};


export type QueryContractRoadArgs = {
  id: Scalars['ID'];
};


export type QueryContractRoadsArgs = {
  filter?: InputMaybe<ContractRoadFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ContractRoadSort>>;
};


export type QueryContractStateArgs = {
  id: Scalars['ID'];
};


export type QueryContractStatesArgs = {
  filter?: InputMaybe<ContractStateFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ContractStateSort>>;
};


export type QueryContractTransitionArgs = {
  id: Scalars['ID'];
};


export type QueryContractTransitionsArgs = {
  filter?: InputMaybe<ContractTransitionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ContractTransitionSort>>;
};


export type QueryContractTypeArgs = {
  id: Scalars['ID'];
};


export type QueryContractTypesArgs = {
  filter?: InputMaybe<ContractTypeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ContractTypeSort>>;
};


export type QueryContractUserArgs = {
  id: Scalars['ID'];
};


export type QueryContractUsersArgs = {
  filter?: InputMaybe<ContractUserFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ContractUserSort>>;
};


export type QueryContractsArgs = {
  filter?: InputMaybe<ContractFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ContractSort>>;
};


export type QueryControlCenterArgs = {
  id: Scalars['ID'];
};


export type QueryControlCentersArgs = {
  filter?: InputMaybe<ControlCenterFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ControlCenterSort>>;
};


export type QueryDirectionArgs = {
  id: Scalars['ID'];
};


export type QueryDirectionsArgs = {
  filter?: InputMaybe<DirectionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<DirectionSort>>;
};


export type QueryDocumentNotificationArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentNotificationsArgs = {
  filter?: InputMaybe<DocumentNotificationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<DocumentNotificationSort>>;
};


export type QueryDocumentStateArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentStatesArgs = {
  filter?: InputMaybe<DocumentStateFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<DocumentStateSort>>;
};


export type QueryDocumentTemplatesArgs = {
  filter?: InputMaybe<DocumentTemplateFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<DocumentTemplateSort>>;
};


export type QueryFileTypeArgs = {
  id: Scalars['ID'];
};


export type QueryFileTypesArgs = {
  filter?: InputMaybe<FileTypeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<FileTypeSort>>;
};


export type QueryInterventionArgs = {
  id: Scalars['ID'];
};


export type QueryInterventionElementArgs = {
  id: Scalars['ID'];
};


export type QueryInterventionElementsArgs = {
  filter?: InputMaybe<InterventionElementFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<InterventionElementSort>>;
};


export type QueryInterventionLocationArgs = {
  id: Scalars['ID'];
};


export type QueryInterventionLocationsArgs = {
  filter?: InputMaybe<InterventionLocationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<InterventionLocationSort>>;
};


export type QueryInterventionTypeArgs = {
  id: Scalars['ID'];
};


export type QueryInterventionTypesArgs = {
  filter?: InputMaybe<InterventionTypeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<InterventionTypeSort>>;
};


export type QueryInterventionsArgs = {
  filter?: InputMaybe<InterventionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<InterventionSort>>;
};


export type QueryJunctionArgs = {
  id: Scalars['ID'];
};


export type QueryJunctionsArgs = {
  filter?: InputMaybe<JunctionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<JunctionSort>>;
};


export type QueryLayoutArgs = {
  id: Scalars['ID'];
};


export type QueryLayoutsArgs = {
  filter?: InputMaybe<LayoutFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<LayoutSort>>;
};


export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationTypeArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationTypesArgs = {
  filter?: InputMaybe<OrganizationTypeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<OrganizationTypeSort>>;
};


export type QueryOrganizationsArgs = {
  filter?: InputMaybe<OrganizationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<OrganizationSort>>;
};


export type QueryOwnConcessionOrganizationDocumentsArgs = {
  filter?: InputMaybe<ConcessionOrganizationDocumentFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionOrganizationDocumentSort>>;
};


export type QueryOwnSubcontractOrganizationDocumentsArgs = {
  filter?: InputMaybe<ConcessionOrganizationDocumentFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionOrganizationDocumentSort>>;
};


export type QueryPermissionArgs = {
  id: Scalars['ID'];
};


export type QueryPermissionsArgs = {
  filter?: InputMaybe<PermissionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<PermissionSort>>;
};


export type QueryRestrictionArgs = {
  id: Scalars['ID'];
};


export type QueryRestrictionsArgs = {
  filter?: InputMaybe<RestrictionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<RestrictionSort>>;
};


export type QueryRoadArgs = {
  id: Scalars['ID'];
};


export type QueryRoadsArgs = {
  filter?: InputMaybe<RoadFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<RoadSort>>;
};


export type QueryRoleArgs = {
  id: Scalars['ID'];
};


export type QueryRolesArgs = {
  filter?: InputMaybe<RoleFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<RoleSort>>;
};


export type QuerySelectedAccreditationArgs = {
  input: SelectedAccreditationInput;
};


export type QueryServiceArgs = {
  id: Scalars['ID'];
};


export type QueryServicesArgs = {
  filter?: InputMaybe<ServiceFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ServiceSort>>;
};


export type QuerySubcontractsArgs = {
  filter?: InputMaybe<ContractConcessionOrganizationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ContractConcessionOrganizationSort>>;
};


export type QuerySurveyRecordArgs = {
  id: Scalars['ID'];
};


export type QuerySurveyRecordAuthorsArgs = {
  filter?: InputMaybe<UserFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<UserSort>>;
};


export type QuerySurveyRecordPhotoArgs = {
  id: Scalars['ID'];
};


export type QuerySurveyRecordPhotosArgs = {
  filter?: InputMaybe<SurveyRecordPhotoFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SurveyRecordPhotoSort>>;
};


export type QuerySurveyRecordVerificationArgs = {
  id: Scalars['ID'];
};


export type QuerySurveyRecordVerificationClassificationArgs = {
  id: Scalars['ID'];
};


export type QuerySurveyRecordVerificationClassificationsArgs = {
  filter?: InputMaybe<SurveyRecordVerificationClassificationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SurveyRecordVerificationClassificationSort>>;
};


export type QuerySurveyRecordVerificationStateArgs = {
  id: Scalars['ID'];
};


export type QuerySurveyRecordVerificationStatesArgs = {
  filter?: InputMaybe<SurveyRecordVerificationStateFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SurveyRecordVerificationStateSort>>;
};


export type QuerySurveyRecordVerificationsArgs = {
  filter?: InputMaybe<SurveyRecordVerificationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SurveyRecordVerificationSort>>;
};


export type QuerySurveyRecordsArgs = {
  filter?: InputMaybe<SurveyRecordFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SurveyRecordSort>>;
};


export type QueryTaskArgs = {
  id: Scalars['ID'];
};


export type QueryTasksArgs = {
  filter?: InputMaybe<TaskFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<TaskSort>>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<UserSort>>;
};


export type QueryVerificationArgs = {
  id: Scalars['ID'];
};


export type QueryVerificationScopeArgs = {
  id: Scalars['ID'];
};


export type QueryVerificationScopesArgs = {
  filter?: InputMaybe<VerificationScopeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<VerificationScopeSort>>;
};


export type QueryVerificationsArgs = {
  filter?: InputMaybe<VerificationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<VerificationSort>>;
};


export type QueryWeatherConditionArgs = {
  id: Scalars['ID'];
};


export type QueryWeatherConditionsArgs = {
  filter?: InputMaybe<WeatherConditionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<WeatherConditionSort>>;
};


export type QueryWorkPermitArgs = {
  id: Scalars['ID'];
};


export type QueryWorkPermitsArgs = {
  filter?: InputMaybe<WorkPermitFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<WorkPermitSort>>;
};

export type RemoveBadgesFromWorkPermitInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveChecklistFromChecklistItemInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveChecklistFromWorkPermitInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveChecklistItemsFromChecklistInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveChildrenFromConcessionOrganizationDocumentCategoryInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveChildrenFromContractDocumentCategoryInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveConcessionFromConcessionOrganizationAccreditationStateInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromConcessionOrganizationDocumentCategoryInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromConcessionOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromConcessionUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromContractConcessionOrganizationStateInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromContractDocumentCategoryInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromContractStateInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromContractTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromControlCenterInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromDirectionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromInterventionElementInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromInterventionLocationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromInterventionTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromJunctionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromLayoutInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromRestrictionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromRoadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromServiceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromSurveyRecordVerificationClassificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromSurveyRecordVerificationStateInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromVerificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromVerificationScopeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionFromWeatherConditionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionOrganizationAccreditationDocumentsFromConcessionOrganizationAccreditationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveConcessionOrganizationAccreditationFromConcessionOrganizationAccreditationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionOrganizationAccreditationFromContractConcessionOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionOrganizationAccreditationStateFromConcessionOrganizationAccreditationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionOrganizationAccreditationTypeFromConcessionOrganizationAccreditationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionOrganizationDocumentCategoryFromConcessionOrganizationAccreditationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionOrganizationDocumentCategoryFromConcessionOrganizationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionOrganizationDocumentFromDocumentNotificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionOrganizationDocumentSubcategoryFromConcessionOrganizationAccreditationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionOrganizationDocumentSubcategoryFromConcessionOrganizationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionOrganizationDocumentsFromConcessionOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveConcessionOrganizationFromConcessionOrganizationAccreditationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionOrganizationFromConcessionOrganizationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionOrganizationFromConcessionOrganizationUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionOrganizationFromContractConcessionOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionOrganizationFromDocumentNotificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveConcessionOrganizationUsersFromUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveConcessionOrganizationsFromOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveConcessionUsersFromConcessionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveConcessionUsersFromUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveConcessionsFromConcessionOrganizationAccreditationTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveContractConcessionOrganizationStateFromContractConcessionOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveContractConcessionOrganizationsFromContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveContractDocumentCategoryFromContractDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveContractDocumentFromDocumentNotificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveContractDocumentSubcategoryFromContractDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveContractDocumentsFromContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveContractFromContractConcessionOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveContractFromContractDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveContractFromContractRoadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveContractFromContractUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveContractFromDocumentNotificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveContractFromSurveyRecordInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveContractFromWorkPermitInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveContractRoadsFromContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveContractRoadsFromRoadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveContractStateFromContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveContractTransitionsFromContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveContractTypeFromContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveContractUsersFromContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveContractUsersFromUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveContractsFromConcessionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveContractsFromContractStateInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveContractsFromContractTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveContractsFromInterventionElementInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveContractsFromInterventionLocationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveContractsFromInterventionTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveContractsFromServiceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveControlCentersFromConcessionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveDefaultConcessionFromUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveDirectionFromTaskInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveDocumentStateFromConcessionOrganizationAccreditationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveDocumentStateFromConcessionOrganizationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveDocumentStateFromContractDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveDocumentStateFromDocumentNotificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveEndJunctionFromSurveyRecordInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveEndJunctionFromTaskInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveEndJuntionSurveyRecordsFromJunctionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveEndJuntionTasksFromJunctionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveFileTypeFromConcessionOrganizationAccreditationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveFileTypeFromConcessionOrganizationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveFileTypeFromContractDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveFromStateFromContractTransitionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveInterventionElementFromContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveInterventionFromTaskInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveInterventionLocationFromContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveInterventionLocationFromTaskInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveInterventionTypeFromContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveInterventionsFromWorkPermitInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveJunctionsFromRoadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveLayoutFromTaskInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveMediatingOrganizationFromConcessionOrganizationAccreditationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveOrganizationFromConcessionOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveOrganizationTypeFromConcessionOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveOrganizationTypesFromConcessionOrganizationAccreditationTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveOwnerFromConcessionOrganizationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveOwnerFromContractDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveParentFromConcessionOrganizationDocumentCategoryInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveParentFromContractDocumentCategoryInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemovePermissionsFromRoleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveRestrictionFromTaskInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveRoadFromContractRoadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveRoadFromJunctionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveRoadFromSurveyRecordInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveRoadFromTaskInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveRoadsFromConcessionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveRoleFromPermissionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveServiceFromContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveStartJunctionFromSurveyRecordInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveStartJunctionFromTaskInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveStartJuntionSurveyRecordsFromJunctionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveStartJuntionTasksFromJunctionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveSurveyRecordFromSurveyRecordPhotoInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveSurveyRecordFromSurveyRecordVerificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveSurveyRecordPhotosFromSurveyRecordInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveSurveyRecordVerificationClassificationFromSurveyRecordVerificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveSurveyRecordVerificationStateFromSurveyRecordVerificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveSurveyRecordVerificationsFromSurveyRecordInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveSurveyRecordVerificationsFromVerificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveSurveyRecordsFromContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveSurveyRecordsFromRoadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveSurveyRecordsFromUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveSurveyRecordsFromWeatherConditionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveTasksFromInterventionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveTasksFromRestrictionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveTasksFromRoadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveToStateFromContractTransitionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveUserFromConcessionOrganizationUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveUserFromConcessionUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveUserFromContractUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveUserFromSurveyRecordInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveUserFromWorkspaceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveVerificationFromSurveyRecordVerificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveVerificationScopeFromVerificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveVerificationsFromVerificationScopeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveWeatherConditionFromSurveyRecordInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveWorkPermitFromBadgeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveWorkPermitFromInterventionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveWorkPermitsFromContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveWorkspaceFromWorkspaceViewInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveWorkspaceViewColumnsFromWorkspaceViewInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveWorkspaceViewsFromWorkspaceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type Restriction = {
  __typename?: 'Restriction';
  concession: Concession;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  tasks?: Maybe<Array<Task>>;
  updatedAt: Scalars['DateTime'];
};


export type RestrictionTasksArgs = {
  filter?: InputMaybe<TaskFilter>;
  sorting?: InputMaybe<Array<TaskSort>>;
};

export type RestrictionAggregateGroupBy = {
  __typename?: 'RestrictionAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type RestrictionAvgAggregate = {
  __typename?: 'RestrictionAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type RestrictionConnection = {
  __typename?: 'RestrictionConnection';
  /** Array of nodes. */
  nodes: Array<Restriction>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type RestrictionCountAggregate = {
  __typename?: 'RestrictionCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type RestrictionDeleteFilter = {
  and?: InputMaybe<Array<RestrictionDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RestrictionDeleteFilter>>;
};

export type RestrictionDeleteResponse = {
  __typename?: 'RestrictionDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RestrictionFilter = {
  and?: InputMaybe<Array<RestrictionFilter>>;
  concession?: InputMaybe<RestrictionFilterConcessionFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RestrictionFilter>>;
  tasks?: InputMaybe<RestrictionFilterTaskFilter>;
};

export type RestrictionFilterConcessionFilter = {
  and?: InputMaybe<Array<RestrictionFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RestrictionFilterConcessionFilter>>;
};

export type RestrictionFilterTaskFilter = {
  and?: InputMaybe<Array<RestrictionFilterTaskFilter>>;
  endAt?: InputMaybe<DateFieldComparison>;
  endPk?: InputMaybe<StringFieldComparison>;
  executingAuthority?: InputMaybe<StringFieldComparison>;
  executingAuthorityContact?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  extension?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isoWeek?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RestrictionFilterTaskFilter>>;
  overlaps?: InputMaybe<StringFieldComparison>;
  reason?: InputMaybe<StringFieldComparison>;
  sequence?: InputMaybe<NumberFieldComparison>;
  signalingAuthority?: InputMaybe<StringFieldComparison>;
  signalingAuthorityContact?: InputMaybe<StringFieldComparison>;
  startAt?: InputMaybe<DateFieldComparison>;
  startPk?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
};

export type RestrictionMaxAggregate = {
  __typename?: 'RestrictionMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type RestrictionMinAggregate = {
  __typename?: 'RestrictionMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type RestrictionSort = {
  direction: SortDirection;
  field: RestrictionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum RestrictionSortFields {
  Id = 'id',
  Name = 'name'
}

export type RestrictionSumAggregate = {
  __typename?: 'RestrictionSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type RestrictionUpdateFilter = {
  and?: InputMaybe<Array<RestrictionUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RestrictionUpdateFilter>>;
};

export type Road = {
  __typename?: 'Road';
  concession: Concession;
  contractRoads?: Maybe<Array<ContractRoad>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  junctions?: Maybe<Array<Junction>>;
  name: Scalars['String'];
  surveyRecords?: Maybe<Array<SurveyRecord>>;
  tasks?: Maybe<Array<Task>>;
  updatedAt: Scalars['DateTime'];
};


export type RoadContractRoadsArgs = {
  filter?: InputMaybe<ContractRoadFilter>;
  sorting?: InputMaybe<Array<ContractRoadSort>>;
};


export type RoadJunctionsArgs = {
  filter?: InputMaybe<JunctionFilter>;
  sorting?: InputMaybe<Array<JunctionSort>>;
};


export type RoadSurveyRecordsArgs = {
  filter?: InputMaybe<SurveyRecordFilter>;
  sorting?: InputMaybe<Array<SurveyRecordSort>>;
};


export type RoadTasksArgs = {
  filter?: InputMaybe<TaskFilter>;
  sorting?: InputMaybe<Array<TaskSort>>;
};

export type RoadAggregateGroupBy = {
  __typename?: 'RoadAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type RoadAvgAggregate = {
  __typename?: 'RoadAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type RoadConnection = {
  __typename?: 'RoadConnection';
  /** Array of nodes. */
  nodes: Array<Road>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type RoadCountAggregate = {
  __typename?: 'RoadCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type RoadDeleteFilter = {
  and?: InputMaybe<Array<RoadDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RoadDeleteFilter>>;
};

export type RoadDeleteResponse = {
  __typename?: 'RoadDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RoadFilter = {
  and?: InputMaybe<Array<RoadFilter>>;
  concession?: InputMaybe<RoadFilterConcessionFilter>;
  contractRoads?: InputMaybe<RoadFilterContractRoadFilter>;
  id?: InputMaybe<IdFilterComparison>;
  junctions?: InputMaybe<RoadFilterJunctionFilter>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RoadFilter>>;
  surveyRecords?: InputMaybe<RoadFilterSurveyRecordFilter>;
  tasks?: InputMaybe<RoadFilterTaskFilter>;
};

export type RoadFilterConcessionFilter = {
  and?: InputMaybe<Array<RoadFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RoadFilterConcessionFilter>>;
};

export type RoadFilterContractRoadFilter = {
  and?: InputMaybe<Array<RoadFilterContractRoadFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<RoadFilterContractRoadFilter>>;
};

export type RoadFilterJunctionFilter = {
  and?: InputMaybe<Array<RoadFilterJunctionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RoadFilterJunctionFilter>>;
  order?: InputMaybe<NumberFieldComparison>;
  pk?: InputMaybe<StringFieldComparison>;
};

export type RoadFilterSurveyRecordFilter = {
  and?: InputMaybe<Array<RoadFilterSurveyRecordFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endPk?: InputMaybe<StringFieldComparison>;
  hasCorrectiveRequests?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isPublished?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RoadFilterSurveyRecordFilter>>;
  participants?: InputMaybe<StringFieldComparison>;
  sequence?: InputMaybe<NumberFieldComparison>;
  startPk?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  surveyedAt?: InputMaybe<DateFieldComparison>;
};

export type RoadFilterTaskFilter = {
  and?: InputMaybe<Array<RoadFilterTaskFilter>>;
  endAt?: InputMaybe<DateFieldComparison>;
  endPk?: InputMaybe<StringFieldComparison>;
  executingAuthority?: InputMaybe<StringFieldComparison>;
  executingAuthorityContact?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  extension?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isoWeek?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RoadFilterTaskFilter>>;
  overlaps?: InputMaybe<StringFieldComparison>;
  reason?: InputMaybe<StringFieldComparison>;
  sequence?: InputMaybe<NumberFieldComparison>;
  signalingAuthority?: InputMaybe<StringFieldComparison>;
  signalingAuthorityContact?: InputMaybe<StringFieldComparison>;
  startAt?: InputMaybe<DateFieldComparison>;
  startPk?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
};

export type RoadMaxAggregate = {
  __typename?: 'RoadMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type RoadMinAggregate = {
  __typename?: 'RoadMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type RoadSort = {
  direction: SortDirection;
  field: RoadSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum RoadSortFields {
  Id = 'id',
  Name = 'name'
}

export type RoadSumAggregate = {
  __typename?: 'RoadSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type RoadUpdateFilter = {
  and?: InputMaybe<Array<RoadUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RoadUpdateFilter>>;
};

export type Role = {
  __typename?: 'Role';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<Permission>;
  updatedAt: Scalars['DateTime'];
};


export type RolePermissionsArgs = {
  filter?: InputMaybe<PermissionFilter>;
  sorting?: InputMaybe<Array<PermissionSort>>;
};

export type RoleAggregateGroupBy = {
  __typename?: 'RoleAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type RoleAvgAggregate = {
  __typename?: 'RoleAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type RoleConnection = {
  __typename?: 'RoleConnection';
  /** Array of nodes. */
  nodes: Array<Role>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type RoleCountAggregate = {
  __typename?: 'RoleCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type RoleFilter = {
  and?: InputMaybe<Array<RoleFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RoleFilter>>;
};

export type RoleMaxAggregate = {
  __typename?: 'RoleMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type RoleMinAggregate = {
  __typename?: 'RoleMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type RoleSort = {
  direction: SortDirection;
  field: RoleSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum RoleSortFields {
  Id = 'id',
  Name = 'name'
}

export type RoleSumAggregate = {
  __typename?: 'RoleSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SelectedAccreditationInput = {
  concessionOrganization: Scalars['ID'];
  concessionOrganizationAccreditationType: Scalars['ID'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type Service = {
  __typename?: 'Service';
  abbreviation: Scalars['String'];
  concession: Concession;
  contracts: Array<Contract>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type ServiceContractsArgs = {
  filter?: InputMaybe<ContractFilter>;
  sorting?: InputMaybe<Array<ContractSort>>;
};

export type ServiceAggregateGroupBy = {
  __typename?: 'ServiceAggregateGroupBy';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ServiceAvgAggregate = {
  __typename?: 'ServiceAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ServiceConnection = {
  __typename?: 'ServiceConnection';
  /** Array of nodes. */
  nodes: Array<Service>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ServiceCountAggregate = {
  __typename?: 'ServiceCountAggregate';
  abbreviation?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type ServiceDeleteFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ServiceDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ServiceDeleteFilter>>;
};

export type ServiceDeleteResponse = {
  __typename?: 'ServiceDeleteResponse';
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ServiceFilter>>;
  concession?: InputMaybe<ServiceFilterConcessionFilter>;
  contract?: InputMaybe<ServiceFilterContractFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ServiceFilter>>;
};

export type ServiceFilterConcessionFilter = {
  and?: InputMaybe<Array<ServiceFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ServiceFilterConcessionFilter>>;
};

export type ServiceFilterContractFilter = {
  and?: InputMaybe<Array<ServiceFilterContractFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ServiceFilterContractFilter>>;
  realEndDate?: InputMaybe<StringFieldComparison>;
  realStartDate?: InputMaybe<StringFieldComparison>;
  road?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type ServiceMaxAggregate = {
  __typename?: 'ServiceMaxAggregate';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ServiceMinAggregate = {
  __typename?: 'ServiceMinAggregate';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ServiceSort = {
  direction: SortDirection;
  field: ServiceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ServiceSortFields {
  Abbreviation = 'abbreviation',
  Id = 'id',
  Name = 'name'
}

export type ServiceSumAggregate = {
  __typename?: 'ServiceSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ServiceUpdateFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ServiceUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ServiceUpdateFilter>>;
};

export type SetBadgesOnWorkPermitInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetChecklistItemsOnChecklistInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetChecklistOnChecklistItemInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetChecklistOnWorkPermitInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetChildrenOnConcessionOrganizationDocumentCategoryInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetChildrenOnContractDocumentCategoryInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetConcessionOnConcessionOrganizationAccreditationStateInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnConcessionOrganizationDocumentCategoryInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnConcessionOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnConcessionUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnContractConcessionOrganizationStateInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnContractDocumentCategoryInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnContractStateInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnContractTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnControlCenterInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnDirectionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnInterventionElementInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnInterventionLocationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnInterventionTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnJunctionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnLayoutInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnRestrictionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnRoadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnServiceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnSurveyRecordVerificationClassificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnSurveyRecordVerificationStateInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnVerificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnVerificationScopeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOnWeatherConditionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOrganizationAccreditationDocumentsOnConcessionOrganizationAccreditationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetConcessionOrganizationAccreditationOnConcessionOrganizationAccreditationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOrganizationAccreditationOnContractConcessionOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOrganizationAccreditationStateOnConcessionOrganizationAccreditationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOrganizationAccreditationTypeOnConcessionOrganizationAccreditationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOrganizationDocumentCategoryOnConcessionOrganizationAccreditationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOrganizationDocumentCategoryOnConcessionOrganizationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOrganizationDocumentOnDocumentNotificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOrganizationDocumentSubcategoryOnConcessionOrganizationAccreditationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOrganizationDocumentSubcategoryOnConcessionOrganizationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOrganizationDocumentsOnConcessionOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetConcessionOrganizationOnConcessionOrganizationAccreditationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOrganizationOnConcessionOrganizationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOrganizationOnConcessionOrganizationUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOrganizationOnContractConcessionOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOrganizationOnDocumentNotificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConcessionOrganizationUsersOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetConcessionOrganizationsOnOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetConcessionUsersOnConcessionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetConcessionUsersOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetConcessionsOnConcessionOrganizationAccreditationTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetContractConcessionOrganizationStateOnContractConcessionOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetContractConcessionOrganizationsOnContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetContractDocumentCategoryOnContractDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetContractDocumentOnDocumentNotificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetContractDocumentSubcategoryOnContractDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetContractDocumentsOnContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetContractOnContractConcessionOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetContractOnContractDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetContractOnContractRoadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetContractOnContractUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetContractOnDocumentNotificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetContractOnSurveyRecordInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetContractOnWorkPermitInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetContractRoadsForContractInput = {
  id: Scalars['ID'];
  relatedIds: Array<Scalars['ID']>;
};

export type SetContractRoadsOnContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetContractRoadsOnRoadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetContractStateOnContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetContractTransitionsOnContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetContractTypeOnContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetContractUsersForContractInput = {
  contractUserRoles: Array<ContractUserRole>;
  id: Scalars['ID'];
};

export type SetContractUsersOnContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetContractUsersOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetContractsOnConcessionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetContractsOnContractStateInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetContractsOnContractTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetContractsOnInterventionElementInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetContractsOnInterventionLocationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetContractsOnInterventionTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetContractsOnServiceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetControlCentersOnConcessionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetDefaultConcessionOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetDirectionOnTaskInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetDocumentStateOnConcessionOrganizationAccreditationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetDocumentStateOnConcessionOrganizationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetDocumentStateOnContractDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetDocumentStateOnDocumentNotificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetEndJunctionOnSurveyRecordInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetEndJunctionOnTaskInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetEndJuntionSurveyRecordsOnJunctionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetEndJuntionTasksOnJunctionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetFileTypeOnConcessionOrganizationAccreditationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetFileTypeOnConcessionOrganizationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetFileTypeOnContractDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetFromStateOnContractTransitionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetInterventionElementOnContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetInterventionLocationOnContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetInterventionLocationOnTaskInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetInterventionOnTaskInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetInterventionTypeOnContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetInterventionsOnWorkPermitInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetJunctionsOnRoadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetLayoutOnTaskInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMediatingOrganizationOnConcessionOrganizationAccreditationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetOrganizationOnConcessionOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetOrganizationTypeOnConcessionOrganizationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetOrganizationTypesOnConcessionOrganizationAccreditationTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetOwnerOnConcessionOrganizationDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetOwnerOnContractDocumentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetParentOnConcessionOrganizationDocumentCategoryInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetParentOnContractDocumentCategoryInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetPasswordInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type SetPermissionsOnRoleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetRestrictionOnTaskInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetRoadOnContractRoadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetRoadOnJunctionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetRoadOnSurveyRecordInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetRoadOnTaskInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetRoadsOnConcessionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetRoleOnPermissionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetServiceOnContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetStartJunctionOnSurveyRecordInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetStartJunctionOnTaskInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetStartJuntionSurveyRecordsOnJunctionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetStartJuntionTasksOnJunctionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetSurveyRecordOnSurveyRecordPhotoInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSurveyRecordOnSurveyRecordVerificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSurveyRecordPhotosOnSurveyRecordInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetSurveyRecordVerificationClassificationOnSurveyRecordVerificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSurveyRecordVerificationStateOnSurveyRecordVerificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSurveyRecordVerificationsOnSurveyRecordInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetSurveyRecordVerificationsOnVerificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetSurveyRecordsOnContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetSurveyRecordsOnRoadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetSurveyRecordsOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetSurveyRecordsOnWeatherConditionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetTasksOnInterventionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetTasksOnRestrictionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetTasksOnRoadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetToStateOnContractTransitionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnConcessionOrganizationUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnConcessionUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnContractUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnSurveyRecordInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnWorkspaceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetVerificationOnSurveyRecordVerificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetVerificationScopeOnVerificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetVerificationsOnVerificationScopeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetWeatherConditionOnSurveyRecordInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetWorkPermitOnBadgeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetWorkPermitOnInterventionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetWorkPermitsOnContractInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetWorkspaceOnWorkspaceViewInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetWorkspaceView = {
  columns: Array<SetWorkspaceViewColumn>;
  view: Scalars['String'];
};

export type SetWorkspaceViewColumn = {
  name: Scalars['String'];
  size?: InputMaybe<Scalars['Float']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type SetWorkspaceViewColumnsOnWorkspaceViewInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetWorkspaceViewsOnWorkspaceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  iLike?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  notILike?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  notLike?: InputMaybe<Scalars['String']>;
};

export type SubcontractDeleteResponse = {
  __typename?: 'SubcontractDeleteResponse';
  concessionOrganization: ConcessionOrganization;
  concessionOrganizationAccreditation?: Maybe<ConcessionOrganizationAccreditation>;
  contract: Contract;
  contractConcessionOrganizationState?: Maybe<ContractConcessionOrganizationState>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type SurveyRecord = {
  __typename?: 'SurveyRecord';
  contract: Contract;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  endJunction: Junction;
  endPk: Scalars['String'];
  hasCorrectiveRequests: Scalars['Boolean'];
  id: Scalars['ID'];
  isPublished: Scalars['Boolean'];
  name: Scalars['String'];
  observations?: Maybe<Scalars['String']>;
  openCorrectiveRequests?: Maybe<Scalars['Float']>;
  participants?: Maybe<Scalars['String']>;
  road: Road;
  sequence: Scalars['Float'];
  startJunction: Junction;
  startPk: Scalars['String'];
  state: Scalars['String'];
  surveyRecordPhotos?: Maybe<Array<SurveyRecordPhoto>>;
  surveyRecordVerifications?: Maybe<Array<SurveyRecordVerification>>;
  surveyedAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user: User;
  weatherCondition: WeatherCondition;
};


export type SurveyRecordSurveyRecordPhotosArgs = {
  filter?: InputMaybe<SurveyRecordPhotoFilter>;
  sorting?: InputMaybe<Array<SurveyRecordPhotoSort>>;
};


export type SurveyRecordSurveyRecordVerificationsArgs = {
  filter?: InputMaybe<SurveyRecordVerificationFilter>;
  sorting?: InputMaybe<Array<SurveyRecordVerificationSort>>;
};

export type SurveyRecordAggregateGroupBy = {
  __typename?: 'SurveyRecordAggregateGroupBy';
  description?: Maybe<Scalars['String']>;
  endPk?: Maybe<Scalars['String']>;
  hasCorrectiveRequests?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  participants?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  startPk?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  surveyedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyRecordAvgAggregate = {
  __typename?: 'SurveyRecordAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

export type SurveyRecordCountAggregate = {
  __typename?: 'SurveyRecordCountAggregate';
  description?: Maybe<Scalars['Int']>;
  endPk?: Maybe<Scalars['Int']>;
  hasCorrectiveRequests?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  observations?: Maybe<Scalars['Int']>;
  participants?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  startPk?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
  surveyedAt?: Maybe<Scalars['Int']>;
};

export type SurveyRecordDeleteFilter = {
  and?: InputMaybe<Array<SurveyRecordDeleteFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endPk?: InputMaybe<StringFieldComparison>;
  hasCorrectiveRequests?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isPublished?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordDeleteFilter>>;
  participants?: InputMaybe<StringFieldComparison>;
  sequence?: InputMaybe<NumberFieldComparison>;
  startPk?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  surveyedAt?: InputMaybe<DateFieldComparison>;
};

export type SurveyRecordDeleteResponse = {
  __typename?: 'SurveyRecordDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  endPk?: Maybe<Scalars['String']>;
  hasCorrectiveRequests?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  openCorrectiveRequests?: Maybe<Scalars['Float']>;
  participants?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  startPk?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  surveyedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyRecordFilter = {
  and?: InputMaybe<Array<SurveyRecordFilter>>;
  contract?: InputMaybe<SurveyRecordFilterContractFilter>;
  description?: InputMaybe<StringFieldComparison>;
  endJunction?: InputMaybe<SurveyRecordFilterJunctionFilter>;
  endPk?: InputMaybe<StringFieldComparison>;
  hasCorrectiveRequests?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isPublished?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordFilter>>;
  participants?: InputMaybe<StringFieldComparison>;
  road?: InputMaybe<SurveyRecordFilterRoadFilter>;
  sequence?: InputMaybe<NumberFieldComparison>;
  startJunction?: InputMaybe<SurveyRecordFilterJunctionFilter>;
  startPk?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  surveyRecordPhotos?: InputMaybe<SurveyRecordFilterSurveyRecordPhotoFilter>;
  surveyRecordVerifications?: InputMaybe<SurveyRecordFilterSurveyRecordVerificationFilter>;
  surveyedAt?: InputMaybe<DateFieldComparison>;
  user?: InputMaybe<SurveyRecordFilterUserFilter>;
  weatherCondition?: InputMaybe<SurveyRecordFilterWeatherConditionFilter>;
};

export type SurveyRecordFilterContractFilter = {
  and?: InputMaybe<Array<SurveyRecordFilterContractFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordFilterContractFilter>>;
  realEndDate?: InputMaybe<StringFieldComparison>;
  realStartDate?: InputMaybe<StringFieldComparison>;
  road?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type SurveyRecordFilterJunctionFilter = {
  and?: InputMaybe<Array<SurveyRecordFilterJunctionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordFilterJunctionFilter>>;
  order?: InputMaybe<NumberFieldComparison>;
  pk?: InputMaybe<StringFieldComparison>;
};

export type SurveyRecordFilterRoadFilter = {
  and?: InputMaybe<Array<SurveyRecordFilterRoadFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordFilterRoadFilter>>;
};

export type SurveyRecordFilterSurveyRecordPhotoFilter = {
  and?: InputMaybe<Array<SurveyRecordFilterSurveyRecordPhotoFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordFilterSurveyRecordPhotoFilter>>;
  path?: InputMaybe<StringFieldComparison>;
  thumbnail?: InputMaybe<StringFieldComparison>;
};

export type SurveyRecordFilterSurveyRecordVerificationFilter = {
  and?: InputMaybe<Array<SurveyRecordFilterSurveyRecordVerificationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SurveyRecordFilterSurveyRecordVerificationFilter>>;
};

export type SurveyRecordFilterUserFilter = {
  and?: InputMaybe<Array<SurveyRecordFilterUserFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordFilterUserFilter>>;
};

export type SurveyRecordFilterWeatherConditionFilter = {
  and?: InputMaybe<Array<SurveyRecordFilterWeatherConditionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordFilterWeatherConditionFilter>>;
};

export type SurveyRecordMaxAggregate = {
  __typename?: 'SurveyRecordMaxAggregate';
  description?: Maybe<Scalars['String']>;
  endPk?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  participants?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  startPk?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  surveyedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyRecordMinAggregate = {
  __typename?: 'SurveyRecordMinAggregate';
  description?: Maybe<Scalars['String']>;
  endPk?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  participants?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  startPk?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  surveyedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyRecordOffsetConnection = {
  __typename?: 'SurveyRecordOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SurveyRecord>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type SurveyRecordPhoto = {
  __typename?: 'SurveyRecordPhoto';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  path: Scalars['String'];
  surveyRecord: SurveyRecord;
  thumbnail: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SurveyRecordPhotoAggregateGroupBy = {
  __typename?: 'SurveyRecordPhotoAggregateGroupBy';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type SurveyRecordPhotoAvgAggregate = {
  __typename?: 'SurveyRecordPhotoAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SurveyRecordPhotoConnection = {
  __typename?: 'SurveyRecordPhotoConnection';
  /** Array of nodes. */
  nodes: Array<SurveyRecordPhoto>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type SurveyRecordPhotoCountAggregate = {
  __typename?: 'SurveyRecordPhotoCountAggregate';
  description?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['Int']>;
};

export type SurveyRecordPhotoDeleteFilter = {
  and?: InputMaybe<Array<SurveyRecordPhotoDeleteFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordPhotoDeleteFilter>>;
  path?: InputMaybe<StringFieldComparison>;
  thumbnail?: InputMaybe<StringFieldComparison>;
};

export type SurveyRecordPhotoDeleteResponse = {
  __typename?: 'SurveyRecordPhotoDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyRecordPhotoFilter = {
  and?: InputMaybe<Array<SurveyRecordPhotoFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordPhotoFilter>>;
  path?: InputMaybe<StringFieldComparison>;
  surveyRecord?: InputMaybe<SurveyRecordPhotoFilterSurveyRecordFilter>;
  thumbnail?: InputMaybe<StringFieldComparison>;
};

export type SurveyRecordPhotoFilterSurveyRecordFilter = {
  and?: InputMaybe<Array<SurveyRecordPhotoFilterSurveyRecordFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endPk?: InputMaybe<StringFieldComparison>;
  hasCorrectiveRequests?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isPublished?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordPhotoFilterSurveyRecordFilter>>;
  participants?: InputMaybe<StringFieldComparison>;
  sequence?: InputMaybe<NumberFieldComparison>;
  startPk?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  surveyedAt?: InputMaybe<DateFieldComparison>;
};

export type SurveyRecordPhotoMaxAggregate = {
  __typename?: 'SurveyRecordPhotoMaxAggregate';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type SurveyRecordPhotoMinAggregate = {
  __typename?: 'SurveyRecordPhotoMinAggregate';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type SurveyRecordPhotoSort = {
  direction: SortDirection;
  field: SurveyRecordPhotoSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SurveyRecordPhotoSortFields {
  Description = 'description',
  Id = 'id',
  Name = 'name',
  Path = 'path',
  Thumbnail = 'thumbnail'
}

export type SurveyRecordPhotoSumAggregate = {
  __typename?: 'SurveyRecordPhotoSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SurveyRecordPhotoUpdateFilter = {
  and?: InputMaybe<Array<SurveyRecordPhotoUpdateFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordPhotoUpdateFilter>>;
  path?: InputMaybe<StringFieldComparison>;
  thumbnail?: InputMaybe<StringFieldComparison>;
};

export type SurveyRecordSort = {
  direction: SortDirection;
  field: SurveyRecordSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SurveyRecordSortFields {
  Description = 'description',
  EndPk = 'endPk',
  HasCorrectiveRequests = 'hasCorrectiveRequests',
  Id = 'id',
  IsPublished = 'isPublished',
  Name = 'name',
  Observations = 'observations',
  Participants = 'participants',
  Sequence = 'sequence',
  StartPk = 'startPk',
  State = 'state',
  SurveyedAt = 'surveyedAt'
}

export enum SurveyRecordStates {
  Issued = 'ISSUED',
  Preparation = 'PREPARATION'
}

export type SurveyRecordSumAggregate = {
  __typename?: 'SurveyRecordSumAggregate';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

export type SurveyRecordUpdateFilter = {
  and?: InputMaybe<Array<SurveyRecordUpdateFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endPk?: InputMaybe<StringFieldComparison>;
  hasCorrectiveRequests?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isPublished?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordUpdateFilter>>;
  participants?: InputMaybe<StringFieldComparison>;
  sequence?: InputMaybe<NumberFieldComparison>;
  startPk?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  surveyedAt?: InputMaybe<DateFieldComparison>;
};

export type SurveyRecordVerification = {
  __typename?: 'SurveyRecordVerification';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  surveyRecord: SurveyRecord;
  surveyRecordVerificationClassification?: Maybe<SurveyRecordVerificationClassification>;
  surveyRecordVerificationState?: Maybe<SurveyRecordVerificationState>;
  updatedAt: Scalars['DateTime'];
  verification: Verification;
};

export type SurveyRecordVerificationAggregateGroupBy = {
  __typename?: 'SurveyRecordVerificationAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type SurveyRecordVerificationAvgAggregate = {
  __typename?: 'SurveyRecordVerificationAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SurveyRecordVerificationClassification = {
  __typename?: 'SurveyRecordVerificationClassification';
  abbreviation: Scalars['String'];
  concession: Concession;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SurveyRecordVerificationClassificationAggregateGroupBy = {
  __typename?: 'SurveyRecordVerificationClassificationAggregateGroupBy';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SurveyRecordVerificationClassificationAvgAggregate = {
  __typename?: 'SurveyRecordVerificationClassificationAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SurveyRecordVerificationClassificationConnection = {
  __typename?: 'SurveyRecordVerificationClassificationConnection';
  /** Array of nodes. */
  nodes: Array<SurveyRecordVerificationClassification>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type SurveyRecordVerificationClassificationCountAggregate = {
  __typename?: 'SurveyRecordVerificationClassificationCountAggregate';
  abbreviation?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type SurveyRecordVerificationClassificationDeleteFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<SurveyRecordVerificationClassificationDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordVerificationClassificationDeleteFilter>>;
};

export type SurveyRecordVerificationClassificationDeleteResponse = {
  __typename?: 'SurveyRecordVerificationClassificationDeleteResponse';
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyRecordVerificationClassificationFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<SurveyRecordVerificationClassificationFilter>>;
  concession?: InputMaybe<SurveyRecordVerificationClassificationFilterConcessionFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordVerificationClassificationFilter>>;
};

export type SurveyRecordVerificationClassificationFilterConcessionFilter = {
  and?: InputMaybe<Array<SurveyRecordVerificationClassificationFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordVerificationClassificationFilterConcessionFilter>>;
};

export type SurveyRecordVerificationClassificationMaxAggregate = {
  __typename?: 'SurveyRecordVerificationClassificationMaxAggregate';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SurveyRecordVerificationClassificationMinAggregate = {
  __typename?: 'SurveyRecordVerificationClassificationMinAggregate';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SurveyRecordVerificationClassificationSort = {
  direction: SortDirection;
  field: SurveyRecordVerificationClassificationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SurveyRecordVerificationClassificationSortFields {
  Abbreviation = 'abbreviation',
  Id = 'id',
  Name = 'name'
}

export type SurveyRecordVerificationClassificationSumAggregate = {
  __typename?: 'SurveyRecordVerificationClassificationSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SurveyRecordVerificationClassificationUpdateFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<SurveyRecordVerificationClassificationUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordVerificationClassificationUpdateFilter>>;
};

export type SurveyRecordVerificationConnection = {
  __typename?: 'SurveyRecordVerificationConnection';
  /** Array of nodes. */
  nodes: Array<SurveyRecordVerification>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type SurveyRecordVerificationCountAggregate = {
  __typename?: 'SurveyRecordVerificationCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type SurveyRecordVerificationDeleteFilter = {
  and?: InputMaybe<Array<SurveyRecordVerificationDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SurveyRecordVerificationDeleteFilter>>;
};

export type SurveyRecordVerificationDeleteResponse = {
  __typename?: 'SurveyRecordVerificationDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyRecordVerificationFilter = {
  and?: InputMaybe<Array<SurveyRecordVerificationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SurveyRecordVerificationFilter>>;
  surveyRecord?: InputMaybe<SurveyRecordVerificationFilterSurveyRecordFilter>;
  surveyRecordVerificationClassification?: InputMaybe<SurveyRecordVerificationFilterSurveyRecordVerificationClassificationFilter>;
  surveyRecordVerificationState?: InputMaybe<SurveyRecordVerificationFilterSurveyRecordVerificationStateFilter>;
  verification?: InputMaybe<SurveyRecordVerificationFilterVerificationFilter>;
};

export type SurveyRecordVerificationFilterSurveyRecordFilter = {
  and?: InputMaybe<Array<SurveyRecordVerificationFilterSurveyRecordFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endPk?: InputMaybe<StringFieldComparison>;
  hasCorrectiveRequests?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isPublished?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordVerificationFilterSurveyRecordFilter>>;
  participants?: InputMaybe<StringFieldComparison>;
  sequence?: InputMaybe<NumberFieldComparison>;
  startPk?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  surveyedAt?: InputMaybe<DateFieldComparison>;
};

export type SurveyRecordVerificationFilterSurveyRecordVerificationClassificationFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<SurveyRecordVerificationFilterSurveyRecordVerificationClassificationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordVerificationFilterSurveyRecordVerificationClassificationFilter>>;
};

export type SurveyRecordVerificationFilterSurveyRecordVerificationStateFilter = {
  and?: InputMaybe<Array<SurveyRecordVerificationFilterSurveyRecordVerificationStateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordVerificationFilterSurveyRecordVerificationStateFilter>>;
  stateType?: InputMaybe<StringFieldComparison>;
};

export type SurveyRecordVerificationFilterVerificationFilter = {
  and?: InputMaybe<Array<SurveyRecordVerificationFilterVerificationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordVerificationFilterVerificationFilter>>;
};

export type SurveyRecordVerificationMaxAggregate = {
  __typename?: 'SurveyRecordVerificationMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type SurveyRecordVerificationMinAggregate = {
  __typename?: 'SurveyRecordVerificationMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type SurveyRecordVerificationSort = {
  direction: SortDirection;
  field: SurveyRecordVerificationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SurveyRecordVerificationSortFields {
  Id = 'id'
}

export type SurveyRecordVerificationState = {
  __typename?: 'SurveyRecordVerificationState';
  concession: Concession;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  stateType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SurveyRecordVerificationStateAggregateGroupBy = {
  __typename?: 'SurveyRecordVerificationStateAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  stateType?: Maybe<Scalars['String']>;
};

export type SurveyRecordVerificationStateAvgAggregate = {
  __typename?: 'SurveyRecordVerificationStateAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SurveyRecordVerificationStateConnection = {
  __typename?: 'SurveyRecordVerificationStateConnection';
  /** Array of nodes. */
  nodes: Array<SurveyRecordVerificationState>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type SurveyRecordVerificationStateCountAggregate = {
  __typename?: 'SurveyRecordVerificationStateCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  stateType?: Maybe<Scalars['Int']>;
};

export type SurveyRecordVerificationStateDeleteFilter = {
  and?: InputMaybe<Array<SurveyRecordVerificationStateDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordVerificationStateDeleteFilter>>;
  stateType?: InputMaybe<StringFieldComparison>;
};

export type SurveyRecordVerificationStateDeleteResponse = {
  __typename?: 'SurveyRecordVerificationStateDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  stateType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyRecordVerificationStateFilter = {
  and?: InputMaybe<Array<SurveyRecordVerificationStateFilter>>;
  concession?: InputMaybe<SurveyRecordVerificationStateFilterConcessionFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordVerificationStateFilter>>;
  stateType?: InputMaybe<StringFieldComparison>;
};

export type SurveyRecordVerificationStateFilterConcessionFilter = {
  and?: InputMaybe<Array<SurveyRecordVerificationStateFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordVerificationStateFilterConcessionFilter>>;
};

export type SurveyRecordVerificationStateMaxAggregate = {
  __typename?: 'SurveyRecordVerificationStateMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  stateType?: Maybe<Scalars['String']>;
};

export type SurveyRecordVerificationStateMinAggregate = {
  __typename?: 'SurveyRecordVerificationStateMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  stateType?: Maybe<Scalars['String']>;
};

export type SurveyRecordVerificationStateSort = {
  direction: SortDirection;
  field: SurveyRecordVerificationStateSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SurveyRecordVerificationStateSortFields {
  Id = 'id',
  Name = 'name',
  StateType = 'stateType'
}

export type SurveyRecordVerificationStateSumAggregate = {
  __typename?: 'SurveyRecordVerificationStateSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SurveyRecordVerificationStateUpdateFilter = {
  and?: InputMaybe<Array<SurveyRecordVerificationStateUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SurveyRecordVerificationStateUpdateFilter>>;
  stateType?: InputMaybe<StringFieldComparison>;
};

export type SurveyRecordVerificationSumAggregate = {
  __typename?: 'SurveyRecordVerificationSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SurveyRecordVerificationUpdateFilter = {
  and?: InputMaybe<Array<SurveyRecordVerificationUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SurveyRecordVerificationUpdateFilter>>;
};

export type Task = {
  __typename?: 'Task';
  controlCenterObservations?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  direction: Direction;
  endAt: Scalars['DateTime'];
  endJunction: Junction;
  endPk: Scalars['String'];
  executingAuthority: Scalars['String'];
  executingAuthorityContact: Scalars['String'];
  executingOrganization?: Maybe<Scalars['String']>;
  extension: Scalars['Float'];
  id: Scalars['ID'];
  incident?: Maybe<Scalars['String']>;
  intervention: Intervention;
  interventionLocation: InterventionLocation;
  isoWeek?: Maybe<Scalars['String']>;
  layout: Layout;
  name: Scalars['String'];
  observations?: Maybe<Scalars['String']>;
  overlaps?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
  restriction: Restriction;
  road: Road;
  sequence: Scalars['Float'];
  signalingAuthority: Scalars['String'];
  signalingAuthorityContact: Scalars['String'];
  startAt: Scalars['DateTime'];
  startJunction: Junction;
  startPk: Scalars['String'];
  state: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TaskAggregateGroupBy = {
  __typename?: 'TaskAggregateGroupBy';
  endAt?: Maybe<Scalars['DateTime']>;
  endPk?: Maybe<Scalars['String']>;
  executingAuthority?: Maybe<Scalars['String']>;
  executingAuthorityContact?: Maybe<Scalars['String']>;
  executingOrganization?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  isoWeek?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  overlaps?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  signalingAuthority?: Maybe<Scalars['String']>;
  signalingAuthorityContact?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  startPk?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type TaskAvgAggregate = {
  __typename?: 'TaskAvgAggregate';
  extension?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

export type TaskCountAggregate = {
  __typename?: 'TaskCountAggregate';
  endAt?: Maybe<Scalars['Int']>;
  endPk?: Maybe<Scalars['Int']>;
  executingAuthority?: Maybe<Scalars['Int']>;
  executingAuthorityContact?: Maybe<Scalars['Int']>;
  executingOrganization?: Maybe<Scalars['Int']>;
  extension?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isoWeek?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  observations?: Maybe<Scalars['Int']>;
  overlaps?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  signalingAuthority?: Maybe<Scalars['Int']>;
  signalingAuthorityContact?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['Int']>;
  startPk?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
};

export type TaskDeleteFilter = {
  and?: InputMaybe<Array<TaskDeleteFilter>>;
  endAt?: InputMaybe<DateFieldComparison>;
  endPk?: InputMaybe<StringFieldComparison>;
  executingAuthority?: InputMaybe<StringFieldComparison>;
  executingAuthorityContact?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  extension?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isoWeek?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<TaskDeleteFilter>>;
  overlaps?: InputMaybe<StringFieldComparison>;
  reason?: InputMaybe<StringFieldComparison>;
  sequence?: InputMaybe<NumberFieldComparison>;
  signalingAuthority?: InputMaybe<StringFieldComparison>;
  signalingAuthorityContact?: InputMaybe<StringFieldComparison>;
  startAt?: InputMaybe<DateFieldComparison>;
  startPk?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
};

export type TaskDeleteResponse = {
  __typename?: 'TaskDeleteResponse';
  controlCenterObservations?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
  endPk?: Maybe<Scalars['String']>;
  executingAuthority?: Maybe<Scalars['String']>;
  executingAuthorityContact?: Maybe<Scalars['String']>;
  executingOrganization?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  incident?: Maybe<Scalars['String']>;
  isoWeek?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  overlaps?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  signalingAuthority?: Maybe<Scalars['String']>;
  signalingAuthorityContact?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  startPk?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TaskFilter = {
  and?: InputMaybe<Array<TaskFilter>>;
  direction?: InputMaybe<TaskFilterDirectionFilter>;
  endAt?: InputMaybe<DateFieldComparison>;
  endJunction?: InputMaybe<TaskFilterJunctionFilter>;
  endPk?: InputMaybe<StringFieldComparison>;
  executingAuthority?: InputMaybe<StringFieldComparison>;
  executingAuthorityContact?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  extension?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  intervention?: InputMaybe<TaskFilterInterventionFilter>;
  interventionLocation?: InputMaybe<TaskFilterInterventionLocationFilter>;
  isoWeek?: InputMaybe<StringFieldComparison>;
  layout?: InputMaybe<TaskFilterLayoutFilter>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<TaskFilter>>;
  overlaps?: InputMaybe<StringFieldComparison>;
  reason?: InputMaybe<StringFieldComparison>;
  restriction?: InputMaybe<TaskFilterRestrictionFilter>;
  road?: InputMaybe<TaskFilterRoadFilter>;
  sequence?: InputMaybe<NumberFieldComparison>;
  signalingAuthority?: InputMaybe<StringFieldComparison>;
  signalingAuthorityContact?: InputMaybe<StringFieldComparison>;
  startAt?: InputMaybe<DateFieldComparison>;
  startJunction?: InputMaybe<TaskFilterJunctionFilter>;
  startPk?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
};

export type TaskFilterDirectionFilter = {
  and?: InputMaybe<Array<TaskFilterDirectionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<TaskFilterDirectionFilter>>;
};

export type TaskFilterInterventionFilter = {
  and?: InputMaybe<Array<TaskFilterInterventionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<TaskFilterInterventionFilter>>;
  state?: InputMaybe<StringFieldComparison>;
  week?: InputMaybe<NumberFieldComparison>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type TaskFilterInterventionLocationFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<TaskFilterInterventionLocationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  locationType?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<TaskFilterInterventionLocationFilter>>;
};

export type TaskFilterJunctionFilter = {
  and?: InputMaybe<Array<TaskFilterJunctionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<TaskFilterJunctionFilter>>;
  order?: InputMaybe<NumberFieldComparison>;
  pk?: InputMaybe<StringFieldComparison>;
};

export type TaskFilterLayoutFilter = {
  and?: InputMaybe<Array<TaskFilterLayoutFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<TaskFilterLayoutFilter>>;
};

export type TaskFilterRestrictionFilter = {
  and?: InputMaybe<Array<TaskFilterRestrictionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<TaskFilterRestrictionFilter>>;
};

export type TaskFilterRoadFilter = {
  and?: InputMaybe<Array<TaskFilterRoadFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<TaskFilterRoadFilter>>;
};

export type TaskMaxAggregate = {
  __typename?: 'TaskMaxAggregate';
  endAt?: Maybe<Scalars['DateTime']>;
  endPk?: Maybe<Scalars['String']>;
  executingAuthority?: Maybe<Scalars['String']>;
  executingAuthorityContact?: Maybe<Scalars['String']>;
  executingOrganization?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  isoWeek?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  overlaps?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  signalingAuthority?: Maybe<Scalars['String']>;
  signalingAuthorityContact?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  startPk?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type TaskMinAggregate = {
  __typename?: 'TaskMinAggregate';
  endAt?: Maybe<Scalars['DateTime']>;
  endPk?: Maybe<Scalars['String']>;
  executingAuthority?: Maybe<Scalars['String']>;
  executingAuthorityContact?: Maybe<Scalars['String']>;
  executingOrganization?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  isoWeek?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  overlaps?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  signalingAuthority?: Maybe<Scalars['String']>;
  signalingAuthorityContact?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  startPk?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type TaskOffsetConnection = {
  __typename?: 'TaskOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Task>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type TaskSort = {
  direction: SortDirection;
  field: TaskSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum TaskSortFields {
  EndAt = 'endAt',
  EndPk = 'endPk',
  ExecutingAuthority = 'executingAuthority',
  ExecutingAuthorityContact = 'executingAuthorityContact',
  ExecutingOrganization = 'executingOrganization',
  Extension = 'extension',
  Id = 'id',
  IsoWeek = 'isoWeek',
  Name = 'name',
  Observations = 'observations',
  Overlaps = 'overlaps',
  Reason = 'reason',
  Sequence = 'sequence',
  SignalingAuthority = 'signalingAuthority',
  SignalingAuthorityContact = 'signalingAuthorityContact',
  StartAt = 'startAt',
  StartPk = 'startPk',
  State = 'state'
}

export enum TaskStates {
  Approved = 'APPROVED',
  Executed = 'EXECUTED',
  InExecution = 'IN_EXECUTION',
  NotExecuted = 'NOT_EXECUTED',
  Preparation = 'PREPARATION',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED'
}

export type TaskSumAggregate = {
  __typename?: 'TaskSumAggregate';
  extension?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

export type TaskUpdateFilter = {
  and?: InputMaybe<Array<TaskUpdateFilter>>;
  endAt?: InputMaybe<DateFieldComparison>;
  endPk?: InputMaybe<StringFieldComparison>;
  executingAuthority?: InputMaybe<StringFieldComparison>;
  executingAuthorityContact?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  extension?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isoWeek?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<TaskUpdateFilter>>;
  overlaps?: InputMaybe<StringFieldComparison>;
  reason?: InputMaybe<StringFieldComparison>;
  sequence?: InputMaybe<NumberFieldComparison>;
  signalingAuthority?: InputMaybe<StringFieldComparison>;
  signalingAuthorityContact?: InputMaybe<StringFieldComparison>;
  startAt?: InputMaybe<DateFieldComparison>;
  startPk?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
};

export type UpdateBadge = {
  company?: InputMaybe<Scalars['String']>;
  driver?: InputMaybe<Scalars['String']>;
  licensePlate?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<BadgeStates>;
};

export type UpdateChecklistItem = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  isOptional?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateConcessionOrganization = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateConcessionOrganizationAccreditation = {
  concessionOrganizationAccreditationState?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  observations?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateConcessionOrganizationAccreditationState = {
  backgroundColor?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateConcessionOrganizationAccreditationType = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateConcessionOrganizationDocument = {
  concessionOrganizationDocumentSubcategory?: InputMaybe<Scalars['ID']>;
  documentState?: InputMaybe<Scalars['ID']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateConcessionOrganizationDocumentCategory = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expirationRequired?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  observations?: InputMaybe<Scalars['String']>;
  policy?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateConcessionOrganizationDocumentUpdate = {
  expiresAt?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateConcessionOrganizationUser = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  role?: InputMaybe<ConcessionOrganizationUserRoles>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateContract = {
  amendedValue?: InputMaybe<Scalars['Float']>;
  billedValue?: InputMaybe<Scalars['Float']>;
  contractRoadsObservations?: InputMaybe<Scalars['String']>;
  contractState?: InputMaybe<Scalars['ID']>;
  contractType?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  interventionElement?: InputMaybe<Scalars['ID']>;
  interventionLocation?: InputMaybe<Scalars['ID']>;
  interventionType?: InputMaybe<Scalars['ID']>;
  originalValue?: InputMaybe<Scalars['Float']>;
  realEndDate?: InputMaybe<Scalars['DateTime']>;
  realStartDate?: InputMaybe<Scalars['DateTime']>;
  service?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateContractConcessionOrganization = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateContractConcessionOrganizationState = {
  backgroundColor?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateContractDocument = {
  contractDocumentSubcategory?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  documentState?: InputMaybe<Scalars['ID']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateContractDocumentCategory = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expirationRequired?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  observations?: InputMaybe<Scalars['String']>;
  policy?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateControlCenter = {
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdateDirection = {
  name: Scalars['String'];
};

export type UpdateIntervention = {
  state: InterventionStates;
  week?: InputMaybe<Scalars['Float']>;
  year?: InputMaybe<Scalars['Float']>;
};

export type UpdateLayout = {
  name: Scalars['String'];
};

export type UpdateManyBadgesInput = {
  /** Filter used to find fields to update */
  filter: BadgeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateBadge;
};

export type UpdateManyConcessionOrganizationAccreditationStatesInput = {
  /** Filter used to find fields to update */
  filter: ConcessionOrganizationAccreditationStateUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateConcessionOrganizationAccreditationState;
};

export type UpdateManyConcessionOrganizationAccreditationTypesInput = {
  /** Filter used to find fields to update */
  filter: ConcessionOrganizationAccreditationTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateConcessionOrganizationAccreditationType;
};

export type UpdateManyConcessionOrganizationAccreditationsInput = {
  /** Filter used to find fields to update */
  filter: ConcessionOrganizationAccreditationUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateConcessionOrganizationAccreditation;
};

export type UpdateManyConcessionOrganizationDocumentCategoriesInput = {
  /** Filter used to find fields to update */
  filter: ConcessionOrganizationDocumentCategoryUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateConcessionOrganizationDocumentCategory;
};

export type UpdateManyConcessionOrganizationDocumentsInput = {
  /** Filter used to find fields to update */
  filter: ConcessionOrganizationDocumentUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateConcessionOrganizationDocument;
};

export type UpdateManyConcessionOrganizationUsersInput = {
  /** Filter used to find fields to update */
  filter: ConcessionOrganizationUserUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateConcessionOrganizationUser;
};

export type UpdateManyConcessionOrganizationsInput = {
  /** Filter used to find fields to update */
  filter: ConcessionOrganizationUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateConcessionOrganization;
};

export type UpdateManyConcessionUsersInput = {
  /** Filter used to find fields to update */
  filter: ConcessionUserUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateConcessionUser;
};

export type UpdateManyConcessionsInput = {
  /** Filter used to find fields to update */
  filter: ConcessionUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateConcession;
};

export type UpdateManyContractConcessionOrganizationStatesInput = {
  /** Filter used to find fields to update */
  filter: ContractConcessionOrganizationStateUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateContractConcessionOrganizationState;
};

export type UpdateManyContractConcessionOrganizationsInput = {
  /** Filter used to find fields to update */
  filter: ContractConcessionOrganizationUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateContractConcessionOrganization;
};

export type UpdateManyContractDocumentCategoriesInput = {
  /** Filter used to find fields to update */
  filter: ContractDocumentCategoryUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateContractDocumentCategory;
};

export type UpdateManyContractDocumentsInput = {
  /** Filter used to find fields to update */
  filter: ContractDocumentUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateContractDocument;
};

export type UpdateManyContractRoadsInput = {
  /** Filter used to find fields to update */
  filter: ContractRoadUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateContractRoad;
};

export type UpdateManyContractStatesInput = {
  /** Filter used to find fields to update */
  filter: ContractStateUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateContractState;
};

export type UpdateManyContractTransitionsInput = {
  /** Filter used to find fields to update */
  filter: ContractTransitionUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateContractTransition;
};

export type UpdateManyContractTypesInput = {
  /** Filter used to find fields to update */
  filter: ContractTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateContractType;
};

export type UpdateManyContractUsersInput = {
  /** Filter used to find fields to update */
  filter: ContractUserUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateContractUser;
};

export type UpdateManyContractsInput = {
  /** Filter used to find fields to update */
  filter: ContractUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateContract;
};

export type UpdateManyControlCentersInput = {
  /** Filter used to find fields to update */
  filter: ControlCenterUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateControlCenter;
};

export type UpdateManyDirectionsInput = {
  /** Filter used to find fields to update */
  filter: DirectionUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateDirection;
};

export type UpdateManyInterventionElementsInput = {
  /** Filter used to find fields to update */
  filter: InterventionElementUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateInterventionElement;
};

export type UpdateManyInterventionLocationsInput = {
  /** Filter used to find fields to update */
  filter: InterventionLocationUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateInterventionLocation;
};

export type UpdateManyInterventionTypesInput = {
  /** Filter used to find fields to update */
  filter: InterventionTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateInterventionType;
};

export type UpdateManyInterventionsInput = {
  /** Filter used to find fields to update */
  filter: InterventionUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateIntervention;
};

export type UpdateManyJunctionsInput = {
  /** Filter used to find fields to update */
  filter: JunctionUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateJuntion;
};

export type UpdateManyLayoutsInput = {
  /** Filter used to find fields to update */
  filter: LayoutUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateLayout;
};

export type UpdateManyOrganizationTypesInput = {
  /** Filter used to find fields to update */
  filter: OrganizationTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateOrganizationType;
};

export type UpdateManyOrganizationsInput = {
  /** Filter used to find fields to update */
  filter: OrganizationUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateOrganization;
};

export type UpdateManyResponse = {
  __typename?: 'UpdateManyResponse';
  /** The number of records updated. */
  updatedCount: Scalars['Int'];
};

export type UpdateManyRestrictionsInput = {
  /** Filter used to find fields to update */
  filter: RestrictionUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateRestriction;
};

export type UpdateManyRoadsInput = {
  /** Filter used to find fields to update */
  filter: RoadUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateRoad;
};

export type UpdateManyServicesInput = {
  /** Filter used to find fields to update */
  filter: ServiceUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateService;
};

export type UpdateManySurveyRecordPhotosInput = {
  /** Filter used to find fields to update */
  filter: SurveyRecordPhotoUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSurveyRecordPhoto;
};

export type UpdateManySurveyRecordVerificationClassificationsInput = {
  /** Filter used to find fields to update */
  filter: SurveyRecordVerificationClassificationUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSurveyRecordVerificationClassification;
};

export type UpdateManySurveyRecordVerificationStatesInput = {
  /** Filter used to find fields to update */
  filter: SurveyRecordVerificationStateUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSurveyRecordVerificationState;
};

export type UpdateManySurveyRecordVerificationsInput = {
  /** Filter used to find fields to update */
  filter: SurveyRecordVerificationUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSurveyRecordVerification;
};

export type UpdateManySurveyRecordsInput = {
  /** Filter used to find fields to update */
  filter: SurveyRecordUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSurveyRecord;
};

export type UpdateManyTasksInput = {
  /** Filter used to find fields to update */
  filter: TaskUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateTask;
};

export type UpdateManyUsersInput = {
  /** Filter used to find fields to update */
  filter: UserUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateUser;
};

export type UpdateManyVerificationScopesInput = {
  /** Filter used to find fields to update */
  filter: VerificationScopeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateVerificationScope;
};

export type UpdateManyVerificationsInput = {
  /** Filter used to find fields to update */
  filter: VerificationUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateVerification;
};

export type UpdateManyWeatherConditionsInput = {
  /** Filter used to find fields to update */
  filter: WeatherConditionUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateWeatherCondition;
};

export type UpdateManyWorkPermitsInput = {
  /** Filter used to find fields to update */
  filter: WorkPermitUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateWorkPermit;
};

export type UpdateMe = {
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdateOneBadgeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateBadge;
};

export type UpdateOneChecklistItemInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateChecklistItem;
};

export type UpdateOneConcessionInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateConcession;
};

export type UpdateOneConcessionOrganizationAccreditationInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateConcessionOrganizationAccreditation;
};

export type UpdateOneConcessionOrganizationAccreditationStateInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateConcessionOrganizationAccreditationState;
};

export type UpdateOneConcessionOrganizationAccreditationTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateConcessionOrganizationAccreditationType;
};

export type UpdateOneConcessionOrganizationDocumentCategoryInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateConcessionOrganizationDocumentCategory;
};

export type UpdateOneConcessionOrganizationDocumentInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateConcessionOrganizationDocument;
};

export type UpdateOneConcessionOrganizationInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateConcessionOrganization;
};

export type UpdateOneConcessionOrganizationUserInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateConcessionOrganizationUser;
};

export type UpdateOneConcessionUserInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateConcessionUser;
};

export type UpdateOneContractConcessionOrganizationInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateContractConcessionOrganization;
};

export type UpdateOneContractConcessionOrganizationStateInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateContractConcessionOrganizationState;
};

export type UpdateOneContractDocumentCategoryInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateContractDocumentCategory;
};

export type UpdateOneContractDocumentInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateContractDocument;
};

export type UpdateOneContractInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateContract;
};

export type UpdateOneContractRoadInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateContractRoad;
};

export type UpdateOneContractStateInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateContractState;
};

export type UpdateOneContractTransitionInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateContractTransition;
};

export type UpdateOneContractTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateContractType;
};

export type UpdateOneContractUserInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateContractUser;
};

export type UpdateOneControlCenterInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateControlCenter;
};

export type UpdateOneDirectionInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateDirection;
};

export type UpdateOneInterventionElementInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateInterventionElement;
};

export type UpdateOneInterventionInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateIntervention;
};

export type UpdateOneInterventionLocationInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateInterventionLocation;
};

export type UpdateOneInterventionTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateInterventionType;
};

export type UpdateOneJunctionInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateJuntion;
};

export type UpdateOneLayoutInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateLayout;
};

export type UpdateOneOrganizationInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateOrganization;
};

export type UpdateOneOrganizationTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateOrganizationType;
};

export type UpdateOneRestrictionInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateRestriction;
};

export type UpdateOneRoadInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateRoad;
};

export type UpdateOneServiceInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateService;
};

export type UpdateOneSubcontractInput = {
  id: Scalars['ID'];
  update: UpdateSubcontract;
};

export type UpdateOneSurveyRecordInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateSurveyRecord;
};

export type UpdateOneSurveyRecordPhotoInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateSurveyRecordPhoto;
};

export type UpdateOneSurveyRecordVerificationClassificationInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateSurveyRecordVerificationClassification;
};

export type UpdateOneSurveyRecordVerificationInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateSurveyRecordVerification;
};

export type UpdateOneSurveyRecordVerificationStateInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateSurveyRecordVerificationState;
};

export type UpdateOneTaskInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateTask;
};

export type UpdateOneUserInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateUser;
};

export type UpdateOneVerificationInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateVerification;
};

export type UpdateOneVerificationScopeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateVerificationScope;
};

export type UpdateOneWeatherConditionInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateWeatherCondition;
};

export type UpdateOneWorkPermitInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateWorkPermit;
};

export type UpdateOwnConcesssionOrganizationDocument = {
  id: Scalars['ID'];
  update: UpdateConcessionOrganizationDocumentUpdate;
};

export type UpdateRoad = {
  name: Scalars['String'];
};

export type UpdateSubcontract = {
  contractConcessionOrganizationState: Scalars['ID'];
};

export type UpdateSurveyRecord = {
  description?: InputMaybe<Scalars['String']>;
  endJunction?: InputMaybe<Scalars['ID']>;
  endPk?: InputMaybe<Scalars['String']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  observations?: InputMaybe<Scalars['String']>;
  participants?: InputMaybe<Scalars['String']>;
  road?: InputMaybe<Scalars['ID']>;
  startJunction?: InputMaybe<Scalars['ID']>;
  startPk?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<SurveyRecordStates>;
  surveyedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<Scalars['ID']>;
  weatherCondition?: InputMaybe<Scalars['ID']>;
};

export type UpdateSurveyRecordPhoto = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateSurveyRecordVerification = {
  description?: InputMaybe<Scalars['String']>;
  surveyRecordVerificationClassification?: InputMaybe<Scalars['ID']>;
  surveyRecordVerificationState?: InputMaybe<Scalars['ID']>;
  verification?: InputMaybe<Scalars['ID']>;
};

export type UpdateSurveyRecordVerificationClassification = {
  abbreviation?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateSurveyRecordVerificationState = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  stateType?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateTask = {
  controlCenterObservations?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<Scalars['ID']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  endJunction?: InputMaybe<Scalars['ID']>;
  endPk?: InputMaybe<Scalars['String']>;
  executingAuthority?: InputMaybe<Scalars['String']>;
  executingAuthorityContact?: InputMaybe<Scalars['String']>;
  incident?: InputMaybe<Scalars['String']>;
  intervention?: InputMaybe<Scalars['ID']>;
  interventionLocation?: InputMaybe<Scalars['ID']>;
  layout?: InputMaybe<Scalars['String']>;
  observations?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  restriction?: InputMaybe<Scalars['ID']>;
  road?: InputMaybe<Scalars['ID']>;
  signalingAuthority?: InputMaybe<Scalars['String']>;
  signalingAuthorityContact?: InputMaybe<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  startJunction?: InputMaybe<Scalars['ID']>;
  startPk?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<TaskStates>;
};

export type UpdateUser = {
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdateVerification = {
  name: Scalars['String'];
};

export type UpdateVerificationScope = {
  abbreviation?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateWorkPermit = {
  contract?: InputMaybe<Scalars['ID']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  observations?: InputMaybe<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  state?: InputMaybe<WorkPermitStates>;
};

export type User = {
  __typename?: 'User';
  concessionOrganizationUsers?: Maybe<Array<ConcessionOrganizationUser>>;
  concessionUsers?: Maybe<Array<ConcessionUser>>;
  contractUsers?: Maybe<Array<ContractUser>>;
  createdAt: Scalars['DateTime'];
  defaultConcession: Concession;
  email: Scalars['String'];
  id: Scalars['ID'];
  isSuperuser: Scalars['Boolean'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  surveyRecords?: Maybe<Array<SurveyRecord>>;
  updatedAt: Scalars['DateTime'];
};


export type UserConcessionOrganizationUsersArgs = {
  filter?: InputMaybe<ConcessionOrganizationUserFilter>;
  sorting?: InputMaybe<Array<ConcessionOrganizationUserSort>>;
};


export type UserConcessionUsersArgs = {
  filter?: InputMaybe<ConcessionUserFilter>;
  sorting?: InputMaybe<Array<ConcessionUserSort>>;
};


export type UserContractUsersArgs = {
  filter?: InputMaybe<ContractUserFilter>;
  sorting?: InputMaybe<Array<ContractUserSort>>;
};


export type UserSurveyRecordsArgs = {
  filter?: InputMaybe<SurveyRecordFilter>;
  sorting?: InputMaybe<Array<SurveyRecordSort>>;
};

export type UserAggregateGroupBy = {
  __typename?: 'UserAggregateGroupBy';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserAvgAggregate = {
  __typename?: 'UserAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** Array of nodes. */
  nodes: Array<User>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  email?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type UserDeleteFilter = {
  and?: InputMaybe<Array<UserDeleteFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserDeleteFilter>>;
};

export type UserDeleteResponse = {
  __typename?: 'UserDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isSuperuser?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserFilter = {
  and?: InputMaybe<Array<UserFilter>>;
  concessionOrganizationUser?: InputMaybe<UserFilterConcessionOrganizationUserFilter>;
  concessionUser?: InputMaybe<UserFilterConcessionUserFilter>;
  contractUser?: InputMaybe<UserFilterContractUserFilter>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserFilter>>;
  surveyRecords?: InputMaybe<UserFilterSurveyRecordFilter>;
};

export type UserFilterConcessionOrganizationUserFilter = {
  and?: InputMaybe<Array<UserFilterConcessionOrganizationUserFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<UserFilterConcessionOrganizationUserFilter>>;
  role?: InputMaybe<ConcessionOrganizationUserRolesFilterComparison>;
};

export type UserFilterConcessionUserFilter = {
  and?: InputMaybe<Array<UserFilterConcessionUserFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<UserFilterConcessionUserFilter>>;
  role?: InputMaybe<ConcessionUserRolesFilterComparison>;
};

export type UserFilterContractUserFilter = {
  and?: InputMaybe<Array<UserFilterContractUserFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<UserFilterContractUserFilter>>;
};

export type UserFilterSurveyRecordFilter = {
  and?: InputMaybe<Array<UserFilterSurveyRecordFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endPk?: InputMaybe<StringFieldComparison>;
  hasCorrectiveRequests?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isPublished?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserFilterSurveyRecordFilter>>;
  participants?: InputMaybe<StringFieldComparison>;
  sequence?: InputMaybe<NumberFieldComparison>;
  startPk?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  surveyedAt?: InputMaybe<DateFieldComparison>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserOffsetConnection = {
  __typename?: 'UserOffsetConnection';
  /** Array of nodes. */
  nodes: Array<User>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type UserSort = {
  direction: SortDirection;
  field: UserSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserSortFields {
  Email = 'email',
  Id = 'id',
  Name = 'name'
}

export type UserSumAggregate = {
  __typename?: 'UserSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UserUpdateFilter = {
  and?: InputMaybe<Array<UserUpdateFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserUpdateFilter>>;
};

export type ValidateTaskImportInput = {
  intervention: Scalars['ID'];
  path: Scalars['Upload'];
};

export type Verification = {
  __typename?: 'Verification';
  concession: Concession;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  surveyRecordVerifications: Array<SurveyRecordVerification>;
  updatedAt: Scalars['DateTime'];
  verificationScope: VerificationScope;
};


export type VerificationSurveyRecordVerificationsArgs = {
  filter?: InputMaybe<SurveyRecordVerificationFilter>;
  sorting?: InputMaybe<Array<SurveyRecordVerificationSort>>;
};

export type VerificationAggregateGroupBy = {
  __typename?: 'VerificationAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type VerificationAvgAggregate = {
  __typename?: 'VerificationAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type VerificationConnection = {
  __typename?: 'VerificationConnection';
  /** Array of nodes. */
  nodes: Array<Verification>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type VerificationCountAggregate = {
  __typename?: 'VerificationCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type VerificationDeleteFilter = {
  and?: InputMaybe<Array<VerificationDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<VerificationDeleteFilter>>;
};

export type VerificationDeleteResponse = {
  __typename?: 'VerificationDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VerificationFilter = {
  and?: InputMaybe<Array<VerificationFilter>>;
  concession?: InputMaybe<VerificationFilterConcessionFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<VerificationFilter>>;
  surveyRecordVerification?: InputMaybe<VerificationFilterSurveyRecordVerificationFilter>;
  verificationScope?: InputMaybe<VerificationFilterVerificationScopeFilter>;
};

export type VerificationFilterConcessionFilter = {
  and?: InputMaybe<Array<VerificationFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<VerificationFilterConcessionFilter>>;
};

export type VerificationFilterSurveyRecordVerificationFilter = {
  and?: InputMaybe<Array<VerificationFilterSurveyRecordVerificationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<VerificationFilterSurveyRecordVerificationFilter>>;
};

export type VerificationFilterVerificationScopeFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<VerificationFilterVerificationScopeFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<VerificationFilterVerificationScopeFilter>>;
};

export type VerificationMaxAggregate = {
  __typename?: 'VerificationMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type VerificationMinAggregate = {
  __typename?: 'VerificationMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type VerificationScope = {
  __typename?: 'VerificationScope';
  abbreviation: Scalars['String'];
  concession: Concession;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  verifications: Array<Verification>;
};


export type VerificationScopeVerificationsArgs = {
  filter?: InputMaybe<VerificationFilter>;
  sorting?: InputMaybe<Array<VerificationSort>>;
};

export type VerificationScopeAggregateGroupBy = {
  __typename?: 'VerificationScopeAggregateGroupBy';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type VerificationScopeAvgAggregate = {
  __typename?: 'VerificationScopeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type VerificationScopeConnection = {
  __typename?: 'VerificationScopeConnection';
  /** Array of nodes. */
  nodes: Array<VerificationScope>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type VerificationScopeCountAggregate = {
  __typename?: 'VerificationScopeCountAggregate';
  abbreviation?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type VerificationScopeDeleteFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<VerificationScopeDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<VerificationScopeDeleteFilter>>;
};

export type VerificationScopeDeleteResponse = {
  __typename?: 'VerificationScopeDeleteResponse';
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VerificationScopeFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<VerificationScopeFilter>>;
  concession?: InputMaybe<VerificationScopeFilterConcessionFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<VerificationScopeFilter>>;
  verification?: InputMaybe<VerificationScopeFilterVerificationFilter>;
};

export type VerificationScopeFilterConcessionFilter = {
  and?: InputMaybe<Array<VerificationScopeFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<VerificationScopeFilterConcessionFilter>>;
};

export type VerificationScopeFilterVerificationFilter = {
  and?: InputMaybe<Array<VerificationScopeFilterVerificationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<VerificationScopeFilterVerificationFilter>>;
};

export type VerificationScopeMaxAggregate = {
  __typename?: 'VerificationScopeMaxAggregate';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type VerificationScopeMinAggregate = {
  __typename?: 'VerificationScopeMinAggregate';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type VerificationScopeSort = {
  direction: SortDirection;
  field: VerificationScopeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum VerificationScopeSortFields {
  Abbreviation = 'abbreviation',
  Id = 'id',
  Name = 'name'
}

export type VerificationScopeSumAggregate = {
  __typename?: 'VerificationScopeSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type VerificationScopeUpdateFilter = {
  abbreviation?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<VerificationScopeUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<VerificationScopeUpdateFilter>>;
};

export type VerificationSort = {
  direction: SortDirection;
  field: VerificationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum VerificationSortFields {
  Id = 'id',
  Name = 'name'
}

export type VerificationSumAggregate = {
  __typename?: 'VerificationSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type VerificationUpdateFilter = {
  and?: InputMaybe<Array<VerificationUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<VerificationUpdateFilter>>;
};

export type WeatherCondition = {
  __typename?: 'WeatherCondition';
  concession: Concession;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  surveyRecords: Array<SurveyRecord>;
  updatedAt: Scalars['DateTime'];
};


export type WeatherConditionSurveyRecordsArgs = {
  filter?: InputMaybe<SurveyRecordFilter>;
  sorting?: InputMaybe<Array<SurveyRecordSort>>;
};

export type WeatherConditionAggregateGroupBy = {
  __typename?: 'WeatherConditionAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type WeatherConditionAvgAggregate = {
  __typename?: 'WeatherConditionAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type WeatherConditionConnection = {
  __typename?: 'WeatherConditionConnection';
  /** Array of nodes. */
  nodes: Array<WeatherCondition>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type WeatherConditionCountAggregate = {
  __typename?: 'WeatherConditionCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type WeatherConditionDeleteFilter = {
  and?: InputMaybe<Array<WeatherConditionDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WeatherConditionDeleteFilter>>;
};

export type WeatherConditionDeleteResponse = {
  __typename?: 'WeatherConditionDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WeatherConditionFilter = {
  and?: InputMaybe<Array<WeatherConditionFilter>>;
  concession?: InputMaybe<WeatherConditionFilterConcessionFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WeatherConditionFilter>>;
  surveyRecords?: InputMaybe<WeatherConditionFilterSurveyRecordFilter>;
};

export type WeatherConditionFilterConcessionFilter = {
  and?: InputMaybe<Array<WeatherConditionFilterConcessionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WeatherConditionFilterConcessionFilter>>;
};

export type WeatherConditionFilterSurveyRecordFilter = {
  and?: InputMaybe<Array<WeatherConditionFilterSurveyRecordFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endPk?: InputMaybe<StringFieldComparison>;
  hasCorrectiveRequests?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isPublished?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WeatherConditionFilterSurveyRecordFilter>>;
  participants?: InputMaybe<StringFieldComparison>;
  sequence?: InputMaybe<NumberFieldComparison>;
  startPk?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  surveyedAt?: InputMaybe<DateFieldComparison>;
};

export type WeatherConditionMaxAggregate = {
  __typename?: 'WeatherConditionMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type WeatherConditionMinAggregate = {
  __typename?: 'WeatherConditionMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type WeatherConditionSort = {
  direction: SortDirection;
  field: WeatherConditionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WeatherConditionSortFields {
  Id = 'id',
  Name = 'name'
}

export type WeatherConditionSumAggregate = {
  __typename?: 'WeatherConditionSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type WeatherConditionUpdateFilter = {
  and?: InputMaybe<Array<WeatherConditionUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WeatherConditionUpdateFilter>>;
};

export type WorkPermit = {
  __typename?: 'WorkPermit';
  badges?: Maybe<Array<Badge>>;
  checklist?: Maybe<Checklist>;
  contract: Contract;
  createdAt: Scalars['DateTime'];
  endAt: Scalars['String'];
  id: Scalars['ID'];
  interventions?: Maybe<Array<Intervention>>;
  isPublished: Scalars['Boolean'];
  name: Scalars['String'];
  observations?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['String']>;
  startAt: Scalars['String'];
  state: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type WorkPermitBadgesArgs = {
  filter?: InputMaybe<BadgeFilter>;
  sorting?: InputMaybe<Array<BadgeSort>>;
};


export type WorkPermitInterventionsArgs = {
  filter?: InputMaybe<InterventionFilter>;
  sorting?: InputMaybe<Array<InterventionSort>>;
};

export type WorkPermitAggregateGroupBy = {
  __typename?: 'WorkPermitAggregateGroupBy';
  endAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type WorkPermitAvgAggregate = {
  __typename?: 'WorkPermitAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type WorkPermitCountAggregate = {
  __typename?: 'WorkPermitCountAggregate';
  endAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  publishedAt?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
};

export type WorkPermitDeleteFilter = {
  and?: InputMaybe<Array<WorkPermitDeleteFilter>>;
  endAt?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isPublished?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WorkPermitDeleteFilter>>;
  publishedAt?: InputMaybe<StringFieldComparison>;
  startAt?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
};

export type WorkPermitDeleteResponse = {
  __typename?: 'WorkPermitDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkPermitFilter = {
  and?: InputMaybe<Array<WorkPermitFilter>>;
  badges?: InputMaybe<WorkPermitFilterBadgeFilter>;
  checklist?: InputMaybe<WorkPermitFilterChecklistFilter>;
  contract?: InputMaybe<WorkPermitFilterContractFilter>;
  endAt?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  interventions?: InputMaybe<WorkPermitFilterInterventionFilter>;
  isPublished?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WorkPermitFilter>>;
  publishedAt?: InputMaybe<StringFieldComparison>;
  startAt?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
};

export type WorkPermitFilterBadgeFilter = {
  and?: InputMaybe<Array<WorkPermitFilterBadgeFilter>>;
  company?: InputMaybe<StringFieldComparison>;
  driver?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  licensePlate?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WorkPermitFilterBadgeFilter>>;
};

export type WorkPermitFilterChecklistFilter = {
  and?: InputMaybe<Array<WorkPermitFilterChecklistFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isActive?: InputMaybe<BooleanFieldComparison>;
  isCompleted?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WorkPermitFilterChecklistFilter>>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type WorkPermitFilterContractFilter = {
  and?: InputMaybe<Array<WorkPermitFilterContractFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<StringFieldComparison>;
  executingOrganization?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WorkPermitFilterContractFilter>>;
  realEndDate?: InputMaybe<StringFieldComparison>;
  realStartDate?: InputMaybe<StringFieldComparison>;
  road?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<StringFieldComparison>;
};

export type WorkPermitFilterInterventionFilter = {
  and?: InputMaybe<Array<WorkPermitFilterInterventionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WorkPermitFilterInterventionFilter>>;
  state?: InputMaybe<StringFieldComparison>;
  week?: InputMaybe<NumberFieldComparison>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type WorkPermitMaxAggregate = {
  __typename?: 'WorkPermitMaxAggregate';
  endAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type WorkPermitMinAggregate = {
  __typename?: 'WorkPermitMinAggregate';
  endAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type WorkPermitOffsetConnection = {
  __typename?: 'WorkPermitOffsetConnection';
  /** Array of nodes. */
  nodes: Array<WorkPermit>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type WorkPermitSort = {
  direction: SortDirection;
  field: WorkPermitSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WorkPermitSortFields {
  EndAt = 'endAt',
  Id = 'id',
  IsPublished = 'isPublished',
  Name = 'name',
  PublishedAt = 'publishedAt',
  StartAt = 'startAt',
  State = 'state'
}

export enum WorkPermitStates {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  Preparation = 'PREPARATION',
  Review = 'REVIEW',
  Submitted = 'SUBMITTED',
  Validated = 'VALIDATED'
}

export type WorkPermitSumAggregate = {
  __typename?: 'WorkPermitSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type WorkPermitUpdateFilter = {
  and?: InputMaybe<Array<WorkPermitUpdateFilter>>;
  endAt?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isPublished?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WorkPermitUpdateFilter>>;
  publishedAt?: InputMaybe<StringFieldComparison>;
  startAt?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
};

export type Workspace = {
  __typename?: 'Workspace';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: User;
  workspaceViews?: Maybe<Array<WorkspaceView>>;
};


export type WorkspaceWorkspaceViewsArgs = {
  filter?: InputMaybe<WorkspaceViewFilter>;
  sorting?: InputMaybe<Array<WorkspaceViewSort>>;
};

export type WorkspaceAggregateGroupBy = {
  __typename?: 'WorkspaceAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type WorkspaceAvgAggregate = {
  __typename?: 'WorkspaceAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type WorkspaceCountAggregate = {
  __typename?: 'WorkspaceCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type WorkspaceMaxAggregate = {
  __typename?: 'WorkspaceMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type WorkspaceMinAggregate = {
  __typename?: 'WorkspaceMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type WorkspaceSumAggregate = {
  __typename?: 'WorkspaceSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type WorkspaceView = {
  __typename?: 'WorkspaceView';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workspace: Workspace;
  workspaceViewColumns?: Maybe<Array<WorkspaceViewColumn>>;
};


export type WorkspaceViewWorkspaceViewColumnsArgs = {
  filter?: InputMaybe<WorkspaceViewColumnFilter>;
  sorting?: InputMaybe<Array<WorkspaceViewColumnSort>>;
};

export type WorkspaceViewAggregateGroupBy = {
  __typename?: 'WorkspaceViewAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type WorkspaceViewAvgAggregate = {
  __typename?: 'WorkspaceViewAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type WorkspaceViewColumn = {
  __typename?: 'WorkspaceViewColumn';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Float'];
  size: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  visible: Scalars['Boolean'];
};

export type WorkspaceViewColumnAggregateGroupBy = {
  __typename?: 'WorkspaceViewColumnAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type WorkspaceViewColumnAvgAggregate = {
  __typename?: 'WorkspaceViewColumnAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type WorkspaceViewColumnCountAggregate = {
  __typename?: 'WorkspaceViewColumnCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type WorkspaceViewColumnFilter = {
  and?: InputMaybe<Array<WorkspaceViewColumnFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WorkspaceViewColumnFilter>>;
};

export type WorkspaceViewColumnMaxAggregate = {
  __typename?: 'WorkspaceViewColumnMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type WorkspaceViewColumnMinAggregate = {
  __typename?: 'WorkspaceViewColumnMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type WorkspaceViewColumnSort = {
  direction: SortDirection;
  field: WorkspaceViewColumnSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WorkspaceViewColumnSortFields {
  Id = 'id'
}

export type WorkspaceViewColumnSumAggregate = {
  __typename?: 'WorkspaceViewColumnSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type WorkspaceViewCountAggregate = {
  __typename?: 'WorkspaceViewCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type WorkspaceViewFilter = {
  and?: InputMaybe<Array<WorkspaceViewFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WorkspaceViewFilter>>;
  workspace?: InputMaybe<WorkspaceViewFilterWorkspaceFilter>;
  workspaceViewColumns?: InputMaybe<WorkspaceViewFilterWorkspaceViewColumnFilter>;
};

export type WorkspaceViewFilterWorkspaceFilter = {
  and?: InputMaybe<Array<WorkspaceViewFilterWorkspaceFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WorkspaceViewFilterWorkspaceFilter>>;
};

export type WorkspaceViewFilterWorkspaceViewColumnFilter = {
  and?: InputMaybe<Array<WorkspaceViewFilterWorkspaceViewColumnFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WorkspaceViewFilterWorkspaceViewColumnFilter>>;
};

export type WorkspaceViewMaxAggregate = {
  __typename?: 'WorkspaceViewMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type WorkspaceViewMinAggregate = {
  __typename?: 'WorkspaceViewMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type WorkspaceViewSort = {
  direction: SortDirection;
  field: WorkspaceViewSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WorkspaceViewSortFields {
  Id = 'id',
  Name = 'name'
}

export type WorkspaceViewSumAggregate = {
  __typename?: 'WorkspaceViewSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type CreateOneConcessionOrganizationAccreditationDocumentMutationVariables = Exact<{
  input: CreateOneConcessionOrganizationAccreditationDocumentInput;
}>;


export type CreateOneConcessionOrganizationAccreditationDocumentMutation = { __typename?: 'Mutation', createOneConcessionOrganizationAccreditationDocument: { __typename?: 'ConcessionOrganizationAccreditationDocument', id: string } };

export type DeleteOneConcessionOrganizationAccreditationDocumentMutationVariables = Exact<{
  input: DeleteOneConcessionOrganizationAccreditationDocumentInput;
}>;


export type DeleteOneConcessionOrganizationAccreditationDocumentMutation = { __typename?: 'Mutation', deleteOneConcessionOrganizationAccreditationDocument: { __typename?: 'ConcessionOrganizationAccreditationDocumentDeleteResponse', id?: string | null } };

export type ImportConcessionOrganizationAccreditationDocumentsMutationVariables = Exact<{
  input: ImportConcessionOrganizationAccreditationDocumentsInput;
}>;


export type ImportConcessionOrganizationAccreditationDocumentsMutation = { __typename?: 'Mutation', importConcessionOrganizationAccreditationDocuments: { __typename?: 'ImportConcessionOrganizationAccreditationDocumentsOutput', duplicates: Array<string> } };

export type ConcessionOrganizationAccreditationStatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ConcessionOrganizationAccreditationStatesQuery = { __typename?: 'Query', concessionOrganizationAccreditationStates: { __typename?: 'ConcessionOrganizationAccreditationStateConnection', nodes: Array<{ __typename?: 'ConcessionOrganizationAccreditationState', id: string, name: string }> } };

export type ConcessionOrganizationAccreditationTypesQueryVariables = Exact<{
  filter?: InputMaybe<ConcessionOrganizationAccreditationTypeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionOrganizationAccreditationTypeSort> | ConcessionOrganizationAccreditationTypeSort>;
}>;


export type ConcessionOrganizationAccreditationTypesQuery = { __typename?: 'Query', concessionOrganizationAccreditationTypes: { __typename?: 'ConcessionOrganizationAccreditationTypeConnection', nodes: Array<{ __typename?: 'ConcessionOrganizationAccreditationType', id: string, name: string }> } };

export type ConcessionOrganizationAccreditationsQueryVariables = Exact<{
  filter?: InputMaybe<ConcessionOrganizationAccreditationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionOrganizationAccreditationSort> | ConcessionOrganizationAccreditationSort>;
}>;


export type ConcessionOrganizationAccreditationsQuery = { __typename?: 'Query', concessionOrganizationAccreditations: { __typename?: 'ConcessionOrganizationAccreditationConnection', totalCount: number, nodes: Array<{ __typename?: 'ConcessionOrganizationAccreditation', id: string, name: string, observations?: string | null, startDate: string, endDate: string, concessionOrganizationAccreditationType: { __typename?: 'ConcessionOrganizationAccreditationType', id: string, name: string }, concessionOrganizationAccreditationState: { __typename?: 'ConcessionOrganizationAccreditationState', id: string, name: string, code: string, color: string, backgroundColor: string } }> } };

export type ConcessionOrganizationAccreditationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ConcessionOrganizationAccreditationQuery = { __typename?: 'Query', concessionOrganizationAccreditation?: { __typename?: 'ConcessionOrganizationAccreditation', id: string, name: string, startDate: string, endDate: string, createdAt: any, observations?: string | null, mediatingOrganization?: { __typename?: 'ConcessionOrganization', id: string, organization: { __typename?: 'Organization', id: string, name: string } } | null, concessionOrganization: { __typename?: 'ConcessionOrganization', id: string, organization: { __typename?: 'Organization', id: string, name: string } }, concessionOrganizationAccreditationType: { __typename?: 'ConcessionOrganizationAccreditationType', id: string, name: string }, concessionOrganizationAccreditationState: { __typename?: 'ConcessionOrganizationAccreditationState', id: string, name: string, code: string }, concessionOrganizationAccreditationDocuments?: Array<{ __typename?: 'ConcessionOrganizationAccreditationDocument', id: string, name: string, path: string, expiresAt?: string | null, fileType?: { __typename?: 'FileType', id: string, name: string, mimeType: string, isPreviewable: boolean } | null, documentState?: { __typename?: 'DocumentState', id: string, name: string } | null, concessionOrganizationDocumentCategory?: { __typename?: 'ConcessionOrganizationDocumentCategory', id: string, name: string } | null, concessionOrganizationDocumentSubcategory?: { __typename?: 'ConcessionOrganizationDocumentCategory', id: string, name: string } | null }> | null } | null };

export type SelectedAccreditationQueryVariables = Exact<{
  input: SelectedAccreditationInput;
}>;


export type SelectedAccreditationQuery = { __typename?: 'Query', selectedAccreditation?: { __typename?: 'ConcessionOrganizationAccreditation', id: string, name: string, startDate: string, endDate: string, mediatingOrganization?: { __typename?: 'ConcessionOrganization', organization: { __typename?: 'Organization', id: string, name: string } } | null } | null };

export type CreateOneConcessionOrganizationAccreditationMutationVariables = Exact<{
  input: CreateOneConcessionOrganizationAccreditationInput;
}>;


export type CreateOneConcessionOrganizationAccreditationMutation = { __typename?: 'Mutation', createOneConcessionOrganizationAccreditation: { __typename?: 'ConcessionOrganizationAccreditation', id: string } };

export type UpdateOneConcessionOrganizationAccreditationMutationVariables = Exact<{
  input: UpdateOneConcessionOrganizationAccreditationInput;
}>;


export type UpdateOneConcessionOrganizationAccreditationMutation = { __typename?: 'Mutation', updateOneConcessionOrganizationAccreditation: { __typename?: 'ConcessionOrganizationAccreditation', id: string } };

export type DeleteOneConcessionOrganizationAccreditationMutationVariables = Exact<{
  input: DeleteOneConcessionOrganizationAccreditationInput;
}>;


export type DeleteOneConcessionOrganizationAccreditationMutation = { __typename?: 'Mutation', deleteOneConcessionOrganizationAccreditation: { __typename?: 'ConcessionOrganizationAccreditationDeleteResponse', id?: string | null } };

export type CreateManyBadgesMutationVariables = Exact<{
  input: CreateManyBadgesInput;
}>;


export type CreateManyBadgesMutation = { __typename?: 'Mutation', createManyBadges: Array<{ __typename?: 'Badge', id: string }> };

export type CreateOneBadgeMutationVariables = Exact<{
  input: CreateOneBadgeInput;
}>;


export type CreateOneBadgeMutation = { __typename?: 'Mutation', createOneBadge: { __typename?: 'Badge', id: string } };

export type UpdateOneBadgeMutationVariables = Exact<{
  input: UpdateOneBadgeInput;
}>;


export type UpdateOneBadgeMutation = { __typename?: 'Mutation', updateOneBadge: { __typename?: 'Badge', id: string } };

export type DeleteOneBadgeMutationVariables = Exact<{
  input: DeleteOneBadgeInput;
}>;


export type DeleteOneBadgeMutation = { __typename?: 'Mutation', deleteOneBadge: { __typename?: 'BadgeDeleteResponse', id?: string | null } };

export type UpdateOneChecklistItemMutationVariables = Exact<{
  input: UpdateOneChecklistItemInput;
}>;


export type UpdateOneChecklistItemMutation = { __typename?: 'Mutation', updateOneChecklistItem: { __typename?: 'ChecklistItem', id: string } };

export type ConcessionOrganizationDocumentCategoriesQueryVariables = Exact<{
  filter?: InputMaybe<ConcessionOrganizationDocumentCategoryFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionOrganizationDocumentCategorySort> | ConcessionOrganizationDocumentCategorySort>;
}>;


export type ConcessionOrganizationDocumentCategoriesQuery = { __typename?: 'Query', concessionOrganizationDocumentCategories: { __typename?: 'ConcessionOrganizationDocumentCategoryConnection', nodes: Array<{ __typename?: 'ConcessionOrganizationDocumentCategory', id: string, name: string }> } };

export type ConcessionOrganizationDocumentCategoriesWithSubcategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ConcessionOrganizationDocumentCategoriesWithSubcategoriesQuery = { __typename?: 'Query', concessionOrganizationDocumentCategories: { __typename?: 'ConcessionOrganizationDocumentCategoryConnection', nodes: Array<{ __typename?: 'ConcessionOrganizationDocumentCategory', id: string, name: string, children?: Array<{ __typename?: 'ContractDocumentCategory', id: string, name: string, observations?: string | null, expirationRequired: boolean }> | null }> } };

export type ConcessionOrganizationDocumentDetailsFragment = { __typename?: 'ConcessionOrganizationDocument', id: string, name: string, path: string, expiresAt?: string | null, createdAt: any, fileType?: { __typename?: 'FileType', id: string, name: string, mimeType: string, isPreviewable: boolean } | null, owner?: { __typename?: 'User', name: string } | null, concessionOrganizationDocumentCategory?: { __typename?: 'ConcessionOrganizationDocumentCategory', id: string, name: string, expirationRequired: boolean, policy: string } | null, concessionOrganizationDocumentSubcategory?: { __typename?: 'ConcessionOrganizationDocumentCategory', id: string, name: string, expirationRequired: boolean, policy: string } | null, documentState?: { __typename?: 'DocumentState', id: string, name: string, code: string } | null };

export type ConcessionOrganizationDocumentsQueryVariables = Exact<{
  filter?: InputMaybe<ConcessionOrganizationDocumentFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionOrganizationDocumentSort> | ConcessionOrganizationDocumentSort>;
}>;


export type ConcessionOrganizationDocumentsQuery = { __typename?: 'Query', concessionOrganizationDocuments: { __typename?: 'ConcessionOrganizationDocumentOffsetConnection', totalCount: number, nodes: Array<{ __typename?: 'ConcessionOrganizationDocument', id: string, name: string, path: string, expiresAt?: string | null, createdAt: any, fileType?: { __typename?: 'FileType', id: string, name: string, mimeType: string, isPreviewable: boolean } | null, owner?: { __typename?: 'User', name: string } | null, concessionOrganizationDocumentCategory?: { __typename?: 'ConcessionOrganizationDocumentCategory', id: string, name: string, expirationRequired: boolean, policy: string } | null, concessionOrganizationDocumentSubcategory?: { __typename?: 'ConcessionOrganizationDocumentCategory', id: string, name: string, expirationRequired: boolean, policy: string } | null, documentState?: { __typename?: 'DocumentState', id: string, name: string, code: string } | null }> } };

export type CreateOneConcessionOrganizationDocumentMutationVariables = Exact<{
  input: CreateOneConcessionOrganizationDocumentInput;
}>;


export type CreateOneConcessionOrganizationDocumentMutation = { __typename?: 'Mutation', createOneConcessionOrganizationDocument: { __typename?: 'ConcessionOrganizationDocument', id: string } };

export type UpdateOneConcessionOrganizationDocumentMutationVariables = Exact<{
  input: UpdateOneConcessionOrganizationDocumentInput;
}>;


export type UpdateOneConcessionOrganizationDocumentMutation = { __typename?: 'Mutation', updateOneConcessionOrganizationDocument: { __typename?: 'ConcessionOrganizationDocument', id: string } };

export type UpdateManyConcessionOrganizationDocumentsMutationVariables = Exact<{
  input: UpdateManyConcessionOrganizationDocumentsInput;
}>;


export type UpdateManyConcessionOrganizationDocumentsMutation = { __typename?: 'Mutation', updateManyConcessionOrganizationDocuments: { __typename?: 'UpdateManyResponse', updatedCount: number } };

export type DeleteOneConcessionOrganizationDocumentMutationVariables = Exact<{
  input: DeleteOneConcessionOrganizationDocumentInput;
}>;


export type DeleteOneConcessionOrganizationDocumentMutation = { __typename?: 'Mutation', deleteOneConcessionOrganizationDocument: { __typename?: 'ConcessionOrganizationDocumentDeleteResponse', id?: string | null } };

export type DeleteManyConcessionOrganizationDocumentsMutationVariables = Exact<{
  input: DeleteManyConcessionOrganizationDocumentsInput;
}>;


export type DeleteManyConcessionOrganizationDocumentsMutation = { __typename?: 'Mutation', deleteManyConcessionOrganizationDocuments: { __typename?: 'DeleteManyResponse', deletedCount: number } };

export type ConcessionOrganizationUsersQueryVariables = Exact<{
  filter?: InputMaybe<ConcessionOrganizationUserFilter>;
}>;


export type ConcessionOrganizationUsersQuery = { __typename?: 'Query', concessionOrganizationUsers: { __typename?: 'ConcessionOrganizationUserConnection', nodes: Array<{ __typename?: 'ConcessionOrganizationUser', id: string, role: ConcessionOrganizationUserRoles, user: { __typename?: 'User', id: string, name: string }, concessionOrganization: { __typename?: 'ConcessionOrganization', organization: { __typename?: 'Organization', id: string, name: string }, concession: { __typename?: 'Concession', id: string, name: string } } }> } };

export type CreateOneConcessionOrganizationUserMutationVariables = Exact<{
  input: CreateOneConcessionOrganizationUserInput;
}>;


export type CreateOneConcessionOrganizationUserMutation = { __typename?: 'Mutation', createOneConcessionOrganizationUser: { __typename?: 'ConcessionOrganizationUser', id: string } };

export type UpdateOneConcessionOrganizationUserMutationVariables = Exact<{
  input: UpdateOneConcessionOrganizationUserInput;
}>;


export type UpdateOneConcessionOrganizationUserMutation = { __typename?: 'Mutation', updateOneConcessionOrganizationUser: { __typename?: 'ConcessionOrganizationUser', id: string } };

export type DeleteOneConcessionOrganizationUserMutationVariables = Exact<{
  input: DeleteOneConcessionOrganizationUserInput;
}>;


export type DeleteOneConcessionOrganizationUserMutation = { __typename?: 'Mutation', deleteOneConcessionOrganizationUser: { __typename?: 'ConcessionOrganizationUserDeleteResponse', id?: string | null } };

export type ConcessionsQueryVariables = Exact<{
  filter?: InputMaybe<ConcessionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionSort> | ConcessionSort>;
}>;


export type ConcessionsQuery = { __typename?: 'Query', concessions: { __typename?: 'ConcessionConnection', nodes: Array<{ __typename?: 'Concession', id: string, name: string }> } };

export type ContractDocumentCategoriesQueryVariables = Exact<{
  filter?: InputMaybe<ContractDocumentCategoryFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ContractDocumentCategorySort> | ContractDocumentCategorySort>;
}>;


export type ContractDocumentCategoriesQuery = { __typename?: 'Query', contractDocumentCategories: { __typename?: 'ContractDocumentCategoryConnection', nodes: Array<{ __typename?: 'ContractDocumentCategory', id: string, name: string }> } };

export type ContractDocumentCategoriesWithSubcategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ContractDocumentCategoriesWithSubcategoriesQuery = { __typename?: 'Query', contractDocumentCategories: { __typename?: 'ContractDocumentCategoryConnection', nodes: Array<{ __typename?: 'ContractDocumentCategory', id: string, name: string, children?: Array<{ __typename?: 'ContractDocumentCategory', id: string, name: string, observations?: string | null, expirationRequired: boolean }> | null }> } };

export type ContractDocumentsQueryVariables = Exact<{
  filter?: InputMaybe<ContractDocumentFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ContractDocumentSort> | ContractDocumentSort>;
}>;


export type ContractDocumentsQuery = { __typename?: 'Query', contractDocuments: { __typename?: 'ContractDocumentOffsetConnection', totalCount: number, nodes: Array<{ __typename?: 'ContractDocument', id: string, name: string, path: string, expiresAt?: string | null, createdAt: any, fileType?: { __typename?: 'FileType', id: string, name: string, mimeType: string, isPreviewable: boolean } | null, owner?: { __typename?: 'User', name: string } | null, contractDocumentCategory?: { __typename?: 'ContractDocumentCategory', id: string, name: string, expirationRequired: boolean, policy: string } | null, contractDocumentSubcategory?: { __typename?: 'ContractDocumentCategory', id: string, name: string, expirationRequired: boolean, policy: string } | null, documentState?: { __typename?: 'DocumentState', id: string, name: string } | null, contract: { __typename?: 'Contract', contractState: { __typename?: 'ContractState', id: string, name: string } } }> } };

export type CreateOneContractDocumentMutationVariables = Exact<{
  input: CreateOneContractDocumentInput;
}>;


export type CreateOneContractDocumentMutation = { __typename?: 'Mutation', createOneContractDocument: { __typename?: 'ContractDocument', id: string } };

export type UpdateOneContractDocumentMutationVariables = Exact<{
  input: UpdateOneContractDocumentInput;
}>;


export type UpdateOneContractDocumentMutation = { __typename?: 'Mutation', updateOneContractDocument: { __typename?: 'ContractDocument', id: string } };

export type UpdateManyContractDocumentsMutationVariables = Exact<{
  input: UpdateManyContractDocumentsInput;
}>;


export type UpdateManyContractDocumentsMutation = { __typename?: 'Mutation', updateManyContractDocuments: { __typename?: 'UpdateManyResponse', updatedCount: number } };

export type DeleteOneContractDocumentMutationVariables = Exact<{
  input: DeleteOneContractDocumentInput;
}>;


export type DeleteOneContractDocumentMutation = { __typename?: 'Mutation', deleteOneContractDocument: { __typename?: 'ContractDocumentDeleteResponse', id?: string | null } };

export type DeleteManyContractDocumentsMutationVariables = Exact<{
  input: DeleteManyContractDocumentsInput;
}>;


export type DeleteManyContractDocumentsMutation = { __typename?: 'Mutation', deleteManyContractDocuments: { __typename?: 'DeleteManyResponse', deletedCount: number } };

export type ContractRoadsQueryVariables = Exact<{
  filter?: InputMaybe<ContractRoadFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ContractRoadSort> | ContractRoadSort>;
}>;


export type ContractRoadsQuery = { __typename?: 'Query', contractRoads: { __typename?: 'ContractRoadConnection', nodes: Array<{ __typename?: 'ContractRoad', id: string, road: { __typename?: 'Road', id: string, name: string } }> } };

export type ContractStatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ContractStatesQuery = { __typename?: 'Query', contractStates: { __typename?: 'ContractStateConnection', nodes: Array<{ __typename?: 'ContractState', id: string, name: string }> } };

export type ContractsQueryVariables = Exact<{
  filter?: InputMaybe<ContractFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ContractSort> | ContractSort>;
}>;


export type ContractsQuery = { __typename?: 'Query', contracts: { __typename?: 'ContractOffsetConnection', totalCount: number, nodes: Array<{ __typename?: 'Contract', id: string, name: string, description: string, startDate: string, endDate: string, contractType: { __typename?: 'ContractType', id: string, name: string }, contractState: { __typename?: 'ContractState', id: string, name: string }, service: { __typename?: 'Service', id: string, name: string }, interventionType: { __typename?: 'InterventionType', id: string, name: string }, concession: { __typename?: 'Concession', id: string, name: string }, contractConcessionOrganizations?: Array<{ __typename?: 'ContractConcessionOrganization', concessionOrganization: { __typename?: 'ConcessionOrganization', id: string, organizationType: { __typename?: 'OrganizationType', id: string }, organization: { __typename?: 'Organization', id: string, name: string } } }> | null }> } };

export type ContractQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ContractQuery = { __typename?: 'Query', contract?: { __typename?: 'Contract', id: string, name: string, description: string, startDate: string, endDate: string, realStartDate: string, realEndDate: string, createdAt: any, contractOpenedNotificationSent: boolean, contractClosedNotificationSent: boolean, contractRoadsObservations?: string | null, originalValue?: number | null, amendedValue?: number | null, billedValue?: number | null, concession: { __typename?: 'Concession', id: string, name: string }, contractType: { __typename?: 'ContractType', id: string, name: string }, contractState: { __typename?: 'ContractState', id: string, name: string }, interventionElement: { __typename?: 'InterventionElement', id: string, name: string }, interventionType: { __typename?: 'InterventionType', id: string, name: string }, interventionLocation: { __typename?: 'InterventionLocation', id: string, name: string }, contractRoads?: Array<{ __typename?: 'ContractRoad', id: string, road: { __typename?: 'Road', id: string, name: string } }> | null, service: { __typename?: 'Service', id: string, name: string }, contractUsers?: Array<{ __typename?: 'ContractUser', id: string, role: string, user: { __typename?: 'User', id: string, name: string } }> | null, contractConcessionOrganizations?: Array<{ __typename?: 'ContractConcessionOrganization', id: string, concessionOrganization: { __typename?: 'ConcessionOrganization', id: string, organization: { __typename?: 'Organization', id: string, name: string }, organizationType: { __typename?: 'OrganizationType', id: string, name: string } } }> | null, contractDocuments?: Array<{ __typename?: 'ContractDocument', id: string, name: string, description?: string | null, path: string }> | null, contractTransitions?: Array<{ __typename?: 'ContractTransition', createdAt: any, fromState: { __typename?: 'ContractState', id: string, name: string }, toState: { __typename?: 'ContractState', id: string, name: string } }> | null } | null };

export type ContractPreviewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ContractPreviewQuery = { __typename?: 'Query', contract?: { __typename?: 'Contract', id: string, name: string, description: string, startDate: string, endDate: string, realStartDate: string, realEndDate: string, contractState: { __typename?: 'ContractState', name: string }, interventionLocation: { __typename?: 'InterventionLocation', id: string, name: string }, contractConcessionOrganizations?: Array<{ __typename?: 'ContractConcessionOrganization', id: string, concessionOrganization: { __typename?: 'ConcessionOrganization', id: string, organization: { __typename?: 'Organization', id: string, name: string }, organizationType: { __typename?: 'OrganizationType', id: string, name: string } } }> | null, workPermits: Array<{ __typename?: 'WorkPermit', id: string, name: string, state: string, startAt: string, endAt: string, isPublished: boolean }> } | null };

export type CreateOneContractMutationVariables = Exact<{
  input: CreateOneContractInput;
}>;


export type CreateOneContractMutation = { __typename?: 'Mutation', createOneContract: { __typename?: 'Contract', id: string } };

export type UpdateOneContractMutationVariables = Exact<{
  input: UpdateOneContractInput;
}>;


export type UpdateOneContractMutation = { __typename?: 'Mutation', updateOneContract: { __typename?: 'Contract', id: string } };

export type DeleteOneContractMutationVariables = Exact<{
  input: DeleteOneContractInput;
}>;


export type DeleteOneContractMutation = { __typename?: 'Mutation', deleteOneContract: { __typename?: 'ContractDeleteResponse', id?: string | null } };

export type ControlCentersQueryVariables = Exact<{
  filter?: InputMaybe<ControlCenterFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ControlCenterSort> | ControlCenterSort>;
}>;


export type ControlCentersQuery = { __typename?: 'Query', controlCenters: { __typename?: 'ControlCenterConnection', nodes: Array<{ __typename?: 'ControlCenter', id: string, name: string, phone: string }> } };

export type CreateOneControlCenterMutationVariables = Exact<{
  input: CreateOneControlCenterInput;
}>;


export type CreateOneControlCenterMutation = { __typename?: 'Mutation', createOneControlCenter: { __typename: 'ControlCenter', id: string, name: string, phone: string } };

export type UpdateOneControlCenterMutationVariables = Exact<{
  input: UpdateOneControlCenterInput;
}>;


export type UpdateOneControlCenterMutation = { __typename?: 'Mutation', updateOneControlCenter: { __typename?: 'ControlCenter', id: string, name: string, phone: string } };

export type DeleteOneControlCenterMutationVariables = Exact<{
  input: DeleteOneControlCenterInput;
}>;


export type DeleteOneControlCenterMutation = { __typename?: 'Mutation', deleteOneControlCenter: { __typename?: 'ControlCenterDeleteResponse', id?: string | null } };

export type DirectionsQueryVariables = Exact<{
  filter?: InputMaybe<DirectionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<DirectionSort> | DirectionSort>;
}>;


export type DirectionsQuery = { __typename?: 'Query', directions: { __typename?: 'DirectionConnection', nodes: Array<{ __typename?: 'Direction', id: string, name: string }> } };

export type CreateOneDirectionMutationVariables = Exact<{
  input: CreateOneDirectionInput;
}>;


export type CreateOneDirectionMutation = { __typename?: 'Mutation', createOneDirection: { __typename?: 'Direction', id: string } };

export type UpdateOneDirectionMutationVariables = Exact<{
  input: UpdateOneDirectionInput;
}>;


export type UpdateOneDirectionMutation = { __typename?: 'Mutation', updateOneDirection: { __typename?: 'Direction', id: string } };

export type DeleteOneDirectionMutationVariables = Exact<{
  input: DeleteOneDirectionInput;
}>;


export type DeleteOneDirectionMutation = { __typename?: 'Mutation', deleteOneDirection: { __typename?: 'DirectionDeleteResponse', id?: string | null } };

export type DocumentTemplatesQueryVariables = Exact<{
  filter?: InputMaybe<DocumentTemplateFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<DocumentTemplateSort> | DocumentTemplateSort>;
}>;


export type DocumentTemplatesQuery = { __typename?: 'Query', documentTemplates: { __typename?: 'DocumentTemplateOffsetConnection', nodes: Array<{ __typename?: 'DocumentTemplate', id: string, name: string, path: string }> } };

export type DocumentStatesQueryVariables = Exact<{
  filter?: InputMaybe<DocumentStateFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<DocumentStateSort> | DocumentStateSort>;
}>;


export type DocumentStatesQuery = { __typename?: 'Query', documentStates: { __typename?: 'DocumentStateConnection', nodes: Array<{ __typename?: 'DocumentState', id: string, name: string }> } };

export type SendResetPasswordEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendResetPasswordEmailMutation = { __typename?: 'Mutation', sendResetPasswordEmail: boolean };

export type SendPublishedSurveyRecordEmailMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type SendPublishedSurveyRecordEmailMutation = { __typename?: 'Mutation', sendPublishedSurveyRecordEmail: boolean };

export type SendPublishedWorkPermitEmailMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type SendPublishedWorkPermitEmailMutation = { __typename?: 'Mutation', sendPublishedWorkPermitEmail: boolean };

export type FileTypesQueryVariables = Exact<{
  filter?: InputMaybe<FileTypeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<FileTypeSort> | FileTypeSort>;
}>;


export type FileTypesQuery = { __typename?: 'Query', fileTypes: { __typename?: 'FileTypeConnection', nodes: Array<{ __typename?: 'FileType', id: string, name: string }> } };

export type InterventionsQueryVariables = Exact<{
  filter?: InputMaybe<InterventionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<InterventionSort> | InterventionSort>;
}>;


export type InterventionsQuery = { __typename?: 'Query', interventions: { __typename?: 'InterventionOffsetConnection', totalCount: number, nodes: Array<{ __typename?: 'Intervention', id: string, name: string, week: number, year: number, state: string, workPermit: { __typename?: 'WorkPermit', id: string, name: string, contract: { __typename?: 'Contract', id: string, name: string } } }> } };

export type InterventionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type InterventionQuery = { __typename?: 'Query', intervention?: { __typename?: 'Intervention', id: string, name: string, state: string, week: number, year: number, workPermit: { __typename?: 'WorkPermit', id: string, name: string, contract: { __typename?: 'Contract', id: string, name: string } } } | null };

export type CreateOneInterventionMutationVariables = Exact<{
  input: CreateOneInterventionInput;
}>;


export type CreateOneInterventionMutation = { __typename?: 'Mutation', createOneIntervention: { __typename?: 'Intervention', id: string } };

export type UpdateOneInterventionMutationVariables = Exact<{
  input: UpdateOneInterventionInput;
}>;


export type UpdateOneInterventionMutation = { __typename?: 'Mutation', updateOneIntervention: { __typename?: 'Intervention', id: string } };

export type DeleteOneInterventionMutationVariables = Exact<{
  input: DeleteOneInterventionInput;
}>;


export type DeleteOneInterventionMutation = { __typename?: 'Mutation', deleteOneIntervention: { __typename?: 'InterventionDeleteResponse', id?: string | null } };

export type GenerateTaskExcelMutationVariables = Exact<{
  input: GenerateTaskExcelTemplate;
}>;


export type GenerateTaskExcelMutation = { __typename?: 'Mutation', generateTaskExcelTemplate: string };

export type ValidateTaskImportMutationVariables = Exact<{
  input: ValidateTaskImportInput;
}>;


export type ValidateTaskImportMutation = { __typename?: 'Mutation', validateTaskImport: Array<{ __typename?: 'ImportValidationErrorOutput', address: string, value: string, code: string }> };

export type ImportManyTasksMutationVariables = Exact<{
  input: ImportManyTasksInput;
}>;


export type ImportManyTasksMutation = { __typename?: 'Mutation', importManyTasks: string };

export type JunctionsQueryVariables = Exact<{
  filter?: InputMaybe<JunctionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<JunctionSort> | JunctionSort>;
}>;


export type JunctionsQuery = { __typename?: 'Query', junctions: { __typename?: 'JunctionConnection', nodes: Array<{ __typename?: 'Junction', id: string, name: string }> } };

export type LayoutsQueryVariables = Exact<{
  filter?: InputMaybe<LayoutFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<LayoutSort> | LayoutSort>;
}>;


export type LayoutsQuery = { __typename?: 'Query', layouts: { __typename?: 'LayoutConnection', nodes: Array<{ __typename?: 'Layout', id: string, name: string }> } };

export type CreateOneLayoutMutationVariables = Exact<{
  input: CreateOneLayoutInput;
}>;


export type CreateOneLayoutMutation = { __typename?: 'Mutation', createOneLayout: { __typename?: 'Layout', id: string } };

export type UpdateOneLayoutMutationVariables = Exact<{
  input: UpdateOneLayoutInput;
}>;


export type UpdateOneLayoutMutation = { __typename?: 'Mutation', updateOneLayout: { __typename?: 'Layout', id: string } };

export type DeleteOneLayoutMutationVariables = Exact<{
  input: DeleteOneLayoutInput;
}>;


export type DeleteOneLayoutMutation = { __typename?: 'Mutation', deleteOneLayout: { __typename?: 'LayoutDeleteResponse', id?: string | null } };

export type OrganizationTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationTypesQuery = { __typename?: 'Query', organizationTypes: { __typename?: 'OrganizationTypeConnection', nodes: Array<{ __typename?: 'OrganizationType', id: string, name: string }> } };

export type OrganizationsQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<OrganizationSort> | OrganizationSort>;
  concession?: InputMaybe<Scalars['ID']>;
}>;


export type OrganizationsQuery = { __typename?: 'Query', organizations: { __typename?: 'OrganizationConnection', nodes: Array<{ __typename?: 'Organization', id: string, name: string, tin: string, address: string, zipCode: string, concessionOrganizations?: Array<{ __typename?: 'ConcessionOrganization', id: string, organizationType: { __typename?: 'OrganizationType', id: string, name: string }, concession: { __typename?: 'Concession', id: string, name: string } }> | null }> } };

export type CreateOneOrganizationMutationVariables = Exact<{
  input: CreateOneOrganizationInput;
}>;


export type CreateOneOrganizationMutation = { __typename?: 'Mutation', createOneOrganization: { __typename?: 'Organization', id: string } };

export type UpdateOneOrganizationMutationVariables = Exact<{
  input: UpdateOneOrganizationInput;
}>;


export type UpdateOneOrganizationMutation = { __typename?: 'Mutation', updateOneOrganization: { __typename?: 'Organization', id: string } };

export type DeleteOneOrganizationMutationVariables = Exact<{
  input: DeleteOneOrganizationInput;
}>;


export type DeleteOneOrganizationMutation = { __typename?: 'Mutation', deleteOneOrganization: { __typename?: 'OrganizationDeleteResponse', id?: string | null } };

export type OwnConcessionOrganizationDocumentsQueryVariables = Exact<{
  filter?: InputMaybe<ConcessionOrganizationDocumentFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionOrganizationDocumentSort> | ConcessionOrganizationDocumentSort>;
}>;


export type OwnConcessionOrganizationDocumentsQuery = { __typename?: 'Query', ownConcessionOrganizationDocuments: { __typename?: 'ConcessionOrganizationDocumentOffsetConnection', totalCount: number, nodes: Array<{ __typename?: 'ConcessionOrganizationDocument', id: string, name: string, path: string, expiresAt?: string | null, createdAt: any, fileType?: { __typename?: 'FileType', id: string, name: string, mimeType: string, isPreviewable: boolean } | null, owner?: { __typename?: 'User', name: string } | null, concessionOrganizationDocumentCategory?: { __typename?: 'ConcessionOrganizationDocumentCategory', id: string, name: string, expirationRequired: boolean, policy: string } | null, concessionOrganizationDocumentSubcategory?: { __typename?: 'ConcessionOrganizationDocumentCategory', id: string, name: string, expirationRequired: boolean, policy: string } | null, documentState?: { __typename?: 'DocumentState', id: string, name: string, code: string } | null }> } };

export type CreateOwnConcessionOrganizationDocumentMutationVariables = Exact<{
  input: CreateOwnConcesssionOrganizationDocumentInput;
}>;


export type CreateOwnConcessionOrganizationDocumentMutation = { __typename?: 'Mutation', createOwnConcessionOrganizationDocument: { __typename?: 'ConcessionOrganizationDocument', id: string } };

export type UpdateOwnConcessionOrganizationDocumentMutationVariables = Exact<{
  input: UpdateOwnConcesssionOrganizationDocument;
}>;


export type UpdateOwnConcessionOrganizationDocumentMutation = { __typename?: 'Mutation', updateOwnConcessionOrganizationDocument: { __typename?: 'ConcessionOrganizationDocument', id: string } };

export type DeleteOwnConcessionOrganizationDocumentMutationVariables = Exact<{
  input: DeleteOwnConcesssionOrganizationDocument;
}>;


export type DeleteOwnConcessionOrganizationDocumentMutation = { __typename?: 'Mutation', deleteOwnConcessionOrganizationDocument: { __typename?: 'ConcessionOrganizationDocument', id: string } };

export type OwnSubcontractOrganizationDocumentsQueryVariables = Exact<{
  filter?: InputMaybe<ConcessionOrganizationDocumentFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionOrganizationDocumentSort> | ConcessionOrganizationDocumentSort>;
}>;


export type OwnSubcontractOrganizationDocumentsQuery = { __typename?: 'Query', ownSubcontractOrganizationDocuments: { __typename?: 'ConcessionOrganizationDocumentOffsetConnection', totalCount: number, nodes: Array<{ __typename?: 'ConcessionOrganizationDocument', id: string, name: string, path: string, expiresAt?: string | null, createdAt: any, fileType?: { __typename?: 'FileType', id: string, name: string, mimeType: string, isPreviewable: boolean } | null, owner?: { __typename?: 'User', name: string } | null, concessionOrganizationDocumentCategory?: { __typename?: 'ConcessionOrganizationDocumentCategory', id: string, name: string, expirationRequired: boolean, policy: string } | null, concessionOrganizationDocumentSubcategory?: { __typename?: 'ConcessionOrganizationDocumentCategory', id: string, name: string, expirationRequired: boolean, policy: string } | null, documentState?: { __typename?: 'DocumentState', id: string, name: string, code: string } | null }> } };

export type CreateOwnSubcontractOrganizationDocumentMutationVariables = Exact<{
  input: CreateOwnConcesssionOrganizationDocumentInput;
}>;


export type CreateOwnSubcontractOrganizationDocumentMutation = { __typename?: 'Mutation', createOwnSubcontractOrganizationDocument: { __typename?: 'ConcessionOrganizationDocument', id: string } };

export type UpdateOwnSubcontractOrganizationDocumentMutationVariables = Exact<{
  input: UpdateOwnConcesssionOrganizationDocument;
}>;


export type UpdateOwnSubcontractOrganizationDocumentMutation = { __typename?: 'Mutation', updateOwnSubcontractOrganizationDocument: { __typename?: 'ConcessionOrganizationDocument', id: string } };

export type DeleteOwnSubcontractOrganizationDocumentMutationVariables = Exact<{
  input: DeleteOwnConcesssionOrganizationDocument;
}>;


export type DeleteOwnSubcontractOrganizationDocumentMutation = { __typename?: 'Mutation', deleteOwnSubcontractOrganizationDocument: { __typename?: 'ConcessionOrganizationDocument', id: string } };

export type CreateManyContractConcessionOrganizationsDedupedMutationVariables = Exact<{
  input: CreateManyContractConcessionOrganizationsDedupedInput;
}>;


export type CreateManyContractConcessionOrganizationsDedupedMutation = { __typename?: 'Mutation', createManyContractConcessionOrganizationsDeduped: { __typename?: 'Contract', id: string } };

export type ConcessionOrganizationsQueryVariables = Exact<{
  filter?: InputMaybe<ConcessionOrganizationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionOrganizationSort> | ConcessionOrganizationSort>;
}>;


export type ConcessionOrganizationsQuery = { __typename?: 'Query', concessionOrganizations: { __typename?: 'ConcessionOrganizationConnection', nodes: Array<{ __typename?: 'ConcessionOrganization', id: string, concession: { __typename?: 'Concession', id: string, name: string }, organization: { __typename?: 'Organization', id: string, name: string, tin: string }, organizationType: { __typename?: 'OrganizationType', id: string, name: string } }> } };

export type CreateOneConcessionOrganizationMutationVariables = Exact<{
  input: CreateOneConcessionOrganizationInput;
}>;


export type CreateOneConcessionOrganizationMutation = { __typename?: 'Mutation', createOneConcessionOrganization: { __typename?: 'ConcessionOrganization', id: string } };

export type UpdateOneConcessionOrganizationMutationVariables = Exact<{
  input: UpdateOneConcessionOrganizationInput;
}>;


export type UpdateOneConcessionOrganizationMutation = { __typename?: 'Mutation', updateOneConcessionOrganization: { __typename?: 'ConcessionOrganization', id: string } };

export type DeleteOneConcessionOrganizationMutationVariables = Exact<{
  input: DeleteOneConcessionOrganizationInput;
}>;


export type DeleteOneConcessionOrganizationMutation = { __typename?: 'Mutation', deleteOneConcessionOrganization: { __typename?: 'ConcessionOrganizationDeleteResponse', id?: string | null } };

export type ConcessionUsersQueryVariables = Exact<{
  filter?: InputMaybe<ConcessionUserFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConcessionUserSort> | ConcessionUserSort>;
}>;


export type ConcessionUsersQuery = { __typename?: 'Query', concessionUsers: { __typename?: 'ConcessionUserConnection', nodes: Array<{ __typename?: 'ConcessionUser', id: string, role: ConcessionUserRoles, user: { __typename?: 'User', id: string, name: string, email: string, phone?: string | null }, concession: { __typename?: 'Concession', id: string, name: string } }> } };

export type CreateOneConcessionUserMutationVariables = Exact<{
  input: CreateOneConcessionUserInput;
}>;


export type CreateOneConcessionUserMutation = { __typename?: 'Mutation', createOneConcessionUser: { __typename?: 'ConcessionUser', id: string, user: { __typename?: 'User', id: string, name: string } } };

export type UpdateOneConcessionUserMutationVariables = Exact<{
  input: UpdateOneConcessionUserInput;
}>;


export type UpdateOneConcessionUserMutation = { __typename?: 'Mutation', updateOneConcessionUser: { __typename?: 'ConcessionUser', id: string } };

export type DeleteOneConcessionUserMutationVariables = Exact<{
  input: DeleteOneConcessionUserInput;
}>;


export type DeleteOneConcessionUserMutation = { __typename?: 'Mutation', deleteOneConcessionUser: { __typename?: 'ConcessionUserDeleteResponse', id?: string | null } };

export type SetContractRoadsForContractMutationVariables = Exact<{
  input: SetContractRoadsForContractInput;
}>;


export type SetContractRoadsForContractMutation = { __typename?: 'Mutation', setContractRoadsForContract: { __typename?: 'Contract', id: string, name: string } };

export type SetContractUsersForContractMutationVariables = Exact<{
  input: SetContractUsersForContractInput;
}>;


export type SetContractUsersForContractMutation = { __typename?: 'Mutation', setContractUsersForContract: { __typename?: 'Contract', id: string, name: string } };

export type CreateManyContractRoadsMutationVariables = Exact<{
  input: CreateManyContractRoadsInput;
}>;


export type CreateManyContractRoadsMutation = { __typename?: 'Mutation', createManyContractRoads: Array<{ __typename?: 'ContractRoad', id: string }> };

export type ContractTypesQueryVariables = Exact<{
  filter?: InputMaybe<ContractTypeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ContractTypeSort> | ContractTypeSort>;
}>;


export type ContractTypesQuery = { __typename?: 'Query', contractTypes: { __typename?: 'ContractTypeConnection', nodes: Array<{ __typename?: 'ContractType', id: string, name: string, abbreviation: string }> } };

export type CreateOneContractTypeMutationVariables = Exact<{
  input: CreateOneContractTypeInput;
}>;


export type CreateOneContractTypeMutation = { __typename?: 'Mutation', createOneContractType: { __typename?: 'ContractType', id: string } };

export type UpdateOneContractTypeMutationVariables = Exact<{
  input: UpdateOneContractTypeInput;
}>;


export type UpdateOneContractTypeMutation = { __typename?: 'Mutation', updateOneContractType: { __typename?: 'ContractType', id: string } };

export type DeleteOneContractTypeMutationVariables = Exact<{
  input: DeleteOneContractTypeInput;
}>;


export type DeleteOneContractTypeMutation = { __typename?: 'Mutation', deleteOneContractType: { __typename?: 'ContractTypeDeleteResponse', id?: string | null } };

export type CreateManyContractUsersMutationVariables = Exact<{
  input: CreateManyContractUsersInput;
}>;


export type CreateManyContractUsersMutation = { __typename?: 'Mutation', createManyContractUsers: Array<{ __typename?: 'ContractUser', id: string, role: string }> };

export type SetPasswordMutationVariables = Exact<{
  input: SetPasswordInput;
}>;


export type SetPasswordMutation = { __typename?: 'Mutation', setPassword: string };

export type ContractFieldQueryVariables = Exact<{
  filter?: InputMaybe<ContractFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ContractSort> | ContractSort>;
}>;


export type ContractFieldQuery = { __typename?: 'Query', contracts: { __typename?: 'ContractOffsetConnection', nodes: Array<{ __typename?: 'Contract', id: string, name: string }> } };

export type ContractManagerFieldQueryVariables = Exact<{
  concession?: InputMaybe<Scalars['ID']>;
}>;


export type ContractManagerFieldQuery = { __typename?: 'Query', concessionUsers: { __typename?: 'ConcessionUserConnection', nodes: Array<{ __typename?: 'ConcessionUser', id: string, role: ConcessionUserRoles, user: { __typename?: 'User', id: string, name: string, email: string } }> } };

export type ServiceManagerFieldQueryVariables = Exact<{
  concession?: InputMaybe<Scalars['ID']>;
}>;


export type ServiceManagerFieldQuery = { __typename?: 'Query', concessionUsers: { __typename?: 'ConcessionUserConnection', nodes: Array<{ __typename?: 'ConcessionUser', id: string, role: ConcessionUserRoles, user: { __typename?: 'User', id: string, name: string, email: string } }> } };

export type SafetyTechnicianFieldQueryVariables = Exact<{
  concession?: InputMaybe<Scalars['ID']>;
}>;


export type SafetyTechnicianFieldQuery = { __typename?: 'Query', concessionUsers: { __typename?: 'ConcessionUserConnection', nodes: Array<{ __typename?: 'ConcessionUser', id: string, role: ConcessionUserRoles, user: { __typename?: 'User', id: string, name: string, email: string } }> } };

export type ContractingOrganizationsFieldQueryVariables = Exact<{
  concession?: InputMaybe<Scalars['ID']>;
  paging?: InputMaybe<OffsetPaging>;
}>;


export type ContractingOrganizationsFieldQuery = { __typename?: 'Query', concessionOrganizations: { __typename?: 'ConcessionOrganizationConnection', nodes: Array<{ __typename?: 'ConcessionOrganization', id: string, concession: { __typename?: 'Concession', id: string, name: string }, organization: { __typename?: 'Organization', id: string, name: string }, organizationType: { __typename?: 'OrganizationType', id: string, name: string } }> } };

export type ExecutingOrganizationsFieldQueryVariables = Exact<{
  concession?: InputMaybe<Scalars['ID']>;
  paging?: InputMaybe<OffsetPaging>;
}>;


export type ExecutingOrganizationsFieldQuery = { __typename?: 'Query', concessionOrganizations: { __typename?: 'ConcessionOrganizationConnection', nodes: Array<{ __typename?: 'ConcessionOrganization', id: string, concession: { __typename?: 'Concession', id: string, name: string }, organization: { __typename?: 'Organization', id: string, name: string }, organizationType: { __typename?: 'OrganizationType', id: string, name: string } }> } };

export type InspectingOrganizationsFieldQueryVariables = Exact<{
  concession?: InputMaybe<Scalars['ID']>;
  paging?: InputMaybe<OffsetPaging>;
}>;


export type InspectingOrganizationsFieldQuery = { __typename?: 'Query', concessionOrganizations: { __typename?: 'ConcessionOrganizationConnection', nodes: Array<{ __typename?: 'ConcessionOrganization', id: string, concession: { __typename?: 'Concession', id: string, name: string }, organization: { __typename?: 'Organization', id: string, name: string }, organizationType: { __typename?: 'OrganizationType', id: string, name: string } }> } };

export type JunctionFieldQueryVariables = Exact<{
  filter?: InputMaybe<JunctionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<JunctionSort> | JunctionSort>;
}>;


export type JunctionFieldQuery = { __typename?: 'Query', junctions: { __typename?: 'JunctionConnection', nodes: Array<{ __typename?: 'Junction', id: string, name: string }> } };

export type ProjectingOrganizationsFieldQueryVariables = Exact<{
  concession?: InputMaybe<Scalars['ID']>;
  paging?: InputMaybe<OffsetPaging>;
}>;


export type ProjectingOrganizationsFieldQuery = { __typename?: 'Query', concessionOrganizations: { __typename?: 'ConcessionOrganizationConnection', nodes: Array<{ __typename?: 'ConcessionOrganization', id: string, concession: { __typename?: 'Concession', id: string, name: string }, organization: { __typename?: 'Organization', id: string, name: string }, organizationType: { __typename?: 'OrganizationType', id: string, name: string } }> } };

export type WeatherConditionFieldQueryVariables = Exact<{
  filter?: InputMaybe<WeatherConditionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<WeatherConditionSort> | WeatherConditionSort>;
}>;


export type WeatherConditionFieldQuery = { __typename?: 'Query', weatherConditions: { __typename?: 'WeatherConditionConnection', nodes: Array<{ __typename?: 'WeatherCondition', id: string, name: string }> } };

export type InterventionElementsQueryVariables = Exact<{
  filter?: InputMaybe<InterventionElementFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<InterventionElementSort> | InterventionElementSort>;
}>;


export type InterventionElementsQuery = { __typename?: 'Query', interventionElements: { __typename?: 'InterventionElementConnection', nodes: Array<{ __typename?: 'InterventionElement', id: string, name: string, abbreviation: string }> } };

export type CreateOneInterventionElementMutationVariables = Exact<{
  input: CreateOneInterventionElementInput;
}>;


export type CreateOneInterventionElementMutation = { __typename?: 'Mutation', createOneInterventionElement: { __typename?: 'InterventionElement', id: string } };

export type UpdateOneInterventionElementMutationVariables = Exact<{
  input: UpdateOneInterventionElementInput;
}>;


export type UpdateOneInterventionElementMutation = { __typename?: 'Mutation', updateOneInterventionElement: { __typename?: 'InterventionElement', id: string } };

export type DeleteOneInterventionElementMutationVariables = Exact<{
  input: DeleteOneInterventionElementInput;
}>;


export type DeleteOneInterventionElementMutation = { __typename?: 'Mutation', deleteOneInterventionElement: { __typename?: 'InterventionElementDeleteResponse', id?: string | null } };

export type InterventionLocationsQueryVariables = Exact<{
  filter?: InputMaybe<InterventionLocationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<InterventionLocationSort> | InterventionLocationSort>;
}>;


export type InterventionLocationsQuery = { __typename?: 'Query', interventionLocations: { __typename?: 'InterventionLocationConnection', nodes: Array<{ __typename?: 'InterventionLocation', id: string, name: string, abbreviation: string }> } };

export type CreateOneInterventionLocationMutationVariables = Exact<{
  input: CreateOneInterventionLocationInput;
}>;


export type CreateOneInterventionLocationMutation = { __typename?: 'Mutation', createOneInterventionLocation: { __typename?: 'InterventionLocation', id: string } };

export type UpdateOneInterventionLocationMutationVariables = Exact<{
  input: UpdateOneInterventionLocationInput;
}>;


export type UpdateOneInterventionLocationMutation = { __typename?: 'Mutation', updateOneInterventionLocation: { __typename?: 'InterventionLocation', id: string } };

export type DeleteOneInterventionLocationMutationVariables = Exact<{
  input: DeleteOneInterventionLocationInput;
}>;


export type DeleteOneInterventionLocationMutation = { __typename?: 'Mutation', deleteOneInterventionLocation: { __typename?: 'InterventionLocationDeleteResponse', id?: string | null } };

export type InterventionTypesQueryVariables = Exact<{
  filter?: InputMaybe<InterventionTypeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<InterventionTypeSort> | InterventionTypeSort>;
}>;


export type InterventionTypesQuery = { __typename?: 'Query', interventionTypes: { __typename?: 'InterventionTypeConnection', nodes: Array<{ __typename?: 'InterventionType', id: string, name: string, abbreviation: string }> } };

export type CreateOneInterventionTypeMutationVariables = Exact<{
  input: CreateOneInterventionTypeInput;
}>;


export type CreateOneInterventionTypeMutation = { __typename?: 'Mutation', createOneInterventionType: { __typename?: 'InterventionType', id: string } };

export type UpdateOneInterventionTypeMutationVariables = Exact<{
  input: UpdateOneInterventionTypeInput;
}>;


export type UpdateOneInterventionTypeMutation = { __typename?: 'Mutation', updateOneInterventionType: { __typename?: 'InterventionType', id: string } };

export type DeleteOneInterventionTypeMutationVariables = Exact<{
  input: DeleteOneInterventionTypeInput;
}>;


export type DeleteOneInterventionTypeMutation = { __typename?: 'Mutation', deleteOneInterventionType: { __typename?: 'InterventionTypeDeleteResponse', id?: string | null } };

export type SendContractOpenedNotificationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type SendContractOpenedNotificationMutation = { __typename?: 'Mutation', sendContractOpenedNotification: boolean };

export type SendContractClosedNotificationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type SendContractClosedNotificationMutation = { __typename?: 'Mutation', sendContractClosedNotification: boolean };

export type RoadsQueryVariables = Exact<{
  filter?: InputMaybe<RoadFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<RoadSort> | RoadSort>;
}>;


export type RoadsQuery = { __typename?: 'Query', roads: { __typename?: 'RoadConnection', nodes: Array<{ __typename?: 'Road', id: string, name: string }> } };

export type CreateOneRoadMutationVariables = Exact<{
  input: CreateOneRoadInput;
}>;


export type CreateOneRoadMutation = { __typename?: 'Mutation', createOneRoad: { __typename?: 'Road', id: string } };

export type UpdateOneRoadMutationVariables = Exact<{
  input: UpdateOneRoadInput;
}>;


export type UpdateOneRoadMutation = { __typename?: 'Mutation', updateOneRoad: { __typename?: 'Road', id: string } };

export type DeleteOneRoadMutationVariables = Exact<{
  input: DeleteOneRoadInput;
}>;


export type DeleteOneRoadMutation = { __typename?: 'Mutation', deleteOneRoad: { __typename?: 'RoadDeleteResponse', id?: string | null } };

export type UsersQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<UserSort> | UserSort>;
  concession?: InputMaybe<Scalars['ID']>;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', nodes: Array<{ __typename?: 'User', id: string, name: string, email: string, phone?: string | null, concessionUsers?: Array<{ __typename?: 'ConcessionUser', id: string, role: ConcessionUserRoles, concession: { __typename?: 'Concession', id: string, name: string } }> | null }> } };

export type CreateOneUserMutationVariables = Exact<{
  input: CreateOneUserInput;
}>;


export type CreateOneUserMutation = { __typename?: 'Mutation', createOneUser: { __typename?: 'User', id: string } };

export type UpdateOneUserMutationVariables = Exact<{
  input: UpdateOneUserInput;
}>;


export type UpdateOneUserMutation = { __typename?: 'Mutation', updateOneUser: { __typename?: 'User', id: string } };

export type DeleteOneUserMutationVariables = Exact<{
  input: DeleteOneUserInput;
}>;


export type DeleteOneUserMutation = { __typename?: 'Mutation', deleteOneUser: { __typename?: 'UserDeleteResponse', id?: string | null } };

export type WeatherConditionsQueryVariables = Exact<{
  filter?: InputMaybe<WeatherConditionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<WeatherConditionSort> | WeatherConditionSort>;
}>;


export type WeatherConditionsQuery = { __typename?: 'Query', weatherConditions: { __typename?: 'WeatherConditionConnection', nodes: Array<{ __typename?: 'WeatherCondition', id: string, name: string }> } };

export type CreateOneWeatherConditionMutationVariables = Exact<{
  input: CreateOneWeatherConditionInput;
}>;


export type CreateOneWeatherConditionMutation = { __typename?: 'Mutation', createOneWeatherCondition: { __typename?: 'WeatherCondition', id: string, name: string } };

export type UpdateOneWeatherConditionMutationVariables = Exact<{
  input: UpdateOneWeatherConditionInput;
}>;


export type UpdateOneWeatherConditionMutation = { __typename?: 'Mutation', updateOneWeatherCondition: { __typename?: 'WeatherCondition', id: string, name: string } };

export type DeleteOneWeatherConditionMutationVariables = Exact<{
  input: DeleteOneWeatherConditionInput;
}>;


export type DeleteOneWeatherConditionMutation = { __typename?: 'Mutation', deleteOneWeatherCondition: { __typename?: 'WeatherConditionDeleteResponse', id?: string | null } };

export type StatusQueryVariables = Exact<{ [key: string]: never; }>;


export type StatusQuery = { __typename?: 'Query', status: string };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, name: string, email: string, phone?: string | null, defaultConcession: { __typename?: 'Concession', id: string, name: string }, concessionUsers?: Array<{ __typename?: 'ConcessionUser', id: string, role: ConcessionUserRoles, concession: { __typename?: 'Concession', id: string, name: string } }> | null, concessionOrganizationUsers?: Array<{ __typename?: 'ConcessionOrganizationUser', role: ConcessionOrganizationUserRoles, concessionOrganization: { __typename?: 'ConcessionOrganization', concession: { __typename?: 'Concession', id: string } } }> | null }, roles: { __typename?: 'RoleConnection', nodes: Array<{ __typename?: 'Role', name: string, permissions: Array<{ __typename?: 'Permission', subject: string, action: string, fields?: string | null }> }> } };

export type UpdateMeMutationVariables = Exact<{
  input: UpdateMe;
}>;


export type UpdateMeMutation = { __typename?: 'Mutation', updateMe: { __typename?: 'User', id: string, name: string, phone?: string | null } };

export type RestrictionsQueryVariables = Exact<{
  filter?: InputMaybe<RestrictionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<RestrictionSort> | RestrictionSort>;
}>;


export type RestrictionsQuery = { __typename?: 'Query', restrictions: { __typename?: 'RestrictionConnection', nodes: Array<{ __typename?: 'Restriction', id: string, name: string }> } };

export type CreateOneRestrictionMutationVariables = Exact<{
  input: CreateOneRestrictionInput;
}>;


export type CreateOneRestrictionMutation = { __typename?: 'Mutation', createOneRestriction: { __typename?: 'Restriction', id: string } };

export type UpdateOneRestrictionMutationVariables = Exact<{
  input: UpdateOneRestrictionInput;
}>;


export type UpdateOneRestrictionMutation = { __typename?: 'Mutation', updateOneRestriction: { __typename?: 'Restriction', id: string } };

export type DeleteOneRestrictionMutationVariables = Exact<{
  input: DeleteOneRestrictionInput;
}>;


export type DeleteOneRestrictionMutation = { __typename?: 'Mutation', deleteOneRestriction: { __typename?: 'RestrictionDeleteResponse', id?: string | null } };

export type ServicesQueryVariables = Exact<{
  filter?: InputMaybe<ServiceFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ServiceSort> | ServiceSort>;
}>;


export type ServicesQuery = { __typename?: 'Query', services: { __typename?: 'ServiceConnection', nodes: Array<{ __typename?: 'Service', id: string, name: string, abbreviation: string }> } };

export type CreateOneServiceMutationVariables = Exact<{
  input: CreateOneServiceInput;
}>;


export type CreateOneServiceMutation = { __typename?: 'Mutation', createOneService: { __typename: 'Service', id: string, name: string, abbreviation: string } };

export type UpdateOneServiceMutationVariables = Exact<{
  input: UpdateOneServiceInput;
}>;


export type UpdateOneServiceMutation = { __typename?: 'Mutation', updateOneService: { __typename?: 'Service', id: string, name: string, abbreviation: string } };

export type DeleteOneServiceMutationVariables = Exact<{
  input: DeleteOneServiceInput;
}>;


export type DeleteOneServiceMutation = { __typename?: 'Mutation', deleteOneService: { __typename?: 'ServiceDeleteResponse', id?: string | null } };

export type SubcontractStatesQueryVariables = Exact<{ [key: string]: never; }>;


export type SubcontractStatesQuery = { __typename?: 'Query', contractConcessionOrganizationStates: { __typename?: 'ContractConcessionOrganizationStateConnection', nodes: Array<{ __typename?: 'ContractConcessionOrganizationState', id: string, name: string }> } };

export type SubcontractsQueryVariables = Exact<{
  filter?: InputMaybe<ContractConcessionOrganizationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ContractConcessionOrganizationSort> | ContractConcessionOrganizationSort>;
}>;


export type SubcontractsQuery = { __typename?: 'Query', subcontracts: { __typename?: 'ContractConcessionOrganizationOffsetConnection', nodes: Array<{ __typename?: 'ContractConcessionOrganization', id: string, description?: string | null, startDate?: string | null, endDate?: string | null, contract: { __typename?: 'Contract', id: string, name: string }, contractConcessionOrganizationState?: { __typename?: 'ContractConcessionOrganizationState', id: string, name: string, code: string, backgroundColor: string, color: string } | null, concessionOrganization: { __typename?: 'ConcessionOrganization', id: string, organization: { __typename?: 'Organization', name: string } }, concessionOrganizationAccreditation?: { __typename?: 'ConcessionOrganizationAccreditation', id: string, concessionOrganizationAccreditationState: { __typename?: 'ConcessionOrganizationAccreditationState', id: string, name: string, backgroundColor: string, color: string }, concessionOrganizationAccreditationType: { __typename?: 'ConcessionOrganizationAccreditationType', id: string, name: string } } | null }> } };

export type UpdateOneSubcontractMutationVariables = Exact<{
  input: UpdateOneSubcontractInput;
}>;


export type UpdateOneSubcontractMutation = { __typename?: 'Mutation', updateOneSubcontract: { __typename?: 'ContractConcessionOrganization', id: string } };

export type CreateOneSubcontractMutationVariables = Exact<{
  input: CreateOneSubcontractInput;
}>;


export type CreateOneSubcontractMutation = { __typename?: 'Mutation', createOneSubcontract: { __typename?: 'ContractConcessionOrganization', id: string } };

export type DeleteOneSubcontractMutationVariables = Exact<{
  input: DeleteOneSubcontractInput;
}>;


export type DeleteOneSubcontractMutation = { __typename?: 'Mutation', deleteOneSubcontract: { __typename?: 'SubcontractDeleteResponse', id?: string | null } };

export type CreateOneSurveyRecordPhotoMutationVariables = Exact<{
  input: CreateOneSurveyRecordPhotoInput;
}>;


export type CreateOneSurveyRecordPhotoMutation = { __typename?: 'Mutation', createOneSurveyRecordPhoto: { __typename?: 'SurveyRecordPhoto', id: string } };

export type UpdateOneSurveyRecordPhotoMutationVariables = Exact<{
  input: UpdateOneSurveyRecordPhotoInput;
}>;


export type UpdateOneSurveyRecordPhotoMutation = { __typename?: 'Mutation', updateOneSurveyRecordPhoto: { __typename?: 'SurveyRecordPhoto', id: string } };

export type DeleteOneSurveyRecordPhotoMutationVariables = Exact<{
  input: DeleteOneSurveyRecordPhotoInput;
}>;


export type DeleteOneSurveyRecordPhotoMutation = { __typename?: 'Mutation', deleteOneSurveyRecordPhoto: { __typename?: 'SurveyRecordPhotoDeleteResponse', id?: string | null } };

export type SurveyRecordVerificationClassificationsQueryVariables = Exact<{
  filter?: InputMaybe<SurveyRecordVerificationClassificationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SurveyRecordVerificationClassificationSort> | SurveyRecordVerificationClassificationSort>;
}>;


export type SurveyRecordVerificationClassificationsQuery = { __typename?: 'Query', surveyRecordVerificationClassifications: { __typename?: 'SurveyRecordVerificationClassificationConnection', nodes: Array<{ __typename?: 'SurveyRecordVerificationClassification', id: string, name: string, abbreviation: string }> } };

export type CreateOneSurveyRecordVerificationClassificationMutationVariables = Exact<{
  input: CreateOneSurveyRecordVerificationClassificationInput;
}>;


export type CreateOneSurveyRecordVerificationClassificationMutation = { __typename?: 'Mutation', createOneSurveyRecordVerificationClassification: { __typename?: 'SurveyRecordVerificationClassification', id: string } };

export type UpdateOneSurveyRecordVerificationClassificationMutationVariables = Exact<{
  input: UpdateOneSurveyRecordVerificationClassificationInput;
}>;


export type UpdateOneSurveyRecordVerificationClassificationMutation = { __typename?: 'Mutation', updateOneSurveyRecordVerificationClassification: { __typename?: 'SurveyRecordVerificationClassification', id: string } };

export type DeleteOneSurveyRecordVerificationClassificationMutationVariables = Exact<{
  input: DeleteOneSurveyRecordVerificationClassificationInput;
}>;


export type DeleteOneSurveyRecordVerificationClassificationMutation = { __typename?: 'Mutation', deleteOneSurveyRecordVerificationClassification: { __typename?: 'SurveyRecordVerificationClassificationDeleteResponse', id?: string | null } };

export type SurveyRecordVerificationStatesQueryVariables = Exact<{
  filter?: InputMaybe<SurveyRecordVerificationStateFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SurveyRecordVerificationStateSort> | SurveyRecordVerificationStateSort>;
}>;


export type SurveyRecordVerificationStatesQuery = { __typename?: 'Query', surveyRecordVerificationStates: { __typename?: 'SurveyRecordVerificationStateConnection', nodes: Array<{ __typename?: 'SurveyRecordVerificationState', id: string, name: string }> } };

export type CreateOneSurveyRecordVerificationMutationVariables = Exact<{
  input: CreateOneSurveyRecordVerificationInput;
}>;


export type CreateOneSurveyRecordVerificationMutation = { __typename?: 'Mutation', createOneSurveyRecordVerification: { __typename?: 'SurveyRecordVerification', id: string } };

export type UpdateOneSurveyRecordVerificationMutationVariables = Exact<{
  input: UpdateOneSurveyRecordVerificationInput;
}>;


export type UpdateOneSurveyRecordVerificationMutation = { __typename?: 'Mutation', updateOneSurveyRecordVerification: { __typename?: 'SurveyRecordVerification', id: string } };

export type DeleteOneSurveyRecordVerificationMutationVariables = Exact<{
  input: DeleteOneSurveyRecordVerificationInput;
}>;


export type DeleteOneSurveyRecordVerificationMutation = { __typename?: 'Mutation', deleteOneSurveyRecordVerification: { __typename?: 'SurveyRecordVerificationDeleteResponse', id?: string | null } };

export type SurveyRecordsQueryVariables = Exact<{
  filter?: InputMaybe<SurveyRecordFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SurveyRecordSort> | SurveyRecordSort>;
}>;


export type SurveyRecordsQuery = { __typename?: 'Query', surveyRecords: { __typename?: 'SurveyRecordOffsetConnection', totalCount: number, nodes: Array<{ __typename?: 'SurveyRecord', id: string, name: string, description: string, state: string, startPk: string, endPk: string, surveyedAt: any, createdAt: any, isPublished: boolean, hasCorrectiveRequests: boolean, openCorrectiveRequests?: number | null, contract: { __typename?: 'Contract', id: string, name: string }, road: { __typename?: 'Road', id: string, name: string }, user: { __typename?: 'User', id: string, name: string }, startJunction: { __typename?: 'Junction', id: string, name: string }, endJunction: { __typename?: 'Junction', id: string, name: string }, weatherCondition: { __typename?: 'WeatherCondition', id: string, name: string } }> } };

export type SurveyRecordQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SurveyRecordQuery = { __typename?: 'Query', surveyRecord?: { __typename?: 'SurveyRecord', id: string, name: string, sequence: number, description: string, startPk: string, endPk: string, surveyedAt: any, participants?: string | null, state: string, isPublished: boolean, observations?: string | null, contract: { __typename?: 'Contract', id: string, name: string }, road: { __typename?: 'Road', id: string, name: string }, user: { __typename?: 'User', id: string, name: string }, startJunction: { __typename?: 'Junction', id: string, name: string }, endJunction: { __typename?: 'Junction', id: string, name: string }, weatherCondition: { __typename?: 'WeatherCondition', id: string, name: string }, surveyRecordPhotos?: Array<{ __typename?: 'SurveyRecordPhoto', id: string, name: string, description?: string | null, path: string, thumbnail: string }> | null, surveyRecordVerifications?: Array<{ __typename?: 'SurveyRecordVerification', id: string, description?: string | null, surveyRecordVerificationState?: { __typename?: 'SurveyRecordVerificationState', id: string, name: string } | null, surveyRecordVerificationClassification?: { __typename?: 'SurveyRecordVerificationClassification', id: string, name: string, abbreviation: string } | null, verification: { __typename?: 'Verification', id: string, name: string, verificationScope: { __typename?: 'VerificationScope', name: string, abbreviation: string } } }> | null } | null };

export type CreateOneSurveyRecordMutationVariables = Exact<{
  input: CreateOneSurveyRecordInput;
}>;


export type CreateOneSurveyRecordMutation = { __typename?: 'Mutation', createOneSurveyRecord: { __typename?: 'SurveyRecord', id: string } };

export type UpdateOneSurveyRecordMutationVariables = Exact<{
  input: UpdateOneSurveyRecordInput;
}>;


export type UpdateOneSurveyRecordMutation = { __typename?: 'Mutation', updateOneSurveyRecord: { __typename?: 'SurveyRecord', id: string } };

export type DeleteOneSurveyRecordMutationVariables = Exact<{
  input: DeleteOneSurveyRecordInput;
}>;


export type DeleteOneSurveyRecordMutation = { __typename?: 'Mutation', deleteOneSurveyRecord: { __typename?: 'SurveyRecordDeleteResponse', id?: string | null } };

export type ExportSurveyRecordPdfMutationVariables = Exact<{
  input: ExportSurveyRecordInput;
}>;


export type ExportSurveyRecordPdfMutation = { __typename?: 'Mutation', exportSurveyRecordPDF: string };

export type TaskDetailsFragment = { __typename?: 'Task', id: string, name: string, state: string, sequence: number, startAt: any, endAt: any, startPk: string, endPk: string, extension: number, reason: string, observations?: string | null, controlCenterObservations?: string | null, executingAuthority: string, executingAuthorityContact: string, signalingAuthority: string, signalingAuthorityContact: string, overlaps?: string | null, incident?: string | null, direction: { __typename?: 'Direction', id: string, name: string }, interventionLocation: { __typename?: 'InterventionLocation', id: string, name: string }, layout: { __typename?: 'Layout', id: string, name: string }, road: { __typename?: 'Road', id: string, name: string }, startJunction: { __typename?: 'Junction', id: string, name: string }, endJunction: { __typename?: 'Junction', id: string, name: string }, restriction: { __typename?: 'Restriction', id: string, name: string } };

export type TasksQueryVariables = Exact<{
  filter?: InputMaybe<TaskFilter>;
  sorting?: InputMaybe<Array<TaskSort> | TaskSort>;
}>;


export type TasksQuery = { __typename?: 'Query', tasks: { __typename?: 'TaskOffsetConnection', nodes: Array<{ __typename?: 'Task', id: string, name: string, state: string, sequence: number, startAt: any, endAt: any, startPk: string, endPk: string, extension: number, reason: string, observations?: string | null, controlCenterObservations?: string | null, executingAuthority: string, executingAuthorityContact: string, signalingAuthority: string, signalingAuthorityContact: string, overlaps?: string | null, incident?: string | null, intervention: { __typename?: 'Intervention', id: string, name: string, year: number, week: number, workPermit: { __typename?: 'WorkPermit', id: string, name: string, contract: { __typename?: 'Contract', id: string, contractConcessionOrganizations?: Array<{ __typename?: 'ContractConcessionOrganization', id: string, concessionOrganization: { __typename?: 'ConcessionOrganization', id: string, organization: { __typename?: 'Organization', id: string, name: string }, organizationType: { __typename?: 'OrganizationType', id: string, name: string } } }> | null } } }, direction: { __typename?: 'Direction', id: string, name: string }, interventionLocation: { __typename?: 'InterventionLocation', id: string, name: string }, layout: { __typename?: 'Layout', id: string, name: string }, road: { __typename?: 'Road', id: string, name: string }, startJunction: { __typename?: 'Junction', id: string, name: string }, endJunction: { __typename?: 'Junction', id: string, name: string }, restriction: { __typename?: 'Restriction', id: string, name: string } }> } };

export type CreateOneTaskMutationVariables = Exact<{
  input: CreateOneTaskInput;
}>;


export type CreateOneTaskMutation = { __typename?: 'Mutation', createOneTask: { __typename?: 'Task', id: string } };

export type UpdateOneTaskMutationVariables = Exact<{
  input: UpdateOneTaskInput;
}>;


export type UpdateOneTaskMutation = { __typename?: 'Mutation', updateOneTask: { __typename?: 'Task', id: string } };

export type UpdateManyTasksMutationVariables = Exact<{
  input: UpdateManyTasksInput;
}>;


export type UpdateManyTasksMutation = { __typename?: 'Mutation', updateManyTasks: { __typename?: 'UpdateManyResponse', updatedCount: number } };

export type DeleteOneTaskMutationVariables = Exact<{
  input: DeleteOneTaskInput;
}>;


export type DeleteOneTaskMutation = { __typename?: 'Mutation', deleteOneTask: { __typename?: 'TaskDeleteResponse', id?: string | null } };

export type DeleteManyTasksMutationVariables = Exact<{
  input: DeleteManyTasksInput;
}>;


export type DeleteManyTasksMutation = { __typename?: 'Mutation', deleteManyTasks: { __typename?: 'DeleteManyResponse', deletedCount: number } };

export type FindOverlapsMutationVariables = Exact<{
  input: FindOverlapsInput;
}>;


export type FindOverlapsMutation = { __typename?: 'Mutation', findOverlaps: string };

export type ClearOverlapsMutationVariables = Exact<{
  input: FindOverlapsInput;
}>;


export type ClearOverlapsMutation = { __typename?: 'Mutation', clearOverlaps: string };

export type GenerateWeeklyTasksZipMutationVariables = Exact<{
  input: GenerateWeeklyTasksZip;
}>;


export type GenerateWeeklyTasksZipMutation = { __typename?: 'Mutation', generateWeeklyTasksZip: string };

export type GenerateWeeklyTasksSheetMutationVariables = Exact<{
  input: GenerateWeeklyTasksZip;
}>;


export type GenerateWeeklyTasksSheetMutation = { __typename?: 'Mutation', generateWeeklyTasksSheet: string };

export type SurveyRecordAuthorsQueryVariables = Exact<{ [key: string]: never; }>;


export type SurveyRecordAuthorsQuery = { __typename?: 'Query', surveyRecordAuthors: { __typename?: 'UserOffsetConnection', nodes: Array<{ __typename?: 'User', id: string, name: string }> } };

export type VerificationScopesQueryVariables = Exact<{
  filter?: InputMaybe<VerificationScopeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<VerificationScopeSort> | VerificationScopeSort>;
}>;


export type VerificationScopesQuery = { __typename?: 'Query', verificationScopes: { __typename?: 'VerificationScopeConnection', nodes: Array<{ __typename?: 'VerificationScope', id: string, name: string, abbreviation: string }> } };

export type CreateOneVerificationScopeMutationVariables = Exact<{
  input: CreateOneVerificationScopeInput;
}>;


export type CreateOneVerificationScopeMutation = { __typename?: 'Mutation', createOneVerificationScope: { __typename?: 'VerificationScope', id: string } };

export type UpdateOneVerificationScopeMutationVariables = Exact<{
  input: UpdateOneVerificationScopeInput;
}>;


export type UpdateOneVerificationScopeMutation = { __typename?: 'Mutation', updateOneVerificationScope: { __typename?: 'VerificationScope', id: string } };

export type DeleteOneVerificationScopeMutationVariables = Exact<{
  input: DeleteOneVerificationScopeInput;
}>;


export type DeleteOneVerificationScopeMutation = { __typename?: 'Mutation', deleteOneVerificationScope: { __typename?: 'VerificationScopeDeleteResponse', id?: string | null } };

export type VerificationsQueryVariables = Exact<{
  filter?: InputMaybe<VerificationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<VerificationSort> | VerificationSort>;
}>;


export type VerificationsQuery = { __typename?: 'Query', verifications: { __typename?: 'VerificationConnection', nodes: Array<{ __typename?: 'Verification', id: string, name: string }> } };

export type CreateOneVerificationMutationVariables = Exact<{
  input: CreateOneVerificationInput;
}>;


export type CreateOneVerificationMutation = { __typename?: 'Mutation', createOneVerification: { __typename?: 'Verification', id: string } };

export type UpdateOneVerificationMutationVariables = Exact<{
  input: UpdateOneVerificationInput;
}>;


export type UpdateOneVerificationMutation = { __typename?: 'Mutation', updateOneVerification: { __typename?: 'Verification', id: string } };

export type DeleteOneVerificationMutationVariables = Exact<{
  input: DeleteOneVerificationInput;
}>;


export type DeleteOneVerificationMutation = { __typename?: 'Mutation', deleteOneVerification: { __typename?: 'VerificationDeleteResponse', id?: string | null } };

export type WorkPermitsQueryVariables = Exact<{
  filter?: InputMaybe<WorkPermitFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<WorkPermitSort> | WorkPermitSort>;
}>;


export type WorkPermitsQuery = { __typename?: 'Query', workPermits: { __typename?: 'WorkPermitOffsetConnection', totalCount: number, nodes: Array<{ __typename?: 'WorkPermit', id: string, name: string, startAt: string, endAt: string, state: string, isPublished: boolean, contract: { __typename?: 'Contract', id: string, name: string } }> } };

export type WorkPermitQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WorkPermitQuery = { __typename?: 'Query', workPermit?: { __typename?: 'WorkPermit', id: string, name: string, startAt: string, endAt: string, state: string, createdAt: any, observations?: string | null, isPublished: boolean, publishedAt?: string | null, contract: { __typename?: 'Contract', id: string, name: string, description: string, contractState: { __typename?: 'ContractState', id: string }, contractUsers?: Array<{ __typename?: 'ContractUser', id: string, role: string, user: { __typename?: 'User', id: string, name: string, phone?: string | null } }> | null, contractConcessionOrganizations?: Array<{ __typename?: 'ContractConcessionOrganization', id: string, concessionOrganization: { __typename?: 'ConcessionOrganization', organizationType: { __typename?: 'OrganizationType', id: string, name: string }, concession: { __typename?: 'Concession', id: string, name: string, logo?: string | null, controlCenters: Array<{ __typename?: 'ControlCenter', name: string, phone: string }> }, organization: { __typename?: 'Organization', id: string, name: string, tin: string, address: string, zipCode: string } } }> | null }, badges?: Array<{ __typename?: 'Badge', id: string, name: string, company: string, driver: string, licensePlate: string, state: string }> | null, checklist?: { __typename?: 'Checklist', id: string, isCompleted: boolean, checklistItems?: Array<{ __typename?: 'ChecklistItem', id: string, name: string, isCompleted: boolean, isOptional: boolean }> | null } | null } | null };

export type CreateOneWorkPermitMutationVariables = Exact<{
  input: CreateOneWorkPermitInput;
}>;


export type CreateOneWorkPermitMutation = { __typename?: 'Mutation', createOneWorkPermit: { __typename?: 'WorkPermit', id: string } };

export type UpdateOneWorkPermitMutationVariables = Exact<{
  input: UpdateOneWorkPermitInput;
}>;


export type UpdateOneWorkPermitMutation = { __typename?: 'Mutation', updateOneWorkPermit: { __typename?: 'WorkPermit', id: string } };

export type DeleteOneWorkPermitMutationVariables = Exact<{
  input: DeleteOneWorkPermitInput;
}>;


export type DeleteOneWorkPermitMutation = { __typename?: 'Mutation', deleteOneWorkPermit: { __typename?: 'WorkPermitDeleteResponse', id?: string | null } };

export type WorkPermitFieldQueryVariables = Exact<{
  filter?: InputMaybe<WorkPermitFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<WorkPermitSort> | WorkPermitSort>;
}>;


export type WorkPermitFieldQuery = { __typename?: 'Query', workPermits: { __typename?: 'WorkPermitOffsetConnection', nodes: Array<{ __typename?: 'WorkPermit', id: string, name: string, contract: { __typename?: 'Contract', id: string, name: string, realStartDate: string, realEndDate: string } }> } };

export type WorkspaceQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkspaceQuery = { __typename?: 'Query', workspace: { __typename?: 'Workspace', workspaceViews?: Array<{ __typename?: 'WorkspaceView', name: string, workspaceViewColumns?: Array<{ __typename?: 'WorkspaceViewColumn', name: string, visible: boolean, order: number }> | null }> | null } };

export type ManageWorkspaceViewColumnMutationVariables = Exact<{
  input: ManageWorkspaceViewColumn;
}>;


export type ManageWorkspaceViewColumnMutation = { __typename?: 'Mutation', manageWorkspaceViewColumn: boolean };

export type SetWorkspaceViewMutationVariables = Exact<{
  input: SetWorkspaceView;
}>;


export type SetWorkspaceViewMutation = { __typename?: 'Mutation', setWorkspaceView: boolean };

export const ConcessionOrganizationDocumentDetailsFragmentDoc = gql`
    fragment ConcessionOrganizationDocumentDetails on ConcessionOrganizationDocument {
  id
  name
  path
  expiresAt
  createdAt
  fileType {
    id
    name
    mimeType
    isPreviewable
  }
  owner {
    name
  }
  concessionOrganizationDocumentCategory {
    id
    name
    expirationRequired
    policy
  }
  concessionOrganizationDocumentSubcategory {
    id
    name
    expirationRequired
    policy
  }
  documentState {
    id
    name
    code
  }
}
    `;
export const TaskDetailsFragmentDoc = gql`
    fragment TaskDetails on Task {
  id
  name
  state
  sequence
  startAt
  endAt
  startPk
  endPk
  extension
  reason
  observations
  controlCenterObservations
  executingAuthority
  executingAuthorityContact
  signalingAuthority
  signalingAuthorityContact
  overlaps
  direction {
    id
    name
  }
  interventionLocation {
    id
    name
  }
  layout {
    id
    name
  }
  incident
  road {
    id
    name
  }
  startJunction {
    id
    name
  }
  endJunction {
    id
    name
  }
  restriction {
    id
    name
  }
}
    `;
export const CreateOneConcessionOrganizationAccreditationDocumentDocument = gql`
    mutation createOneConcessionOrganizationAccreditationDocument($input: CreateOneConcessionOrganizationAccreditationDocumentInput!) {
  createOneConcessionOrganizationAccreditationDocument(input: $input) {
    id
  }
}
    `;
export type CreateOneConcessionOrganizationAccreditationDocumentMutationFn = Apollo.MutationFunction<CreateOneConcessionOrganizationAccreditationDocumentMutation, CreateOneConcessionOrganizationAccreditationDocumentMutationVariables>;

/**
 * __useCreateOneConcessionOrganizationAccreditationDocumentMutation__
 *
 * To run a mutation, you first call `useCreateOneConcessionOrganizationAccreditationDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneConcessionOrganizationAccreditationDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneConcessionOrganizationAccreditationDocumentMutation, { data, loading, error }] = useCreateOneConcessionOrganizationAccreditationDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneConcessionOrganizationAccreditationDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneConcessionOrganizationAccreditationDocumentMutation, CreateOneConcessionOrganizationAccreditationDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneConcessionOrganizationAccreditationDocumentMutation, CreateOneConcessionOrganizationAccreditationDocumentMutationVariables>(CreateOneConcessionOrganizationAccreditationDocumentDocument, options);
      }
export type CreateOneConcessionOrganizationAccreditationDocumentMutationHookResult = ReturnType<typeof useCreateOneConcessionOrganizationAccreditationDocumentMutation>;
export type CreateOneConcessionOrganizationAccreditationDocumentMutationResult = Apollo.MutationResult<CreateOneConcessionOrganizationAccreditationDocumentMutation>;
export type CreateOneConcessionOrganizationAccreditationDocumentMutationOptions = Apollo.BaseMutationOptions<CreateOneConcessionOrganizationAccreditationDocumentMutation, CreateOneConcessionOrganizationAccreditationDocumentMutationVariables>;
export const DeleteOneConcessionOrganizationAccreditationDocumentDocument = gql`
    mutation deleteOneConcessionOrganizationAccreditationDocument($input: DeleteOneConcessionOrganizationAccreditationDocumentInput!) {
  deleteOneConcessionOrganizationAccreditationDocument(input: $input) {
    id
  }
}
    `;
export type DeleteOneConcessionOrganizationAccreditationDocumentMutationFn = Apollo.MutationFunction<DeleteOneConcessionOrganizationAccreditationDocumentMutation, DeleteOneConcessionOrganizationAccreditationDocumentMutationVariables>;

/**
 * __useDeleteOneConcessionOrganizationAccreditationDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteOneConcessionOrganizationAccreditationDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneConcessionOrganizationAccreditationDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneConcessionOrganizationAccreditationDocumentMutation, { data, loading, error }] = useDeleteOneConcessionOrganizationAccreditationDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneConcessionOrganizationAccreditationDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneConcessionOrganizationAccreditationDocumentMutation, DeleteOneConcessionOrganizationAccreditationDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneConcessionOrganizationAccreditationDocumentMutation, DeleteOneConcessionOrganizationAccreditationDocumentMutationVariables>(DeleteOneConcessionOrganizationAccreditationDocumentDocument, options);
      }
export type DeleteOneConcessionOrganizationAccreditationDocumentMutationHookResult = ReturnType<typeof useDeleteOneConcessionOrganizationAccreditationDocumentMutation>;
export type DeleteOneConcessionOrganizationAccreditationDocumentMutationResult = Apollo.MutationResult<DeleteOneConcessionOrganizationAccreditationDocumentMutation>;
export type DeleteOneConcessionOrganizationAccreditationDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteOneConcessionOrganizationAccreditationDocumentMutation, DeleteOneConcessionOrganizationAccreditationDocumentMutationVariables>;
export const ImportConcessionOrganizationAccreditationDocumentsDocument = gql`
    mutation importConcessionOrganizationAccreditationDocuments($input: ImportConcessionOrganizationAccreditationDocumentsInput!) {
  importConcessionOrganizationAccreditationDocuments(input: $input) {
    duplicates
  }
}
    `;
export type ImportConcessionOrganizationAccreditationDocumentsMutationFn = Apollo.MutationFunction<ImportConcessionOrganizationAccreditationDocumentsMutation, ImportConcessionOrganizationAccreditationDocumentsMutationVariables>;

/**
 * __useImportConcessionOrganizationAccreditationDocumentsMutation__
 *
 * To run a mutation, you first call `useImportConcessionOrganizationAccreditationDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportConcessionOrganizationAccreditationDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importConcessionOrganizationAccreditationDocumentsMutation, { data, loading, error }] = useImportConcessionOrganizationAccreditationDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportConcessionOrganizationAccreditationDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<ImportConcessionOrganizationAccreditationDocumentsMutation, ImportConcessionOrganizationAccreditationDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportConcessionOrganizationAccreditationDocumentsMutation, ImportConcessionOrganizationAccreditationDocumentsMutationVariables>(ImportConcessionOrganizationAccreditationDocumentsDocument, options);
      }
export type ImportConcessionOrganizationAccreditationDocumentsMutationHookResult = ReturnType<typeof useImportConcessionOrganizationAccreditationDocumentsMutation>;
export type ImportConcessionOrganizationAccreditationDocumentsMutationResult = Apollo.MutationResult<ImportConcessionOrganizationAccreditationDocumentsMutation>;
export type ImportConcessionOrganizationAccreditationDocumentsMutationOptions = Apollo.BaseMutationOptions<ImportConcessionOrganizationAccreditationDocumentsMutation, ImportConcessionOrganizationAccreditationDocumentsMutationVariables>;
export const ConcessionOrganizationAccreditationStatesDocument = gql`
    query concessionOrganizationAccreditationStates {
  concessionOrganizationAccreditationStates {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useConcessionOrganizationAccreditationStatesQuery__
 *
 * To run a query within a React component, call `useConcessionOrganizationAccreditationStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConcessionOrganizationAccreditationStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConcessionOrganizationAccreditationStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useConcessionOrganizationAccreditationStatesQuery(baseOptions?: Apollo.QueryHookOptions<ConcessionOrganizationAccreditationStatesQuery, ConcessionOrganizationAccreditationStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConcessionOrganizationAccreditationStatesQuery, ConcessionOrganizationAccreditationStatesQueryVariables>(ConcessionOrganizationAccreditationStatesDocument, options);
      }
export function useConcessionOrganizationAccreditationStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConcessionOrganizationAccreditationStatesQuery, ConcessionOrganizationAccreditationStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConcessionOrganizationAccreditationStatesQuery, ConcessionOrganizationAccreditationStatesQueryVariables>(ConcessionOrganizationAccreditationStatesDocument, options);
        }
export type ConcessionOrganizationAccreditationStatesQueryHookResult = ReturnType<typeof useConcessionOrganizationAccreditationStatesQuery>;
export type ConcessionOrganizationAccreditationStatesLazyQueryHookResult = ReturnType<typeof useConcessionOrganizationAccreditationStatesLazyQuery>;
export type ConcessionOrganizationAccreditationStatesQueryResult = Apollo.QueryResult<ConcessionOrganizationAccreditationStatesQuery, ConcessionOrganizationAccreditationStatesQueryVariables>;
export const ConcessionOrganizationAccreditationTypesDocument = gql`
    query concessionOrganizationAccreditationTypes($filter: ConcessionOrganizationAccreditationTypeFilter, $paging: OffsetPaging, $sorting: [ConcessionOrganizationAccreditationTypeSort!]) {
  concessionOrganizationAccreditationTypes(
    filter: $filter
    paging: $paging
    sorting: $sorting
  ) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useConcessionOrganizationAccreditationTypesQuery__
 *
 * To run a query within a React component, call `useConcessionOrganizationAccreditationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConcessionOrganizationAccreditationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConcessionOrganizationAccreditationTypesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useConcessionOrganizationAccreditationTypesQuery(baseOptions?: Apollo.QueryHookOptions<ConcessionOrganizationAccreditationTypesQuery, ConcessionOrganizationAccreditationTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConcessionOrganizationAccreditationTypesQuery, ConcessionOrganizationAccreditationTypesQueryVariables>(ConcessionOrganizationAccreditationTypesDocument, options);
      }
export function useConcessionOrganizationAccreditationTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConcessionOrganizationAccreditationTypesQuery, ConcessionOrganizationAccreditationTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConcessionOrganizationAccreditationTypesQuery, ConcessionOrganizationAccreditationTypesQueryVariables>(ConcessionOrganizationAccreditationTypesDocument, options);
        }
export type ConcessionOrganizationAccreditationTypesQueryHookResult = ReturnType<typeof useConcessionOrganizationAccreditationTypesQuery>;
export type ConcessionOrganizationAccreditationTypesLazyQueryHookResult = ReturnType<typeof useConcessionOrganizationAccreditationTypesLazyQuery>;
export type ConcessionOrganizationAccreditationTypesQueryResult = Apollo.QueryResult<ConcessionOrganizationAccreditationTypesQuery, ConcessionOrganizationAccreditationTypesQueryVariables>;
export const ConcessionOrganizationAccreditationsDocument = gql`
    query concessionOrganizationAccreditations($filter: ConcessionOrganizationAccreditationFilter, $paging: OffsetPaging, $sorting: [ConcessionOrganizationAccreditationSort!]) {
  concessionOrganizationAccreditations(
    filter: $filter
    paging: $paging
    sorting: $sorting
  ) {
    totalCount
    nodes {
      id
      name
      observations
      startDate
      endDate
      concessionOrganizationAccreditationType {
        id
        name
      }
      concessionOrganizationAccreditationState {
        id
        name
        code
        color
        backgroundColor
      }
    }
  }
}
    `;

/**
 * __useConcessionOrganizationAccreditationsQuery__
 *
 * To run a query within a React component, call `useConcessionOrganizationAccreditationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConcessionOrganizationAccreditationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConcessionOrganizationAccreditationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useConcessionOrganizationAccreditationsQuery(baseOptions?: Apollo.QueryHookOptions<ConcessionOrganizationAccreditationsQuery, ConcessionOrganizationAccreditationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConcessionOrganizationAccreditationsQuery, ConcessionOrganizationAccreditationsQueryVariables>(ConcessionOrganizationAccreditationsDocument, options);
      }
export function useConcessionOrganizationAccreditationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConcessionOrganizationAccreditationsQuery, ConcessionOrganizationAccreditationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConcessionOrganizationAccreditationsQuery, ConcessionOrganizationAccreditationsQueryVariables>(ConcessionOrganizationAccreditationsDocument, options);
        }
export type ConcessionOrganizationAccreditationsQueryHookResult = ReturnType<typeof useConcessionOrganizationAccreditationsQuery>;
export type ConcessionOrganizationAccreditationsLazyQueryHookResult = ReturnType<typeof useConcessionOrganizationAccreditationsLazyQuery>;
export type ConcessionOrganizationAccreditationsQueryResult = Apollo.QueryResult<ConcessionOrganizationAccreditationsQuery, ConcessionOrganizationAccreditationsQueryVariables>;
export const ConcessionOrganizationAccreditationDocument = gql`
    query concessionOrganizationAccreditation($id: ID!) {
  concessionOrganizationAccreditation(id: $id) {
    id
    name
    startDate
    endDate
    createdAt
    observations
    mediatingOrganization {
      id
      organization {
        id
        name
      }
    }
    concessionOrganization {
      id
      organization {
        id
        name
      }
    }
    concessionOrganizationAccreditationType {
      id
      name
    }
    concessionOrganizationAccreditationState {
      id
      name
      code
    }
    concessionOrganizationAccreditationDocuments(
      sorting: {field: id, direction: ASC}
    ) {
      id
      name
      path
      expiresAt
      fileType {
        id
        name
        mimeType
        isPreviewable
      }
      documentState {
        id
        name
      }
      concessionOrganizationDocumentCategory {
        id
        name
      }
      concessionOrganizationDocumentSubcategory {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useConcessionOrganizationAccreditationQuery__
 *
 * To run a query within a React component, call `useConcessionOrganizationAccreditationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConcessionOrganizationAccreditationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConcessionOrganizationAccreditationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConcessionOrganizationAccreditationQuery(baseOptions: Apollo.QueryHookOptions<ConcessionOrganizationAccreditationQuery, ConcessionOrganizationAccreditationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConcessionOrganizationAccreditationQuery, ConcessionOrganizationAccreditationQueryVariables>(ConcessionOrganizationAccreditationDocument, options);
      }
export function useConcessionOrganizationAccreditationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConcessionOrganizationAccreditationQuery, ConcessionOrganizationAccreditationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConcessionOrganizationAccreditationQuery, ConcessionOrganizationAccreditationQueryVariables>(ConcessionOrganizationAccreditationDocument, options);
        }
export type ConcessionOrganizationAccreditationQueryHookResult = ReturnType<typeof useConcessionOrganizationAccreditationQuery>;
export type ConcessionOrganizationAccreditationLazyQueryHookResult = ReturnType<typeof useConcessionOrganizationAccreditationLazyQuery>;
export type ConcessionOrganizationAccreditationQueryResult = Apollo.QueryResult<ConcessionOrganizationAccreditationQuery, ConcessionOrganizationAccreditationQueryVariables>;
export const SelectedAccreditationDocument = gql`
    query selectedAccreditation($input: SelectedAccreditationInput!) {
  selectedAccreditation(input: $input) {
    id
    name
    startDate
    endDate
    mediatingOrganization {
      organization {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSelectedAccreditationQuery__
 *
 * To run a query within a React component, call `useSelectedAccreditationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedAccreditationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedAccreditationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelectedAccreditationQuery(baseOptions: Apollo.QueryHookOptions<SelectedAccreditationQuery, SelectedAccreditationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectedAccreditationQuery, SelectedAccreditationQueryVariables>(SelectedAccreditationDocument, options);
      }
export function useSelectedAccreditationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectedAccreditationQuery, SelectedAccreditationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectedAccreditationQuery, SelectedAccreditationQueryVariables>(SelectedAccreditationDocument, options);
        }
export type SelectedAccreditationQueryHookResult = ReturnType<typeof useSelectedAccreditationQuery>;
export type SelectedAccreditationLazyQueryHookResult = ReturnType<typeof useSelectedAccreditationLazyQuery>;
export type SelectedAccreditationQueryResult = Apollo.QueryResult<SelectedAccreditationQuery, SelectedAccreditationQueryVariables>;
export const CreateOneConcessionOrganizationAccreditationDocument = gql`
    mutation createOneConcessionOrganizationAccreditation($input: CreateOneConcessionOrganizationAccreditationInput!) {
  createOneConcessionOrganizationAccreditation(input: $input) {
    id
  }
}
    `;
export type CreateOneConcessionOrganizationAccreditationMutationFn = Apollo.MutationFunction<CreateOneConcessionOrganizationAccreditationMutation, CreateOneConcessionOrganizationAccreditationMutationVariables>;

/**
 * __useCreateOneConcessionOrganizationAccreditationMutation__
 *
 * To run a mutation, you first call `useCreateOneConcessionOrganizationAccreditationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneConcessionOrganizationAccreditationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneConcessionOrganizationAccreditationMutation, { data, loading, error }] = useCreateOneConcessionOrganizationAccreditationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneConcessionOrganizationAccreditationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneConcessionOrganizationAccreditationMutation, CreateOneConcessionOrganizationAccreditationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneConcessionOrganizationAccreditationMutation, CreateOneConcessionOrganizationAccreditationMutationVariables>(CreateOneConcessionOrganizationAccreditationDocument, options);
      }
export type CreateOneConcessionOrganizationAccreditationMutationHookResult = ReturnType<typeof useCreateOneConcessionOrganizationAccreditationMutation>;
export type CreateOneConcessionOrganizationAccreditationMutationResult = Apollo.MutationResult<CreateOneConcessionOrganizationAccreditationMutation>;
export type CreateOneConcessionOrganizationAccreditationMutationOptions = Apollo.BaseMutationOptions<CreateOneConcessionOrganizationAccreditationMutation, CreateOneConcessionOrganizationAccreditationMutationVariables>;
export const UpdateOneConcessionOrganizationAccreditationDocument = gql`
    mutation updateOneConcessionOrganizationAccreditation($input: UpdateOneConcessionOrganizationAccreditationInput!) {
  updateOneConcessionOrganizationAccreditation(input: $input) {
    id
  }
}
    `;
export type UpdateOneConcessionOrganizationAccreditationMutationFn = Apollo.MutationFunction<UpdateOneConcessionOrganizationAccreditationMutation, UpdateOneConcessionOrganizationAccreditationMutationVariables>;

/**
 * __useUpdateOneConcessionOrganizationAccreditationMutation__
 *
 * To run a mutation, you first call `useUpdateOneConcessionOrganizationAccreditationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneConcessionOrganizationAccreditationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneConcessionOrganizationAccreditationMutation, { data, loading, error }] = useUpdateOneConcessionOrganizationAccreditationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneConcessionOrganizationAccreditationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneConcessionOrganizationAccreditationMutation, UpdateOneConcessionOrganizationAccreditationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneConcessionOrganizationAccreditationMutation, UpdateOneConcessionOrganizationAccreditationMutationVariables>(UpdateOneConcessionOrganizationAccreditationDocument, options);
      }
export type UpdateOneConcessionOrganizationAccreditationMutationHookResult = ReturnType<typeof useUpdateOneConcessionOrganizationAccreditationMutation>;
export type UpdateOneConcessionOrganizationAccreditationMutationResult = Apollo.MutationResult<UpdateOneConcessionOrganizationAccreditationMutation>;
export type UpdateOneConcessionOrganizationAccreditationMutationOptions = Apollo.BaseMutationOptions<UpdateOneConcessionOrganizationAccreditationMutation, UpdateOneConcessionOrganizationAccreditationMutationVariables>;
export const DeleteOneConcessionOrganizationAccreditationDocument = gql`
    mutation deleteOneConcessionOrganizationAccreditation($input: DeleteOneConcessionOrganizationAccreditationInput!) {
  deleteOneConcessionOrganizationAccreditation(input: $input) {
    id
  }
}
    `;
export type DeleteOneConcessionOrganizationAccreditationMutationFn = Apollo.MutationFunction<DeleteOneConcessionOrganizationAccreditationMutation, DeleteOneConcessionOrganizationAccreditationMutationVariables>;

/**
 * __useDeleteOneConcessionOrganizationAccreditationMutation__
 *
 * To run a mutation, you first call `useDeleteOneConcessionOrganizationAccreditationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneConcessionOrganizationAccreditationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneConcessionOrganizationAccreditationMutation, { data, loading, error }] = useDeleteOneConcessionOrganizationAccreditationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneConcessionOrganizationAccreditationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneConcessionOrganizationAccreditationMutation, DeleteOneConcessionOrganizationAccreditationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneConcessionOrganizationAccreditationMutation, DeleteOneConcessionOrganizationAccreditationMutationVariables>(DeleteOneConcessionOrganizationAccreditationDocument, options);
      }
export type DeleteOneConcessionOrganizationAccreditationMutationHookResult = ReturnType<typeof useDeleteOneConcessionOrganizationAccreditationMutation>;
export type DeleteOneConcessionOrganizationAccreditationMutationResult = Apollo.MutationResult<DeleteOneConcessionOrganizationAccreditationMutation>;
export type DeleteOneConcessionOrganizationAccreditationMutationOptions = Apollo.BaseMutationOptions<DeleteOneConcessionOrganizationAccreditationMutation, DeleteOneConcessionOrganizationAccreditationMutationVariables>;
export const CreateManyBadgesDocument = gql`
    mutation createManyBadges($input: CreateManyBadgesInput!) {
  createManyBadges(input: $input) {
    id
  }
}
    `;
export type CreateManyBadgesMutationFn = Apollo.MutationFunction<CreateManyBadgesMutation, CreateManyBadgesMutationVariables>;

/**
 * __useCreateManyBadgesMutation__
 *
 * To run a mutation, you first call `useCreateManyBadgesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyBadgesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManyBadgesMutation, { data, loading, error }] = useCreateManyBadgesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManyBadgesMutation(baseOptions?: Apollo.MutationHookOptions<CreateManyBadgesMutation, CreateManyBadgesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManyBadgesMutation, CreateManyBadgesMutationVariables>(CreateManyBadgesDocument, options);
      }
export type CreateManyBadgesMutationHookResult = ReturnType<typeof useCreateManyBadgesMutation>;
export type CreateManyBadgesMutationResult = Apollo.MutationResult<CreateManyBadgesMutation>;
export type CreateManyBadgesMutationOptions = Apollo.BaseMutationOptions<CreateManyBadgesMutation, CreateManyBadgesMutationVariables>;
export const CreateOneBadgeDocument = gql`
    mutation createOneBadge($input: CreateOneBadgeInput!) {
  createOneBadge(input: $input) {
    id
  }
}
    `;
export type CreateOneBadgeMutationFn = Apollo.MutationFunction<CreateOneBadgeMutation, CreateOneBadgeMutationVariables>;

/**
 * __useCreateOneBadgeMutation__
 *
 * To run a mutation, you first call `useCreateOneBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneBadgeMutation, { data, loading, error }] = useCreateOneBadgeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneBadgeMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneBadgeMutation, CreateOneBadgeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneBadgeMutation, CreateOneBadgeMutationVariables>(CreateOneBadgeDocument, options);
      }
export type CreateOneBadgeMutationHookResult = ReturnType<typeof useCreateOneBadgeMutation>;
export type CreateOneBadgeMutationResult = Apollo.MutationResult<CreateOneBadgeMutation>;
export type CreateOneBadgeMutationOptions = Apollo.BaseMutationOptions<CreateOneBadgeMutation, CreateOneBadgeMutationVariables>;
export const UpdateOneBadgeDocument = gql`
    mutation updateOneBadge($input: UpdateOneBadgeInput!) {
  updateOneBadge(input: $input) {
    id
  }
}
    `;
export type UpdateOneBadgeMutationFn = Apollo.MutationFunction<UpdateOneBadgeMutation, UpdateOneBadgeMutationVariables>;

/**
 * __useUpdateOneBadgeMutation__
 *
 * To run a mutation, you first call `useUpdateOneBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneBadgeMutation, { data, loading, error }] = useUpdateOneBadgeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneBadgeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneBadgeMutation, UpdateOneBadgeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneBadgeMutation, UpdateOneBadgeMutationVariables>(UpdateOneBadgeDocument, options);
      }
export type UpdateOneBadgeMutationHookResult = ReturnType<typeof useUpdateOneBadgeMutation>;
export type UpdateOneBadgeMutationResult = Apollo.MutationResult<UpdateOneBadgeMutation>;
export type UpdateOneBadgeMutationOptions = Apollo.BaseMutationOptions<UpdateOneBadgeMutation, UpdateOneBadgeMutationVariables>;
export const DeleteOneBadgeDocument = gql`
    mutation deleteOneBadge($input: DeleteOneBadgeInput!) {
  deleteOneBadge(input: $input) {
    id
  }
}
    `;
export type DeleteOneBadgeMutationFn = Apollo.MutationFunction<DeleteOneBadgeMutation, DeleteOneBadgeMutationVariables>;

/**
 * __useDeleteOneBadgeMutation__
 *
 * To run a mutation, you first call `useDeleteOneBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneBadgeMutation, { data, loading, error }] = useDeleteOneBadgeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneBadgeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneBadgeMutation, DeleteOneBadgeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneBadgeMutation, DeleteOneBadgeMutationVariables>(DeleteOneBadgeDocument, options);
      }
export type DeleteOneBadgeMutationHookResult = ReturnType<typeof useDeleteOneBadgeMutation>;
export type DeleteOneBadgeMutationResult = Apollo.MutationResult<DeleteOneBadgeMutation>;
export type DeleteOneBadgeMutationOptions = Apollo.BaseMutationOptions<DeleteOneBadgeMutation, DeleteOneBadgeMutationVariables>;
export const UpdateOneChecklistItemDocument = gql`
    mutation updateOneChecklistItem($input: UpdateOneChecklistItemInput!) {
  updateOneChecklistItem(input: $input) {
    id
  }
}
    `;
export type UpdateOneChecklistItemMutationFn = Apollo.MutationFunction<UpdateOneChecklistItemMutation, UpdateOneChecklistItemMutationVariables>;

/**
 * __useUpdateOneChecklistItemMutation__
 *
 * To run a mutation, you first call `useUpdateOneChecklistItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneChecklistItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneChecklistItemMutation, { data, loading, error }] = useUpdateOneChecklistItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneChecklistItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneChecklistItemMutation, UpdateOneChecklistItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneChecklistItemMutation, UpdateOneChecklistItemMutationVariables>(UpdateOneChecklistItemDocument, options);
      }
export type UpdateOneChecklistItemMutationHookResult = ReturnType<typeof useUpdateOneChecklistItemMutation>;
export type UpdateOneChecklistItemMutationResult = Apollo.MutationResult<UpdateOneChecklistItemMutation>;
export type UpdateOneChecklistItemMutationOptions = Apollo.BaseMutationOptions<UpdateOneChecklistItemMutation, UpdateOneChecklistItemMutationVariables>;
export const ConcessionOrganizationDocumentCategoriesDocument = gql`
    query concessionOrganizationDocumentCategories($filter: ConcessionOrganizationDocumentCategoryFilter, $paging: OffsetPaging, $sorting: [ConcessionOrganizationDocumentCategorySort!]) {
  concessionOrganizationDocumentCategories(
    filter: $filter
    paging: $paging
    sorting: $sorting
  ) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useConcessionOrganizationDocumentCategoriesQuery__
 *
 * To run a query within a React component, call `useConcessionOrganizationDocumentCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConcessionOrganizationDocumentCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConcessionOrganizationDocumentCategoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useConcessionOrganizationDocumentCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ConcessionOrganizationDocumentCategoriesQuery, ConcessionOrganizationDocumentCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConcessionOrganizationDocumentCategoriesQuery, ConcessionOrganizationDocumentCategoriesQueryVariables>(ConcessionOrganizationDocumentCategoriesDocument, options);
      }
export function useConcessionOrganizationDocumentCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConcessionOrganizationDocumentCategoriesQuery, ConcessionOrganizationDocumentCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConcessionOrganizationDocumentCategoriesQuery, ConcessionOrganizationDocumentCategoriesQueryVariables>(ConcessionOrganizationDocumentCategoriesDocument, options);
        }
export type ConcessionOrganizationDocumentCategoriesQueryHookResult = ReturnType<typeof useConcessionOrganizationDocumentCategoriesQuery>;
export type ConcessionOrganizationDocumentCategoriesLazyQueryHookResult = ReturnType<typeof useConcessionOrganizationDocumentCategoriesLazyQuery>;
export type ConcessionOrganizationDocumentCategoriesQueryResult = Apollo.QueryResult<ConcessionOrganizationDocumentCategoriesQuery, ConcessionOrganizationDocumentCategoriesQueryVariables>;
export const ConcessionOrganizationDocumentCategoriesWithSubcategoriesDocument = gql`
    query concessionOrganizationDocumentCategoriesWithSubcategories {
  concessionOrganizationDocumentCategories(
    filter: {parent: {id: {is: null}}}
    paging: {limit: 9999}
    sorting: {field: name, direction: ASC}
  ) {
    nodes {
      id
      name
      children(sorting: {field: name, direction: ASC}) {
        id
        name
        observations
        expirationRequired
      }
    }
  }
}
    `;

/**
 * __useConcessionOrganizationDocumentCategoriesWithSubcategoriesQuery__
 *
 * To run a query within a React component, call `useConcessionOrganizationDocumentCategoriesWithSubcategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConcessionOrganizationDocumentCategoriesWithSubcategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConcessionOrganizationDocumentCategoriesWithSubcategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useConcessionOrganizationDocumentCategoriesWithSubcategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ConcessionOrganizationDocumentCategoriesWithSubcategoriesQuery, ConcessionOrganizationDocumentCategoriesWithSubcategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConcessionOrganizationDocumentCategoriesWithSubcategoriesQuery, ConcessionOrganizationDocumentCategoriesWithSubcategoriesQueryVariables>(ConcessionOrganizationDocumentCategoriesWithSubcategoriesDocument, options);
      }
export function useConcessionOrganizationDocumentCategoriesWithSubcategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConcessionOrganizationDocumentCategoriesWithSubcategoriesQuery, ConcessionOrganizationDocumentCategoriesWithSubcategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConcessionOrganizationDocumentCategoriesWithSubcategoriesQuery, ConcessionOrganizationDocumentCategoriesWithSubcategoriesQueryVariables>(ConcessionOrganizationDocumentCategoriesWithSubcategoriesDocument, options);
        }
export type ConcessionOrganizationDocumentCategoriesWithSubcategoriesQueryHookResult = ReturnType<typeof useConcessionOrganizationDocumentCategoriesWithSubcategoriesQuery>;
export type ConcessionOrganizationDocumentCategoriesWithSubcategoriesLazyQueryHookResult = ReturnType<typeof useConcessionOrganizationDocumentCategoriesWithSubcategoriesLazyQuery>;
export type ConcessionOrganizationDocumentCategoriesWithSubcategoriesQueryResult = Apollo.QueryResult<ConcessionOrganizationDocumentCategoriesWithSubcategoriesQuery, ConcessionOrganizationDocumentCategoriesWithSubcategoriesQueryVariables>;
export const ConcessionOrganizationDocumentsDocument = gql`
    query concessionOrganizationDocuments($filter: ConcessionOrganizationDocumentFilter, $paging: OffsetPaging, $sorting: [ConcessionOrganizationDocumentSort!]) {
  concessionOrganizationDocuments(
    filter: $filter
    paging: $paging
    sorting: $sorting
  ) {
    totalCount
    nodes {
      ...ConcessionOrganizationDocumentDetails
    }
  }
}
    ${ConcessionOrganizationDocumentDetailsFragmentDoc}`;

/**
 * __useConcessionOrganizationDocumentsQuery__
 *
 * To run a query within a React component, call `useConcessionOrganizationDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConcessionOrganizationDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConcessionOrganizationDocumentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useConcessionOrganizationDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<ConcessionOrganizationDocumentsQuery, ConcessionOrganizationDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConcessionOrganizationDocumentsQuery, ConcessionOrganizationDocumentsQueryVariables>(ConcessionOrganizationDocumentsDocument, options);
      }
export function useConcessionOrganizationDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConcessionOrganizationDocumentsQuery, ConcessionOrganizationDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConcessionOrganizationDocumentsQuery, ConcessionOrganizationDocumentsQueryVariables>(ConcessionOrganizationDocumentsDocument, options);
        }
export type ConcessionOrganizationDocumentsQueryHookResult = ReturnType<typeof useConcessionOrganizationDocumentsQuery>;
export type ConcessionOrganizationDocumentsLazyQueryHookResult = ReturnType<typeof useConcessionOrganizationDocumentsLazyQuery>;
export type ConcessionOrganizationDocumentsQueryResult = Apollo.QueryResult<ConcessionOrganizationDocumentsQuery, ConcessionOrganizationDocumentsQueryVariables>;
export const CreateOneConcessionOrganizationDocumentDocument = gql`
    mutation createOneConcessionOrganizationDocument($input: CreateOneConcessionOrganizationDocumentInput!) {
  createOneConcessionOrganizationDocument(input: $input) {
    id
  }
}
    `;
export type CreateOneConcessionOrganizationDocumentMutationFn = Apollo.MutationFunction<CreateOneConcessionOrganizationDocumentMutation, CreateOneConcessionOrganizationDocumentMutationVariables>;

/**
 * __useCreateOneConcessionOrganizationDocumentMutation__
 *
 * To run a mutation, you first call `useCreateOneConcessionOrganizationDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneConcessionOrganizationDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneConcessionOrganizationDocumentMutation, { data, loading, error }] = useCreateOneConcessionOrganizationDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneConcessionOrganizationDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneConcessionOrganizationDocumentMutation, CreateOneConcessionOrganizationDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneConcessionOrganizationDocumentMutation, CreateOneConcessionOrganizationDocumentMutationVariables>(CreateOneConcessionOrganizationDocumentDocument, options);
      }
export type CreateOneConcessionOrganizationDocumentMutationHookResult = ReturnType<typeof useCreateOneConcessionOrganizationDocumentMutation>;
export type CreateOneConcessionOrganizationDocumentMutationResult = Apollo.MutationResult<CreateOneConcessionOrganizationDocumentMutation>;
export type CreateOneConcessionOrganizationDocumentMutationOptions = Apollo.BaseMutationOptions<CreateOneConcessionOrganizationDocumentMutation, CreateOneConcessionOrganizationDocumentMutationVariables>;
export const UpdateOneConcessionOrganizationDocumentDocument = gql`
    mutation updateOneConcessionOrganizationDocument($input: UpdateOneConcessionOrganizationDocumentInput!) {
  updateOneConcessionOrganizationDocument(input: $input) {
    id
  }
}
    `;
export type UpdateOneConcessionOrganizationDocumentMutationFn = Apollo.MutationFunction<UpdateOneConcessionOrganizationDocumentMutation, UpdateOneConcessionOrganizationDocumentMutationVariables>;

/**
 * __useUpdateOneConcessionOrganizationDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateOneConcessionOrganizationDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneConcessionOrganizationDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneConcessionOrganizationDocumentMutation, { data, loading, error }] = useUpdateOneConcessionOrganizationDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneConcessionOrganizationDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneConcessionOrganizationDocumentMutation, UpdateOneConcessionOrganizationDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneConcessionOrganizationDocumentMutation, UpdateOneConcessionOrganizationDocumentMutationVariables>(UpdateOneConcessionOrganizationDocumentDocument, options);
      }
export type UpdateOneConcessionOrganizationDocumentMutationHookResult = ReturnType<typeof useUpdateOneConcessionOrganizationDocumentMutation>;
export type UpdateOneConcessionOrganizationDocumentMutationResult = Apollo.MutationResult<UpdateOneConcessionOrganizationDocumentMutation>;
export type UpdateOneConcessionOrganizationDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateOneConcessionOrganizationDocumentMutation, UpdateOneConcessionOrganizationDocumentMutationVariables>;
export const UpdateManyConcessionOrganizationDocumentsDocument = gql`
    mutation updateManyConcessionOrganizationDocuments($input: UpdateManyConcessionOrganizationDocumentsInput!) {
  updateManyConcessionOrganizationDocuments(input: $input) {
    updatedCount
  }
}
    `;
export type UpdateManyConcessionOrganizationDocumentsMutationFn = Apollo.MutationFunction<UpdateManyConcessionOrganizationDocumentsMutation, UpdateManyConcessionOrganizationDocumentsMutationVariables>;

/**
 * __useUpdateManyConcessionOrganizationDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateManyConcessionOrganizationDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyConcessionOrganizationDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyConcessionOrganizationDocumentsMutation, { data, loading, error }] = useUpdateManyConcessionOrganizationDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyConcessionOrganizationDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManyConcessionOrganizationDocumentsMutation, UpdateManyConcessionOrganizationDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManyConcessionOrganizationDocumentsMutation, UpdateManyConcessionOrganizationDocumentsMutationVariables>(UpdateManyConcessionOrganizationDocumentsDocument, options);
      }
export type UpdateManyConcessionOrganizationDocumentsMutationHookResult = ReturnType<typeof useUpdateManyConcessionOrganizationDocumentsMutation>;
export type UpdateManyConcessionOrganizationDocumentsMutationResult = Apollo.MutationResult<UpdateManyConcessionOrganizationDocumentsMutation>;
export type UpdateManyConcessionOrganizationDocumentsMutationOptions = Apollo.BaseMutationOptions<UpdateManyConcessionOrganizationDocumentsMutation, UpdateManyConcessionOrganizationDocumentsMutationVariables>;
export const DeleteOneConcessionOrganizationDocumentDocument = gql`
    mutation deleteOneConcessionOrganizationDocument($input: DeleteOneConcessionOrganizationDocumentInput!) {
  deleteOneConcessionOrganizationDocument(input: $input) {
    id
  }
}
    `;
export type DeleteOneConcessionOrganizationDocumentMutationFn = Apollo.MutationFunction<DeleteOneConcessionOrganizationDocumentMutation, DeleteOneConcessionOrganizationDocumentMutationVariables>;

/**
 * __useDeleteOneConcessionOrganizationDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteOneConcessionOrganizationDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneConcessionOrganizationDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneConcessionOrganizationDocumentMutation, { data, loading, error }] = useDeleteOneConcessionOrganizationDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneConcessionOrganizationDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneConcessionOrganizationDocumentMutation, DeleteOneConcessionOrganizationDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneConcessionOrganizationDocumentMutation, DeleteOneConcessionOrganizationDocumentMutationVariables>(DeleteOneConcessionOrganizationDocumentDocument, options);
      }
export type DeleteOneConcessionOrganizationDocumentMutationHookResult = ReturnType<typeof useDeleteOneConcessionOrganizationDocumentMutation>;
export type DeleteOneConcessionOrganizationDocumentMutationResult = Apollo.MutationResult<DeleteOneConcessionOrganizationDocumentMutation>;
export type DeleteOneConcessionOrganizationDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteOneConcessionOrganizationDocumentMutation, DeleteOneConcessionOrganizationDocumentMutationVariables>;
export const DeleteManyConcessionOrganizationDocumentsDocument = gql`
    mutation deleteManyConcessionOrganizationDocuments($input: DeleteManyConcessionOrganizationDocumentsInput!) {
  deleteManyConcessionOrganizationDocuments(input: $input) {
    deletedCount
  }
}
    `;
export type DeleteManyConcessionOrganizationDocumentsMutationFn = Apollo.MutationFunction<DeleteManyConcessionOrganizationDocumentsMutation, DeleteManyConcessionOrganizationDocumentsMutationVariables>;

/**
 * __useDeleteManyConcessionOrganizationDocumentsMutation__
 *
 * To run a mutation, you first call `useDeleteManyConcessionOrganizationDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyConcessionOrganizationDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyConcessionOrganizationDocumentsMutation, { data, loading, error }] = useDeleteManyConcessionOrganizationDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyConcessionOrganizationDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyConcessionOrganizationDocumentsMutation, DeleteManyConcessionOrganizationDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyConcessionOrganizationDocumentsMutation, DeleteManyConcessionOrganizationDocumentsMutationVariables>(DeleteManyConcessionOrganizationDocumentsDocument, options);
      }
export type DeleteManyConcessionOrganizationDocumentsMutationHookResult = ReturnType<typeof useDeleteManyConcessionOrganizationDocumentsMutation>;
export type DeleteManyConcessionOrganizationDocumentsMutationResult = Apollo.MutationResult<DeleteManyConcessionOrganizationDocumentsMutation>;
export type DeleteManyConcessionOrganizationDocumentsMutationOptions = Apollo.BaseMutationOptions<DeleteManyConcessionOrganizationDocumentsMutation, DeleteManyConcessionOrganizationDocumentsMutationVariables>;
export const ConcessionOrganizationUsersDocument = gql`
    query concessionOrganizationUsers($filter: ConcessionOrganizationUserFilter) {
  concessionOrganizationUsers(filter: $filter) {
    nodes {
      id
      role
      user {
        id
        name
      }
      concessionOrganization {
        organization {
          id
          name
        }
        concession {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useConcessionOrganizationUsersQuery__
 *
 * To run a query within a React component, call `useConcessionOrganizationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useConcessionOrganizationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConcessionOrganizationUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useConcessionOrganizationUsersQuery(baseOptions?: Apollo.QueryHookOptions<ConcessionOrganizationUsersQuery, ConcessionOrganizationUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConcessionOrganizationUsersQuery, ConcessionOrganizationUsersQueryVariables>(ConcessionOrganizationUsersDocument, options);
      }
export function useConcessionOrganizationUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConcessionOrganizationUsersQuery, ConcessionOrganizationUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConcessionOrganizationUsersQuery, ConcessionOrganizationUsersQueryVariables>(ConcessionOrganizationUsersDocument, options);
        }
export type ConcessionOrganizationUsersQueryHookResult = ReturnType<typeof useConcessionOrganizationUsersQuery>;
export type ConcessionOrganizationUsersLazyQueryHookResult = ReturnType<typeof useConcessionOrganizationUsersLazyQuery>;
export type ConcessionOrganizationUsersQueryResult = Apollo.QueryResult<ConcessionOrganizationUsersQuery, ConcessionOrganizationUsersQueryVariables>;
export const CreateOneConcessionOrganizationUserDocument = gql`
    mutation createOneConcessionOrganizationUser($input: CreateOneConcessionOrganizationUserInput!) {
  createOneConcessionOrganizationUser(input: $input) {
    id
  }
}
    `;
export type CreateOneConcessionOrganizationUserMutationFn = Apollo.MutationFunction<CreateOneConcessionOrganizationUserMutation, CreateOneConcessionOrganizationUserMutationVariables>;

/**
 * __useCreateOneConcessionOrganizationUserMutation__
 *
 * To run a mutation, you first call `useCreateOneConcessionOrganizationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneConcessionOrganizationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneConcessionOrganizationUserMutation, { data, loading, error }] = useCreateOneConcessionOrganizationUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneConcessionOrganizationUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneConcessionOrganizationUserMutation, CreateOneConcessionOrganizationUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneConcessionOrganizationUserMutation, CreateOneConcessionOrganizationUserMutationVariables>(CreateOneConcessionOrganizationUserDocument, options);
      }
export type CreateOneConcessionOrganizationUserMutationHookResult = ReturnType<typeof useCreateOneConcessionOrganizationUserMutation>;
export type CreateOneConcessionOrganizationUserMutationResult = Apollo.MutationResult<CreateOneConcessionOrganizationUserMutation>;
export type CreateOneConcessionOrganizationUserMutationOptions = Apollo.BaseMutationOptions<CreateOneConcessionOrganizationUserMutation, CreateOneConcessionOrganizationUserMutationVariables>;
export const UpdateOneConcessionOrganizationUserDocument = gql`
    mutation updateOneConcessionOrganizationUser($input: UpdateOneConcessionOrganizationUserInput!) {
  updateOneConcessionOrganizationUser(input: $input) {
    id
  }
}
    `;
export type UpdateOneConcessionOrganizationUserMutationFn = Apollo.MutationFunction<UpdateOneConcessionOrganizationUserMutation, UpdateOneConcessionOrganizationUserMutationVariables>;

/**
 * __useUpdateOneConcessionOrganizationUserMutation__
 *
 * To run a mutation, you first call `useUpdateOneConcessionOrganizationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneConcessionOrganizationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneConcessionOrganizationUserMutation, { data, loading, error }] = useUpdateOneConcessionOrganizationUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneConcessionOrganizationUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneConcessionOrganizationUserMutation, UpdateOneConcessionOrganizationUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneConcessionOrganizationUserMutation, UpdateOneConcessionOrganizationUserMutationVariables>(UpdateOneConcessionOrganizationUserDocument, options);
      }
export type UpdateOneConcessionOrganizationUserMutationHookResult = ReturnType<typeof useUpdateOneConcessionOrganizationUserMutation>;
export type UpdateOneConcessionOrganizationUserMutationResult = Apollo.MutationResult<UpdateOneConcessionOrganizationUserMutation>;
export type UpdateOneConcessionOrganizationUserMutationOptions = Apollo.BaseMutationOptions<UpdateOneConcessionOrganizationUserMutation, UpdateOneConcessionOrganizationUserMutationVariables>;
export const DeleteOneConcessionOrganizationUserDocument = gql`
    mutation deleteOneConcessionOrganizationUser($input: DeleteOneConcessionOrganizationUserInput!) {
  deleteOneConcessionOrganizationUser(input: $input) {
    id
  }
}
    `;
export type DeleteOneConcessionOrganizationUserMutationFn = Apollo.MutationFunction<DeleteOneConcessionOrganizationUserMutation, DeleteOneConcessionOrganizationUserMutationVariables>;

/**
 * __useDeleteOneConcessionOrganizationUserMutation__
 *
 * To run a mutation, you first call `useDeleteOneConcessionOrganizationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneConcessionOrganizationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneConcessionOrganizationUserMutation, { data, loading, error }] = useDeleteOneConcessionOrganizationUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneConcessionOrganizationUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneConcessionOrganizationUserMutation, DeleteOneConcessionOrganizationUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneConcessionOrganizationUserMutation, DeleteOneConcessionOrganizationUserMutationVariables>(DeleteOneConcessionOrganizationUserDocument, options);
      }
export type DeleteOneConcessionOrganizationUserMutationHookResult = ReturnType<typeof useDeleteOneConcessionOrganizationUserMutation>;
export type DeleteOneConcessionOrganizationUserMutationResult = Apollo.MutationResult<DeleteOneConcessionOrganizationUserMutation>;
export type DeleteOneConcessionOrganizationUserMutationOptions = Apollo.BaseMutationOptions<DeleteOneConcessionOrganizationUserMutation, DeleteOneConcessionOrganizationUserMutationVariables>;
export const ConcessionsDocument = gql`
    query concessions($filter: ConcessionFilter, $paging: OffsetPaging, $sorting: [ConcessionSort!]) {
  concessions(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useConcessionsQuery__
 *
 * To run a query within a React component, call `useConcessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConcessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConcessionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useConcessionsQuery(baseOptions?: Apollo.QueryHookOptions<ConcessionsQuery, ConcessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConcessionsQuery, ConcessionsQueryVariables>(ConcessionsDocument, options);
      }
export function useConcessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConcessionsQuery, ConcessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConcessionsQuery, ConcessionsQueryVariables>(ConcessionsDocument, options);
        }
export type ConcessionsQueryHookResult = ReturnType<typeof useConcessionsQuery>;
export type ConcessionsLazyQueryHookResult = ReturnType<typeof useConcessionsLazyQuery>;
export type ConcessionsQueryResult = Apollo.QueryResult<ConcessionsQuery, ConcessionsQueryVariables>;
export const ContractDocumentCategoriesDocument = gql`
    query contractDocumentCategories($filter: ContractDocumentCategoryFilter, $paging: OffsetPaging, $sorting: [ContractDocumentCategorySort!]) {
  contractDocumentCategories(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useContractDocumentCategoriesQuery__
 *
 * To run a query within a React component, call `useContractDocumentCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractDocumentCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractDocumentCategoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useContractDocumentCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ContractDocumentCategoriesQuery, ContractDocumentCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractDocumentCategoriesQuery, ContractDocumentCategoriesQueryVariables>(ContractDocumentCategoriesDocument, options);
      }
export function useContractDocumentCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractDocumentCategoriesQuery, ContractDocumentCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractDocumentCategoriesQuery, ContractDocumentCategoriesQueryVariables>(ContractDocumentCategoriesDocument, options);
        }
export type ContractDocumentCategoriesQueryHookResult = ReturnType<typeof useContractDocumentCategoriesQuery>;
export type ContractDocumentCategoriesLazyQueryHookResult = ReturnType<typeof useContractDocumentCategoriesLazyQuery>;
export type ContractDocumentCategoriesQueryResult = Apollo.QueryResult<ContractDocumentCategoriesQuery, ContractDocumentCategoriesQueryVariables>;
export const ContractDocumentCategoriesWithSubcategoriesDocument = gql`
    query contractDocumentCategoriesWithSubcategories {
  contractDocumentCategories(
    filter: {parent: {id: {is: null}}}
    paging: {limit: 9999}
    sorting: {field: name, direction: ASC}
  ) {
    nodes {
      id
      name
      children(sorting: {field: name, direction: ASC}) {
        id
        name
        observations
        expirationRequired
      }
    }
  }
}
    `;

/**
 * __useContractDocumentCategoriesWithSubcategoriesQuery__
 *
 * To run a query within a React component, call `useContractDocumentCategoriesWithSubcategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractDocumentCategoriesWithSubcategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractDocumentCategoriesWithSubcategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useContractDocumentCategoriesWithSubcategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ContractDocumentCategoriesWithSubcategoriesQuery, ContractDocumentCategoriesWithSubcategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractDocumentCategoriesWithSubcategoriesQuery, ContractDocumentCategoriesWithSubcategoriesQueryVariables>(ContractDocumentCategoriesWithSubcategoriesDocument, options);
      }
export function useContractDocumentCategoriesWithSubcategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractDocumentCategoriesWithSubcategoriesQuery, ContractDocumentCategoriesWithSubcategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractDocumentCategoriesWithSubcategoriesQuery, ContractDocumentCategoriesWithSubcategoriesQueryVariables>(ContractDocumentCategoriesWithSubcategoriesDocument, options);
        }
export type ContractDocumentCategoriesWithSubcategoriesQueryHookResult = ReturnType<typeof useContractDocumentCategoriesWithSubcategoriesQuery>;
export type ContractDocumentCategoriesWithSubcategoriesLazyQueryHookResult = ReturnType<typeof useContractDocumentCategoriesWithSubcategoriesLazyQuery>;
export type ContractDocumentCategoriesWithSubcategoriesQueryResult = Apollo.QueryResult<ContractDocumentCategoriesWithSubcategoriesQuery, ContractDocumentCategoriesWithSubcategoriesQueryVariables>;
export const ContractDocumentsDocument = gql`
    query contractDocuments($filter: ContractDocumentFilter, $paging: OffsetPaging, $sorting: [ContractDocumentSort!]) {
  contractDocuments(filter: $filter, paging: $paging, sorting: $sorting) {
    totalCount
    nodes {
      id
      name
      path
      expiresAt
      createdAt
      fileType {
        id
        name
        mimeType
        isPreviewable
      }
      owner {
        name
      }
      contractDocumentCategory {
        id
        name
        expirationRequired
        policy
      }
      contractDocumentSubcategory {
        id
        name
        expirationRequired
        policy
      }
      documentState {
        id
        name
      }
      contract {
        contractState {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useContractDocumentsQuery__
 *
 * To run a query within a React component, call `useContractDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractDocumentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useContractDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<ContractDocumentsQuery, ContractDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractDocumentsQuery, ContractDocumentsQueryVariables>(ContractDocumentsDocument, options);
      }
export function useContractDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractDocumentsQuery, ContractDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractDocumentsQuery, ContractDocumentsQueryVariables>(ContractDocumentsDocument, options);
        }
export type ContractDocumentsQueryHookResult = ReturnType<typeof useContractDocumentsQuery>;
export type ContractDocumentsLazyQueryHookResult = ReturnType<typeof useContractDocumentsLazyQuery>;
export type ContractDocumentsQueryResult = Apollo.QueryResult<ContractDocumentsQuery, ContractDocumentsQueryVariables>;
export const CreateOneContractDocumentDocument = gql`
    mutation createOneContractDocument($input: CreateOneContractDocumentInput!) {
  createOneContractDocument(input: $input) {
    id
  }
}
    `;
export type CreateOneContractDocumentMutationFn = Apollo.MutationFunction<CreateOneContractDocumentMutation, CreateOneContractDocumentMutationVariables>;

/**
 * __useCreateOneContractDocumentMutation__
 *
 * To run a mutation, you first call `useCreateOneContractDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneContractDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneContractDocumentMutation, { data, loading, error }] = useCreateOneContractDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneContractDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneContractDocumentMutation, CreateOneContractDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneContractDocumentMutation, CreateOneContractDocumentMutationVariables>(CreateOneContractDocumentDocument, options);
      }
export type CreateOneContractDocumentMutationHookResult = ReturnType<typeof useCreateOneContractDocumentMutation>;
export type CreateOneContractDocumentMutationResult = Apollo.MutationResult<CreateOneContractDocumentMutation>;
export type CreateOneContractDocumentMutationOptions = Apollo.BaseMutationOptions<CreateOneContractDocumentMutation, CreateOneContractDocumentMutationVariables>;
export const UpdateOneContractDocumentDocument = gql`
    mutation updateOneContractDocument($input: UpdateOneContractDocumentInput!) {
  updateOneContractDocument(input: $input) {
    id
  }
}
    `;
export type UpdateOneContractDocumentMutationFn = Apollo.MutationFunction<UpdateOneContractDocumentMutation, UpdateOneContractDocumentMutationVariables>;

/**
 * __useUpdateOneContractDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateOneContractDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneContractDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneContractDocumentMutation, { data, loading, error }] = useUpdateOneContractDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneContractDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneContractDocumentMutation, UpdateOneContractDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneContractDocumentMutation, UpdateOneContractDocumentMutationVariables>(UpdateOneContractDocumentDocument, options);
      }
export type UpdateOneContractDocumentMutationHookResult = ReturnType<typeof useUpdateOneContractDocumentMutation>;
export type UpdateOneContractDocumentMutationResult = Apollo.MutationResult<UpdateOneContractDocumentMutation>;
export type UpdateOneContractDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateOneContractDocumentMutation, UpdateOneContractDocumentMutationVariables>;
export const UpdateManyContractDocumentsDocument = gql`
    mutation updateManyContractDocuments($input: UpdateManyContractDocumentsInput!) {
  updateManyContractDocuments(input: $input) {
    updatedCount
  }
}
    `;
export type UpdateManyContractDocumentsMutationFn = Apollo.MutationFunction<UpdateManyContractDocumentsMutation, UpdateManyContractDocumentsMutationVariables>;

/**
 * __useUpdateManyContractDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateManyContractDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyContractDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyContractDocumentsMutation, { data, loading, error }] = useUpdateManyContractDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyContractDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManyContractDocumentsMutation, UpdateManyContractDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManyContractDocumentsMutation, UpdateManyContractDocumentsMutationVariables>(UpdateManyContractDocumentsDocument, options);
      }
export type UpdateManyContractDocumentsMutationHookResult = ReturnType<typeof useUpdateManyContractDocumentsMutation>;
export type UpdateManyContractDocumentsMutationResult = Apollo.MutationResult<UpdateManyContractDocumentsMutation>;
export type UpdateManyContractDocumentsMutationOptions = Apollo.BaseMutationOptions<UpdateManyContractDocumentsMutation, UpdateManyContractDocumentsMutationVariables>;
export const DeleteOneContractDocumentDocument = gql`
    mutation deleteOneContractDocument($input: DeleteOneContractDocumentInput!) {
  deleteOneContractDocument(input: $input) {
    id
  }
}
    `;
export type DeleteOneContractDocumentMutationFn = Apollo.MutationFunction<DeleteOneContractDocumentMutation, DeleteOneContractDocumentMutationVariables>;

/**
 * __useDeleteOneContractDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteOneContractDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneContractDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneContractDocumentMutation, { data, loading, error }] = useDeleteOneContractDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneContractDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneContractDocumentMutation, DeleteOneContractDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneContractDocumentMutation, DeleteOneContractDocumentMutationVariables>(DeleteOneContractDocumentDocument, options);
      }
export type DeleteOneContractDocumentMutationHookResult = ReturnType<typeof useDeleteOneContractDocumentMutation>;
export type DeleteOneContractDocumentMutationResult = Apollo.MutationResult<DeleteOneContractDocumentMutation>;
export type DeleteOneContractDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteOneContractDocumentMutation, DeleteOneContractDocumentMutationVariables>;
export const DeleteManyContractDocumentsDocument = gql`
    mutation deleteManyContractDocuments($input: DeleteManyContractDocumentsInput!) {
  deleteManyContractDocuments(input: $input) {
    deletedCount
  }
}
    `;
export type DeleteManyContractDocumentsMutationFn = Apollo.MutationFunction<DeleteManyContractDocumentsMutation, DeleteManyContractDocumentsMutationVariables>;

/**
 * __useDeleteManyContractDocumentsMutation__
 *
 * To run a mutation, you first call `useDeleteManyContractDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyContractDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyContractDocumentsMutation, { data, loading, error }] = useDeleteManyContractDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyContractDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyContractDocumentsMutation, DeleteManyContractDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyContractDocumentsMutation, DeleteManyContractDocumentsMutationVariables>(DeleteManyContractDocumentsDocument, options);
      }
export type DeleteManyContractDocumentsMutationHookResult = ReturnType<typeof useDeleteManyContractDocumentsMutation>;
export type DeleteManyContractDocumentsMutationResult = Apollo.MutationResult<DeleteManyContractDocumentsMutation>;
export type DeleteManyContractDocumentsMutationOptions = Apollo.BaseMutationOptions<DeleteManyContractDocumentsMutation, DeleteManyContractDocumentsMutationVariables>;
export const ContractRoadsDocument = gql`
    query contractRoads($filter: ContractRoadFilter, $paging: OffsetPaging, $sorting: [ContractRoadSort!]) {
  contractRoads(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      road {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useContractRoadsQuery__
 *
 * To run a query within a React component, call `useContractRoadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractRoadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractRoadsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useContractRoadsQuery(baseOptions?: Apollo.QueryHookOptions<ContractRoadsQuery, ContractRoadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractRoadsQuery, ContractRoadsQueryVariables>(ContractRoadsDocument, options);
      }
export function useContractRoadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractRoadsQuery, ContractRoadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractRoadsQuery, ContractRoadsQueryVariables>(ContractRoadsDocument, options);
        }
export type ContractRoadsQueryHookResult = ReturnType<typeof useContractRoadsQuery>;
export type ContractRoadsLazyQueryHookResult = ReturnType<typeof useContractRoadsLazyQuery>;
export type ContractRoadsQueryResult = Apollo.QueryResult<ContractRoadsQuery, ContractRoadsQueryVariables>;
export const ContractStatesDocument = gql`
    query contractStates {
  contractStates {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useContractStatesQuery__
 *
 * To run a query within a React component, call `useContractStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useContractStatesQuery(baseOptions?: Apollo.QueryHookOptions<ContractStatesQuery, ContractStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractStatesQuery, ContractStatesQueryVariables>(ContractStatesDocument, options);
      }
export function useContractStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractStatesQuery, ContractStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractStatesQuery, ContractStatesQueryVariables>(ContractStatesDocument, options);
        }
export type ContractStatesQueryHookResult = ReturnType<typeof useContractStatesQuery>;
export type ContractStatesLazyQueryHookResult = ReturnType<typeof useContractStatesLazyQuery>;
export type ContractStatesQueryResult = Apollo.QueryResult<ContractStatesQuery, ContractStatesQueryVariables>;
export const ContractsDocument = gql`
    query contracts($filter: ContractFilter, $paging: OffsetPaging, $sorting: [ContractSort!]) {
  contracts(filter: $filter, paging: $paging, sorting: $sorting) {
    totalCount
    nodes {
      id
      name
      description
      startDate
      endDate
      contractType {
        id
        name
      }
      contractState {
        id
        name
      }
      service {
        id
        name
      }
      interventionType {
        id
        name
      }
      concession {
        id
        name
      }
      contractConcessionOrganizations {
        concessionOrganization {
          id
          organizationType {
            id
          }
          organization {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useContractsQuery__
 *
 * To run a query within a React component, call `useContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useContractsQuery(baseOptions?: Apollo.QueryHookOptions<ContractsQuery, ContractsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractsQuery, ContractsQueryVariables>(ContractsDocument, options);
      }
export function useContractsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractsQuery, ContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractsQuery, ContractsQueryVariables>(ContractsDocument, options);
        }
export type ContractsQueryHookResult = ReturnType<typeof useContractsQuery>;
export type ContractsLazyQueryHookResult = ReturnType<typeof useContractsLazyQuery>;
export type ContractsQueryResult = Apollo.QueryResult<ContractsQuery, ContractsQueryVariables>;
export const ContractDocument = gql`
    query contract($id: ID!) {
  contract(id: $id) {
    id
    name
    description
    startDate
    endDate
    realStartDate
    realEndDate
    createdAt
    contractOpenedNotificationSent
    contractClosedNotificationSent
    contractRoadsObservations
    originalValue
    amendedValue
    billedValue
    concession {
      id
      name
    }
    contractType {
      id
      name
    }
    contractState {
      id
      name
    }
    interventionElement {
      id
      name
    }
    interventionType {
      id
      name
    }
    interventionLocation {
      id
      name
    }
    contractRoads {
      id
      road {
        id
        name
      }
    }
    service {
      id
      name
    }
    contractUsers {
      id
      role
      user {
        id
        name
      }
    }
    contractConcessionOrganizations {
      id
      concessionOrganization {
        id
        organization {
          id
          name
        }
        organizationType {
          id
          name
        }
      }
    }
    contractDocuments {
      id
      name
      description
      path
    }
    contractTransitions(sorting: [{field: id, direction: ASC}]) {
      createdAt
      fromState {
        id
        name
      }
      toState {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useContractQuery__
 *
 * To run a query within a React component, call `useContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractQuery(baseOptions: Apollo.QueryHookOptions<ContractQuery, ContractQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractQuery, ContractQueryVariables>(ContractDocument, options);
      }
export function useContractLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractQuery, ContractQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractQuery, ContractQueryVariables>(ContractDocument, options);
        }
export type ContractQueryHookResult = ReturnType<typeof useContractQuery>;
export type ContractLazyQueryHookResult = ReturnType<typeof useContractLazyQuery>;
export type ContractQueryResult = Apollo.QueryResult<ContractQuery, ContractQueryVariables>;
export const ContractPreviewDocument = gql`
    query ContractPreview($id: ID!) {
  contract(id: $id) {
    id
    name
    description
    contractState {
      name
    }
    interventionLocation {
      id
      name
    }
    contractConcessionOrganizations {
      id
      concessionOrganization {
        id
        organization {
          id
          name
        }
        organizationType {
          id
          name
        }
      }
    }
    startDate
    endDate
    realStartDate
    realEndDate
    workPermits(sorting: [{field: id, direction: ASC}]) {
      id
      name
      state
      startAt
      endAt
      isPublished
    }
  }
}
    `;

/**
 * __useContractPreviewQuery__
 *
 * To run a query within a React component, call `useContractPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractPreviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractPreviewQuery(baseOptions: Apollo.QueryHookOptions<ContractPreviewQuery, ContractPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractPreviewQuery, ContractPreviewQueryVariables>(ContractPreviewDocument, options);
      }
export function useContractPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractPreviewQuery, ContractPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractPreviewQuery, ContractPreviewQueryVariables>(ContractPreviewDocument, options);
        }
export type ContractPreviewQueryHookResult = ReturnType<typeof useContractPreviewQuery>;
export type ContractPreviewLazyQueryHookResult = ReturnType<typeof useContractPreviewLazyQuery>;
export type ContractPreviewQueryResult = Apollo.QueryResult<ContractPreviewQuery, ContractPreviewQueryVariables>;
export const CreateOneContractDocument = gql`
    mutation createOneContract($input: CreateOneContractInput!) {
  createOneContract(input: $input) {
    id
  }
}
    `;
export type CreateOneContractMutationFn = Apollo.MutationFunction<CreateOneContractMutation, CreateOneContractMutationVariables>;

/**
 * __useCreateOneContractMutation__
 *
 * To run a mutation, you first call `useCreateOneContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneContractMutation, { data, loading, error }] = useCreateOneContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneContractMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneContractMutation, CreateOneContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneContractMutation, CreateOneContractMutationVariables>(CreateOneContractDocument, options);
      }
export type CreateOneContractMutationHookResult = ReturnType<typeof useCreateOneContractMutation>;
export type CreateOneContractMutationResult = Apollo.MutationResult<CreateOneContractMutation>;
export type CreateOneContractMutationOptions = Apollo.BaseMutationOptions<CreateOneContractMutation, CreateOneContractMutationVariables>;
export const UpdateOneContractDocument = gql`
    mutation updateOneContract($input: UpdateOneContractInput!) {
  updateOneContract(input: $input) {
    id
  }
}
    `;
export type UpdateOneContractMutationFn = Apollo.MutationFunction<UpdateOneContractMutation, UpdateOneContractMutationVariables>;

/**
 * __useUpdateOneContractMutation__
 *
 * To run a mutation, you first call `useUpdateOneContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneContractMutation, { data, loading, error }] = useUpdateOneContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneContractMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneContractMutation, UpdateOneContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneContractMutation, UpdateOneContractMutationVariables>(UpdateOneContractDocument, options);
      }
export type UpdateOneContractMutationHookResult = ReturnType<typeof useUpdateOneContractMutation>;
export type UpdateOneContractMutationResult = Apollo.MutationResult<UpdateOneContractMutation>;
export type UpdateOneContractMutationOptions = Apollo.BaseMutationOptions<UpdateOneContractMutation, UpdateOneContractMutationVariables>;
export const DeleteOneContractDocument = gql`
    mutation deleteOneContract($input: DeleteOneContractInput!) {
  deleteOneContract(input: $input) {
    id
  }
}
    `;
export type DeleteOneContractMutationFn = Apollo.MutationFunction<DeleteOneContractMutation, DeleteOneContractMutationVariables>;

/**
 * __useDeleteOneContractMutation__
 *
 * To run a mutation, you first call `useDeleteOneContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneContractMutation, { data, loading, error }] = useDeleteOneContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneContractMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneContractMutation, DeleteOneContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneContractMutation, DeleteOneContractMutationVariables>(DeleteOneContractDocument, options);
      }
export type DeleteOneContractMutationHookResult = ReturnType<typeof useDeleteOneContractMutation>;
export type DeleteOneContractMutationResult = Apollo.MutationResult<DeleteOneContractMutation>;
export type DeleteOneContractMutationOptions = Apollo.BaseMutationOptions<DeleteOneContractMutation, DeleteOneContractMutationVariables>;
export const ControlCentersDocument = gql`
    query controlCenters($filter: ControlCenterFilter, $paging: OffsetPaging, $sorting: [ControlCenterSort!]) {
  controlCenters(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
      phone
    }
  }
}
    `;

/**
 * __useControlCentersQuery__
 *
 * To run a query within a React component, call `useControlCentersQuery` and pass it any options that fit your needs.
 * When your component renders, `useControlCentersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useControlCentersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useControlCentersQuery(baseOptions?: Apollo.QueryHookOptions<ControlCentersQuery, ControlCentersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ControlCentersQuery, ControlCentersQueryVariables>(ControlCentersDocument, options);
      }
export function useControlCentersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ControlCentersQuery, ControlCentersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ControlCentersQuery, ControlCentersQueryVariables>(ControlCentersDocument, options);
        }
export type ControlCentersQueryHookResult = ReturnType<typeof useControlCentersQuery>;
export type ControlCentersLazyQueryHookResult = ReturnType<typeof useControlCentersLazyQuery>;
export type ControlCentersQueryResult = Apollo.QueryResult<ControlCentersQuery, ControlCentersQueryVariables>;
export const CreateOneControlCenterDocument = gql`
    mutation createOneControlCenter($input: CreateOneControlCenterInput!) {
  createOneControlCenter(input: $input) {
    id
    name
    phone
    __typename
  }
}
    `;
export type CreateOneControlCenterMutationFn = Apollo.MutationFunction<CreateOneControlCenterMutation, CreateOneControlCenterMutationVariables>;

/**
 * __useCreateOneControlCenterMutation__
 *
 * To run a mutation, you first call `useCreateOneControlCenterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneControlCenterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneControlCenterMutation, { data, loading, error }] = useCreateOneControlCenterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneControlCenterMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneControlCenterMutation, CreateOneControlCenterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneControlCenterMutation, CreateOneControlCenterMutationVariables>(CreateOneControlCenterDocument, options);
      }
export type CreateOneControlCenterMutationHookResult = ReturnType<typeof useCreateOneControlCenterMutation>;
export type CreateOneControlCenterMutationResult = Apollo.MutationResult<CreateOneControlCenterMutation>;
export type CreateOneControlCenterMutationOptions = Apollo.BaseMutationOptions<CreateOneControlCenterMutation, CreateOneControlCenterMutationVariables>;
export const UpdateOneControlCenterDocument = gql`
    mutation updateOneControlCenter($input: UpdateOneControlCenterInput!) {
  updateOneControlCenter(input: $input) {
    id
    name
    phone
  }
}
    `;
export type UpdateOneControlCenterMutationFn = Apollo.MutationFunction<UpdateOneControlCenterMutation, UpdateOneControlCenterMutationVariables>;

/**
 * __useUpdateOneControlCenterMutation__
 *
 * To run a mutation, you first call `useUpdateOneControlCenterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneControlCenterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneControlCenterMutation, { data, loading, error }] = useUpdateOneControlCenterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneControlCenterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneControlCenterMutation, UpdateOneControlCenterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneControlCenterMutation, UpdateOneControlCenterMutationVariables>(UpdateOneControlCenterDocument, options);
      }
export type UpdateOneControlCenterMutationHookResult = ReturnType<typeof useUpdateOneControlCenterMutation>;
export type UpdateOneControlCenterMutationResult = Apollo.MutationResult<UpdateOneControlCenterMutation>;
export type UpdateOneControlCenterMutationOptions = Apollo.BaseMutationOptions<UpdateOneControlCenterMutation, UpdateOneControlCenterMutationVariables>;
export const DeleteOneControlCenterDocument = gql`
    mutation deleteOneControlCenter($input: DeleteOneControlCenterInput!) {
  deleteOneControlCenter(input: $input) {
    id
  }
}
    `;
export type DeleteOneControlCenterMutationFn = Apollo.MutationFunction<DeleteOneControlCenterMutation, DeleteOneControlCenterMutationVariables>;

/**
 * __useDeleteOneControlCenterMutation__
 *
 * To run a mutation, you first call `useDeleteOneControlCenterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneControlCenterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneControlCenterMutation, { data, loading, error }] = useDeleteOneControlCenterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneControlCenterMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneControlCenterMutation, DeleteOneControlCenterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneControlCenterMutation, DeleteOneControlCenterMutationVariables>(DeleteOneControlCenterDocument, options);
      }
export type DeleteOneControlCenterMutationHookResult = ReturnType<typeof useDeleteOneControlCenterMutation>;
export type DeleteOneControlCenterMutationResult = Apollo.MutationResult<DeleteOneControlCenterMutation>;
export type DeleteOneControlCenterMutationOptions = Apollo.BaseMutationOptions<DeleteOneControlCenterMutation, DeleteOneControlCenterMutationVariables>;
export const DirectionsDocument = gql`
    query directions($filter: DirectionFilter, $paging: OffsetPaging, $sorting: [DirectionSort!]) {
  directions(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useDirectionsQuery__
 *
 * To run a query within a React component, call `useDirectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useDirectionsQuery(baseOptions?: Apollo.QueryHookOptions<DirectionsQuery, DirectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DirectionsQuery, DirectionsQueryVariables>(DirectionsDocument, options);
      }
export function useDirectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DirectionsQuery, DirectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DirectionsQuery, DirectionsQueryVariables>(DirectionsDocument, options);
        }
export type DirectionsQueryHookResult = ReturnType<typeof useDirectionsQuery>;
export type DirectionsLazyQueryHookResult = ReturnType<typeof useDirectionsLazyQuery>;
export type DirectionsQueryResult = Apollo.QueryResult<DirectionsQuery, DirectionsQueryVariables>;
export const CreateOneDirectionDocument = gql`
    mutation createOneDirection($input: CreateOneDirectionInput!) {
  createOneDirection(input: $input) {
    id
  }
}
    `;
export type CreateOneDirectionMutationFn = Apollo.MutationFunction<CreateOneDirectionMutation, CreateOneDirectionMutationVariables>;

/**
 * __useCreateOneDirectionMutation__
 *
 * To run a mutation, you first call `useCreateOneDirectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneDirectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneDirectionMutation, { data, loading, error }] = useCreateOneDirectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneDirectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneDirectionMutation, CreateOneDirectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneDirectionMutation, CreateOneDirectionMutationVariables>(CreateOneDirectionDocument, options);
      }
export type CreateOneDirectionMutationHookResult = ReturnType<typeof useCreateOneDirectionMutation>;
export type CreateOneDirectionMutationResult = Apollo.MutationResult<CreateOneDirectionMutation>;
export type CreateOneDirectionMutationOptions = Apollo.BaseMutationOptions<CreateOneDirectionMutation, CreateOneDirectionMutationVariables>;
export const UpdateOneDirectionDocument = gql`
    mutation updateOneDirection($input: UpdateOneDirectionInput!) {
  updateOneDirection(input: $input) {
    id
  }
}
    `;
export type UpdateOneDirectionMutationFn = Apollo.MutationFunction<UpdateOneDirectionMutation, UpdateOneDirectionMutationVariables>;

/**
 * __useUpdateOneDirectionMutation__
 *
 * To run a mutation, you first call `useUpdateOneDirectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneDirectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneDirectionMutation, { data, loading, error }] = useUpdateOneDirectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneDirectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneDirectionMutation, UpdateOneDirectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneDirectionMutation, UpdateOneDirectionMutationVariables>(UpdateOneDirectionDocument, options);
      }
export type UpdateOneDirectionMutationHookResult = ReturnType<typeof useUpdateOneDirectionMutation>;
export type UpdateOneDirectionMutationResult = Apollo.MutationResult<UpdateOneDirectionMutation>;
export type UpdateOneDirectionMutationOptions = Apollo.BaseMutationOptions<UpdateOneDirectionMutation, UpdateOneDirectionMutationVariables>;
export const DeleteOneDirectionDocument = gql`
    mutation deleteOneDirection($input: DeleteOneDirectionInput!) {
  deleteOneDirection(input: $input) {
    id
  }
}
    `;
export type DeleteOneDirectionMutationFn = Apollo.MutationFunction<DeleteOneDirectionMutation, DeleteOneDirectionMutationVariables>;

/**
 * __useDeleteOneDirectionMutation__
 *
 * To run a mutation, you first call `useDeleteOneDirectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneDirectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneDirectionMutation, { data, loading, error }] = useDeleteOneDirectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneDirectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneDirectionMutation, DeleteOneDirectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneDirectionMutation, DeleteOneDirectionMutationVariables>(DeleteOneDirectionDocument, options);
      }
export type DeleteOneDirectionMutationHookResult = ReturnType<typeof useDeleteOneDirectionMutation>;
export type DeleteOneDirectionMutationResult = Apollo.MutationResult<DeleteOneDirectionMutation>;
export type DeleteOneDirectionMutationOptions = Apollo.BaseMutationOptions<DeleteOneDirectionMutation, DeleteOneDirectionMutationVariables>;
export const DocumentTemplatesDocument = gql`
    query documentTemplates($filter: DocumentTemplateFilter, $paging: OffsetPaging, $sorting: [DocumentTemplateSort!]) {
  documentTemplates(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
      path
    }
  }
}
    `;

/**
 * __useDocumentTemplatesQuery__
 *
 * To run a query within a React component, call `useDocumentTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentTemplatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useDocumentTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<DocumentTemplatesQuery, DocumentTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentTemplatesQuery, DocumentTemplatesQueryVariables>(DocumentTemplatesDocument, options);
      }
export function useDocumentTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentTemplatesQuery, DocumentTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentTemplatesQuery, DocumentTemplatesQueryVariables>(DocumentTemplatesDocument, options);
        }
export type DocumentTemplatesQueryHookResult = ReturnType<typeof useDocumentTemplatesQuery>;
export type DocumentTemplatesLazyQueryHookResult = ReturnType<typeof useDocumentTemplatesLazyQuery>;
export type DocumentTemplatesQueryResult = Apollo.QueryResult<DocumentTemplatesQuery, DocumentTemplatesQueryVariables>;
export const DocumentStatesDocument = gql`
    query documentStates($filter: DocumentStateFilter, $paging: OffsetPaging, $sorting: [DocumentStateSort!]) {
  documentStates(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useDocumentStatesQuery__
 *
 * To run a query within a React component, call `useDocumentStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentStatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useDocumentStatesQuery(baseOptions?: Apollo.QueryHookOptions<DocumentStatesQuery, DocumentStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentStatesQuery, DocumentStatesQueryVariables>(DocumentStatesDocument, options);
      }
export function useDocumentStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentStatesQuery, DocumentStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentStatesQuery, DocumentStatesQueryVariables>(DocumentStatesDocument, options);
        }
export type DocumentStatesQueryHookResult = ReturnType<typeof useDocumentStatesQuery>;
export type DocumentStatesLazyQueryHookResult = ReturnType<typeof useDocumentStatesLazyQuery>;
export type DocumentStatesQueryResult = Apollo.QueryResult<DocumentStatesQuery, DocumentStatesQueryVariables>;
export const SendResetPasswordEmailDocument = gql`
    mutation sendResetPasswordEmail($email: String!) {
  sendResetPasswordEmail(email: $email)
}
    `;
export type SendResetPasswordEmailMutationFn = Apollo.MutationFunction<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;

/**
 * __useSendResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordEmailMutation, { data, loading, error }] = useSendResetPasswordEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>(SendResetPasswordEmailDocument, options);
      }
export type SendResetPasswordEmailMutationHookResult = ReturnType<typeof useSendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationResult = Apollo.MutationResult<SendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;
export const SendPublishedSurveyRecordEmailDocument = gql`
    mutation sendPublishedSurveyRecordEmail($id: String!) {
  sendPublishedSurveyRecordEmail(id: $id)
}
    `;
export type SendPublishedSurveyRecordEmailMutationFn = Apollo.MutationFunction<SendPublishedSurveyRecordEmailMutation, SendPublishedSurveyRecordEmailMutationVariables>;

/**
 * __useSendPublishedSurveyRecordEmailMutation__
 *
 * To run a mutation, you first call `useSendPublishedSurveyRecordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPublishedSurveyRecordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPublishedSurveyRecordEmailMutation, { data, loading, error }] = useSendPublishedSurveyRecordEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendPublishedSurveyRecordEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendPublishedSurveyRecordEmailMutation, SendPublishedSurveyRecordEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPublishedSurveyRecordEmailMutation, SendPublishedSurveyRecordEmailMutationVariables>(SendPublishedSurveyRecordEmailDocument, options);
      }
export type SendPublishedSurveyRecordEmailMutationHookResult = ReturnType<typeof useSendPublishedSurveyRecordEmailMutation>;
export type SendPublishedSurveyRecordEmailMutationResult = Apollo.MutationResult<SendPublishedSurveyRecordEmailMutation>;
export type SendPublishedSurveyRecordEmailMutationOptions = Apollo.BaseMutationOptions<SendPublishedSurveyRecordEmailMutation, SendPublishedSurveyRecordEmailMutationVariables>;
export const SendPublishedWorkPermitEmailDocument = gql`
    mutation sendPublishedWorkPermitEmail($id: String!) {
  sendPublishedWorkPermitEmail(id: $id)
}
    `;
export type SendPublishedWorkPermitEmailMutationFn = Apollo.MutationFunction<SendPublishedWorkPermitEmailMutation, SendPublishedWorkPermitEmailMutationVariables>;

/**
 * __useSendPublishedWorkPermitEmailMutation__
 *
 * To run a mutation, you first call `useSendPublishedWorkPermitEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPublishedWorkPermitEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPublishedWorkPermitEmailMutation, { data, loading, error }] = useSendPublishedWorkPermitEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendPublishedWorkPermitEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendPublishedWorkPermitEmailMutation, SendPublishedWorkPermitEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPublishedWorkPermitEmailMutation, SendPublishedWorkPermitEmailMutationVariables>(SendPublishedWorkPermitEmailDocument, options);
      }
export type SendPublishedWorkPermitEmailMutationHookResult = ReturnType<typeof useSendPublishedWorkPermitEmailMutation>;
export type SendPublishedWorkPermitEmailMutationResult = Apollo.MutationResult<SendPublishedWorkPermitEmailMutation>;
export type SendPublishedWorkPermitEmailMutationOptions = Apollo.BaseMutationOptions<SendPublishedWorkPermitEmailMutation, SendPublishedWorkPermitEmailMutationVariables>;
export const FileTypesDocument = gql`
    query fileTypes($filter: FileTypeFilter, $paging: OffsetPaging, $sorting: [FileTypeSort!]) {
  fileTypes(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useFileTypesQuery__
 *
 * To run a query within a React component, call `useFileTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileTypesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useFileTypesQuery(baseOptions?: Apollo.QueryHookOptions<FileTypesQuery, FileTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileTypesQuery, FileTypesQueryVariables>(FileTypesDocument, options);
      }
export function useFileTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileTypesQuery, FileTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileTypesQuery, FileTypesQueryVariables>(FileTypesDocument, options);
        }
export type FileTypesQueryHookResult = ReturnType<typeof useFileTypesQuery>;
export type FileTypesLazyQueryHookResult = ReturnType<typeof useFileTypesLazyQuery>;
export type FileTypesQueryResult = Apollo.QueryResult<FileTypesQuery, FileTypesQueryVariables>;
export const InterventionsDocument = gql`
    query interventions($filter: InterventionFilter, $paging: OffsetPaging, $sorting: [InterventionSort!]) {
  interventions(filter: $filter, paging: $paging, sorting: $sorting) {
    totalCount
    nodes {
      id
      name
      week
      year
      state
      workPermit {
        id
        name
        contract {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useInterventionsQuery__
 *
 * To run a query within a React component, call `useInterventionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterventionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterventionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useInterventionsQuery(baseOptions?: Apollo.QueryHookOptions<InterventionsQuery, InterventionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterventionsQuery, InterventionsQueryVariables>(InterventionsDocument, options);
      }
export function useInterventionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterventionsQuery, InterventionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterventionsQuery, InterventionsQueryVariables>(InterventionsDocument, options);
        }
export type InterventionsQueryHookResult = ReturnType<typeof useInterventionsQuery>;
export type InterventionsLazyQueryHookResult = ReturnType<typeof useInterventionsLazyQuery>;
export type InterventionsQueryResult = Apollo.QueryResult<InterventionsQuery, InterventionsQueryVariables>;
export const InterventionDocument = gql`
    query intervention($id: ID!) {
  intervention(id: $id) {
    id
    name
    state
    week
    year
    workPermit {
      id
      name
      contract {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useInterventionQuery__
 *
 * To run a query within a React component, call `useInterventionQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterventionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterventionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInterventionQuery(baseOptions: Apollo.QueryHookOptions<InterventionQuery, InterventionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterventionQuery, InterventionQueryVariables>(InterventionDocument, options);
      }
export function useInterventionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterventionQuery, InterventionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterventionQuery, InterventionQueryVariables>(InterventionDocument, options);
        }
export type InterventionQueryHookResult = ReturnType<typeof useInterventionQuery>;
export type InterventionLazyQueryHookResult = ReturnType<typeof useInterventionLazyQuery>;
export type InterventionQueryResult = Apollo.QueryResult<InterventionQuery, InterventionQueryVariables>;
export const CreateOneInterventionDocument = gql`
    mutation createOneIntervention($input: CreateOneInterventionInput!) {
  createOneIntervention(input: $input) {
    id
  }
}
    `;
export type CreateOneInterventionMutationFn = Apollo.MutationFunction<CreateOneInterventionMutation, CreateOneInterventionMutationVariables>;

/**
 * __useCreateOneInterventionMutation__
 *
 * To run a mutation, you first call `useCreateOneInterventionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneInterventionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneInterventionMutation, { data, loading, error }] = useCreateOneInterventionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneInterventionMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneInterventionMutation, CreateOneInterventionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneInterventionMutation, CreateOneInterventionMutationVariables>(CreateOneInterventionDocument, options);
      }
export type CreateOneInterventionMutationHookResult = ReturnType<typeof useCreateOneInterventionMutation>;
export type CreateOneInterventionMutationResult = Apollo.MutationResult<CreateOneInterventionMutation>;
export type CreateOneInterventionMutationOptions = Apollo.BaseMutationOptions<CreateOneInterventionMutation, CreateOneInterventionMutationVariables>;
export const UpdateOneInterventionDocument = gql`
    mutation updateOneIntervention($input: UpdateOneInterventionInput!) {
  updateOneIntervention(input: $input) {
    id
  }
}
    `;
export type UpdateOneInterventionMutationFn = Apollo.MutationFunction<UpdateOneInterventionMutation, UpdateOneInterventionMutationVariables>;

/**
 * __useUpdateOneInterventionMutation__
 *
 * To run a mutation, you first call `useUpdateOneInterventionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneInterventionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneInterventionMutation, { data, loading, error }] = useUpdateOneInterventionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneInterventionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneInterventionMutation, UpdateOneInterventionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneInterventionMutation, UpdateOneInterventionMutationVariables>(UpdateOneInterventionDocument, options);
      }
export type UpdateOneInterventionMutationHookResult = ReturnType<typeof useUpdateOneInterventionMutation>;
export type UpdateOneInterventionMutationResult = Apollo.MutationResult<UpdateOneInterventionMutation>;
export type UpdateOneInterventionMutationOptions = Apollo.BaseMutationOptions<UpdateOneInterventionMutation, UpdateOneInterventionMutationVariables>;
export const DeleteOneInterventionDocument = gql`
    mutation deleteOneIntervention($input: DeleteOneInterventionInput!) {
  deleteOneIntervention(input: $input) {
    id
  }
}
    `;
export type DeleteOneInterventionMutationFn = Apollo.MutationFunction<DeleteOneInterventionMutation, DeleteOneInterventionMutationVariables>;

/**
 * __useDeleteOneInterventionMutation__
 *
 * To run a mutation, you first call `useDeleteOneInterventionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneInterventionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneInterventionMutation, { data, loading, error }] = useDeleteOneInterventionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneInterventionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneInterventionMutation, DeleteOneInterventionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneInterventionMutation, DeleteOneInterventionMutationVariables>(DeleteOneInterventionDocument, options);
      }
export type DeleteOneInterventionMutationHookResult = ReturnType<typeof useDeleteOneInterventionMutation>;
export type DeleteOneInterventionMutationResult = Apollo.MutationResult<DeleteOneInterventionMutation>;
export type DeleteOneInterventionMutationOptions = Apollo.BaseMutationOptions<DeleteOneInterventionMutation, DeleteOneInterventionMutationVariables>;
export const GenerateTaskExcelDocument = gql`
    mutation generateTaskExcel($input: GenerateTaskExcelTemplate!) {
  generateTaskExcelTemplate(input: $input)
}
    `;
export type GenerateTaskExcelMutationFn = Apollo.MutationFunction<GenerateTaskExcelMutation, GenerateTaskExcelMutationVariables>;

/**
 * __useGenerateTaskExcelMutation__
 *
 * To run a mutation, you first call `useGenerateTaskExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTaskExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTaskExcelMutation, { data, loading, error }] = useGenerateTaskExcelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateTaskExcelMutation(baseOptions?: Apollo.MutationHookOptions<GenerateTaskExcelMutation, GenerateTaskExcelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateTaskExcelMutation, GenerateTaskExcelMutationVariables>(GenerateTaskExcelDocument, options);
      }
export type GenerateTaskExcelMutationHookResult = ReturnType<typeof useGenerateTaskExcelMutation>;
export type GenerateTaskExcelMutationResult = Apollo.MutationResult<GenerateTaskExcelMutation>;
export type GenerateTaskExcelMutationOptions = Apollo.BaseMutationOptions<GenerateTaskExcelMutation, GenerateTaskExcelMutationVariables>;
export const ValidateTaskImportDocument = gql`
    mutation validateTaskImport($input: ValidateTaskImportInput!) {
  validateTaskImport(input: $input) {
    address
    value
    code
  }
}
    `;
export type ValidateTaskImportMutationFn = Apollo.MutationFunction<ValidateTaskImportMutation, ValidateTaskImportMutationVariables>;

/**
 * __useValidateTaskImportMutation__
 *
 * To run a mutation, you first call `useValidateTaskImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateTaskImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateTaskImportMutation, { data, loading, error }] = useValidateTaskImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateTaskImportMutation(baseOptions?: Apollo.MutationHookOptions<ValidateTaskImportMutation, ValidateTaskImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateTaskImportMutation, ValidateTaskImportMutationVariables>(ValidateTaskImportDocument, options);
      }
export type ValidateTaskImportMutationHookResult = ReturnType<typeof useValidateTaskImportMutation>;
export type ValidateTaskImportMutationResult = Apollo.MutationResult<ValidateTaskImportMutation>;
export type ValidateTaskImportMutationOptions = Apollo.BaseMutationOptions<ValidateTaskImportMutation, ValidateTaskImportMutationVariables>;
export const ImportManyTasksDocument = gql`
    mutation importManyTasks($input: ImportManyTasksInput!) {
  importManyTasks(input: $input)
}
    `;
export type ImportManyTasksMutationFn = Apollo.MutationFunction<ImportManyTasksMutation, ImportManyTasksMutationVariables>;

/**
 * __useImportManyTasksMutation__
 *
 * To run a mutation, you first call `useImportManyTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportManyTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importManyTasksMutation, { data, loading, error }] = useImportManyTasksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportManyTasksMutation(baseOptions?: Apollo.MutationHookOptions<ImportManyTasksMutation, ImportManyTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportManyTasksMutation, ImportManyTasksMutationVariables>(ImportManyTasksDocument, options);
      }
export type ImportManyTasksMutationHookResult = ReturnType<typeof useImportManyTasksMutation>;
export type ImportManyTasksMutationResult = Apollo.MutationResult<ImportManyTasksMutation>;
export type ImportManyTasksMutationOptions = Apollo.BaseMutationOptions<ImportManyTasksMutation, ImportManyTasksMutationVariables>;
export const JunctionsDocument = gql`
    query junctions($filter: JunctionFilter, $paging: OffsetPaging, $sorting: [JunctionSort!]) {
  junctions(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useJunctionsQuery__
 *
 * To run a query within a React component, call `useJunctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJunctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJunctionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useJunctionsQuery(baseOptions?: Apollo.QueryHookOptions<JunctionsQuery, JunctionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JunctionsQuery, JunctionsQueryVariables>(JunctionsDocument, options);
      }
export function useJunctionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JunctionsQuery, JunctionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JunctionsQuery, JunctionsQueryVariables>(JunctionsDocument, options);
        }
export type JunctionsQueryHookResult = ReturnType<typeof useJunctionsQuery>;
export type JunctionsLazyQueryHookResult = ReturnType<typeof useJunctionsLazyQuery>;
export type JunctionsQueryResult = Apollo.QueryResult<JunctionsQuery, JunctionsQueryVariables>;
export const LayoutsDocument = gql`
    query layouts($filter: LayoutFilter, $paging: OffsetPaging, $sorting: [LayoutSort!]) {
  layouts(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useLayoutsQuery__
 *
 * To run a query within a React component, call `useLayoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLayoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLayoutsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useLayoutsQuery(baseOptions?: Apollo.QueryHookOptions<LayoutsQuery, LayoutsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LayoutsQuery, LayoutsQueryVariables>(LayoutsDocument, options);
      }
export function useLayoutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LayoutsQuery, LayoutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LayoutsQuery, LayoutsQueryVariables>(LayoutsDocument, options);
        }
export type LayoutsQueryHookResult = ReturnType<typeof useLayoutsQuery>;
export type LayoutsLazyQueryHookResult = ReturnType<typeof useLayoutsLazyQuery>;
export type LayoutsQueryResult = Apollo.QueryResult<LayoutsQuery, LayoutsQueryVariables>;
export const CreateOneLayoutDocument = gql`
    mutation createOneLayout($input: CreateOneLayoutInput!) {
  createOneLayout(input: $input) {
    id
  }
}
    `;
export type CreateOneLayoutMutationFn = Apollo.MutationFunction<CreateOneLayoutMutation, CreateOneLayoutMutationVariables>;

/**
 * __useCreateOneLayoutMutation__
 *
 * To run a mutation, you first call `useCreateOneLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneLayoutMutation, { data, loading, error }] = useCreateOneLayoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneLayoutMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneLayoutMutation, CreateOneLayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneLayoutMutation, CreateOneLayoutMutationVariables>(CreateOneLayoutDocument, options);
      }
export type CreateOneLayoutMutationHookResult = ReturnType<typeof useCreateOneLayoutMutation>;
export type CreateOneLayoutMutationResult = Apollo.MutationResult<CreateOneLayoutMutation>;
export type CreateOneLayoutMutationOptions = Apollo.BaseMutationOptions<CreateOneLayoutMutation, CreateOneLayoutMutationVariables>;
export const UpdateOneLayoutDocument = gql`
    mutation updateOneLayout($input: UpdateOneLayoutInput!) {
  updateOneLayout(input: $input) {
    id
  }
}
    `;
export type UpdateOneLayoutMutationFn = Apollo.MutationFunction<UpdateOneLayoutMutation, UpdateOneLayoutMutationVariables>;

/**
 * __useUpdateOneLayoutMutation__
 *
 * To run a mutation, you first call `useUpdateOneLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneLayoutMutation, { data, loading, error }] = useUpdateOneLayoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneLayoutMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneLayoutMutation, UpdateOneLayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneLayoutMutation, UpdateOneLayoutMutationVariables>(UpdateOneLayoutDocument, options);
      }
export type UpdateOneLayoutMutationHookResult = ReturnType<typeof useUpdateOneLayoutMutation>;
export type UpdateOneLayoutMutationResult = Apollo.MutationResult<UpdateOneLayoutMutation>;
export type UpdateOneLayoutMutationOptions = Apollo.BaseMutationOptions<UpdateOneLayoutMutation, UpdateOneLayoutMutationVariables>;
export const DeleteOneLayoutDocument = gql`
    mutation deleteOneLayout($input: DeleteOneLayoutInput!) {
  deleteOneLayout(input: $input) {
    id
  }
}
    `;
export type DeleteOneLayoutMutationFn = Apollo.MutationFunction<DeleteOneLayoutMutation, DeleteOneLayoutMutationVariables>;

/**
 * __useDeleteOneLayoutMutation__
 *
 * To run a mutation, you first call `useDeleteOneLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneLayoutMutation, { data, loading, error }] = useDeleteOneLayoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneLayoutMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneLayoutMutation, DeleteOneLayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneLayoutMutation, DeleteOneLayoutMutationVariables>(DeleteOneLayoutDocument, options);
      }
export type DeleteOneLayoutMutationHookResult = ReturnType<typeof useDeleteOneLayoutMutation>;
export type DeleteOneLayoutMutationResult = Apollo.MutationResult<DeleteOneLayoutMutation>;
export type DeleteOneLayoutMutationOptions = Apollo.BaseMutationOptions<DeleteOneLayoutMutation, DeleteOneLayoutMutationVariables>;
export const OrganizationTypesDocument = gql`
    query organizationTypes {
  organizationTypes(sorting: {field: name, direction: ASC}) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useOrganizationTypesQuery__
 *
 * To run a query within a React component, call `useOrganizationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationTypesQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationTypesQuery, OrganizationTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationTypesQuery, OrganizationTypesQueryVariables>(OrganizationTypesDocument, options);
      }
export function useOrganizationTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationTypesQuery, OrganizationTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationTypesQuery, OrganizationTypesQueryVariables>(OrganizationTypesDocument, options);
        }
export type OrganizationTypesQueryHookResult = ReturnType<typeof useOrganizationTypesQuery>;
export type OrganizationTypesLazyQueryHookResult = ReturnType<typeof useOrganizationTypesLazyQuery>;
export type OrganizationTypesQueryResult = Apollo.QueryResult<OrganizationTypesQuery, OrganizationTypesQueryVariables>;
export const OrganizationsDocument = gql`
    query organizations($filter: OrganizationFilter, $paging: OffsetPaging, $sorting: [OrganizationSort!], $concession: ID) {
  organizations(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
      tin
      address
      zipCode
      concessionOrganizations(filter: {concession: {id: {eq: $concession}}}) {
        id
        organizationType {
          id
          name
        }
        concession {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      concession: // value for 'concession'
 *   },
 * });
 */
export function useOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, options);
      }
export function useOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, options);
        }
export type OrganizationsQueryHookResult = ReturnType<typeof useOrganizationsQuery>;
export type OrganizationsLazyQueryHookResult = ReturnType<typeof useOrganizationsLazyQuery>;
export type OrganizationsQueryResult = Apollo.QueryResult<OrganizationsQuery, OrganizationsQueryVariables>;
export const CreateOneOrganizationDocument = gql`
    mutation createOneOrganization($input: CreateOneOrganizationInput!) {
  createOneOrganization(input: $input) {
    id
  }
}
    `;
export type CreateOneOrganizationMutationFn = Apollo.MutationFunction<CreateOneOrganizationMutation, CreateOneOrganizationMutationVariables>;

/**
 * __useCreateOneOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOneOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneOrganizationMutation, { data, loading, error }] = useCreateOneOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneOrganizationMutation, CreateOneOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneOrganizationMutation, CreateOneOrganizationMutationVariables>(CreateOneOrganizationDocument, options);
      }
export type CreateOneOrganizationMutationHookResult = ReturnType<typeof useCreateOneOrganizationMutation>;
export type CreateOneOrganizationMutationResult = Apollo.MutationResult<CreateOneOrganizationMutation>;
export type CreateOneOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOneOrganizationMutation, CreateOneOrganizationMutationVariables>;
export const UpdateOneOrganizationDocument = gql`
    mutation updateOneOrganization($input: UpdateOneOrganizationInput!) {
  updateOneOrganization(input: $input) {
    id
  }
}
    `;
export type UpdateOneOrganizationMutationFn = Apollo.MutationFunction<UpdateOneOrganizationMutation, UpdateOneOrganizationMutationVariables>;

/**
 * __useUpdateOneOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOneOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneOrganizationMutation, { data, loading, error }] = useUpdateOneOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneOrganizationMutation, UpdateOneOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneOrganizationMutation, UpdateOneOrganizationMutationVariables>(UpdateOneOrganizationDocument, options);
      }
export type UpdateOneOrganizationMutationHookResult = ReturnType<typeof useUpdateOneOrganizationMutation>;
export type UpdateOneOrganizationMutationResult = Apollo.MutationResult<UpdateOneOrganizationMutation>;
export type UpdateOneOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOneOrganizationMutation, UpdateOneOrganizationMutationVariables>;
export const DeleteOneOrganizationDocument = gql`
    mutation deleteOneOrganization($input: DeleteOneOrganizationInput!) {
  deleteOneOrganization(input: $input) {
    id
  }
}
    `;
export type DeleteOneOrganizationMutationFn = Apollo.MutationFunction<DeleteOneOrganizationMutation, DeleteOneOrganizationMutationVariables>;

/**
 * __useDeleteOneOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOneOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneOrganizationMutation, { data, loading, error }] = useDeleteOneOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneOrganizationMutation, DeleteOneOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneOrganizationMutation, DeleteOneOrganizationMutationVariables>(DeleteOneOrganizationDocument, options);
      }
export type DeleteOneOrganizationMutationHookResult = ReturnType<typeof useDeleteOneOrganizationMutation>;
export type DeleteOneOrganizationMutationResult = Apollo.MutationResult<DeleteOneOrganizationMutation>;
export type DeleteOneOrganizationMutationOptions = Apollo.BaseMutationOptions<DeleteOneOrganizationMutation, DeleteOneOrganizationMutationVariables>;
export const OwnConcessionOrganizationDocumentsDocument = gql`
    query ownConcessionOrganizationDocuments($filter: ConcessionOrganizationDocumentFilter, $paging: OffsetPaging, $sorting: [ConcessionOrganizationDocumentSort!]) {
  ownConcessionOrganizationDocuments(
    filter: $filter
    paging: $paging
    sorting: $sorting
  ) {
    totalCount
    nodes {
      ...ConcessionOrganizationDocumentDetails
    }
  }
}
    ${ConcessionOrganizationDocumentDetailsFragmentDoc}`;

/**
 * __useOwnConcessionOrganizationDocumentsQuery__
 *
 * To run a query within a React component, call `useOwnConcessionOrganizationDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnConcessionOrganizationDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnConcessionOrganizationDocumentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useOwnConcessionOrganizationDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<OwnConcessionOrganizationDocumentsQuery, OwnConcessionOrganizationDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OwnConcessionOrganizationDocumentsQuery, OwnConcessionOrganizationDocumentsQueryVariables>(OwnConcessionOrganizationDocumentsDocument, options);
      }
export function useOwnConcessionOrganizationDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OwnConcessionOrganizationDocumentsQuery, OwnConcessionOrganizationDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OwnConcessionOrganizationDocumentsQuery, OwnConcessionOrganizationDocumentsQueryVariables>(OwnConcessionOrganizationDocumentsDocument, options);
        }
export type OwnConcessionOrganizationDocumentsQueryHookResult = ReturnType<typeof useOwnConcessionOrganizationDocumentsQuery>;
export type OwnConcessionOrganizationDocumentsLazyQueryHookResult = ReturnType<typeof useOwnConcessionOrganizationDocumentsLazyQuery>;
export type OwnConcessionOrganizationDocumentsQueryResult = Apollo.QueryResult<OwnConcessionOrganizationDocumentsQuery, OwnConcessionOrganizationDocumentsQueryVariables>;
export const CreateOwnConcessionOrganizationDocumentDocument = gql`
    mutation createOwnConcessionOrganizationDocument($input: CreateOwnConcesssionOrganizationDocumentInput!) {
  createOwnConcessionOrganizationDocument(input: $input) {
    id
  }
}
    `;
export type CreateOwnConcessionOrganizationDocumentMutationFn = Apollo.MutationFunction<CreateOwnConcessionOrganizationDocumentMutation, CreateOwnConcessionOrganizationDocumentMutationVariables>;

/**
 * __useCreateOwnConcessionOrganizationDocumentMutation__
 *
 * To run a mutation, you first call `useCreateOwnConcessionOrganizationDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOwnConcessionOrganizationDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOwnConcessionOrganizationDocumentMutation, { data, loading, error }] = useCreateOwnConcessionOrganizationDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOwnConcessionOrganizationDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateOwnConcessionOrganizationDocumentMutation, CreateOwnConcessionOrganizationDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOwnConcessionOrganizationDocumentMutation, CreateOwnConcessionOrganizationDocumentMutationVariables>(CreateOwnConcessionOrganizationDocumentDocument, options);
      }
export type CreateOwnConcessionOrganizationDocumentMutationHookResult = ReturnType<typeof useCreateOwnConcessionOrganizationDocumentMutation>;
export type CreateOwnConcessionOrganizationDocumentMutationResult = Apollo.MutationResult<CreateOwnConcessionOrganizationDocumentMutation>;
export type CreateOwnConcessionOrganizationDocumentMutationOptions = Apollo.BaseMutationOptions<CreateOwnConcessionOrganizationDocumentMutation, CreateOwnConcessionOrganizationDocumentMutationVariables>;
export const UpdateOwnConcessionOrganizationDocumentDocument = gql`
    mutation updateOwnConcessionOrganizationDocument($input: UpdateOwnConcesssionOrganizationDocument!) {
  updateOwnConcessionOrganizationDocument(input: $input) {
    id
  }
}
    `;
export type UpdateOwnConcessionOrganizationDocumentMutationFn = Apollo.MutationFunction<UpdateOwnConcessionOrganizationDocumentMutation, UpdateOwnConcessionOrganizationDocumentMutationVariables>;

/**
 * __useUpdateOwnConcessionOrganizationDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateOwnConcessionOrganizationDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnConcessionOrganizationDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnConcessionOrganizationDocumentMutation, { data, loading, error }] = useUpdateOwnConcessionOrganizationDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOwnConcessionOrganizationDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOwnConcessionOrganizationDocumentMutation, UpdateOwnConcessionOrganizationDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOwnConcessionOrganizationDocumentMutation, UpdateOwnConcessionOrganizationDocumentMutationVariables>(UpdateOwnConcessionOrganizationDocumentDocument, options);
      }
export type UpdateOwnConcessionOrganizationDocumentMutationHookResult = ReturnType<typeof useUpdateOwnConcessionOrganizationDocumentMutation>;
export type UpdateOwnConcessionOrganizationDocumentMutationResult = Apollo.MutationResult<UpdateOwnConcessionOrganizationDocumentMutation>;
export type UpdateOwnConcessionOrganizationDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateOwnConcessionOrganizationDocumentMutation, UpdateOwnConcessionOrganizationDocumentMutationVariables>;
export const DeleteOwnConcessionOrganizationDocumentDocument = gql`
    mutation deleteOwnConcessionOrganizationDocument($input: DeleteOwnConcesssionOrganizationDocument!) {
  deleteOwnConcessionOrganizationDocument(input: $input) {
    id
  }
}
    `;
export type DeleteOwnConcessionOrganizationDocumentMutationFn = Apollo.MutationFunction<DeleteOwnConcessionOrganizationDocumentMutation, DeleteOwnConcessionOrganizationDocumentMutationVariables>;

/**
 * __useDeleteOwnConcessionOrganizationDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteOwnConcessionOrganizationDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOwnConcessionOrganizationDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOwnConcessionOrganizationDocumentMutation, { data, loading, error }] = useDeleteOwnConcessionOrganizationDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOwnConcessionOrganizationDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOwnConcessionOrganizationDocumentMutation, DeleteOwnConcessionOrganizationDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOwnConcessionOrganizationDocumentMutation, DeleteOwnConcessionOrganizationDocumentMutationVariables>(DeleteOwnConcessionOrganizationDocumentDocument, options);
      }
export type DeleteOwnConcessionOrganizationDocumentMutationHookResult = ReturnType<typeof useDeleteOwnConcessionOrganizationDocumentMutation>;
export type DeleteOwnConcessionOrganizationDocumentMutationResult = Apollo.MutationResult<DeleteOwnConcessionOrganizationDocumentMutation>;
export type DeleteOwnConcessionOrganizationDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteOwnConcessionOrganizationDocumentMutation, DeleteOwnConcessionOrganizationDocumentMutationVariables>;
export const OwnSubcontractOrganizationDocumentsDocument = gql`
    query ownSubcontractOrganizationDocuments($filter: ConcessionOrganizationDocumentFilter, $paging: OffsetPaging, $sorting: [ConcessionOrganizationDocumentSort!]) {
  ownSubcontractOrganizationDocuments(
    filter: $filter
    paging: $paging
    sorting: $sorting
  ) {
    totalCount
    nodes {
      ...ConcessionOrganizationDocumentDetails
    }
  }
}
    ${ConcessionOrganizationDocumentDetailsFragmentDoc}`;

/**
 * __useOwnSubcontractOrganizationDocumentsQuery__
 *
 * To run a query within a React component, call `useOwnSubcontractOrganizationDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnSubcontractOrganizationDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnSubcontractOrganizationDocumentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useOwnSubcontractOrganizationDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<OwnSubcontractOrganizationDocumentsQuery, OwnSubcontractOrganizationDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OwnSubcontractOrganizationDocumentsQuery, OwnSubcontractOrganizationDocumentsQueryVariables>(OwnSubcontractOrganizationDocumentsDocument, options);
      }
export function useOwnSubcontractOrganizationDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OwnSubcontractOrganizationDocumentsQuery, OwnSubcontractOrganizationDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OwnSubcontractOrganizationDocumentsQuery, OwnSubcontractOrganizationDocumentsQueryVariables>(OwnSubcontractOrganizationDocumentsDocument, options);
        }
export type OwnSubcontractOrganizationDocumentsQueryHookResult = ReturnType<typeof useOwnSubcontractOrganizationDocumentsQuery>;
export type OwnSubcontractOrganizationDocumentsLazyQueryHookResult = ReturnType<typeof useOwnSubcontractOrganizationDocumentsLazyQuery>;
export type OwnSubcontractOrganizationDocumentsQueryResult = Apollo.QueryResult<OwnSubcontractOrganizationDocumentsQuery, OwnSubcontractOrganizationDocumentsQueryVariables>;
export const CreateOwnSubcontractOrganizationDocumentDocument = gql`
    mutation createOwnSubcontractOrganizationDocument($input: CreateOwnConcesssionOrganizationDocumentInput!) {
  createOwnSubcontractOrganizationDocument(input: $input) {
    id
  }
}
    `;
export type CreateOwnSubcontractOrganizationDocumentMutationFn = Apollo.MutationFunction<CreateOwnSubcontractOrganizationDocumentMutation, CreateOwnSubcontractOrganizationDocumentMutationVariables>;

/**
 * __useCreateOwnSubcontractOrganizationDocumentMutation__
 *
 * To run a mutation, you first call `useCreateOwnSubcontractOrganizationDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOwnSubcontractOrganizationDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOwnSubcontractOrganizationDocumentMutation, { data, loading, error }] = useCreateOwnSubcontractOrganizationDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOwnSubcontractOrganizationDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateOwnSubcontractOrganizationDocumentMutation, CreateOwnSubcontractOrganizationDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOwnSubcontractOrganizationDocumentMutation, CreateOwnSubcontractOrganizationDocumentMutationVariables>(CreateOwnSubcontractOrganizationDocumentDocument, options);
      }
export type CreateOwnSubcontractOrganizationDocumentMutationHookResult = ReturnType<typeof useCreateOwnSubcontractOrganizationDocumentMutation>;
export type CreateOwnSubcontractOrganizationDocumentMutationResult = Apollo.MutationResult<CreateOwnSubcontractOrganizationDocumentMutation>;
export type CreateOwnSubcontractOrganizationDocumentMutationOptions = Apollo.BaseMutationOptions<CreateOwnSubcontractOrganizationDocumentMutation, CreateOwnSubcontractOrganizationDocumentMutationVariables>;
export const UpdateOwnSubcontractOrganizationDocumentDocument = gql`
    mutation updateOwnSubcontractOrganizationDocument($input: UpdateOwnConcesssionOrganizationDocument!) {
  updateOwnSubcontractOrganizationDocument(input: $input) {
    id
  }
}
    `;
export type UpdateOwnSubcontractOrganizationDocumentMutationFn = Apollo.MutationFunction<UpdateOwnSubcontractOrganizationDocumentMutation, UpdateOwnSubcontractOrganizationDocumentMutationVariables>;

/**
 * __useUpdateOwnSubcontractOrganizationDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateOwnSubcontractOrganizationDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnSubcontractOrganizationDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnSubcontractOrganizationDocumentMutation, { data, loading, error }] = useUpdateOwnSubcontractOrganizationDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOwnSubcontractOrganizationDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOwnSubcontractOrganizationDocumentMutation, UpdateOwnSubcontractOrganizationDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOwnSubcontractOrganizationDocumentMutation, UpdateOwnSubcontractOrganizationDocumentMutationVariables>(UpdateOwnSubcontractOrganizationDocumentDocument, options);
      }
export type UpdateOwnSubcontractOrganizationDocumentMutationHookResult = ReturnType<typeof useUpdateOwnSubcontractOrganizationDocumentMutation>;
export type UpdateOwnSubcontractOrganizationDocumentMutationResult = Apollo.MutationResult<UpdateOwnSubcontractOrganizationDocumentMutation>;
export type UpdateOwnSubcontractOrganizationDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateOwnSubcontractOrganizationDocumentMutation, UpdateOwnSubcontractOrganizationDocumentMutationVariables>;
export const DeleteOwnSubcontractOrganizationDocumentDocument = gql`
    mutation deleteOwnSubcontractOrganizationDocument($input: DeleteOwnConcesssionOrganizationDocument!) {
  deleteOwnSubcontractOrganizationDocument(input: $input) {
    id
  }
}
    `;
export type DeleteOwnSubcontractOrganizationDocumentMutationFn = Apollo.MutationFunction<DeleteOwnSubcontractOrganizationDocumentMutation, DeleteOwnSubcontractOrganizationDocumentMutationVariables>;

/**
 * __useDeleteOwnSubcontractOrganizationDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteOwnSubcontractOrganizationDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOwnSubcontractOrganizationDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOwnSubcontractOrganizationDocumentMutation, { data, loading, error }] = useDeleteOwnSubcontractOrganizationDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOwnSubcontractOrganizationDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOwnSubcontractOrganizationDocumentMutation, DeleteOwnSubcontractOrganizationDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOwnSubcontractOrganizationDocumentMutation, DeleteOwnSubcontractOrganizationDocumentMutationVariables>(DeleteOwnSubcontractOrganizationDocumentDocument, options);
      }
export type DeleteOwnSubcontractOrganizationDocumentMutationHookResult = ReturnType<typeof useDeleteOwnSubcontractOrganizationDocumentMutation>;
export type DeleteOwnSubcontractOrganizationDocumentMutationResult = Apollo.MutationResult<DeleteOwnSubcontractOrganizationDocumentMutation>;
export type DeleteOwnSubcontractOrganizationDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteOwnSubcontractOrganizationDocumentMutation, DeleteOwnSubcontractOrganizationDocumentMutationVariables>;
export const CreateManyContractConcessionOrganizationsDedupedDocument = gql`
    mutation createManyContractConcessionOrganizationsDeduped($input: CreateManyContractConcessionOrganizationsDedupedInput!) {
  createManyContractConcessionOrganizationsDeduped(input: $input) {
    id
  }
}
    `;
export type CreateManyContractConcessionOrganizationsDedupedMutationFn = Apollo.MutationFunction<CreateManyContractConcessionOrganizationsDedupedMutation, CreateManyContractConcessionOrganizationsDedupedMutationVariables>;

/**
 * __useCreateManyContractConcessionOrganizationsDedupedMutation__
 *
 * To run a mutation, you first call `useCreateManyContractConcessionOrganizationsDedupedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyContractConcessionOrganizationsDedupedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManyContractConcessionOrganizationsDedupedMutation, { data, loading, error }] = useCreateManyContractConcessionOrganizationsDedupedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManyContractConcessionOrganizationsDedupedMutation(baseOptions?: Apollo.MutationHookOptions<CreateManyContractConcessionOrganizationsDedupedMutation, CreateManyContractConcessionOrganizationsDedupedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManyContractConcessionOrganizationsDedupedMutation, CreateManyContractConcessionOrganizationsDedupedMutationVariables>(CreateManyContractConcessionOrganizationsDedupedDocument, options);
      }
export type CreateManyContractConcessionOrganizationsDedupedMutationHookResult = ReturnType<typeof useCreateManyContractConcessionOrganizationsDedupedMutation>;
export type CreateManyContractConcessionOrganizationsDedupedMutationResult = Apollo.MutationResult<CreateManyContractConcessionOrganizationsDedupedMutation>;
export type CreateManyContractConcessionOrganizationsDedupedMutationOptions = Apollo.BaseMutationOptions<CreateManyContractConcessionOrganizationsDedupedMutation, CreateManyContractConcessionOrganizationsDedupedMutationVariables>;
export const ConcessionOrganizationsDocument = gql`
    query concessionOrganizations($filter: ConcessionOrganizationFilter, $paging: OffsetPaging, $sorting: [ConcessionOrganizationSort!]) {
  concessionOrganizations(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      concession {
        id
        name
      }
      organization {
        id
        name
        tin
      }
      organizationType {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useConcessionOrganizationsQuery__
 *
 * To run a query within a React component, call `useConcessionOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConcessionOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConcessionOrganizationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useConcessionOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<ConcessionOrganizationsQuery, ConcessionOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConcessionOrganizationsQuery, ConcessionOrganizationsQueryVariables>(ConcessionOrganizationsDocument, options);
      }
export function useConcessionOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConcessionOrganizationsQuery, ConcessionOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConcessionOrganizationsQuery, ConcessionOrganizationsQueryVariables>(ConcessionOrganizationsDocument, options);
        }
export type ConcessionOrganizationsQueryHookResult = ReturnType<typeof useConcessionOrganizationsQuery>;
export type ConcessionOrganizationsLazyQueryHookResult = ReturnType<typeof useConcessionOrganizationsLazyQuery>;
export type ConcessionOrganizationsQueryResult = Apollo.QueryResult<ConcessionOrganizationsQuery, ConcessionOrganizationsQueryVariables>;
export const CreateOneConcessionOrganizationDocument = gql`
    mutation createOneConcessionOrganization($input: CreateOneConcessionOrganizationInput!) {
  createOneConcessionOrganization(input: $input) {
    id
  }
}
    `;
export type CreateOneConcessionOrganizationMutationFn = Apollo.MutationFunction<CreateOneConcessionOrganizationMutation, CreateOneConcessionOrganizationMutationVariables>;

/**
 * __useCreateOneConcessionOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOneConcessionOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneConcessionOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneConcessionOrganizationMutation, { data, loading, error }] = useCreateOneConcessionOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneConcessionOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneConcessionOrganizationMutation, CreateOneConcessionOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneConcessionOrganizationMutation, CreateOneConcessionOrganizationMutationVariables>(CreateOneConcessionOrganizationDocument, options);
      }
export type CreateOneConcessionOrganizationMutationHookResult = ReturnType<typeof useCreateOneConcessionOrganizationMutation>;
export type CreateOneConcessionOrganizationMutationResult = Apollo.MutationResult<CreateOneConcessionOrganizationMutation>;
export type CreateOneConcessionOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOneConcessionOrganizationMutation, CreateOneConcessionOrganizationMutationVariables>;
export const UpdateOneConcessionOrganizationDocument = gql`
    mutation updateOneConcessionOrganization($input: UpdateOneConcessionOrganizationInput!) {
  updateOneConcessionOrganization(input: $input) {
    id
  }
}
    `;
export type UpdateOneConcessionOrganizationMutationFn = Apollo.MutationFunction<UpdateOneConcessionOrganizationMutation, UpdateOneConcessionOrganizationMutationVariables>;

/**
 * __useUpdateOneConcessionOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOneConcessionOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneConcessionOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneConcessionOrganizationMutation, { data, loading, error }] = useUpdateOneConcessionOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneConcessionOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneConcessionOrganizationMutation, UpdateOneConcessionOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneConcessionOrganizationMutation, UpdateOneConcessionOrganizationMutationVariables>(UpdateOneConcessionOrganizationDocument, options);
      }
export type UpdateOneConcessionOrganizationMutationHookResult = ReturnType<typeof useUpdateOneConcessionOrganizationMutation>;
export type UpdateOneConcessionOrganizationMutationResult = Apollo.MutationResult<UpdateOneConcessionOrganizationMutation>;
export type UpdateOneConcessionOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOneConcessionOrganizationMutation, UpdateOneConcessionOrganizationMutationVariables>;
export const DeleteOneConcessionOrganizationDocument = gql`
    mutation deleteOneConcessionOrganization($input: DeleteOneConcessionOrganizationInput!) {
  deleteOneConcessionOrganization(input: $input) {
    id
  }
}
    `;
export type DeleteOneConcessionOrganizationMutationFn = Apollo.MutationFunction<DeleteOneConcessionOrganizationMutation, DeleteOneConcessionOrganizationMutationVariables>;

/**
 * __useDeleteOneConcessionOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOneConcessionOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneConcessionOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneConcessionOrganizationMutation, { data, loading, error }] = useDeleteOneConcessionOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneConcessionOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneConcessionOrganizationMutation, DeleteOneConcessionOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneConcessionOrganizationMutation, DeleteOneConcessionOrganizationMutationVariables>(DeleteOneConcessionOrganizationDocument, options);
      }
export type DeleteOneConcessionOrganizationMutationHookResult = ReturnType<typeof useDeleteOneConcessionOrganizationMutation>;
export type DeleteOneConcessionOrganizationMutationResult = Apollo.MutationResult<DeleteOneConcessionOrganizationMutation>;
export type DeleteOneConcessionOrganizationMutationOptions = Apollo.BaseMutationOptions<DeleteOneConcessionOrganizationMutation, DeleteOneConcessionOrganizationMutationVariables>;
export const ConcessionUsersDocument = gql`
    query concessionUsers($filter: ConcessionUserFilter, $paging: OffsetPaging, $sorting: [ConcessionUserSort!]) {
  concessionUsers(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      role
      user {
        id
        name
        email
        phone
      }
      concession {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useConcessionUsersQuery__
 *
 * To run a query within a React component, call `useConcessionUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useConcessionUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConcessionUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useConcessionUsersQuery(baseOptions?: Apollo.QueryHookOptions<ConcessionUsersQuery, ConcessionUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConcessionUsersQuery, ConcessionUsersQueryVariables>(ConcessionUsersDocument, options);
      }
export function useConcessionUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConcessionUsersQuery, ConcessionUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConcessionUsersQuery, ConcessionUsersQueryVariables>(ConcessionUsersDocument, options);
        }
export type ConcessionUsersQueryHookResult = ReturnType<typeof useConcessionUsersQuery>;
export type ConcessionUsersLazyQueryHookResult = ReturnType<typeof useConcessionUsersLazyQuery>;
export type ConcessionUsersQueryResult = Apollo.QueryResult<ConcessionUsersQuery, ConcessionUsersQueryVariables>;
export const CreateOneConcessionUserDocument = gql`
    mutation createOneConcessionUser($input: CreateOneConcessionUserInput!) {
  createOneConcessionUser(input: $input) {
    id
    user {
      id
      name
    }
  }
}
    `;
export type CreateOneConcessionUserMutationFn = Apollo.MutationFunction<CreateOneConcessionUserMutation, CreateOneConcessionUserMutationVariables>;

/**
 * __useCreateOneConcessionUserMutation__
 *
 * To run a mutation, you first call `useCreateOneConcessionUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneConcessionUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneConcessionUserMutation, { data, loading, error }] = useCreateOneConcessionUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneConcessionUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneConcessionUserMutation, CreateOneConcessionUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneConcessionUserMutation, CreateOneConcessionUserMutationVariables>(CreateOneConcessionUserDocument, options);
      }
export type CreateOneConcessionUserMutationHookResult = ReturnType<typeof useCreateOneConcessionUserMutation>;
export type CreateOneConcessionUserMutationResult = Apollo.MutationResult<CreateOneConcessionUserMutation>;
export type CreateOneConcessionUserMutationOptions = Apollo.BaseMutationOptions<CreateOneConcessionUserMutation, CreateOneConcessionUserMutationVariables>;
export const UpdateOneConcessionUserDocument = gql`
    mutation updateOneConcessionUser($input: UpdateOneConcessionUserInput!) {
  updateOneConcessionUser(input: $input) {
    id
  }
}
    `;
export type UpdateOneConcessionUserMutationFn = Apollo.MutationFunction<UpdateOneConcessionUserMutation, UpdateOneConcessionUserMutationVariables>;

/**
 * __useUpdateOneConcessionUserMutation__
 *
 * To run a mutation, you first call `useUpdateOneConcessionUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneConcessionUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneConcessionUserMutation, { data, loading, error }] = useUpdateOneConcessionUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneConcessionUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneConcessionUserMutation, UpdateOneConcessionUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneConcessionUserMutation, UpdateOneConcessionUserMutationVariables>(UpdateOneConcessionUserDocument, options);
      }
export type UpdateOneConcessionUserMutationHookResult = ReturnType<typeof useUpdateOneConcessionUserMutation>;
export type UpdateOneConcessionUserMutationResult = Apollo.MutationResult<UpdateOneConcessionUserMutation>;
export type UpdateOneConcessionUserMutationOptions = Apollo.BaseMutationOptions<UpdateOneConcessionUserMutation, UpdateOneConcessionUserMutationVariables>;
export const DeleteOneConcessionUserDocument = gql`
    mutation deleteOneConcessionUser($input: DeleteOneConcessionUserInput!) {
  deleteOneConcessionUser(input: $input) {
    id
  }
}
    `;
export type DeleteOneConcessionUserMutationFn = Apollo.MutationFunction<DeleteOneConcessionUserMutation, DeleteOneConcessionUserMutationVariables>;

/**
 * __useDeleteOneConcessionUserMutation__
 *
 * To run a mutation, you first call `useDeleteOneConcessionUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneConcessionUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneConcessionUserMutation, { data, loading, error }] = useDeleteOneConcessionUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneConcessionUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneConcessionUserMutation, DeleteOneConcessionUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneConcessionUserMutation, DeleteOneConcessionUserMutationVariables>(DeleteOneConcessionUserDocument, options);
      }
export type DeleteOneConcessionUserMutationHookResult = ReturnType<typeof useDeleteOneConcessionUserMutation>;
export type DeleteOneConcessionUserMutationResult = Apollo.MutationResult<DeleteOneConcessionUserMutation>;
export type DeleteOneConcessionUserMutationOptions = Apollo.BaseMutationOptions<DeleteOneConcessionUserMutation, DeleteOneConcessionUserMutationVariables>;
export const SetContractRoadsForContractDocument = gql`
    mutation setContractRoadsForContract($input: SetContractRoadsForContractInput!) {
  setContractRoadsForContract(input: $input) {
    id
    name
  }
}
    `;
export type SetContractRoadsForContractMutationFn = Apollo.MutationFunction<SetContractRoadsForContractMutation, SetContractRoadsForContractMutationVariables>;

/**
 * __useSetContractRoadsForContractMutation__
 *
 * To run a mutation, you first call `useSetContractRoadsForContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetContractRoadsForContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setContractRoadsForContractMutation, { data, loading, error }] = useSetContractRoadsForContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetContractRoadsForContractMutation(baseOptions?: Apollo.MutationHookOptions<SetContractRoadsForContractMutation, SetContractRoadsForContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetContractRoadsForContractMutation, SetContractRoadsForContractMutationVariables>(SetContractRoadsForContractDocument, options);
      }
export type SetContractRoadsForContractMutationHookResult = ReturnType<typeof useSetContractRoadsForContractMutation>;
export type SetContractRoadsForContractMutationResult = Apollo.MutationResult<SetContractRoadsForContractMutation>;
export type SetContractRoadsForContractMutationOptions = Apollo.BaseMutationOptions<SetContractRoadsForContractMutation, SetContractRoadsForContractMutationVariables>;
export const SetContractUsersForContractDocument = gql`
    mutation setContractUsersForContract($input: SetContractUsersForContractInput!) {
  setContractUsersForContract(input: $input) {
    id
    name
  }
}
    `;
export type SetContractUsersForContractMutationFn = Apollo.MutationFunction<SetContractUsersForContractMutation, SetContractUsersForContractMutationVariables>;

/**
 * __useSetContractUsersForContractMutation__
 *
 * To run a mutation, you first call `useSetContractUsersForContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetContractUsersForContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setContractUsersForContractMutation, { data, loading, error }] = useSetContractUsersForContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetContractUsersForContractMutation(baseOptions?: Apollo.MutationHookOptions<SetContractUsersForContractMutation, SetContractUsersForContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetContractUsersForContractMutation, SetContractUsersForContractMutationVariables>(SetContractUsersForContractDocument, options);
      }
export type SetContractUsersForContractMutationHookResult = ReturnType<typeof useSetContractUsersForContractMutation>;
export type SetContractUsersForContractMutationResult = Apollo.MutationResult<SetContractUsersForContractMutation>;
export type SetContractUsersForContractMutationOptions = Apollo.BaseMutationOptions<SetContractUsersForContractMutation, SetContractUsersForContractMutationVariables>;
export const CreateManyContractRoadsDocument = gql`
    mutation createManyContractRoads($input: CreateManyContractRoadsInput!) {
  createManyContractRoads(input: $input) {
    id
  }
}
    `;
export type CreateManyContractRoadsMutationFn = Apollo.MutationFunction<CreateManyContractRoadsMutation, CreateManyContractRoadsMutationVariables>;

/**
 * __useCreateManyContractRoadsMutation__
 *
 * To run a mutation, you first call `useCreateManyContractRoadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyContractRoadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManyContractRoadsMutation, { data, loading, error }] = useCreateManyContractRoadsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManyContractRoadsMutation(baseOptions?: Apollo.MutationHookOptions<CreateManyContractRoadsMutation, CreateManyContractRoadsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManyContractRoadsMutation, CreateManyContractRoadsMutationVariables>(CreateManyContractRoadsDocument, options);
      }
export type CreateManyContractRoadsMutationHookResult = ReturnType<typeof useCreateManyContractRoadsMutation>;
export type CreateManyContractRoadsMutationResult = Apollo.MutationResult<CreateManyContractRoadsMutation>;
export type CreateManyContractRoadsMutationOptions = Apollo.BaseMutationOptions<CreateManyContractRoadsMutation, CreateManyContractRoadsMutationVariables>;
export const ContractTypesDocument = gql`
    query contractTypes($filter: ContractTypeFilter, $paging: OffsetPaging, $sorting: [ContractTypeSort!]) {
  contractTypes(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
      abbreviation
    }
  }
}
    `;

/**
 * __useContractTypesQuery__
 *
 * To run a query within a React component, call `useContractTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractTypesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useContractTypesQuery(baseOptions?: Apollo.QueryHookOptions<ContractTypesQuery, ContractTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractTypesQuery, ContractTypesQueryVariables>(ContractTypesDocument, options);
      }
export function useContractTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractTypesQuery, ContractTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractTypesQuery, ContractTypesQueryVariables>(ContractTypesDocument, options);
        }
export type ContractTypesQueryHookResult = ReturnType<typeof useContractTypesQuery>;
export type ContractTypesLazyQueryHookResult = ReturnType<typeof useContractTypesLazyQuery>;
export type ContractTypesQueryResult = Apollo.QueryResult<ContractTypesQuery, ContractTypesQueryVariables>;
export const CreateOneContractTypeDocument = gql`
    mutation createOneContractType($input: CreateOneContractTypeInput!) {
  createOneContractType(input: $input) {
    id
  }
}
    `;
export type CreateOneContractTypeMutationFn = Apollo.MutationFunction<CreateOneContractTypeMutation, CreateOneContractTypeMutationVariables>;

/**
 * __useCreateOneContractTypeMutation__
 *
 * To run a mutation, you first call `useCreateOneContractTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneContractTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneContractTypeMutation, { data, loading, error }] = useCreateOneContractTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneContractTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneContractTypeMutation, CreateOneContractTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneContractTypeMutation, CreateOneContractTypeMutationVariables>(CreateOneContractTypeDocument, options);
      }
export type CreateOneContractTypeMutationHookResult = ReturnType<typeof useCreateOneContractTypeMutation>;
export type CreateOneContractTypeMutationResult = Apollo.MutationResult<CreateOneContractTypeMutation>;
export type CreateOneContractTypeMutationOptions = Apollo.BaseMutationOptions<CreateOneContractTypeMutation, CreateOneContractTypeMutationVariables>;
export const UpdateOneContractTypeDocument = gql`
    mutation updateOneContractType($input: UpdateOneContractTypeInput!) {
  updateOneContractType(input: $input) {
    id
  }
}
    `;
export type UpdateOneContractTypeMutationFn = Apollo.MutationFunction<UpdateOneContractTypeMutation, UpdateOneContractTypeMutationVariables>;

/**
 * __useUpdateOneContractTypeMutation__
 *
 * To run a mutation, you first call `useUpdateOneContractTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneContractTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneContractTypeMutation, { data, loading, error }] = useUpdateOneContractTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneContractTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneContractTypeMutation, UpdateOneContractTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneContractTypeMutation, UpdateOneContractTypeMutationVariables>(UpdateOneContractTypeDocument, options);
      }
export type UpdateOneContractTypeMutationHookResult = ReturnType<typeof useUpdateOneContractTypeMutation>;
export type UpdateOneContractTypeMutationResult = Apollo.MutationResult<UpdateOneContractTypeMutation>;
export type UpdateOneContractTypeMutationOptions = Apollo.BaseMutationOptions<UpdateOneContractTypeMutation, UpdateOneContractTypeMutationVariables>;
export const DeleteOneContractTypeDocument = gql`
    mutation deleteOneContractType($input: DeleteOneContractTypeInput!) {
  deleteOneContractType(input: $input) {
    id
  }
}
    `;
export type DeleteOneContractTypeMutationFn = Apollo.MutationFunction<DeleteOneContractTypeMutation, DeleteOneContractTypeMutationVariables>;

/**
 * __useDeleteOneContractTypeMutation__
 *
 * To run a mutation, you first call `useDeleteOneContractTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneContractTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneContractTypeMutation, { data, loading, error }] = useDeleteOneContractTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneContractTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneContractTypeMutation, DeleteOneContractTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneContractTypeMutation, DeleteOneContractTypeMutationVariables>(DeleteOneContractTypeDocument, options);
      }
export type DeleteOneContractTypeMutationHookResult = ReturnType<typeof useDeleteOneContractTypeMutation>;
export type DeleteOneContractTypeMutationResult = Apollo.MutationResult<DeleteOneContractTypeMutation>;
export type DeleteOneContractTypeMutationOptions = Apollo.BaseMutationOptions<DeleteOneContractTypeMutation, DeleteOneContractTypeMutationVariables>;
export const CreateManyContractUsersDocument = gql`
    mutation createManyContractUsers($input: CreateManyContractUsersInput!) {
  createManyContractUsers(input: $input) {
    id
    role
  }
}
    `;
export type CreateManyContractUsersMutationFn = Apollo.MutationFunction<CreateManyContractUsersMutation, CreateManyContractUsersMutationVariables>;

/**
 * __useCreateManyContractUsersMutation__
 *
 * To run a mutation, you first call `useCreateManyContractUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyContractUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManyContractUsersMutation, { data, loading, error }] = useCreateManyContractUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManyContractUsersMutation(baseOptions?: Apollo.MutationHookOptions<CreateManyContractUsersMutation, CreateManyContractUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManyContractUsersMutation, CreateManyContractUsersMutationVariables>(CreateManyContractUsersDocument, options);
      }
export type CreateManyContractUsersMutationHookResult = ReturnType<typeof useCreateManyContractUsersMutation>;
export type CreateManyContractUsersMutationResult = Apollo.MutationResult<CreateManyContractUsersMutation>;
export type CreateManyContractUsersMutationOptions = Apollo.BaseMutationOptions<CreateManyContractUsersMutation, CreateManyContractUsersMutationVariables>;
export const SetPasswordDocument = gql`
    mutation setPassword($input: SetPasswordInput!) {
  setPassword(input: $input)
}
    `;
export type SetPasswordMutationFn = Apollo.MutationFunction<SetPasswordMutation, SetPasswordMutationVariables>;

/**
 * __useSetPasswordMutation__
 *
 * To run a mutation, you first call `useSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordMutation, { data, loading, error }] = useSetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SetPasswordMutation, SetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPasswordMutation, SetPasswordMutationVariables>(SetPasswordDocument, options);
      }
export type SetPasswordMutationHookResult = ReturnType<typeof useSetPasswordMutation>;
export type SetPasswordMutationResult = Apollo.MutationResult<SetPasswordMutation>;
export type SetPasswordMutationOptions = Apollo.BaseMutationOptions<SetPasswordMutation, SetPasswordMutationVariables>;
export const ContractFieldDocument = gql`
    query contractField($filter: ContractFilter, $paging: OffsetPaging, $sorting: [ContractSort!]) {
  contracts(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useContractFieldQuery__
 *
 * To run a query within a React component, call `useContractFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractFieldQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useContractFieldQuery(baseOptions?: Apollo.QueryHookOptions<ContractFieldQuery, ContractFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractFieldQuery, ContractFieldQueryVariables>(ContractFieldDocument, options);
      }
export function useContractFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractFieldQuery, ContractFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractFieldQuery, ContractFieldQueryVariables>(ContractFieldDocument, options);
        }
export type ContractFieldQueryHookResult = ReturnType<typeof useContractFieldQuery>;
export type ContractFieldLazyQueryHookResult = ReturnType<typeof useContractFieldLazyQuery>;
export type ContractFieldQueryResult = Apollo.QueryResult<ContractFieldQuery, ContractFieldQueryVariables>;
export const ContractManagerFieldDocument = gql`
    query contractManagerField($concession: ID) {
  concessionUsers(
    filter: {concession: {id: {eq: $concession}}, role: {eq: CONTRACTMANAGER}}
  ) {
    nodes {
      id
      role
      user {
        id
        name
        email
      }
    }
  }
}
    `;

/**
 * __useContractManagerFieldQuery__
 *
 * To run a query within a React component, call `useContractManagerFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractManagerFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractManagerFieldQuery({
 *   variables: {
 *      concession: // value for 'concession'
 *   },
 * });
 */
export function useContractManagerFieldQuery(baseOptions?: Apollo.QueryHookOptions<ContractManagerFieldQuery, ContractManagerFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractManagerFieldQuery, ContractManagerFieldQueryVariables>(ContractManagerFieldDocument, options);
      }
export function useContractManagerFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractManagerFieldQuery, ContractManagerFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractManagerFieldQuery, ContractManagerFieldQueryVariables>(ContractManagerFieldDocument, options);
        }
export type ContractManagerFieldQueryHookResult = ReturnType<typeof useContractManagerFieldQuery>;
export type ContractManagerFieldLazyQueryHookResult = ReturnType<typeof useContractManagerFieldLazyQuery>;
export type ContractManagerFieldQueryResult = Apollo.QueryResult<ContractManagerFieldQuery, ContractManagerFieldQueryVariables>;
export const ServiceManagerFieldDocument = gql`
    query serviceManagerField($concession: ID) {
  concessionUsers(
    filter: {concession: {id: {eq: $concession}}, role: {eq: SERVICEMANAGER}}
  ) {
    nodes {
      id
      role
      user {
        id
        name
        email
      }
    }
  }
}
    `;

/**
 * __useServiceManagerFieldQuery__
 *
 * To run a query within a React component, call `useServiceManagerFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceManagerFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceManagerFieldQuery({
 *   variables: {
 *      concession: // value for 'concession'
 *   },
 * });
 */
export function useServiceManagerFieldQuery(baseOptions?: Apollo.QueryHookOptions<ServiceManagerFieldQuery, ServiceManagerFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceManagerFieldQuery, ServiceManagerFieldQueryVariables>(ServiceManagerFieldDocument, options);
      }
export function useServiceManagerFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceManagerFieldQuery, ServiceManagerFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceManagerFieldQuery, ServiceManagerFieldQueryVariables>(ServiceManagerFieldDocument, options);
        }
export type ServiceManagerFieldQueryHookResult = ReturnType<typeof useServiceManagerFieldQuery>;
export type ServiceManagerFieldLazyQueryHookResult = ReturnType<typeof useServiceManagerFieldLazyQuery>;
export type ServiceManagerFieldQueryResult = Apollo.QueryResult<ServiceManagerFieldQuery, ServiceManagerFieldQueryVariables>;
export const SafetyTechnicianFieldDocument = gql`
    query safetyTechnicianField($concession: ID) {
  concessionUsers(
    filter: {concession: {id: {eq: $concession}}, role: {eq: SAFETYTECHNICIAN}}
  ) {
    nodes {
      id
      role
      user {
        id
        name
        email
      }
    }
  }
}
    `;

/**
 * __useSafetyTechnicianFieldQuery__
 *
 * To run a query within a React component, call `useSafetyTechnicianFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useSafetyTechnicianFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSafetyTechnicianFieldQuery({
 *   variables: {
 *      concession: // value for 'concession'
 *   },
 * });
 */
export function useSafetyTechnicianFieldQuery(baseOptions?: Apollo.QueryHookOptions<SafetyTechnicianFieldQuery, SafetyTechnicianFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SafetyTechnicianFieldQuery, SafetyTechnicianFieldQueryVariables>(SafetyTechnicianFieldDocument, options);
      }
export function useSafetyTechnicianFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SafetyTechnicianFieldQuery, SafetyTechnicianFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SafetyTechnicianFieldQuery, SafetyTechnicianFieldQueryVariables>(SafetyTechnicianFieldDocument, options);
        }
export type SafetyTechnicianFieldQueryHookResult = ReturnType<typeof useSafetyTechnicianFieldQuery>;
export type SafetyTechnicianFieldLazyQueryHookResult = ReturnType<typeof useSafetyTechnicianFieldLazyQuery>;
export type SafetyTechnicianFieldQueryResult = Apollo.QueryResult<SafetyTechnicianFieldQuery, SafetyTechnicianFieldQueryVariables>;
export const ContractingOrganizationsFieldDocument = gql`
    query contractingOrganizationsField($concession: ID, $paging: OffsetPaging) {
  concessionOrganizations(
    filter: {organizationType: {id: {eq: 1}}, concession: {id: {eq: $concession}}}
    paging: $paging
  ) {
    nodes {
      id
      concession {
        id
        name
      }
      organization {
        id
        name
      }
      organizationType {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useContractingOrganizationsFieldQuery__
 *
 * To run a query within a React component, call `useContractingOrganizationsFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractingOrganizationsFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractingOrganizationsFieldQuery({
 *   variables: {
 *      concession: // value for 'concession'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useContractingOrganizationsFieldQuery(baseOptions?: Apollo.QueryHookOptions<ContractingOrganizationsFieldQuery, ContractingOrganizationsFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractingOrganizationsFieldQuery, ContractingOrganizationsFieldQueryVariables>(ContractingOrganizationsFieldDocument, options);
      }
export function useContractingOrganizationsFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractingOrganizationsFieldQuery, ContractingOrganizationsFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractingOrganizationsFieldQuery, ContractingOrganizationsFieldQueryVariables>(ContractingOrganizationsFieldDocument, options);
        }
export type ContractingOrganizationsFieldQueryHookResult = ReturnType<typeof useContractingOrganizationsFieldQuery>;
export type ContractingOrganizationsFieldLazyQueryHookResult = ReturnType<typeof useContractingOrganizationsFieldLazyQuery>;
export type ContractingOrganizationsFieldQueryResult = Apollo.QueryResult<ContractingOrganizationsFieldQuery, ContractingOrganizationsFieldQueryVariables>;
export const ExecutingOrganizationsFieldDocument = gql`
    query executingOrganizationsField($concession: ID, $paging: OffsetPaging) {
  concessionOrganizations(
    filter: {organizationType: {id: {eq: 2}}, concession: {id: {eq: $concession}}}
    paging: $paging
  ) {
    nodes {
      id
      concession {
        id
        name
      }
      organization {
        id
        name
      }
      organizationType {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useExecutingOrganizationsFieldQuery__
 *
 * To run a query within a React component, call `useExecutingOrganizationsFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useExecutingOrganizationsFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExecutingOrganizationsFieldQuery({
 *   variables: {
 *      concession: // value for 'concession'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useExecutingOrganizationsFieldQuery(baseOptions?: Apollo.QueryHookOptions<ExecutingOrganizationsFieldQuery, ExecutingOrganizationsFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExecutingOrganizationsFieldQuery, ExecutingOrganizationsFieldQueryVariables>(ExecutingOrganizationsFieldDocument, options);
      }
export function useExecutingOrganizationsFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExecutingOrganizationsFieldQuery, ExecutingOrganizationsFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExecutingOrganizationsFieldQuery, ExecutingOrganizationsFieldQueryVariables>(ExecutingOrganizationsFieldDocument, options);
        }
export type ExecutingOrganizationsFieldQueryHookResult = ReturnType<typeof useExecutingOrganizationsFieldQuery>;
export type ExecutingOrganizationsFieldLazyQueryHookResult = ReturnType<typeof useExecutingOrganizationsFieldLazyQuery>;
export type ExecutingOrganizationsFieldQueryResult = Apollo.QueryResult<ExecutingOrganizationsFieldQuery, ExecutingOrganizationsFieldQueryVariables>;
export const InspectingOrganizationsFieldDocument = gql`
    query inspectingOrganizationsField($concession: ID, $paging: OffsetPaging) {
  concessionOrganizations(
    filter: {organizationType: {id: {eq: 3}}, concession: {id: {eq: $concession}}}
    paging: $paging
  ) {
    nodes {
      id
      concession {
        id
        name
      }
      organization {
        id
        name
      }
      organizationType {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useInspectingOrganizationsFieldQuery__
 *
 * To run a query within a React component, call `useInspectingOrganizationsFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useInspectingOrganizationsFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInspectingOrganizationsFieldQuery({
 *   variables: {
 *      concession: // value for 'concession'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useInspectingOrganizationsFieldQuery(baseOptions?: Apollo.QueryHookOptions<InspectingOrganizationsFieldQuery, InspectingOrganizationsFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InspectingOrganizationsFieldQuery, InspectingOrganizationsFieldQueryVariables>(InspectingOrganizationsFieldDocument, options);
      }
export function useInspectingOrganizationsFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InspectingOrganizationsFieldQuery, InspectingOrganizationsFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InspectingOrganizationsFieldQuery, InspectingOrganizationsFieldQueryVariables>(InspectingOrganizationsFieldDocument, options);
        }
export type InspectingOrganizationsFieldQueryHookResult = ReturnType<typeof useInspectingOrganizationsFieldQuery>;
export type InspectingOrganizationsFieldLazyQueryHookResult = ReturnType<typeof useInspectingOrganizationsFieldLazyQuery>;
export type InspectingOrganizationsFieldQueryResult = Apollo.QueryResult<InspectingOrganizationsFieldQuery, InspectingOrganizationsFieldQueryVariables>;
export const JunctionFieldDocument = gql`
    query junctionField($filter: JunctionFilter, $paging: OffsetPaging, $sorting: [JunctionSort!]) {
  junctions(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useJunctionFieldQuery__
 *
 * To run a query within a React component, call `useJunctionFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useJunctionFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJunctionFieldQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useJunctionFieldQuery(baseOptions?: Apollo.QueryHookOptions<JunctionFieldQuery, JunctionFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JunctionFieldQuery, JunctionFieldQueryVariables>(JunctionFieldDocument, options);
      }
export function useJunctionFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JunctionFieldQuery, JunctionFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JunctionFieldQuery, JunctionFieldQueryVariables>(JunctionFieldDocument, options);
        }
export type JunctionFieldQueryHookResult = ReturnType<typeof useJunctionFieldQuery>;
export type JunctionFieldLazyQueryHookResult = ReturnType<typeof useJunctionFieldLazyQuery>;
export type JunctionFieldQueryResult = Apollo.QueryResult<JunctionFieldQuery, JunctionFieldQueryVariables>;
export const ProjectingOrganizationsFieldDocument = gql`
    query projectingOrganizationsField($concession: ID, $paging: OffsetPaging) {
  concessionOrganizations(
    filter: {organizationType: {id: {eq: 4}}, concession: {id: {eq: $concession}}}
    paging: $paging
  ) {
    nodes {
      id
      concession {
        id
        name
      }
      organization {
        id
        name
      }
      organizationType {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useProjectingOrganizationsFieldQuery__
 *
 * To run a query within a React component, call `useProjectingOrganizationsFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectingOrganizationsFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectingOrganizationsFieldQuery({
 *   variables: {
 *      concession: // value for 'concession'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useProjectingOrganizationsFieldQuery(baseOptions?: Apollo.QueryHookOptions<ProjectingOrganizationsFieldQuery, ProjectingOrganizationsFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectingOrganizationsFieldQuery, ProjectingOrganizationsFieldQueryVariables>(ProjectingOrganizationsFieldDocument, options);
      }
export function useProjectingOrganizationsFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectingOrganizationsFieldQuery, ProjectingOrganizationsFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectingOrganizationsFieldQuery, ProjectingOrganizationsFieldQueryVariables>(ProjectingOrganizationsFieldDocument, options);
        }
export type ProjectingOrganizationsFieldQueryHookResult = ReturnType<typeof useProjectingOrganizationsFieldQuery>;
export type ProjectingOrganizationsFieldLazyQueryHookResult = ReturnType<typeof useProjectingOrganizationsFieldLazyQuery>;
export type ProjectingOrganizationsFieldQueryResult = Apollo.QueryResult<ProjectingOrganizationsFieldQuery, ProjectingOrganizationsFieldQueryVariables>;
export const WeatherConditionFieldDocument = gql`
    query weatherConditionField($filter: WeatherConditionFilter, $paging: OffsetPaging, $sorting: [WeatherConditionSort!]) {
  weatherConditions(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useWeatherConditionFieldQuery__
 *
 * To run a query within a React component, call `useWeatherConditionFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeatherConditionFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeatherConditionFieldQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useWeatherConditionFieldQuery(baseOptions?: Apollo.QueryHookOptions<WeatherConditionFieldQuery, WeatherConditionFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WeatherConditionFieldQuery, WeatherConditionFieldQueryVariables>(WeatherConditionFieldDocument, options);
      }
export function useWeatherConditionFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WeatherConditionFieldQuery, WeatherConditionFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WeatherConditionFieldQuery, WeatherConditionFieldQueryVariables>(WeatherConditionFieldDocument, options);
        }
export type WeatherConditionFieldQueryHookResult = ReturnType<typeof useWeatherConditionFieldQuery>;
export type WeatherConditionFieldLazyQueryHookResult = ReturnType<typeof useWeatherConditionFieldLazyQuery>;
export type WeatherConditionFieldQueryResult = Apollo.QueryResult<WeatherConditionFieldQuery, WeatherConditionFieldQueryVariables>;
export const InterventionElementsDocument = gql`
    query interventionElements($filter: InterventionElementFilter, $paging: OffsetPaging, $sorting: [InterventionElementSort!]) {
  interventionElements(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
      abbreviation
    }
  }
}
    `;

/**
 * __useInterventionElementsQuery__
 *
 * To run a query within a React component, call `useInterventionElementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterventionElementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterventionElementsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useInterventionElementsQuery(baseOptions?: Apollo.QueryHookOptions<InterventionElementsQuery, InterventionElementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterventionElementsQuery, InterventionElementsQueryVariables>(InterventionElementsDocument, options);
      }
export function useInterventionElementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterventionElementsQuery, InterventionElementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterventionElementsQuery, InterventionElementsQueryVariables>(InterventionElementsDocument, options);
        }
export type InterventionElementsQueryHookResult = ReturnType<typeof useInterventionElementsQuery>;
export type InterventionElementsLazyQueryHookResult = ReturnType<typeof useInterventionElementsLazyQuery>;
export type InterventionElementsQueryResult = Apollo.QueryResult<InterventionElementsQuery, InterventionElementsQueryVariables>;
export const CreateOneInterventionElementDocument = gql`
    mutation createOneInterventionElement($input: CreateOneInterventionElementInput!) {
  createOneInterventionElement(input: $input) {
    id
  }
}
    `;
export type CreateOneInterventionElementMutationFn = Apollo.MutationFunction<CreateOneInterventionElementMutation, CreateOneInterventionElementMutationVariables>;

/**
 * __useCreateOneInterventionElementMutation__
 *
 * To run a mutation, you first call `useCreateOneInterventionElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneInterventionElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneInterventionElementMutation, { data, loading, error }] = useCreateOneInterventionElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneInterventionElementMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneInterventionElementMutation, CreateOneInterventionElementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneInterventionElementMutation, CreateOneInterventionElementMutationVariables>(CreateOneInterventionElementDocument, options);
      }
export type CreateOneInterventionElementMutationHookResult = ReturnType<typeof useCreateOneInterventionElementMutation>;
export type CreateOneInterventionElementMutationResult = Apollo.MutationResult<CreateOneInterventionElementMutation>;
export type CreateOneInterventionElementMutationOptions = Apollo.BaseMutationOptions<CreateOneInterventionElementMutation, CreateOneInterventionElementMutationVariables>;
export const UpdateOneInterventionElementDocument = gql`
    mutation updateOneInterventionElement($input: UpdateOneInterventionElementInput!) {
  updateOneInterventionElement(input: $input) {
    id
  }
}
    `;
export type UpdateOneInterventionElementMutationFn = Apollo.MutationFunction<UpdateOneInterventionElementMutation, UpdateOneInterventionElementMutationVariables>;

/**
 * __useUpdateOneInterventionElementMutation__
 *
 * To run a mutation, you first call `useUpdateOneInterventionElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneInterventionElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneInterventionElementMutation, { data, loading, error }] = useUpdateOneInterventionElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneInterventionElementMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneInterventionElementMutation, UpdateOneInterventionElementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneInterventionElementMutation, UpdateOneInterventionElementMutationVariables>(UpdateOneInterventionElementDocument, options);
      }
export type UpdateOneInterventionElementMutationHookResult = ReturnType<typeof useUpdateOneInterventionElementMutation>;
export type UpdateOneInterventionElementMutationResult = Apollo.MutationResult<UpdateOneInterventionElementMutation>;
export type UpdateOneInterventionElementMutationOptions = Apollo.BaseMutationOptions<UpdateOneInterventionElementMutation, UpdateOneInterventionElementMutationVariables>;
export const DeleteOneInterventionElementDocument = gql`
    mutation deleteOneInterventionElement($input: DeleteOneInterventionElementInput!) {
  deleteOneInterventionElement(input: $input) {
    id
  }
}
    `;
export type DeleteOneInterventionElementMutationFn = Apollo.MutationFunction<DeleteOneInterventionElementMutation, DeleteOneInterventionElementMutationVariables>;

/**
 * __useDeleteOneInterventionElementMutation__
 *
 * To run a mutation, you first call `useDeleteOneInterventionElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneInterventionElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneInterventionElementMutation, { data, loading, error }] = useDeleteOneInterventionElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneInterventionElementMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneInterventionElementMutation, DeleteOneInterventionElementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneInterventionElementMutation, DeleteOneInterventionElementMutationVariables>(DeleteOneInterventionElementDocument, options);
      }
export type DeleteOneInterventionElementMutationHookResult = ReturnType<typeof useDeleteOneInterventionElementMutation>;
export type DeleteOneInterventionElementMutationResult = Apollo.MutationResult<DeleteOneInterventionElementMutation>;
export type DeleteOneInterventionElementMutationOptions = Apollo.BaseMutationOptions<DeleteOneInterventionElementMutation, DeleteOneInterventionElementMutationVariables>;
export const InterventionLocationsDocument = gql`
    query interventionLocations($filter: InterventionLocationFilter, $paging: OffsetPaging, $sorting: [InterventionLocationSort!]) {
  interventionLocations(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
      abbreviation
    }
  }
}
    `;

/**
 * __useInterventionLocationsQuery__
 *
 * To run a query within a React component, call `useInterventionLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterventionLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterventionLocationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useInterventionLocationsQuery(baseOptions?: Apollo.QueryHookOptions<InterventionLocationsQuery, InterventionLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterventionLocationsQuery, InterventionLocationsQueryVariables>(InterventionLocationsDocument, options);
      }
export function useInterventionLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterventionLocationsQuery, InterventionLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterventionLocationsQuery, InterventionLocationsQueryVariables>(InterventionLocationsDocument, options);
        }
export type InterventionLocationsQueryHookResult = ReturnType<typeof useInterventionLocationsQuery>;
export type InterventionLocationsLazyQueryHookResult = ReturnType<typeof useInterventionLocationsLazyQuery>;
export type InterventionLocationsQueryResult = Apollo.QueryResult<InterventionLocationsQuery, InterventionLocationsQueryVariables>;
export const CreateOneInterventionLocationDocument = gql`
    mutation createOneInterventionLocation($input: CreateOneInterventionLocationInput!) {
  createOneInterventionLocation(input: $input) {
    id
  }
}
    `;
export type CreateOneInterventionLocationMutationFn = Apollo.MutationFunction<CreateOneInterventionLocationMutation, CreateOneInterventionLocationMutationVariables>;

/**
 * __useCreateOneInterventionLocationMutation__
 *
 * To run a mutation, you first call `useCreateOneInterventionLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneInterventionLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneInterventionLocationMutation, { data, loading, error }] = useCreateOneInterventionLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneInterventionLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneInterventionLocationMutation, CreateOneInterventionLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneInterventionLocationMutation, CreateOneInterventionLocationMutationVariables>(CreateOneInterventionLocationDocument, options);
      }
export type CreateOneInterventionLocationMutationHookResult = ReturnType<typeof useCreateOneInterventionLocationMutation>;
export type CreateOneInterventionLocationMutationResult = Apollo.MutationResult<CreateOneInterventionLocationMutation>;
export type CreateOneInterventionLocationMutationOptions = Apollo.BaseMutationOptions<CreateOneInterventionLocationMutation, CreateOneInterventionLocationMutationVariables>;
export const UpdateOneInterventionLocationDocument = gql`
    mutation updateOneInterventionLocation($input: UpdateOneInterventionLocationInput!) {
  updateOneInterventionLocation(input: $input) {
    id
  }
}
    `;
export type UpdateOneInterventionLocationMutationFn = Apollo.MutationFunction<UpdateOneInterventionLocationMutation, UpdateOneInterventionLocationMutationVariables>;

/**
 * __useUpdateOneInterventionLocationMutation__
 *
 * To run a mutation, you first call `useUpdateOneInterventionLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneInterventionLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneInterventionLocationMutation, { data, loading, error }] = useUpdateOneInterventionLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneInterventionLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneInterventionLocationMutation, UpdateOneInterventionLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneInterventionLocationMutation, UpdateOneInterventionLocationMutationVariables>(UpdateOneInterventionLocationDocument, options);
      }
export type UpdateOneInterventionLocationMutationHookResult = ReturnType<typeof useUpdateOneInterventionLocationMutation>;
export type UpdateOneInterventionLocationMutationResult = Apollo.MutationResult<UpdateOneInterventionLocationMutation>;
export type UpdateOneInterventionLocationMutationOptions = Apollo.BaseMutationOptions<UpdateOneInterventionLocationMutation, UpdateOneInterventionLocationMutationVariables>;
export const DeleteOneInterventionLocationDocument = gql`
    mutation deleteOneInterventionLocation($input: DeleteOneInterventionLocationInput!) {
  deleteOneInterventionLocation(input: $input) {
    id
  }
}
    `;
export type DeleteOneInterventionLocationMutationFn = Apollo.MutationFunction<DeleteOneInterventionLocationMutation, DeleteOneInterventionLocationMutationVariables>;

/**
 * __useDeleteOneInterventionLocationMutation__
 *
 * To run a mutation, you first call `useDeleteOneInterventionLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneInterventionLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneInterventionLocationMutation, { data, loading, error }] = useDeleteOneInterventionLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneInterventionLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneInterventionLocationMutation, DeleteOneInterventionLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneInterventionLocationMutation, DeleteOneInterventionLocationMutationVariables>(DeleteOneInterventionLocationDocument, options);
      }
export type DeleteOneInterventionLocationMutationHookResult = ReturnType<typeof useDeleteOneInterventionLocationMutation>;
export type DeleteOneInterventionLocationMutationResult = Apollo.MutationResult<DeleteOneInterventionLocationMutation>;
export type DeleteOneInterventionLocationMutationOptions = Apollo.BaseMutationOptions<DeleteOneInterventionLocationMutation, DeleteOneInterventionLocationMutationVariables>;
export const InterventionTypesDocument = gql`
    query interventionTypes($filter: InterventionTypeFilter, $paging: OffsetPaging, $sorting: [InterventionTypeSort!]) {
  interventionTypes(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
      abbreviation
    }
  }
}
    `;

/**
 * __useInterventionTypesQuery__
 *
 * To run a query within a React component, call `useInterventionTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterventionTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterventionTypesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useInterventionTypesQuery(baseOptions?: Apollo.QueryHookOptions<InterventionTypesQuery, InterventionTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterventionTypesQuery, InterventionTypesQueryVariables>(InterventionTypesDocument, options);
      }
export function useInterventionTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterventionTypesQuery, InterventionTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterventionTypesQuery, InterventionTypesQueryVariables>(InterventionTypesDocument, options);
        }
export type InterventionTypesQueryHookResult = ReturnType<typeof useInterventionTypesQuery>;
export type InterventionTypesLazyQueryHookResult = ReturnType<typeof useInterventionTypesLazyQuery>;
export type InterventionTypesQueryResult = Apollo.QueryResult<InterventionTypesQuery, InterventionTypesQueryVariables>;
export const CreateOneInterventionTypeDocument = gql`
    mutation createOneInterventionType($input: CreateOneInterventionTypeInput!) {
  createOneInterventionType(input: $input) {
    id
  }
}
    `;
export type CreateOneInterventionTypeMutationFn = Apollo.MutationFunction<CreateOneInterventionTypeMutation, CreateOneInterventionTypeMutationVariables>;

/**
 * __useCreateOneInterventionTypeMutation__
 *
 * To run a mutation, you first call `useCreateOneInterventionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneInterventionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneInterventionTypeMutation, { data, loading, error }] = useCreateOneInterventionTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneInterventionTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneInterventionTypeMutation, CreateOneInterventionTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneInterventionTypeMutation, CreateOneInterventionTypeMutationVariables>(CreateOneInterventionTypeDocument, options);
      }
export type CreateOneInterventionTypeMutationHookResult = ReturnType<typeof useCreateOneInterventionTypeMutation>;
export type CreateOneInterventionTypeMutationResult = Apollo.MutationResult<CreateOneInterventionTypeMutation>;
export type CreateOneInterventionTypeMutationOptions = Apollo.BaseMutationOptions<CreateOneInterventionTypeMutation, CreateOneInterventionTypeMutationVariables>;
export const UpdateOneInterventionTypeDocument = gql`
    mutation updateOneInterventionType($input: UpdateOneInterventionTypeInput!) {
  updateOneInterventionType(input: $input) {
    id
  }
}
    `;
export type UpdateOneInterventionTypeMutationFn = Apollo.MutationFunction<UpdateOneInterventionTypeMutation, UpdateOneInterventionTypeMutationVariables>;

/**
 * __useUpdateOneInterventionTypeMutation__
 *
 * To run a mutation, you first call `useUpdateOneInterventionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneInterventionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneInterventionTypeMutation, { data, loading, error }] = useUpdateOneInterventionTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneInterventionTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneInterventionTypeMutation, UpdateOneInterventionTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneInterventionTypeMutation, UpdateOneInterventionTypeMutationVariables>(UpdateOneInterventionTypeDocument, options);
      }
export type UpdateOneInterventionTypeMutationHookResult = ReturnType<typeof useUpdateOneInterventionTypeMutation>;
export type UpdateOneInterventionTypeMutationResult = Apollo.MutationResult<UpdateOneInterventionTypeMutation>;
export type UpdateOneInterventionTypeMutationOptions = Apollo.BaseMutationOptions<UpdateOneInterventionTypeMutation, UpdateOneInterventionTypeMutationVariables>;
export const DeleteOneInterventionTypeDocument = gql`
    mutation deleteOneInterventionType($input: DeleteOneInterventionTypeInput!) {
  deleteOneInterventionType(input: $input) {
    id
  }
}
    `;
export type DeleteOneInterventionTypeMutationFn = Apollo.MutationFunction<DeleteOneInterventionTypeMutation, DeleteOneInterventionTypeMutationVariables>;

/**
 * __useDeleteOneInterventionTypeMutation__
 *
 * To run a mutation, you first call `useDeleteOneInterventionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneInterventionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneInterventionTypeMutation, { data, loading, error }] = useDeleteOneInterventionTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneInterventionTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneInterventionTypeMutation, DeleteOneInterventionTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneInterventionTypeMutation, DeleteOneInterventionTypeMutationVariables>(DeleteOneInterventionTypeDocument, options);
      }
export type DeleteOneInterventionTypeMutationHookResult = ReturnType<typeof useDeleteOneInterventionTypeMutation>;
export type DeleteOneInterventionTypeMutationResult = Apollo.MutationResult<DeleteOneInterventionTypeMutation>;
export type DeleteOneInterventionTypeMutationOptions = Apollo.BaseMutationOptions<DeleteOneInterventionTypeMutation, DeleteOneInterventionTypeMutationVariables>;
export const SendContractOpenedNotificationDocument = gql`
    mutation sendContractOpenedNotification($id: String!) {
  sendContractOpenedNotification(id: $id)
}
    `;
export type SendContractOpenedNotificationMutationFn = Apollo.MutationFunction<SendContractOpenedNotificationMutation, SendContractOpenedNotificationMutationVariables>;

/**
 * __useSendContractOpenedNotificationMutation__
 *
 * To run a mutation, you first call `useSendContractOpenedNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContractOpenedNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContractOpenedNotificationMutation, { data, loading, error }] = useSendContractOpenedNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendContractOpenedNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendContractOpenedNotificationMutation, SendContractOpenedNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendContractOpenedNotificationMutation, SendContractOpenedNotificationMutationVariables>(SendContractOpenedNotificationDocument, options);
      }
export type SendContractOpenedNotificationMutationHookResult = ReturnType<typeof useSendContractOpenedNotificationMutation>;
export type SendContractOpenedNotificationMutationResult = Apollo.MutationResult<SendContractOpenedNotificationMutation>;
export type SendContractOpenedNotificationMutationOptions = Apollo.BaseMutationOptions<SendContractOpenedNotificationMutation, SendContractOpenedNotificationMutationVariables>;
export const SendContractClosedNotificationDocument = gql`
    mutation sendContractClosedNotification($id: String!) {
  sendContractClosedNotification(id: $id)
}
    `;
export type SendContractClosedNotificationMutationFn = Apollo.MutationFunction<SendContractClosedNotificationMutation, SendContractClosedNotificationMutationVariables>;

/**
 * __useSendContractClosedNotificationMutation__
 *
 * To run a mutation, you first call `useSendContractClosedNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContractClosedNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContractClosedNotificationMutation, { data, loading, error }] = useSendContractClosedNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendContractClosedNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendContractClosedNotificationMutation, SendContractClosedNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendContractClosedNotificationMutation, SendContractClosedNotificationMutationVariables>(SendContractClosedNotificationDocument, options);
      }
export type SendContractClosedNotificationMutationHookResult = ReturnType<typeof useSendContractClosedNotificationMutation>;
export type SendContractClosedNotificationMutationResult = Apollo.MutationResult<SendContractClosedNotificationMutation>;
export type SendContractClosedNotificationMutationOptions = Apollo.BaseMutationOptions<SendContractClosedNotificationMutation, SendContractClosedNotificationMutationVariables>;
export const RoadsDocument = gql`
    query roads($filter: RoadFilter, $paging: OffsetPaging, $sorting: [RoadSort!]) {
  roads(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useRoadsQuery__
 *
 * To run a query within a React component, call `useRoadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useRoadsQuery(baseOptions?: Apollo.QueryHookOptions<RoadsQuery, RoadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoadsQuery, RoadsQueryVariables>(RoadsDocument, options);
      }
export function useRoadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoadsQuery, RoadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoadsQuery, RoadsQueryVariables>(RoadsDocument, options);
        }
export type RoadsQueryHookResult = ReturnType<typeof useRoadsQuery>;
export type RoadsLazyQueryHookResult = ReturnType<typeof useRoadsLazyQuery>;
export type RoadsQueryResult = Apollo.QueryResult<RoadsQuery, RoadsQueryVariables>;
export const CreateOneRoadDocument = gql`
    mutation createOneRoad($input: CreateOneRoadInput!) {
  createOneRoad(input: $input) {
    id
  }
}
    `;
export type CreateOneRoadMutationFn = Apollo.MutationFunction<CreateOneRoadMutation, CreateOneRoadMutationVariables>;

/**
 * __useCreateOneRoadMutation__
 *
 * To run a mutation, you first call `useCreateOneRoadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneRoadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneRoadMutation, { data, loading, error }] = useCreateOneRoadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneRoadMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneRoadMutation, CreateOneRoadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneRoadMutation, CreateOneRoadMutationVariables>(CreateOneRoadDocument, options);
      }
export type CreateOneRoadMutationHookResult = ReturnType<typeof useCreateOneRoadMutation>;
export type CreateOneRoadMutationResult = Apollo.MutationResult<CreateOneRoadMutation>;
export type CreateOneRoadMutationOptions = Apollo.BaseMutationOptions<CreateOneRoadMutation, CreateOneRoadMutationVariables>;
export const UpdateOneRoadDocument = gql`
    mutation updateOneRoad($input: UpdateOneRoadInput!) {
  updateOneRoad(input: $input) {
    id
  }
}
    `;
export type UpdateOneRoadMutationFn = Apollo.MutationFunction<UpdateOneRoadMutation, UpdateOneRoadMutationVariables>;

/**
 * __useUpdateOneRoadMutation__
 *
 * To run a mutation, you first call `useUpdateOneRoadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneRoadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneRoadMutation, { data, loading, error }] = useUpdateOneRoadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneRoadMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneRoadMutation, UpdateOneRoadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneRoadMutation, UpdateOneRoadMutationVariables>(UpdateOneRoadDocument, options);
      }
export type UpdateOneRoadMutationHookResult = ReturnType<typeof useUpdateOneRoadMutation>;
export type UpdateOneRoadMutationResult = Apollo.MutationResult<UpdateOneRoadMutation>;
export type UpdateOneRoadMutationOptions = Apollo.BaseMutationOptions<UpdateOneRoadMutation, UpdateOneRoadMutationVariables>;
export const DeleteOneRoadDocument = gql`
    mutation deleteOneRoad($input: DeleteOneRoadInput!) {
  deleteOneRoad(input: $input) {
    id
  }
}
    `;
export type DeleteOneRoadMutationFn = Apollo.MutationFunction<DeleteOneRoadMutation, DeleteOneRoadMutationVariables>;

/**
 * __useDeleteOneRoadMutation__
 *
 * To run a mutation, you first call `useDeleteOneRoadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneRoadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneRoadMutation, { data, loading, error }] = useDeleteOneRoadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneRoadMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneRoadMutation, DeleteOneRoadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneRoadMutation, DeleteOneRoadMutationVariables>(DeleteOneRoadDocument, options);
      }
export type DeleteOneRoadMutationHookResult = ReturnType<typeof useDeleteOneRoadMutation>;
export type DeleteOneRoadMutationResult = Apollo.MutationResult<DeleteOneRoadMutation>;
export type DeleteOneRoadMutationOptions = Apollo.BaseMutationOptions<DeleteOneRoadMutation, DeleteOneRoadMutationVariables>;
export const UsersDocument = gql`
    query users($filter: UserFilter, $paging: OffsetPaging, $sorting: [UserSort!], $concession: ID) {
  users(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
      email
      phone
      concessionUsers(filter: {concession: {id: {eq: $concession}}}) {
        id
        role
        concession {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      concession: // value for 'concession'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const CreateOneUserDocument = gql`
    mutation createOneUser($input: CreateOneUserInput!) {
  createOneUser(input: $input) {
    id
  }
}
    `;
export type CreateOneUserMutationFn = Apollo.MutationFunction<CreateOneUserMutation, CreateOneUserMutationVariables>;

/**
 * __useCreateOneUserMutation__
 *
 * To run a mutation, you first call `useCreateOneUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneUserMutation, { data, loading, error }] = useCreateOneUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneUserMutation, CreateOneUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneUserMutation, CreateOneUserMutationVariables>(CreateOneUserDocument, options);
      }
export type CreateOneUserMutationHookResult = ReturnType<typeof useCreateOneUserMutation>;
export type CreateOneUserMutationResult = Apollo.MutationResult<CreateOneUserMutation>;
export type CreateOneUserMutationOptions = Apollo.BaseMutationOptions<CreateOneUserMutation, CreateOneUserMutationVariables>;
export const UpdateOneUserDocument = gql`
    mutation updateOneUser($input: UpdateOneUserInput!) {
  updateOneUser(input: $input) {
    id
  }
}
    `;
export type UpdateOneUserMutationFn = Apollo.MutationFunction<UpdateOneUserMutation, UpdateOneUserMutationVariables>;

/**
 * __useUpdateOneUserMutation__
 *
 * To run a mutation, you first call `useUpdateOneUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneUserMutation, { data, loading, error }] = useUpdateOneUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneUserMutation, UpdateOneUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneUserMutation, UpdateOneUserMutationVariables>(UpdateOneUserDocument, options);
      }
export type UpdateOneUserMutationHookResult = ReturnType<typeof useUpdateOneUserMutation>;
export type UpdateOneUserMutationResult = Apollo.MutationResult<UpdateOneUserMutation>;
export type UpdateOneUserMutationOptions = Apollo.BaseMutationOptions<UpdateOneUserMutation, UpdateOneUserMutationVariables>;
export const DeleteOneUserDocument = gql`
    mutation deleteOneUser($input: DeleteOneUserInput!) {
  deleteOneUser(input: $input) {
    id
  }
}
    `;
export type DeleteOneUserMutationFn = Apollo.MutationFunction<DeleteOneUserMutation, DeleteOneUserMutationVariables>;

/**
 * __useDeleteOneUserMutation__
 *
 * To run a mutation, you first call `useDeleteOneUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneUserMutation, { data, loading, error }] = useDeleteOneUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneUserMutation, DeleteOneUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneUserMutation, DeleteOneUserMutationVariables>(DeleteOneUserDocument, options);
      }
export type DeleteOneUserMutationHookResult = ReturnType<typeof useDeleteOneUserMutation>;
export type DeleteOneUserMutationResult = Apollo.MutationResult<DeleteOneUserMutation>;
export type DeleteOneUserMutationOptions = Apollo.BaseMutationOptions<DeleteOneUserMutation, DeleteOneUserMutationVariables>;
export const WeatherConditionsDocument = gql`
    query weatherConditions($filter: WeatherConditionFilter, $paging: OffsetPaging, $sorting: [WeatherConditionSort!]) {
  weatherConditions(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useWeatherConditionsQuery__
 *
 * To run a query within a React component, call `useWeatherConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeatherConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeatherConditionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useWeatherConditionsQuery(baseOptions?: Apollo.QueryHookOptions<WeatherConditionsQuery, WeatherConditionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WeatherConditionsQuery, WeatherConditionsQueryVariables>(WeatherConditionsDocument, options);
      }
export function useWeatherConditionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WeatherConditionsQuery, WeatherConditionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WeatherConditionsQuery, WeatherConditionsQueryVariables>(WeatherConditionsDocument, options);
        }
export type WeatherConditionsQueryHookResult = ReturnType<typeof useWeatherConditionsQuery>;
export type WeatherConditionsLazyQueryHookResult = ReturnType<typeof useWeatherConditionsLazyQuery>;
export type WeatherConditionsQueryResult = Apollo.QueryResult<WeatherConditionsQuery, WeatherConditionsQueryVariables>;
export const CreateOneWeatherConditionDocument = gql`
    mutation createOneWeatherCondition($input: CreateOneWeatherConditionInput!) {
  createOneWeatherCondition(input: $input) {
    id
    name
  }
}
    `;
export type CreateOneWeatherConditionMutationFn = Apollo.MutationFunction<CreateOneWeatherConditionMutation, CreateOneWeatherConditionMutationVariables>;

/**
 * __useCreateOneWeatherConditionMutation__
 *
 * To run a mutation, you first call `useCreateOneWeatherConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneWeatherConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneWeatherConditionMutation, { data, loading, error }] = useCreateOneWeatherConditionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneWeatherConditionMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneWeatherConditionMutation, CreateOneWeatherConditionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneWeatherConditionMutation, CreateOneWeatherConditionMutationVariables>(CreateOneWeatherConditionDocument, options);
      }
export type CreateOneWeatherConditionMutationHookResult = ReturnType<typeof useCreateOneWeatherConditionMutation>;
export type CreateOneWeatherConditionMutationResult = Apollo.MutationResult<CreateOneWeatherConditionMutation>;
export type CreateOneWeatherConditionMutationOptions = Apollo.BaseMutationOptions<CreateOneWeatherConditionMutation, CreateOneWeatherConditionMutationVariables>;
export const UpdateOneWeatherConditionDocument = gql`
    mutation updateOneWeatherCondition($input: UpdateOneWeatherConditionInput!) {
  updateOneWeatherCondition(input: $input) {
    id
    name
  }
}
    `;
export type UpdateOneWeatherConditionMutationFn = Apollo.MutationFunction<UpdateOneWeatherConditionMutation, UpdateOneWeatherConditionMutationVariables>;

/**
 * __useUpdateOneWeatherConditionMutation__
 *
 * To run a mutation, you first call `useUpdateOneWeatherConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneWeatherConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneWeatherConditionMutation, { data, loading, error }] = useUpdateOneWeatherConditionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneWeatherConditionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneWeatherConditionMutation, UpdateOneWeatherConditionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneWeatherConditionMutation, UpdateOneWeatherConditionMutationVariables>(UpdateOneWeatherConditionDocument, options);
      }
export type UpdateOneWeatherConditionMutationHookResult = ReturnType<typeof useUpdateOneWeatherConditionMutation>;
export type UpdateOneWeatherConditionMutationResult = Apollo.MutationResult<UpdateOneWeatherConditionMutation>;
export type UpdateOneWeatherConditionMutationOptions = Apollo.BaseMutationOptions<UpdateOneWeatherConditionMutation, UpdateOneWeatherConditionMutationVariables>;
export const DeleteOneWeatherConditionDocument = gql`
    mutation deleteOneWeatherCondition($input: DeleteOneWeatherConditionInput!) {
  deleteOneWeatherCondition(input: $input) {
    id
  }
}
    `;
export type DeleteOneWeatherConditionMutationFn = Apollo.MutationFunction<DeleteOneWeatherConditionMutation, DeleteOneWeatherConditionMutationVariables>;

/**
 * __useDeleteOneWeatherConditionMutation__
 *
 * To run a mutation, you first call `useDeleteOneWeatherConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneWeatherConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneWeatherConditionMutation, { data, loading, error }] = useDeleteOneWeatherConditionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneWeatherConditionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneWeatherConditionMutation, DeleteOneWeatherConditionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneWeatherConditionMutation, DeleteOneWeatherConditionMutationVariables>(DeleteOneWeatherConditionDocument, options);
      }
export type DeleteOneWeatherConditionMutationHookResult = ReturnType<typeof useDeleteOneWeatherConditionMutation>;
export type DeleteOneWeatherConditionMutationResult = Apollo.MutationResult<DeleteOneWeatherConditionMutation>;
export type DeleteOneWeatherConditionMutationOptions = Apollo.BaseMutationOptions<DeleteOneWeatherConditionMutation, DeleteOneWeatherConditionMutationVariables>;
export const StatusDocument = gql`
    query status {
  status
}
    `;

/**
 * __useStatusQuery__
 *
 * To run a query within a React component, call `useStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatusQuery(baseOptions?: Apollo.QueryHookOptions<StatusQuery, StatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatusQuery, StatusQueryVariables>(StatusDocument, options);
      }
export function useStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatusQuery, StatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatusQuery, StatusQueryVariables>(StatusDocument, options);
        }
export type StatusQueryHookResult = ReturnType<typeof useStatusQuery>;
export type StatusLazyQueryHookResult = ReturnType<typeof useStatusLazyQuery>;
export type StatusQueryResult = Apollo.QueryResult<StatusQuery, StatusQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    name
    email
    phone
    defaultConcession {
      id
      name
    }
    concessionUsers {
      id
      role
      concession {
        id
        name
      }
    }
    concessionOrganizationUsers {
      role
      concessionOrganization {
        concession {
          id
        }
      }
    }
  }
  roles(paging: {limit: 9999}) {
    nodes {
      name
      permissions {
        subject
        action
        fields
      }
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UpdateMeDocument = gql`
    mutation updateMe($input: UpdateMe!) {
  updateMe(input: $input) {
    id
    name
    phone
  }
}
    `;
export type UpdateMeMutationFn = Apollo.MutationFunction<UpdateMeMutation, UpdateMeMutationVariables>;

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options);
      }
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMeMutation, UpdateMeMutationVariables>;
export const RestrictionsDocument = gql`
    query restrictions($filter: RestrictionFilter, $paging: OffsetPaging, $sorting: [RestrictionSort!]) {
  restrictions(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useRestrictionsQuery__
 *
 * To run a query within a React component, call `useRestrictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestrictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestrictionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useRestrictionsQuery(baseOptions?: Apollo.QueryHookOptions<RestrictionsQuery, RestrictionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RestrictionsQuery, RestrictionsQueryVariables>(RestrictionsDocument, options);
      }
export function useRestrictionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RestrictionsQuery, RestrictionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RestrictionsQuery, RestrictionsQueryVariables>(RestrictionsDocument, options);
        }
export type RestrictionsQueryHookResult = ReturnType<typeof useRestrictionsQuery>;
export type RestrictionsLazyQueryHookResult = ReturnType<typeof useRestrictionsLazyQuery>;
export type RestrictionsQueryResult = Apollo.QueryResult<RestrictionsQuery, RestrictionsQueryVariables>;
export const CreateOneRestrictionDocument = gql`
    mutation createOneRestriction($input: CreateOneRestrictionInput!) {
  createOneRestriction(input: $input) {
    id
  }
}
    `;
export type CreateOneRestrictionMutationFn = Apollo.MutationFunction<CreateOneRestrictionMutation, CreateOneRestrictionMutationVariables>;

/**
 * __useCreateOneRestrictionMutation__
 *
 * To run a mutation, you first call `useCreateOneRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneRestrictionMutation, { data, loading, error }] = useCreateOneRestrictionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneRestrictionMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneRestrictionMutation, CreateOneRestrictionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneRestrictionMutation, CreateOneRestrictionMutationVariables>(CreateOneRestrictionDocument, options);
      }
export type CreateOneRestrictionMutationHookResult = ReturnType<typeof useCreateOneRestrictionMutation>;
export type CreateOneRestrictionMutationResult = Apollo.MutationResult<CreateOneRestrictionMutation>;
export type CreateOneRestrictionMutationOptions = Apollo.BaseMutationOptions<CreateOneRestrictionMutation, CreateOneRestrictionMutationVariables>;
export const UpdateOneRestrictionDocument = gql`
    mutation updateOneRestriction($input: UpdateOneRestrictionInput!) {
  updateOneRestriction(input: $input) {
    id
  }
}
    `;
export type UpdateOneRestrictionMutationFn = Apollo.MutationFunction<UpdateOneRestrictionMutation, UpdateOneRestrictionMutationVariables>;

/**
 * __useUpdateOneRestrictionMutation__
 *
 * To run a mutation, you first call `useUpdateOneRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneRestrictionMutation, { data, loading, error }] = useUpdateOneRestrictionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneRestrictionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneRestrictionMutation, UpdateOneRestrictionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneRestrictionMutation, UpdateOneRestrictionMutationVariables>(UpdateOneRestrictionDocument, options);
      }
export type UpdateOneRestrictionMutationHookResult = ReturnType<typeof useUpdateOneRestrictionMutation>;
export type UpdateOneRestrictionMutationResult = Apollo.MutationResult<UpdateOneRestrictionMutation>;
export type UpdateOneRestrictionMutationOptions = Apollo.BaseMutationOptions<UpdateOneRestrictionMutation, UpdateOneRestrictionMutationVariables>;
export const DeleteOneRestrictionDocument = gql`
    mutation deleteOneRestriction($input: DeleteOneRestrictionInput!) {
  deleteOneRestriction(input: $input) {
    id
  }
}
    `;
export type DeleteOneRestrictionMutationFn = Apollo.MutationFunction<DeleteOneRestrictionMutation, DeleteOneRestrictionMutationVariables>;

/**
 * __useDeleteOneRestrictionMutation__
 *
 * To run a mutation, you first call `useDeleteOneRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneRestrictionMutation, { data, loading, error }] = useDeleteOneRestrictionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneRestrictionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneRestrictionMutation, DeleteOneRestrictionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneRestrictionMutation, DeleteOneRestrictionMutationVariables>(DeleteOneRestrictionDocument, options);
      }
export type DeleteOneRestrictionMutationHookResult = ReturnType<typeof useDeleteOneRestrictionMutation>;
export type DeleteOneRestrictionMutationResult = Apollo.MutationResult<DeleteOneRestrictionMutation>;
export type DeleteOneRestrictionMutationOptions = Apollo.BaseMutationOptions<DeleteOneRestrictionMutation, DeleteOneRestrictionMutationVariables>;
export const ServicesDocument = gql`
    query services($filter: ServiceFilter, $paging: OffsetPaging, $sorting: [ServiceSort!]) {
  services(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
      abbreviation
    }
  }
}
    `;

/**
 * __useServicesQuery__
 *
 * To run a query within a React component, call `useServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useServicesQuery(baseOptions?: Apollo.QueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
      }
export function useServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
        }
export type ServicesQueryHookResult = ReturnType<typeof useServicesQuery>;
export type ServicesLazyQueryHookResult = ReturnType<typeof useServicesLazyQuery>;
export type ServicesQueryResult = Apollo.QueryResult<ServicesQuery, ServicesQueryVariables>;
export const CreateOneServiceDocument = gql`
    mutation createOneService($input: CreateOneServiceInput!) {
  createOneService(input: $input) {
    id
    name
    abbreviation
    __typename
  }
}
    `;
export type CreateOneServiceMutationFn = Apollo.MutationFunction<CreateOneServiceMutation, CreateOneServiceMutationVariables>;

/**
 * __useCreateOneServiceMutation__
 *
 * To run a mutation, you first call `useCreateOneServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneServiceMutation, { data, loading, error }] = useCreateOneServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneServiceMutation, CreateOneServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneServiceMutation, CreateOneServiceMutationVariables>(CreateOneServiceDocument, options);
      }
export type CreateOneServiceMutationHookResult = ReturnType<typeof useCreateOneServiceMutation>;
export type CreateOneServiceMutationResult = Apollo.MutationResult<CreateOneServiceMutation>;
export type CreateOneServiceMutationOptions = Apollo.BaseMutationOptions<CreateOneServiceMutation, CreateOneServiceMutationVariables>;
export const UpdateOneServiceDocument = gql`
    mutation updateOneService($input: UpdateOneServiceInput!) {
  updateOneService(input: $input) {
    id
    name
    abbreviation
  }
}
    `;
export type UpdateOneServiceMutationFn = Apollo.MutationFunction<UpdateOneServiceMutation, UpdateOneServiceMutationVariables>;

/**
 * __useUpdateOneServiceMutation__
 *
 * To run a mutation, you first call `useUpdateOneServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneServiceMutation, { data, loading, error }] = useUpdateOneServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneServiceMutation, UpdateOneServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneServiceMutation, UpdateOneServiceMutationVariables>(UpdateOneServiceDocument, options);
      }
export type UpdateOneServiceMutationHookResult = ReturnType<typeof useUpdateOneServiceMutation>;
export type UpdateOneServiceMutationResult = Apollo.MutationResult<UpdateOneServiceMutation>;
export type UpdateOneServiceMutationOptions = Apollo.BaseMutationOptions<UpdateOneServiceMutation, UpdateOneServiceMutationVariables>;
export const DeleteOneServiceDocument = gql`
    mutation deleteOneService($input: DeleteOneServiceInput!) {
  deleteOneService(input: $input) {
    id
  }
}
    `;
export type DeleteOneServiceMutationFn = Apollo.MutationFunction<DeleteOneServiceMutation, DeleteOneServiceMutationVariables>;

/**
 * __useDeleteOneServiceMutation__
 *
 * To run a mutation, you first call `useDeleteOneServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneServiceMutation, { data, loading, error }] = useDeleteOneServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneServiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneServiceMutation, DeleteOneServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneServiceMutation, DeleteOneServiceMutationVariables>(DeleteOneServiceDocument, options);
      }
export type DeleteOneServiceMutationHookResult = ReturnType<typeof useDeleteOneServiceMutation>;
export type DeleteOneServiceMutationResult = Apollo.MutationResult<DeleteOneServiceMutation>;
export type DeleteOneServiceMutationOptions = Apollo.BaseMutationOptions<DeleteOneServiceMutation, DeleteOneServiceMutationVariables>;
export const SubcontractStatesDocument = gql`
    query subcontractStates {
  contractConcessionOrganizationStates {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useSubcontractStatesQuery__
 *
 * To run a query within a React component, call `useSubcontractStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubcontractStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubcontractStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubcontractStatesQuery(baseOptions?: Apollo.QueryHookOptions<SubcontractStatesQuery, SubcontractStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubcontractStatesQuery, SubcontractStatesQueryVariables>(SubcontractStatesDocument, options);
      }
export function useSubcontractStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubcontractStatesQuery, SubcontractStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubcontractStatesQuery, SubcontractStatesQueryVariables>(SubcontractStatesDocument, options);
        }
export type SubcontractStatesQueryHookResult = ReturnType<typeof useSubcontractStatesQuery>;
export type SubcontractStatesLazyQueryHookResult = ReturnType<typeof useSubcontractStatesLazyQuery>;
export type SubcontractStatesQueryResult = Apollo.QueryResult<SubcontractStatesQuery, SubcontractStatesQueryVariables>;
export const SubcontractsDocument = gql`
    query subcontracts($filter: ContractConcessionOrganizationFilter, $paging: OffsetPaging, $sorting: [ContractConcessionOrganizationSort!]) {
  subcontracts(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      description
      startDate
      endDate
      contract {
        id
        name
      }
      contractConcessionOrganizationState {
        id
        name
        code
        backgroundColor
        color
      }
      concessionOrganization {
        id
        organization {
          name
        }
      }
      concessionOrganizationAccreditation {
        id
        concessionOrganizationAccreditationState {
          id
          name
          backgroundColor
          color
        }
        concessionOrganizationAccreditationType {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useSubcontractsQuery__
 *
 * To run a query within a React component, call `useSubcontractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubcontractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubcontractsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useSubcontractsQuery(baseOptions?: Apollo.QueryHookOptions<SubcontractsQuery, SubcontractsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubcontractsQuery, SubcontractsQueryVariables>(SubcontractsDocument, options);
      }
export function useSubcontractsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubcontractsQuery, SubcontractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubcontractsQuery, SubcontractsQueryVariables>(SubcontractsDocument, options);
        }
export type SubcontractsQueryHookResult = ReturnType<typeof useSubcontractsQuery>;
export type SubcontractsLazyQueryHookResult = ReturnType<typeof useSubcontractsLazyQuery>;
export type SubcontractsQueryResult = Apollo.QueryResult<SubcontractsQuery, SubcontractsQueryVariables>;
export const UpdateOneSubcontractDocument = gql`
    mutation updateOneSubcontract($input: UpdateOneSubcontractInput!) {
  updateOneSubcontract(input: $input) {
    id
  }
}
    `;
export type UpdateOneSubcontractMutationFn = Apollo.MutationFunction<UpdateOneSubcontractMutation, UpdateOneSubcontractMutationVariables>;

/**
 * __useUpdateOneSubcontractMutation__
 *
 * To run a mutation, you first call `useUpdateOneSubcontractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneSubcontractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneSubcontractMutation, { data, loading, error }] = useUpdateOneSubcontractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneSubcontractMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneSubcontractMutation, UpdateOneSubcontractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneSubcontractMutation, UpdateOneSubcontractMutationVariables>(UpdateOneSubcontractDocument, options);
      }
export type UpdateOneSubcontractMutationHookResult = ReturnType<typeof useUpdateOneSubcontractMutation>;
export type UpdateOneSubcontractMutationResult = Apollo.MutationResult<UpdateOneSubcontractMutation>;
export type UpdateOneSubcontractMutationOptions = Apollo.BaseMutationOptions<UpdateOneSubcontractMutation, UpdateOneSubcontractMutationVariables>;
export const CreateOneSubcontractDocument = gql`
    mutation createOneSubcontract($input: CreateOneSubcontractInput!) {
  createOneSubcontract(input: $input) {
    id
  }
}
    `;
export type CreateOneSubcontractMutationFn = Apollo.MutationFunction<CreateOneSubcontractMutation, CreateOneSubcontractMutationVariables>;

/**
 * __useCreateOneSubcontractMutation__
 *
 * To run a mutation, you first call `useCreateOneSubcontractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneSubcontractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneSubcontractMutation, { data, loading, error }] = useCreateOneSubcontractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneSubcontractMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneSubcontractMutation, CreateOneSubcontractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneSubcontractMutation, CreateOneSubcontractMutationVariables>(CreateOneSubcontractDocument, options);
      }
export type CreateOneSubcontractMutationHookResult = ReturnType<typeof useCreateOneSubcontractMutation>;
export type CreateOneSubcontractMutationResult = Apollo.MutationResult<CreateOneSubcontractMutation>;
export type CreateOneSubcontractMutationOptions = Apollo.BaseMutationOptions<CreateOneSubcontractMutation, CreateOneSubcontractMutationVariables>;
export const DeleteOneSubcontractDocument = gql`
    mutation deleteOneSubcontract($input: DeleteOneSubcontractInput!) {
  deleteOneSubcontract(input: $input) {
    id
  }
}
    `;
export type DeleteOneSubcontractMutationFn = Apollo.MutationFunction<DeleteOneSubcontractMutation, DeleteOneSubcontractMutationVariables>;

/**
 * __useDeleteOneSubcontractMutation__
 *
 * To run a mutation, you first call `useDeleteOneSubcontractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneSubcontractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneSubcontractMutation, { data, loading, error }] = useDeleteOneSubcontractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneSubcontractMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneSubcontractMutation, DeleteOneSubcontractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneSubcontractMutation, DeleteOneSubcontractMutationVariables>(DeleteOneSubcontractDocument, options);
      }
export type DeleteOneSubcontractMutationHookResult = ReturnType<typeof useDeleteOneSubcontractMutation>;
export type DeleteOneSubcontractMutationResult = Apollo.MutationResult<DeleteOneSubcontractMutation>;
export type DeleteOneSubcontractMutationOptions = Apollo.BaseMutationOptions<DeleteOneSubcontractMutation, DeleteOneSubcontractMutationVariables>;
export const CreateOneSurveyRecordPhotoDocument = gql`
    mutation createOneSurveyRecordPhoto($input: CreateOneSurveyRecordPhotoInput!) {
  createOneSurveyRecordPhoto(input: $input) {
    id
  }
}
    `;
export type CreateOneSurveyRecordPhotoMutationFn = Apollo.MutationFunction<CreateOneSurveyRecordPhotoMutation, CreateOneSurveyRecordPhotoMutationVariables>;

/**
 * __useCreateOneSurveyRecordPhotoMutation__
 *
 * To run a mutation, you first call `useCreateOneSurveyRecordPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneSurveyRecordPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneSurveyRecordPhotoMutation, { data, loading, error }] = useCreateOneSurveyRecordPhotoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneSurveyRecordPhotoMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneSurveyRecordPhotoMutation, CreateOneSurveyRecordPhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneSurveyRecordPhotoMutation, CreateOneSurveyRecordPhotoMutationVariables>(CreateOneSurveyRecordPhotoDocument, options);
      }
export type CreateOneSurveyRecordPhotoMutationHookResult = ReturnType<typeof useCreateOneSurveyRecordPhotoMutation>;
export type CreateOneSurveyRecordPhotoMutationResult = Apollo.MutationResult<CreateOneSurveyRecordPhotoMutation>;
export type CreateOneSurveyRecordPhotoMutationOptions = Apollo.BaseMutationOptions<CreateOneSurveyRecordPhotoMutation, CreateOneSurveyRecordPhotoMutationVariables>;
export const UpdateOneSurveyRecordPhotoDocument = gql`
    mutation updateOneSurveyRecordPhoto($input: UpdateOneSurveyRecordPhotoInput!) {
  updateOneSurveyRecordPhoto(input: $input) {
    id
  }
}
    `;
export type UpdateOneSurveyRecordPhotoMutationFn = Apollo.MutationFunction<UpdateOneSurveyRecordPhotoMutation, UpdateOneSurveyRecordPhotoMutationVariables>;

/**
 * __useUpdateOneSurveyRecordPhotoMutation__
 *
 * To run a mutation, you first call `useUpdateOneSurveyRecordPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneSurveyRecordPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneSurveyRecordPhotoMutation, { data, loading, error }] = useUpdateOneSurveyRecordPhotoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneSurveyRecordPhotoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneSurveyRecordPhotoMutation, UpdateOneSurveyRecordPhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneSurveyRecordPhotoMutation, UpdateOneSurveyRecordPhotoMutationVariables>(UpdateOneSurveyRecordPhotoDocument, options);
      }
export type UpdateOneSurveyRecordPhotoMutationHookResult = ReturnType<typeof useUpdateOneSurveyRecordPhotoMutation>;
export type UpdateOneSurveyRecordPhotoMutationResult = Apollo.MutationResult<UpdateOneSurveyRecordPhotoMutation>;
export type UpdateOneSurveyRecordPhotoMutationOptions = Apollo.BaseMutationOptions<UpdateOneSurveyRecordPhotoMutation, UpdateOneSurveyRecordPhotoMutationVariables>;
export const DeleteOneSurveyRecordPhotoDocument = gql`
    mutation deleteOneSurveyRecordPhoto($input: DeleteOneSurveyRecordPhotoInput!) {
  deleteOneSurveyRecordPhoto(input: $input) {
    id
  }
}
    `;
export type DeleteOneSurveyRecordPhotoMutationFn = Apollo.MutationFunction<DeleteOneSurveyRecordPhotoMutation, DeleteOneSurveyRecordPhotoMutationVariables>;

/**
 * __useDeleteOneSurveyRecordPhotoMutation__
 *
 * To run a mutation, you first call `useDeleteOneSurveyRecordPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneSurveyRecordPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneSurveyRecordPhotoMutation, { data, loading, error }] = useDeleteOneSurveyRecordPhotoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneSurveyRecordPhotoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneSurveyRecordPhotoMutation, DeleteOneSurveyRecordPhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneSurveyRecordPhotoMutation, DeleteOneSurveyRecordPhotoMutationVariables>(DeleteOneSurveyRecordPhotoDocument, options);
      }
export type DeleteOneSurveyRecordPhotoMutationHookResult = ReturnType<typeof useDeleteOneSurveyRecordPhotoMutation>;
export type DeleteOneSurveyRecordPhotoMutationResult = Apollo.MutationResult<DeleteOneSurveyRecordPhotoMutation>;
export type DeleteOneSurveyRecordPhotoMutationOptions = Apollo.BaseMutationOptions<DeleteOneSurveyRecordPhotoMutation, DeleteOneSurveyRecordPhotoMutationVariables>;
export const SurveyRecordVerificationClassificationsDocument = gql`
    query surveyRecordVerificationClassifications($filter: SurveyRecordVerificationClassificationFilter, $paging: OffsetPaging, $sorting: [SurveyRecordVerificationClassificationSort!]) {
  surveyRecordVerificationClassifications(
    filter: $filter
    paging: $paging
    sorting: $sorting
  ) {
    nodes {
      id
      name
      abbreviation
    }
  }
}
    `;

/**
 * __useSurveyRecordVerificationClassificationsQuery__
 *
 * To run a query within a React component, call `useSurveyRecordVerificationClassificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyRecordVerificationClassificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyRecordVerificationClassificationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useSurveyRecordVerificationClassificationsQuery(baseOptions?: Apollo.QueryHookOptions<SurveyRecordVerificationClassificationsQuery, SurveyRecordVerificationClassificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyRecordVerificationClassificationsQuery, SurveyRecordVerificationClassificationsQueryVariables>(SurveyRecordVerificationClassificationsDocument, options);
      }
export function useSurveyRecordVerificationClassificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyRecordVerificationClassificationsQuery, SurveyRecordVerificationClassificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyRecordVerificationClassificationsQuery, SurveyRecordVerificationClassificationsQueryVariables>(SurveyRecordVerificationClassificationsDocument, options);
        }
export type SurveyRecordVerificationClassificationsQueryHookResult = ReturnType<typeof useSurveyRecordVerificationClassificationsQuery>;
export type SurveyRecordVerificationClassificationsLazyQueryHookResult = ReturnType<typeof useSurveyRecordVerificationClassificationsLazyQuery>;
export type SurveyRecordVerificationClassificationsQueryResult = Apollo.QueryResult<SurveyRecordVerificationClassificationsQuery, SurveyRecordVerificationClassificationsQueryVariables>;
export const CreateOneSurveyRecordVerificationClassificationDocument = gql`
    mutation createOneSurveyRecordVerificationClassification($input: CreateOneSurveyRecordVerificationClassificationInput!) {
  createOneSurveyRecordVerificationClassification(input: $input) {
    id
  }
}
    `;
export type CreateOneSurveyRecordVerificationClassificationMutationFn = Apollo.MutationFunction<CreateOneSurveyRecordVerificationClassificationMutation, CreateOneSurveyRecordVerificationClassificationMutationVariables>;

/**
 * __useCreateOneSurveyRecordVerificationClassificationMutation__
 *
 * To run a mutation, you first call `useCreateOneSurveyRecordVerificationClassificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneSurveyRecordVerificationClassificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneSurveyRecordVerificationClassificationMutation, { data, loading, error }] = useCreateOneSurveyRecordVerificationClassificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneSurveyRecordVerificationClassificationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneSurveyRecordVerificationClassificationMutation, CreateOneSurveyRecordVerificationClassificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneSurveyRecordVerificationClassificationMutation, CreateOneSurveyRecordVerificationClassificationMutationVariables>(CreateOneSurveyRecordVerificationClassificationDocument, options);
      }
export type CreateOneSurveyRecordVerificationClassificationMutationHookResult = ReturnType<typeof useCreateOneSurveyRecordVerificationClassificationMutation>;
export type CreateOneSurveyRecordVerificationClassificationMutationResult = Apollo.MutationResult<CreateOneSurveyRecordVerificationClassificationMutation>;
export type CreateOneSurveyRecordVerificationClassificationMutationOptions = Apollo.BaseMutationOptions<CreateOneSurveyRecordVerificationClassificationMutation, CreateOneSurveyRecordVerificationClassificationMutationVariables>;
export const UpdateOneSurveyRecordVerificationClassificationDocument = gql`
    mutation updateOneSurveyRecordVerificationClassification($input: UpdateOneSurveyRecordVerificationClassificationInput!) {
  updateOneSurveyRecordVerificationClassification(input: $input) {
    id
  }
}
    `;
export type UpdateOneSurveyRecordVerificationClassificationMutationFn = Apollo.MutationFunction<UpdateOneSurveyRecordVerificationClassificationMutation, UpdateOneSurveyRecordVerificationClassificationMutationVariables>;

/**
 * __useUpdateOneSurveyRecordVerificationClassificationMutation__
 *
 * To run a mutation, you first call `useUpdateOneSurveyRecordVerificationClassificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneSurveyRecordVerificationClassificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneSurveyRecordVerificationClassificationMutation, { data, loading, error }] = useUpdateOneSurveyRecordVerificationClassificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneSurveyRecordVerificationClassificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneSurveyRecordVerificationClassificationMutation, UpdateOneSurveyRecordVerificationClassificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneSurveyRecordVerificationClassificationMutation, UpdateOneSurveyRecordVerificationClassificationMutationVariables>(UpdateOneSurveyRecordVerificationClassificationDocument, options);
      }
export type UpdateOneSurveyRecordVerificationClassificationMutationHookResult = ReturnType<typeof useUpdateOneSurveyRecordVerificationClassificationMutation>;
export type UpdateOneSurveyRecordVerificationClassificationMutationResult = Apollo.MutationResult<UpdateOneSurveyRecordVerificationClassificationMutation>;
export type UpdateOneSurveyRecordVerificationClassificationMutationOptions = Apollo.BaseMutationOptions<UpdateOneSurveyRecordVerificationClassificationMutation, UpdateOneSurveyRecordVerificationClassificationMutationVariables>;
export const DeleteOneSurveyRecordVerificationClassificationDocument = gql`
    mutation deleteOneSurveyRecordVerificationClassification($input: DeleteOneSurveyRecordVerificationClassificationInput!) {
  deleteOneSurveyRecordVerificationClassification(input: $input) {
    id
  }
}
    `;
export type DeleteOneSurveyRecordVerificationClassificationMutationFn = Apollo.MutationFunction<DeleteOneSurveyRecordVerificationClassificationMutation, DeleteOneSurveyRecordVerificationClassificationMutationVariables>;

/**
 * __useDeleteOneSurveyRecordVerificationClassificationMutation__
 *
 * To run a mutation, you first call `useDeleteOneSurveyRecordVerificationClassificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneSurveyRecordVerificationClassificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneSurveyRecordVerificationClassificationMutation, { data, loading, error }] = useDeleteOneSurveyRecordVerificationClassificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneSurveyRecordVerificationClassificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneSurveyRecordVerificationClassificationMutation, DeleteOneSurveyRecordVerificationClassificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneSurveyRecordVerificationClassificationMutation, DeleteOneSurveyRecordVerificationClassificationMutationVariables>(DeleteOneSurveyRecordVerificationClassificationDocument, options);
      }
export type DeleteOneSurveyRecordVerificationClassificationMutationHookResult = ReturnType<typeof useDeleteOneSurveyRecordVerificationClassificationMutation>;
export type DeleteOneSurveyRecordVerificationClassificationMutationResult = Apollo.MutationResult<DeleteOneSurveyRecordVerificationClassificationMutation>;
export type DeleteOneSurveyRecordVerificationClassificationMutationOptions = Apollo.BaseMutationOptions<DeleteOneSurveyRecordVerificationClassificationMutation, DeleteOneSurveyRecordVerificationClassificationMutationVariables>;
export const SurveyRecordVerificationStatesDocument = gql`
    query surveyRecordVerificationStates($filter: SurveyRecordVerificationStateFilter, $paging: OffsetPaging, $sorting: [SurveyRecordVerificationStateSort!]) {
  surveyRecordVerificationStates(
    filter: $filter
    paging: $paging
    sorting: $sorting
  ) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useSurveyRecordVerificationStatesQuery__
 *
 * To run a query within a React component, call `useSurveyRecordVerificationStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyRecordVerificationStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyRecordVerificationStatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useSurveyRecordVerificationStatesQuery(baseOptions?: Apollo.QueryHookOptions<SurveyRecordVerificationStatesQuery, SurveyRecordVerificationStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyRecordVerificationStatesQuery, SurveyRecordVerificationStatesQueryVariables>(SurveyRecordVerificationStatesDocument, options);
      }
export function useSurveyRecordVerificationStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyRecordVerificationStatesQuery, SurveyRecordVerificationStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyRecordVerificationStatesQuery, SurveyRecordVerificationStatesQueryVariables>(SurveyRecordVerificationStatesDocument, options);
        }
export type SurveyRecordVerificationStatesQueryHookResult = ReturnType<typeof useSurveyRecordVerificationStatesQuery>;
export type SurveyRecordVerificationStatesLazyQueryHookResult = ReturnType<typeof useSurveyRecordVerificationStatesLazyQuery>;
export type SurveyRecordVerificationStatesQueryResult = Apollo.QueryResult<SurveyRecordVerificationStatesQuery, SurveyRecordVerificationStatesQueryVariables>;
export const CreateOneSurveyRecordVerificationDocument = gql`
    mutation createOneSurveyRecordVerification($input: CreateOneSurveyRecordVerificationInput!) {
  createOneSurveyRecordVerification(input: $input) {
    id
  }
}
    `;
export type CreateOneSurveyRecordVerificationMutationFn = Apollo.MutationFunction<CreateOneSurveyRecordVerificationMutation, CreateOneSurveyRecordVerificationMutationVariables>;

/**
 * __useCreateOneSurveyRecordVerificationMutation__
 *
 * To run a mutation, you first call `useCreateOneSurveyRecordVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneSurveyRecordVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneSurveyRecordVerificationMutation, { data, loading, error }] = useCreateOneSurveyRecordVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneSurveyRecordVerificationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneSurveyRecordVerificationMutation, CreateOneSurveyRecordVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneSurveyRecordVerificationMutation, CreateOneSurveyRecordVerificationMutationVariables>(CreateOneSurveyRecordVerificationDocument, options);
      }
export type CreateOneSurveyRecordVerificationMutationHookResult = ReturnType<typeof useCreateOneSurveyRecordVerificationMutation>;
export type CreateOneSurveyRecordVerificationMutationResult = Apollo.MutationResult<CreateOneSurveyRecordVerificationMutation>;
export type CreateOneSurveyRecordVerificationMutationOptions = Apollo.BaseMutationOptions<CreateOneSurveyRecordVerificationMutation, CreateOneSurveyRecordVerificationMutationVariables>;
export const UpdateOneSurveyRecordVerificationDocument = gql`
    mutation updateOneSurveyRecordVerification($input: UpdateOneSurveyRecordVerificationInput!) {
  updateOneSurveyRecordVerification(input: $input) {
    id
  }
}
    `;
export type UpdateOneSurveyRecordVerificationMutationFn = Apollo.MutationFunction<UpdateOneSurveyRecordVerificationMutation, UpdateOneSurveyRecordVerificationMutationVariables>;

/**
 * __useUpdateOneSurveyRecordVerificationMutation__
 *
 * To run a mutation, you first call `useUpdateOneSurveyRecordVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneSurveyRecordVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneSurveyRecordVerificationMutation, { data, loading, error }] = useUpdateOneSurveyRecordVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneSurveyRecordVerificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneSurveyRecordVerificationMutation, UpdateOneSurveyRecordVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneSurveyRecordVerificationMutation, UpdateOneSurveyRecordVerificationMutationVariables>(UpdateOneSurveyRecordVerificationDocument, options);
      }
export type UpdateOneSurveyRecordVerificationMutationHookResult = ReturnType<typeof useUpdateOneSurveyRecordVerificationMutation>;
export type UpdateOneSurveyRecordVerificationMutationResult = Apollo.MutationResult<UpdateOneSurveyRecordVerificationMutation>;
export type UpdateOneSurveyRecordVerificationMutationOptions = Apollo.BaseMutationOptions<UpdateOneSurveyRecordVerificationMutation, UpdateOneSurveyRecordVerificationMutationVariables>;
export const DeleteOneSurveyRecordVerificationDocument = gql`
    mutation deleteOneSurveyRecordVerification($input: DeleteOneSurveyRecordVerificationInput!) {
  deleteOneSurveyRecordVerification(input: $input) {
    id
  }
}
    `;
export type DeleteOneSurveyRecordVerificationMutationFn = Apollo.MutationFunction<DeleteOneSurveyRecordVerificationMutation, DeleteOneSurveyRecordVerificationMutationVariables>;

/**
 * __useDeleteOneSurveyRecordVerificationMutation__
 *
 * To run a mutation, you first call `useDeleteOneSurveyRecordVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneSurveyRecordVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneSurveyRecordVerificationMutation, { data, loading, error }] = useDeleteOneSurveyRecordVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneSurveyRecordVerificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneSurveyRecordVerificationMutation, DeleteOneSurveyRecordVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneSurveyRecordVerificationMutation, DeleteOneSurveyRecordVerificationMutationVariables>(DeleteOneSurveyRecordVerificationDocument, options);
      }
export type DeleteOneSurveyRecordVerificationMutationHookResult = ReturnType<typeof useDeleteOneSurveyRecordVerificationMutation>;
export type DeleteOneSurveyRecordVerificationMutationResult = Apollo.MutationResult<DeleteOneSurveyRecordVerificationMutation>;
export type DeleteOneSurveyRecordVerificationMutationOptions = Apollo.BaseMutationOptions<DeleteOneSurveyRecordVerificationMutation, DeleteOneSurveyRecordVerificationMutationVariables>;
export const SurveyRecordsDocument = gql`
    query surveyRecords($filter: SurveyRecordFilter, $paging: OffsetPaging, $sorting: [SurveyRecordSort!]) {
  surveyRecords(filter: $filter, paging: $paging, sorting: $sorting) {
    totalCount
    nodes {
      id
      name
      description
      state
      startPk
      endPk
      surveyedAt
      createdAt
      isPublished
      hasCorrectiveRequests
      openCorrectiveRequests
      contract {
        id
        name
      }
      road {
        id
        name
      }
      user {
        id
        name
      }
      startJunction {
        id
        name
      }
      endJunction {
        id
        name
      }
      weatherCondition {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSurveyRecordsQuery__
 *
 * To run a query within a React component, call `useSurveyRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyRecordsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useSurveyRecordsQuery(baseOptions?: Apollo.QueryHookOptions<SurveyRecordsQuery, SurveyRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyRecordsQuery, SurveyRecordsQueryVariables>(SurveyRecordsDocument, options);
      }
export function useSurveyRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyRecordsQuery, SurveyRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyRecordsQuery, SurveyRecordsQueryVariables>(SurveyRecordsDocument, options);
        }
export type SurveyRecordsQueryHookResult = ReturnType<typeof useSurveyRecordsQuery>;
export type SurveyRecordsLazyQueryHookResult = ReturnType<typeof useSurveyRecordsLazyQuery>;
export type SurveyRecordsQueryResult = Apollo.QueryResult<SurveyRecordsQuery, SurveyRecordsQueryVariables>;
export const SurveyRecordDocument = gql`
    query surveyRecord($id: ID!) {
  surveyRecord(id: $id) {
    id
    name
    sequence
    description
    startPk
    endPk
    surveyedAt
    participants
    state
    isPublished
    observations
    contract {
      id
      name
    }
    road {
      id
      name
    }
    user {
      id
      name
    }
    startJunction {
      id
      name
    }
    endJunction {
      id
      name
    }
    weatherCondition {
      id
      name
    }
    surveyRecordPhotos(sorting: {field: id, direction: ASC}) {
      id
      name
      description
      path
      thumbnail
    }
    surveyRecordVerifications(sorting: {field: id, direction: ASC}) {
      id
      description
      surveyRecordVerificationState {
        id
        name
      }
      surveyRecordVerificationClassification {
        id
        name
        abbreviation
      }
      verification {
        id
        name
        verificationScope {
          name
          abbreviation
        }
      }
    }
  }
}
    `;

/**
 * __useSurveyRecordQuery__
 *
 * To run a query within a React component, call `useSurveyRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyRecordQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSurveyRecordQuery(baseOptions: Apollo.QueryHookOptions<SurveyRecordQuery, SurveyRecordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyRecordQuery, SurveyRecordQueryVariables>(SurveyRecordDocument, options);
      }
export function useSurveyRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyRecordQuery, SurveyRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyRecordQuery, SurveyRecordQueryVariables>(SurveyRecordDocument, options);
        }
export type SurveyRecordQueryHookResult = ReturnType<typeof useSurveyRecordQuery>;
export type SurveyRecordLazyQueryHookResult = ReturnType<typeof useSurveyRecordLazyQuery>;
export type SurveyRecordQueryResult = Apollo.QueryResult<SurveyRecordQuery, SurveyRecordQueryVariables>;
export const CreateOneSurveyRecordDocument = gql`
    mutation createOneSurveyRecord($input: CreateOneSurveyRecordInput!) {
  createOneSurveyRecord(input: $input) {
    id
  }
}
    `;
export type CreateOneSurveyRecordMutationFn = Apollo.MutationFunction<CreateOneSurveyRecordMutation, CreateOneSurveyRecordMutationVariables>;

/**
 * __useCreateOneSurveyRecordMutation__
 *
 * To run a mutation, you first call `useCreateOneSurveyRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneSurveyRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneSurveyRecordMutation, { data, loading, error }] = useCreateOneSurveyRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneSurveyRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneSurveyRecordMutation, CreateOneSurveyRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneSurveyRecordMutation, CreateOneSurveyRecordMutationVariables>(CreateOneSurveyRecordDocument, options);
      }
export type CreateOneSurveyRecordMutationHookResult = ReturnType<typeof useCreateOneSurveyRecordMutation>;
export type CreateOneSurveyRecordMutationResult = Apollo.MutationResult<CreateOneSurveyRecordMutation>;
export type CreateOneSurveyRecordMutationOptions = Apollo.BaseMutationOptions<CreateOneSurveyRecordMutation, CreateOneSurveyRecordMutationVariables>;
export const UpdateOneSurveyRecordDocument = gql`
    mutation updateOneSurveyRecord($input: UpdateOneSurveyRecordInput!) {
  updateOneSurveyRecord(input: $input) {
    id
  }
}
    `;
export type UpdateOneSurveyRecordMutationFn = Apollo.MutationFunction<UpdateOneSurveyRecordMutation, UpdateOneSurveyRecordMutationVariables>;

/**
 * __useUpdateOneSurveyRecordMutation__
 *
 * To run a mutation, you first call `useUpdateOneSurveyRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneSurveyRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneSurveyRecordMutation, { data, loading, error }] = useUpdateOneSurveyRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneSurveyRecordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneSurveyRecordMutation, UpdateOneSurveyRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneSurveyRecordMutation, UpdateOneSurveyRecordMutationVariables>(UpdateOneSurveyRecordDocument, options);
      }
export type UpdateOneSurveyRecordMutationHookResult = ReturnType<typeof useUpdateOneSurveyRecordMutation>;
export type UpdateOneSurveyRecordMutationResult = Apollo.MutationResult<UpdateOneSurveyRecordMutation>;
export type UpdateOneSurveyRecordMutationOptions = Apollo.BaseMutationOptions<UpdateOneSurveyRecordMutation, UpdateOneSurveyRecordMutationVariables>;
export const DeleteOneSurveyRecordDocument = gql`
    mutation deleteOneSurveyRecord($input: DeleteOneSurveyRecordInput!) {
  deleteOneSurveyRecord(input: $input) {
    id
  }
}
    `;
export type DeleteOneSurveyRecordMutationFn = Apollo.MutationFunction<DeleteOneSurveyRecordMutation, DeleteOneSurveyRecordMutationVariables>;

/**
 * __useDeleteOneSurveyRecordMutation__
 *
 * To run a mutation, you first call `useDeleteOneSurveyRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneSurveyRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneSurveyRecordMutation, { data, loading, error }] = useDeleteOneSurveyRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneSurveyRecordMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneSurveyRecordMutation, DeleteOneSurveyRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneSurveyRecordMutation, DeleteOneSurveyRecordMutationVariables>(DeleteOneSurveyRecordDocument, options);
      }
export type DeleteOneSurveyRecordMutationHookResult = ReturnType<typeof useDeleteOneSurveyRecordMutation>;
export type DeleteOneSurveyRecordMutationResult = Apollo.MutationResult<DeleteOneSurveyRecordMutation>;
export type DeleteOneSurveyRecordMutationOptions = Apollo.BaseMutationOptions<DeleteOneSurveyRecordMutation, DeleteOneSurveyRecordMutationVariables>;
export const ExportSurveyRecordPdfDocument = gql`
    mutation exportSurveyRecordPDF($input: ExportSurveyRecordInput!) {
  exportSurveyRecordPDF(input: $input)
}
    `;
export type ExportSurveyRecordPdfMutationFn = Apollo.MutationFunction<ExportSurveyRecordPdfMutation, ExportSurveyRecordPdfMutationVariables>;

/**
 * __useExportSurveyRecordPdfMutation__
 *
 * To run a mutation, you first call `useExportSurveyRecordPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportSurveyRecordPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportSurveyRecordPdfMutation, { data, loading, error }] = useExportSurveyRecordPdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportSurveyRecordPdfMutation(baseOptions?: Apollo.MutationHookOptions<ExportSurveyRecordPdfMutation, ExportSurveyRecordPdfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportSurveyRecordPdfMutation, ExportSurveyRecordPdfMutationVariables>(ExportSurveyRecordPdfDocument, options);
      }
export type ExportSurveyRecordPdfMutationHookResult = ReturnType<typeof useExportSurveyRecordPdfMutation>;
export type ExportSurveyRecordPdfMutationResult = Apollo.MutationResult<ExportSurveyRecordPdfMutation>;
export type ExportSurveyRecordPdfMutationOptions = Apollo.BaseMutationOptions<ExportSurveyRecordPdfMutation, ExportSurveyRecordPdfMutationVariables>;
export const TasksDocument = gql`
    query tasks($filter: TaskFilter, $sorting: [TaskSort!]) {
  tasks(filter: $filter, paging: {limit: 9999}, sorting: $sorting) {
    nodes {
      ...TaskDetails
      intervention {
        id
        name
        year
        week
        workPermit {
          id
          name
          contract {
            id
            contractConcessionOrganizations {
              id
              concessionOrganization {
                id
                organization {
                  id
                  name
                }
                organizationType {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${TaskDetailsFragmentDoc}`;

/**
 * __useTasksQuery__
 *
 * To run a query within a React component, call `useTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useTasksQuery(baseOptions?: Apollo.QueryHookOptions<TasksQuery, TasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
      }
export function useTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
        }
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksQueryResult = Apollo.QueryResult<TasksQuery, TasksQueryVariables>;
export const CreateOneTaskDocument = gql`
    mutation createOneTask($input: CreateOneTaskInput!) {
  createOneTask(input: $input) {
    id
  }
}
    `;
export type CreateOneTaskMutationFn = Apollo.MutationFunction<CreateOneTaskMutation, CreateOneTaskMutationVariables>;

/**
 * __useCreateOneTaskMutation__
 *
 * To run a mutation, you first call `useCreateOneTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneTaskMutation, { data, loading, error }] = useCreateOneTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneTaskMutation, CreateOneTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneTaskMutation, CreateOneTaskMutationVariables>(CreateOneTaskDocument, options);
      }
export type CreateOneTaskMutationHookResult = ReturnType<typeof useCreateOneTaskMutation>;
export type CreateOneTaskMutationResult = Apollo.MutationResult<CreateOneTaskMutation>;
export type CreateOneTaskMutationOptions = Apollo.BaseMutationOptions<CreateOneTaskMutation, CreateOneTaskMutationVariables>;
export const UpdateOneTaskDocument = gql`
    mutation updateOneTask($input: UpdateOneTaskInput!) {
  updateOneTask(input: $input) {
    id
  }
}
    `;
export type UpdateOneTaskMutationFn = Apollo.MutationFunction<UpdateOneTaskMutation, UpdateOneTaskMutationVariables>;

/**
 * __useUpdateOneTaskMutation__
 *
 * To run a mutation, you first call `useUpdateOneTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneTaskMutation, { data, loading, error }] = useUpdateOneTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneTaskMutation, UpdateOneTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneTaskMutation, UpdateOneTaskMutationVariables>(UpdateOneTaskDocument, options);
      }
export type UpdateOneTaskMutationHookResult = ReturnType<typeof useUpdateOneTaskMutation>;
export type UpdateOneTaskMutationResult = Apollo.MutationResult<UpdateOneTaskMutation>;
export type UpdateOneTaskMutationOptions = Apollo.BaseMutationOptions<UpdateOneTaskMutation, UpdateOneTaskMutationVariables>;
export const UpdateManyTasksDocument = gql`
    mutation updateManyTasks($input: UpdateManyTasksInput!) {
  updateManyTasks(input: $input) {
    updatedCount
  }
}
    `;
export type UpdateManyTasksMutationFn = Apollo.MutationFunction<UpdateManyTasksMutation, UpdateManyTasksMutationVariables>;

/**
 * __useUpdateManyTasksMutation__
 *
 * To run a mutation, you first call `useUpdateManyTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyTasksMutation, { data, loading, error }] = useUpdateManyTasksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyTasksMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManyTasksMutation, UpdateManyTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManyTasksMutation, UpdateManyTasksMutationVariables>(UpdateManyTasksDocument, options);
      }
export type UpdateManyTasksMutationHookResult = ReturnType<typeof useUpdateManyTasksMutation>;
export type UpdateManyTasksMutationResult = Apollo.MutationResult<UpdateManyTasksMutation>;
export type UpdateManyTasksMutationOptions = Apollo.BaseMutationOptions<UpdateManyTasksMutation, UpdateManyTasksMutationVariables>;
export const DeleteOneTaskDocument = gql`
    mutation deleteOneTask($input: DeleteOneTaskInput!) {
  deleteOneTask(input: $input) {
    id
  }
}
    `;
export type DeleteOneTaskMutationFn = Apollo.MutationFunction<DeleteOneTaskMutation, DeleteOneTaskMutationVariables>;

/**
 * __useDeleteOneTaskMutation__
 *
 * To run a mutation, you first call `useDeleteOneTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneTaskMutation, { data, loading, error }] = useDeleteOneTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneTaskMutation, DeleteOneTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneTaskMutation, DeleteOneTaskMutationVariables>(DeleteOneTaskDocument, options);
      }
export type DeleteOneTaskMutationHookResult = ReturnType<typeof useDeleteOneTaskMutation>;
export type DeleteOneTaskMutationResult = Apollo.MutationResult<DeleteOneTaskMutation>;
export type DeleteOneTaskMutationOptions = Apollo.BaseMutationOptions<DeleteOneTaskMutation, DeleteOneTaskMutationVariables>;
export const DeleteManyTasksDocument = gql`
    mutation deleteManyTasks($input: DeleteManyTasksInput!) {
  deleteManyTasks(input: $input) {
    deletedCount
  }
}
    `;
export type DeleteManyTasksMutationFn = Apollo.MutationFunction<DeleteManyTasksMutation, DeleteManyTasksMutationVariables>;

/**
 * __useDeleteManyTasksMutation__
 *
 * To run a mutation, you first call `useDeleteManyTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyTasksMutation, { data, loading, error }] = useDeleteManyTasksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyTasksMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyTasksMutation, DeleteManyTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyTasksMutation, DeleteManyTasksMutationVariables>(DeleteManyTasksDocument, options);
      }
export type DeleteManyTasksMutationHookResult = ReturnType<typeof useDeleteManyTasksMutation>;
export type DeleteManyTasksMutationResult = Apollo.MutationResult<DeleteManyTasksMutation>;
export type DeleteManyTasksMutationOptions = Apollo.BaseMutationOptions<DeleteManyTasksMutation, DeleteManyTasksMutationVariables>;
export const FindOverlapsDocument = gql`
    mutation findOverlaps($input: FindOverlapsInput!) {
  findOverlaps(input: $input)
}
    `;
export type FindOverlapsMutationFn = Apollo.MutationFunction<FindOverlapsMutation, FindOverlapsMutationVariables>;

/**
 * __useFindOverlapsMutation__
 *
 * To run a mutation, you first call `useFindOverlapsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFindOverlapsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [findOverlapsMutation, { data, loading, error }] = useFindOverlapsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindOverlapsMutation(baseOptions?: Apollo.MutationHookOptions<FindOverlapsMutation, FindOverlapsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FindOverlapsMutation, FindOverlapsMutationVariables>(FindOverlapsDocument, options);
      }
export type FindOverlapsMutationHookResult = ReturnType<typeof useFindOverlapsMutation>;
export type FindOverlapsMutationResult = Apollo.MutationResult<FindOverlapsMutation>;
export type FindOverlapsMutationOptions = Apollo.BaseMutationOptions<FindOverlapsMutation, FindOverlapsMutationVariables>;
export const ClearOverlapsDocument = gql`
    mutation clearOverlaps($input: FindOverlapsInput!) {
  clearOverlaps(input: $input)
}
    `;
export type ClearOverlapsMutationFn = Apollo.MutationFunction<ClearOverlapsMutation, ClearOverlapsMutationVariables>;

/**
 * __useClearOverlapsMutation__
 *
 * To run a mutation, you first call `useClearOverlapsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearOverlapsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearOverlapsMutation, { data, loading, error }] = useClearOverlapsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearOverlapsMutation(baseOptions?: Apollo.MutationHookOptions<ClearOverlapsMutation, ClearOverlapsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearOverlapsMutation, ClearOverlapsMutationVariables>(ClearOverlapsDocument, options);
      }
export type ClearOverlapsMutationHookResult = ReturnType<typeof useClearOverlapsMutation>;
export type ClearOverlapsMutationResult = Apollo.MutationResult<ClearOverlapsMutation>;
export type ClearOverlapsMutationOptions = Apollo.BaseMutationOptions<ClearOverlapsMutation, ClearOverlapsMutationVariables>;
export const GenerateWeeklyTasksZipDocument = gql`
    mutation generateWeeklyTasksZip($input: GenerateWeeklyTasksZip!) {
  generateWeeklyTasksZip(input: $input)
}
    `;
export type GenerateWeeklyTasksZipMutationFn = Apollo.MutationFunction<GenerateWeeklyTasksZipMutation, GenerateWeeklyTasksZipMutationVariables>;

/**
 * __useGenerateWeeklyTasksZipMutation__
 *
 * To run a mutation, you first call `useGenerateWeeklyTasksZipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateWeeklyTasksZipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateWeeklyTasksZipMutation, { data, loading, error }] = useGenerateWeeklyTasksZipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateWeeklyTasksZipMutation(baseOptions?: Apollo.MutationHookOptions<GenerateWeeklyTasksZipMutation, GenerateWeeklyTasksZipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateWeeklyTasksZipMutation, GenerateWeeklyTasksZipMutationVariables>(GenerateWeeklyTasksZipDocument, options);
      }
export type GenerateWeeklyTasksZipMutationHookResult = ReturnType<typeof useGenerateWeeklyTasksZipMutation>;
export type GenerateWeeklyTasksZipMutationResult = Apollo.MutationResult<GenerateWeeklyTasksZipMutation>;
export type GenerateWeeklyTasksZipMutationOptions = Apollo.BaseMutationOptions<GenerateWeeklyTasksZipMutation, GenerateWeeklyTasksZipMutationVariables>;
export const GenerateWeeklyTasksSheetDocument = gql`
    mutation generateWeeklyTasksSheet($input: GenerateWeeklyTasksZip!) {
  generateWeeklyTasksSheet(input: $input)
}
    `;
export type GenerateWeeklyTasksSheetMutationFn = Apollo.MutationFunction<GenerateWeeklyTasksSheetMutation, GenerateWeeklyTasksSheetMutationVariables>;

/**
 * __useGenerateWeeklyTasksSheetMutation__
 *
 * To run a mutation, you first call `useGenerateWeeklyTasksSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateWeeklyTasksSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateWeeklyTasksSheetMutation, { data, loading, error }] = useGenerateWeeklyTasksSheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateWeeklyTasksSheetMutation(baseOptions?: Apollo.MutationHookOptions<GenerateWeeklyTasksSheetMutation, GenerateWeeklyTasksSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateWeeklyTasksSheetMutation, GenerateWeeklyTasksSheetMutationVariables>(GenerateWeeklyTasksSheetDocument, options);
      }
export type GenerateWeeklyTasksSheetMutationHookResult = ReturnType<typeof useGenerateWeeklyTasksSheetMutation>;
export type GenerateWeeklyTasksSheetMutationResult = Apollo.MutationResult<GenerateWeeklyTasksSheetMutation>;
export type GenerateWeeklyTasksSheetMutationOptions = Apollo.BaseMutationOptions<GenerateWeeklyTasksSheetMutation, GenerateWeeklyTasksSheetMutationVariables>;
export const SurveyRecordAuthorsDocument = gql`
    query surveyRecordAuthors {
  surveyRecordAuthors {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useSurveyRecordAuthorsQuery__
 *
 * To run a query within a React component, call `useSurveyRecordAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyRecordAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyRecordAuthorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSurveyRecordAuthorsQuery(baseOptions?: Apollo.QueryHookOptions<SurveyRecordAuthorsQuery, SurveyRecordAuthorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyRecordAuthorsQuery, SurveyRecordAuthorsQueryVariables>(SurveyRecordAuthorsDocument, options);
      }
export function useSurveyRecordAuthorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyRecordAuthorsQuery, SurveyRecordAuthorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyRecordAuthorsQuery, SurveyRecordAuthorsQueryVariables>(SurveyRecordAuthorsDocument, options);
        }
export type SurveyRecordAuthorsQueryHookResult = ReturnType<typeof useSurveyRecordAuthorsQuery>;
export type SurveyRecordAuthorsLazyQueryHookResult = ReturnType<typeof useSurveyRecordAuthorsLazyQuery>;
export type SurveyRecordAuthorsQueryResult = Apollo.QueryResult<SurveyRecordAuthorsQuery, SurveyRecordAuthorsQueryVariables>;
export const VerificationScopesDocument = gql`
    query verificationScopes($filter: VerificationScopeFilter, $paging: OffsetPaging, $sorting: [VerificationScopeSort!]) {
  verificationScopes(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
      abbreviation
    }
  }
}
    `;

/**
 * __useVerificationScopesQuery__
 *
 * To run a query within a React component, call `useVerificationScopesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerificationScopesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerificationScopesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useVerificationScopesQuery(baseOptions?: Apollo.QueryHookOptions<VerificationScopesQuery, VerificationScopesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerificationScopesQuery, VerificationScopesQueryVariables>(VerificationScopesDocument, options);
      }
export function useVerificationScopesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerificationScopesQuery, VerificationScopesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerificationScopesQuery, VerificationScopesQueryVariables>(VerificationScopesDocument, options);
        }
export type VerificationScopesQueryHookResult = ReturnType<typeof useVerificationScopesQuery>;
export type VerificationScopesLazyQueryHookResult = ReturnType<typeof useVerificationScopesLazyQuery>;
export type VerificationScopesQueryResult = Apollo.QueryResult<VerificationScopesQuery, VerificationScopesQueryVariables>;
export const CreateOneVerificationScopeDocument = gql`
    mutation createOneVerificationScope($input: CreateOneVerificationScopeInput!) {
  createOneVerificationScope(input: $input) {
    id
  }
}
    `;
export type CreateOneVerificationScopeMutationFn = Apollo.MutationFunction<CreateOneVerificationScopeMutation, CreateOneVerificationScopeMutationVariables>;

/**
 * __useCreateOneVerificationScopeMutation__
 *
 * To run a mutation, you first call `useCreateOneVerificationScopeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneVerificationScopeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneVerificationScopeMutation, { data, loading, error }] = useCreateOneVerificationScopeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneVerificationScopeMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneVerificationScopeMutation, CreateOneVerificationScopeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneVerificationScopeMutation, CreateOneVerificationScopeMutationVariables>(CreateOneVerificationScopeDocument, options);
      }
export type CreateOneVerificationScopeMutationHookResult = ReturnType<typeof useCreateOneVerificationScopeMutation>;
export type CreateOneVerificationScopeMutationResult = Apollo.MutationResult<CreateOneVerificationScopeMutation>;
export type CreateOneVerificationScopeMutationOptions = Apollo.BaseMutationOptions<CreateOneVerificationScopeMutation, CreateOneVerificationScopeMutationVariables>;
export const UpdateOneVerificationScopeDocument = gql`
    mutation updateOneVerificationScope($input: UpdateOneVerificationScopeInput!) {
  updateOneVerificationScope(input: $input) {
    id
  }
}
    `;
export type UpdateOneVerificationScopeMutationFn = Apollo.MutationFunction<UpdateOneVerificationScopeMutation, UpdateOneVerificationScopeMutationVariables>;

/**
 * __useUpdateOneVerificationScopeMutation__
 *
 * To run a mutation, you first call `useUpdateOneVerificationScopeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneVerificationScopeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneVerificationScopeMutation, { data, loading, error }] = useUpdateOneVerificationScopeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneVerificationScopeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneVerificationScopeMutation, UpdateOneVerificationScopeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneVerificationScopeMutation, UpdateOneVerificationScopeMutationVariables>(UpdateOneVerificationScopeDocument, options);
      }
export type UpdateOneVerificationScopeMutationHookResult = ReturnType<typeof useUpdateOneVerificationScopeMutation>;
export type UpdateOneVerificationScopeMutationResult = Apollo.MutationResult<UpdateOneVerificationScopeMutation>;
export type UpdateOneVerificationScopeMutationOptions = Apollo.BaseMutationOptions<UpdateOneVerificationScopeMutation, UpdateOneVerificationScopeMutationVariables>;
export const DeleteOneVerificationScopeDocument = gql`
    mutation deleteOneVerificationScope($input: DeleteOneVerificationScopeInput!) {
  deleteOneVerificationScope(input: $input) {
    id
  }
}
    `;
export type DeleteOneVerificationScopeMutationFn = Apollo.MutationFunction<DeleteOneVerificationScopeMutation, DeleteOneVerificationScopeMutationVariables>;

/**
 * __useDeleteOneVerificationScopeMutation__
 *
 * To run a mutation, you first call `useDeleteOneVerificationScopeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneVerificationScopeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneVerificationScopeMutation, { data, loading, error }] = useDeleteOneVerificationScopeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneVerificationScopeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneVerificationScopeMutation, DeleteOneVerificationScopeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneVerificationScopeMutation, DeleteOneVerificationScopeMutationVariables>(DeleteOneVerificationScopeDocument, options);
      }
export type DeleteOneVerificationScopeMutationHookResult = ReturnType<typeof useDeleteOneVerificationScopeMutation>;
export type DeleteOneVerificationScopeMutationResult = Apollo.MutationResult<DeleteOneVerificationScopeMutation>;
export type DeleteOneVerificationScopeMutationOptions = Apollo.BaseMutationOptions<DeleteOneVerificationScopeMutation, DeleteOneVerificationScopeMutationVariables>;
export const VerificationsDocument = gql`
    query verifications($filter: VerificationFilter, $paging: OffsetPaging, $sorting: [VerificationSort!]) {
  verifications(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useVerificationsQuery__
 *
 * To run a query within a React component, call `useVerificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerificationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useVerificationsQuery(baseOptions?: Apollo.QueryHookOptions<VerificationsQuery, VerificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerificationsQuery, VerificationsQueryVariables>(VerificationsDocument, options);
      }
export function useVerificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerificationsQuery, VerificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerificationsQuery, VerificationsQueryVariables>(VerificationsDocument, options);
        }
export type VerificationsQueryHookResult = ReturnType<typeof useVerificationsQuery>;
export type VerificationsLazyQueryHookResult = ReturnType<typeof useVerificationsLazyQuery>;
export type VerificationsQueryResult = Apollo.QueryResult<VerificationsQuery, VerificationsQueryVariables>;
export const CreateOneVerificationDocument = gql`
    mutation createOneVerification($input: CreateOneVerificationInput!) {
  createOneVerification(input: $input) {
    id
  }
}
    `;
export type CreateOneVerificationMutationFn = Apollo.MutationFunction<CreateOneVerificationMutation, CreateOneVerificationMutationVariables>;

/**
 * __useCreateOneVerificationMutation__
 *
 * To run a mutation, you first call `useCreateOneVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneVerificationMutation, { data, loading, error }] = useCreateOneVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneVerificationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneVerificationMutation, CreateOneVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneVerificationMutation, CreateOneVerificationMutationVariables>(CreateOneVerificationDocument, options);
      }
export type CreateOneVerificationMutationHookResult = ReturnType<typeof useCreateOneVerificationMutation>;
export type CreateOneVerificationMutationResult = Apollo.MutationResult<CreateOneVerificationMutation>;
export type CreateOneVerificationMutationOptions = Apollo.BaseMutationOptions<CreateOneVerificationMutation, CreateOneVerificationMutationVariables>;
export const UpdateOneVerificationDocument = gql`
    mutation updateOneVerification($input: UpdateOneVerificationInput!) {
  updateOneVerification(input: $input) {
    id
  }
}
    `;
export type UpdateOneVerificationMutationFn = Apollo.MutationFunction<UpdateOneVerificationMutation, UpdateOneVerificationMutationVariables>;

/**
 * __useUpdateOneVerificationMutation__
 *
 * To run a mutation, you first call `useUpdateOneVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneVerificationMutation, { data, loading, error }] = useUpdateOneVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneVerificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneVerificationMutation, UpdateOneVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneVerificationMutation, UpdateOneVerificationMutationVariables>(UpdateOneVerificationDocument, options);
      }
export type UpdateOneVerificationMutationHookResult = ReturnType<typeof useUpdateOneVerificationMutation>;
export type UpdateOneVerificationMutationResult = Apollo.MutationResult<UpdateOneVerificationMutation>;
export type UpdateOneVerificationMutationOptions = Apollo.BaseMutationOptions<UpdateOneVerificationMutation, UpdateOneVerificationMutationVariables>;
export const DeleteOneVerificationDocument = gql`
    mutation deleteOneVerification($input: DeleteOneVerificationInput!) {
  deleteOneVerification(input: $input) {
    id
  }
}
    `;
export type DeleteOneVerificationMutationFn = Apollo.MutationFunction<DeleteOneVerificationMutation, DeleteOneVerificationMutationVariables>;

/**
 * __useDeleteOneVerificationMutation__
 *
 * To run a mutation, you first call `useDeleteOneVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneVerificationMutation, { data, loading, error }] = useDeleteOneVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneVerificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneVerificationMutation, DeleteOneVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneVerificationMutation, DeleteOneVerificationMutationVariables>(DeleteOneVerificationDocument, options);
      }
export type DeleteOneVerificationMutationHookResult = ReturnType<typeof useDeleteOneVerificationMutation>;
export type DeleteOneVerificationMutationResult = Apollo.MutationResult<DeleteOneVerificationMutation>;
export type DeleteOneVerificationMutationOptions = Apollo.BaseMutationOptions<DeleteOneVerificationMutation, DeleteOneVerificationMutationVariables>;
export const WorkPermitsDocument = gql`
    query workPermits($filter: WorkPermitFilter, $paging: OffsetPaging, $sorting: [WorkPermitSort!]) {
  workPermits(filter: $filter, paging: $paging, sorting: $sorting) {
    totalCount
    nodes {
      id
      name
      startAt
      endAt
      state
      isPublished
      contract {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useWorkPermitsQuery__
 *
 * To run a query within a React component, call `useWorkPermitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkPermitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkPermitsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useWorkPermitsQuery(baseOptions?: Apollo.QueryHookOptions<WorkPermitsQuery, WorkPermitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkPermitsQuery, WorkPermitsQueryVariables>(WorkPermitsDocument, options);
      }
export function useWorkPermitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkPermitsQuery, WorkPermitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkPermitsQuery, WorkPermitsQueryVariables>(WorkPermitsDocument, options);
        }
export type WorkPermitsQueryHookResult = ReturnType<typeof useWorkPermitsQuery>;
export type WorkPermitsLazyQueryHookResult = ReturnType<typeof useWorkPermitsLazyQuery>;
export type WorkPermitsQueryResult = Apollo.QueryResult<WorkPermitsQuery, WorkPermitsQueryVariables>;
export const WorkPermitDocument = gql`
    query workPermit($id: ID!) {
  workPermit(id: $id) {
    id
    name
    startAt
    endAt
    state
    createdAt
    observations
    isPublished
    publishedAt
    contract {
      id
      name
      description
      contractState {
        id
      }
      contractUsers {
        id
        role
        user {
          id
          name
          phone
        }
      }
      contractConcessionOrganizations {
        id
        concessionOrganization {
          organizationType {
            id
            name
          }
          concession {
            id
            name
            logo
            controlCenters {
              name
              phone
            }
          }
          organization {
            id
            name
            tin
            address
            zipCode
          }
        }
      }
    }
    badges(sorting: {field: id, direction: ASC}) {
      id
      name
      company
      driver
      licensePlate
      state
    }
    checklist {
      id
      isCompleted
      checklistItems(sorting: {field: order, direction: ASC}) {
        id
        name
        isCompleted
        isOptional
      }
    }
  }
}
    `;

/**
 * __useWorkPermitQuery__
 *
 * To run a query within a React component, call `useWorkPermitQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkPermitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkPermitQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkPermitQuery(baseOptions: Apollo.QueryHookOptions<WorkPermitQuery, WorkPermitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkPermitQuery, WorkPermitQueryVariables>(WorkPermitDocument, options);
      }
export function useWorkPermitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkPermitQuery, WorkPermitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkPermitQuery, WorkPermitQueryVariables>(WorkPermitDocument, options);
        }
export type WorkPermitQueryHookResult = ReturnType<typeof useWorkPermitQuery>;
export type WorkPermitLazyQueryHookResult = ReturnType<typeof useWorkPermitLazyQuery>;
export type WorkPermitQueryResult = Apollo.QueryResult<WorkPermitQuery, WorkPermitQueryVariables>;
export const CreateOneWorkPermitDocument = gql`
    mutation createOneWorkPermit($input: CreateOneWorkPermitInput!) {
  createOneWorkPermit(input: $input) {
    id
  }
}
    `;
export type CreateOneWorkPermitMutationFn = Apollo.MutationFunction<CreateOneWorkPermitMutation, CreateOneWorkPermitMutationVariables>;

/**
 * __useCreateOneWorkPermitMutation__
 *
 * To run a mutation, you first call `useCreateOneWorkPermitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneWorkPermitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneWorkPermitMutation, { data, loading, error }] = useCreateOneWorkPermitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneWorkPermitMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneWorkPermitMutation, CreateOneWorkPermitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneWorkPermitMutation, CreateOneWorkPermitMutationVariables>(CreateOneWorkPermitDocument, options);
      }
export type CreateOneWorkPermitMutationHookResult = ReturnType<typeof useCreateOneWorkPermitMutation>;
export type CreateOneWorkPermitMutationResult = Apollo.MutationResult<CreateOneWorkPermitMutation>;
export type CreateOneWorkPermitMutationOptions = Apollo.BaseMutationOptions<CreateOneWorkPermitMutation, CreateOneWorkPermitMutationVariables>;
export const UpdateOneWorkPermitDocument = gql`
    mutation updateOneWorkPermit($input: UpdateOneWorkPermitInput!) {
  updateOneWorkPermit(input: $input) {
    id
  }
}
    `;
export type UpdateOneWorkPermitMutationFn = Apollo.MutationFunction<UpdateOneWorkPermitMutation, UpdateOneWorkPermitMutationVariables>;

/**
 * __useUpdateOneWorkPermitMutation__
 *
 * To run a mutation, you first call `useUpdateOneWorkPermitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneWorkPermitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneWorkPermitMutation, { data, loading, error }] = useUpdateOneWorkPermitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneWorkPermitMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneWorkPermitMutation, UpdateOneWorkPermitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneWorkPermitMutation, UpdateOneWorkPermitMutationVariables>(UpdateOneWorkPermitDocument, options);
      }
export type UpdateOneWorkPermitMutationHookResult = ReturnType<typeof useUpdateOneWorkPermitMutation>;
export type UpdateOneWorkPermitMutationResult = Apollo.MutationResult<UpdateOneWorkPermitMutation>;
export type UpdateOneWorkPermitMutationOptions = Apollo.BaseMutationOptions<UpdateOneWorkPermitMutation, UpdateOneWorkPermitMutationVariables>;
export const DeleteOneWorkPermitDocument = gql`
    mutation deleteOneWorkPermit($input: DeleteOneWorkPermitInput!) {
  deleteOneWorkPermit(input: $input) {
    id
  }
}
    `;
export type DeleteOneWorkPermitMutationFn = Apollo.MutationFunction<DeleteOneWorkPermitMutation, DeleteOneWorkPermitMutationVariables>;

/**
 * __useDeleteOneWorkPermitMutation__
 *
 * To run a mutation, you first call `useDeleteOneWorkPermitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneWorkPermitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneWorkPermitMutation, { data, loading, error }] = useDeleteOneWorkPermitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneWorkPermitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneWorkPermitMutation, DeleteOneWorkPermitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneWorkPermitMutation, DeleteOneWorkPermitMutationVariables>(DeleteOneWorkPermitDocument, options);
      }
export type DeleteOneWorkPermitMutationHookResult = ReturnType<typeof useDeleteOneWorkPermitMutation>;
export type DeleteOneWorkPermitMutationResult = Apollo.MutationResult<DeleteOneWorkPermitMutation>;
export type DeleteOneWorkPermitMutationOptions = Apollo.BaseMutationOptions<DeleteOneWorkPermitMutation, DeleteOneWorkPermitMutationVariables>;
export const WorkPermitFieldDocument = gql`
    query workPermitField($filter: WorkPermitFilter, $paging: OffsetPaging, $sorting: [WorkPermitSort!]) {
  workPermits(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
      contract {
        id
        name
        realStartDate
        realEndDate
      }
    }
  }
}
    `;

/**
 * __useWorkPermitFieldQuery__
 *
 * To run a query within a React component, call `useWorkPermitFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkPermitFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkPermitFieldQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useWorkPermitFieldQuery(baseOptions?: Apollo.QueryHookOptions<WorkPermitFieldQuery, WorkPermitFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkPermitFieldQuery, WorkPermitFieldQueryVariables>(WorkPermitFieldDocument, options);
      }
export function useWorkPermitFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkPermitFieldQuery, WorkPermitFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkPermitFieldQuery, WorkPermitFieldQueryVariables>(WorkPermitFieldDocument, options);
        }
export type WorkPermitFieldQueryHookResult = ReturnType<typeof useWorkPermitFieldQuery>;
export type WorkPermitFieldLazyQueryHookResult = ReturnType<typeof useWorkPermitFieldLazyQuery>;
export type WorkPermitFieldQueryResult = Apollo.QueryResult<WorkPermitFieldQuery, WorkPermitFieldQueryVariables>;
export const WorkspaceDocument = gql`
    query workspace {
  workspace {
    workspaceViews {
      name
      workspaceViewColumns {
        name
        visible
        order
      }
    }
  }
}
    `;

/**
 * __useWorkspaceQuery__
 *
 * To run a query within a React component, call `useWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceQuery(baseOptions?: Apollo.QueryHookOptions<WorkspaceQuery, WorkspaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceQuery, WorkspaceQueryVariables>(WorkspaceDocument, options);
      }
export function useWorkspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceQuery, WorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceQuery, WorkspaceQueryVariables>(WorkspaceDocument, options);
        }
export type WorkspaceQueryHookResult = ReturnType<typeof useWorkspaceQuery>;
export type WorkspaceLazyQueryHookResult = ReturnType<typeof useWorkspaceLazyQuery>;
export type WorkspaceQueryResult = Apollo.QueryResult<WorkspaceQuery, WorkspaceQueryVariables>;
export const ManageWorkspaceViewColumnDocument = gql`
    mutation manageWorkspaceViewColumn($input: ManageWorkspaceViewColumn!) {
  manageWorkspaceViewColumn(input: $input)
}
    `;
export type ManageWorkspaceViewColumnMutationFn = Apollo.MutationFunction<ManageWorkspaceViewColumnMutation, ManageWorkspaceViewColumnMutationVariables>;

/**
 * __useManageWorkspaceViewColumnMutation__
 *
 * To run a mutation, you first call `useManageWorkspaceViewColumnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageWorkspaceViewColumnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageWorkspaceViewColumnMutation, { data, loading, error }] = useManageWorkspaceViewColumnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageWorkspaceViewColumnMutation(baseOptions?: Apollo.MutationHookOptions<ManageWorkspaceViewColumnMutation, ManageWorkspaceViewColumnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageWorkspaceViewColumnMutation, ManageWorkspaceViewColumnMutationVariables>(ManageWorkspaceViewColumnDocument, options);
      }
export type ManageWorkspaceViewColumnMutationHookResult = ReturnType<typeof useManageWorkspaceViewColumnMutation>;
export type ManageWorkspaceViewColumnMutationResult = Apollo.MutationResult<ManageWorkspaceViewColumnMutation>;
export type ManageWorkspaceViewColumnMutationOptions = Apollo.BaseMutationOptions<ManageWorkspaceViewColumnMutation, ManageWorkspaceViewColumnMutationVariables>;
export const SetWorkspaceViewDocument = gql`
    mutation setWorkspaceView($input: SetWorkspaceView!) {
  setWorkspaceView(input: $input)
}
    `;
export type SetWorkspaceViewMutationFn = Apollo.MutationFunction<SetWorkspaceViewMutation, SetWorkspaceViewMutationVariables>;

/**
 * __useSetWorkspaceViewMutation__
 *
 * To run a mutation, you first call `useSetWorkspaceViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWorkspaceViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWorkspaceViewMutation, { data, loading, error }] = useSetWorkspaceViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetWorkspaceViewMutation(baseOptions?: Apollo.MutationHookOptions<SetWorkspaceViewMutation, SetWorkspaceViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetWorkspaceViewMutation, SetWorkspaceViewMutationVariables>(SetWorkspaceViewDocument, options);
      }
export type SetWorkspaceViewMutationHookResult = ReturnType<typeof useSetWorkspaceViewMutation>;
export type SetWorkspaceViewMutationResult = Apollo.MutationResult<SetWorkspaceViewMutation>;
export type SetWorkspaceViewMutationOptions = Apollo.BaseMutationOptions<SetWorkspaceViewMutation, SetWorkspaceViewMutationVariables>;