/* import { Typography } from "@mui/material"
 * import { FC } from "react"
 * import Config from "../../services/config"
 * import { EmptyProps } from "../../types/types"
 * 
 * const Empty: FC<EmptyProps> = props => {
 *     const { message = Config.NO_RESULTS_TEXT, variant = "body1", color = "secondary" } = props
 * 
 *     return (
 *         <Typography variant={variant} color={color}>
 *             {message}
 *         </Typography>
 *     )
 * }
 * 
 * export default Empty */

import { Typography } from "@mui/material"
import { FC } from "react"
import Config from "../../services/config"
import { EmptyProps } from "../../types/types"

const Empty: FC<EmptyProps> = props => {
    const { message = Config.NO_RESULTS_TEXT, variant = "body1", color = "secondary" } = props

    return (
        <Typography
            variant={variant}
            color={color}
            dangerouslySetInnerHTML={{ __html: message }}
        />
    )
}

export default Empty
