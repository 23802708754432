import { Fade, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useMenuItems from "../../hooks/useMenuItems";
import { useAuth } from "../../services/auth";
import config from "../../services/config";
import Show from "../Show/Show";
import { MenuItem } from "../Sidemenu/Sidemenu";
import styles from './Welcome.module.scss';

const WelcomeAction = (props: any) => {
    const { menuItem } = props
    const { name, icon, route } = menuItem

    const navigate = useNavigate()

    const handleClick = () => {
        navigate(route)
    }

    return (
        <Grid container direction="column" alignItems="center" className={styles.WelcomeAction} onClick={handleClick}>
            <div>{icon}</div>
            <Typography variant="h4" style={{ textAlign: "center" }}>
                {name}
            </Typography>
        </Grid >
    )
}

const Welcome = () => {

    const auth = useAuth()
    const userName = auth?.user?.name
    const concessionName = auth?.user?.concession?.name
    const menuItems = useMenuItems()

    return (
        <Fade in={userName} timeout={config.DEFAULT_FADE_TIMEOUT}>
            <Grid container justifyContent="center" alignContent="center" className={styles.Wrapper}>
                <Grid container direction="column" justifyContent="center" alignItems="center" className={styles.Welcome}>
                    <Typography variant="h1" color="primary" gutterBottom>
                        Olá, <strong>{userName}</strong>, bem-vindo(a)
                    </Typography>
                    <Typography variant="h1" color="primary" gutterBottom>
                        ao <strong>GIN</strong> e à concessão <strong>{concessionName}</strong>
                    </Typography>

                    <Show on={!!menuItems?.length}>

                        <div className={styles.WelcomeActions}>

                            <Grid container justifyContent="center" >
                                <Typography variant="h4" className={styles.ChooseYourOption}>
                                    Escolha uma das seguintes opções
                                </Typography>
                            </Grid>


                            <Grid container direction="row" justifyContent="space-around" alignItems="center">

                                {menuItems?.map((menuItem: MenuItem) => (
                                    <WelcomeAction menuItem={menuItem} key={menuItem?.name} />
                                ))}
                            </Grid>

                        </div>
                    </Show>
                </Grid>
            </Grid>
        </Fade>
    )
}

export default Welcome;
